/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import appConfig from '../../config/app';

import menu from './menu';
import { SUPER_ADMIN } from '../../config/user-roles';

const logo = require('../../assets/img/maxlogo.svg');

const { Sider } = Layout;

export class SideMenu extends Component {
  render() {
    const member = this.props.member.toJS();

    const list = menu.side_menu.map((item, index) => {
      /** Only check for permission if roles has been assigned to this menu */
      /**
       * Get roles that can see this Menu, Role name are used as key and values
       * are boolean.
       * Example:
       *
       * {
       *   icon: 'fas fa-trophy',
       *   link: `/${prefix}/reward`,
       *   name: 'Reward',
       *   // If we don't have roles then this menu will be hidden by default
       *   roles: {
       *     super_admin: true,  // Super Admin can see
       *     admin: true,        // Admin can see
       *     purchase: true,     // Purchase role can see
       *     call_center: false, // Call center cannot see
       *   },
       * },
       * */
      const currentUserRole = (member.user && member.user.profile && member.user.profile.role_name) || '';

      if (item.roles) {
        const isSuperAdminRole = currentUserRole === SUPER_ADMIN;

        /**
         * Check if roles has been assigned to menu or not. If it has not been
         * assigned then that menu will be shown by default
         */
        const roleHasPermission = item.roles && item.roles[currentUserRole] === true;
        const showRootMenu = isSuperAdminRole || roleHasPermission;

        if (!showRootMenu) {
          return null;
        }
      }

      if (item.submenu && item.submenu.length > 0) {
        const submenu = item.submenu.map((it, i) => {
          const canAccess = it.roles && it.roles[currentUserRole] === true;

          if (!it.roles || canAccess) {
            return (
              <Menu.Item key={`${index}-${i}`}>
                <Link to={it.link}>{it.name}</Link>
              </Menu.Item>
            );
          }
          return '';
        });
        return (
          <Menu.SubMenu
            key={index}
            title={
              <span>
                <i className="anticon">
                  <i className={item.icon} />
                </i>
                <span>{item.name}</span>
              </span>
            }
          >
            {submenu}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item key={index}>
          <Link to={item.link}>
            <i className="anticon">
              <i className={item.icon} />
            </i>
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      );
    });

    return (
      <Sider
        className="sider"
        trigger={null}
        collapsible
        collapsed={!this.props.openning}
        width={220}
        collapsedWidth={90}
      >
        <Menu theme="light" mode="inline">
          <div>
            <Logo src={logo} onClick={this.props.toggle} />
          </div>
          <Menu.Divider />
          {list}
          <Version>
            version {appConfig.version}
          </Version>
        </Menu>
      </Sider>
    );
  }
}

const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-height: 100px;
  padding: 20px;
`;

const Version = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-height: 100px;
  text-align: center;
  color: rgba(0, 0, 0, 0.40);
  margin-top: 30px;
`;

export default inject('member')(observer(SideMenu));
