import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form, InputNumber, Input } from 'antd';

import { helper } from '../../utils/helper';
import ButtonSection from './ButtonSection';

export class WrappedRandomCode extends Component {
  onGenerate = async (evt) => {
    const { form } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
    } catch (e) {
      return;
    }

    const { onGenerate } = this.props;
    if (onGenerate) onGenerate({ type: 'multiple', data: this.config.random });
  };

  onExport = async (evt) => {
    const { onExport } = this.props;
    if (onExport) onExport();
  };

  genExample = (params) => {
    const { len, prefix = '', postfix = '', character = '' } = params;
    const code = helper.random({ len, prefix, postfix, character });
    return code;
  };

  onLen = async (val) => {
    this.config.random.len = val;
    this.setDoc(this.config);
  };

  onChange = async (evt) => {
    const { name, value } = evt.target;
    this.config.random[name] = value;
    this.setDoc(this.config);
  };

  setDoc = async (config) => {
    const code = this.genExample(config.random);
    config.random.code = code;
    this.props.gen.setConfig(config);
  };

  render() {
    const { config } = this.props.gen.toJS();
    this.config = config;

    const { code, len, prefix, postfix, character } = config.random;
    const { form, exportor, generator } = this.props;
    const { getFieldDecorator } = form;
    const required = true;
    // const initialValues = this.props.initialValues.toJS()

    console.log('this.props.initialValues', this.props.initialValues);

    return (
      <div>
        <Form hideRequiredMark>
          <Row>
            <Col span={3}>
              <Form.Item label="Single code length">
                {getFieldDecorator('len', {
                  initialValue: this.props.initialValues && this.props.initialValues.len ? this.props.initialValues.len : len,
                  rules: [{ required, message: 'Please single code length!' }],
                })(<InputNumber min={1} max={45} style={css.int} onChange={this.onLen} disabled={!generator} />)}
              </Form.Item>
            </Col>

            <Col span={3} offset={1}>
              <Form.Item label="Code prefix">
                {getFieldDecorator('prefix', {
                  initialValue: this.props.initialValues && this.props.initialValues.prefix ? this.props.initialValues.prefix : prefix,
                })(<Input name="prefix" onChange={this.onChange} disabled={!generator} />)}
              </Form.Item>
            </Col>

            <Col span={3} offset={1}>
              <Form.Item label="Code postfix">
                {getFieldDecorator('postfix', {
                  initialValue: this.props.initialValues && this.props.initialValues.postfix ? this.props.initialValues.postfix : postfix,
                })(<Input name="postfix" onChange={this.onChange} disabled={!generator} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item label="Character set">
                {getFieldDecorator('character', {
                  initialValue: this.props.initialValues && this.props.initialValues.character ? this.props.initialValues.character : character,
                  rules: [{ required, message: 'Please fill character!' }],
                })(<Input name="character" onChange={this.onChange} disabled={!generator} />)}
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <ButtonSection
                exportor={exportor}
                generator={generator}
                onExport={this.onExport}
                onGenerate={this.onGenerate}
                code={code}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const css = {
  int: { width: '100%' },
  btn: {
    borderRadius: '18px',
    height: '36px',
    marginTop: '10%',
  },
};

const GenRandomCode = Form.create({})(WrappedRandomCode);
export default inject('gen')(observer(GenRandomCode));
