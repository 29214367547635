import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

function SaveBtn({ text = 'SAVE', ...props }) {
  return (
    <StyledSaveBtn icon="save" {...props}>
      {text}
    </StyledSaveBtn>
  );
}

const StyledSaveBtn = styled(Button)`
  text-align: center;
  height: 48px;
  background-color: #e77822;
  border-color: #e77822;
  outline-color: #e77822;
  color: #fff;
  width: 100%;

  &:active {
    background-color: transparent;
    border-color: #e77822;
    color: #e77822;
  }

  &:hover {
    background-color: #ffb732;
    border-color: #ffb732;
    color: #fff;
  }

  &:focus {
    background-color: #d95e00;
    border-color: #d95e00;
    color: #fff;
  }
`;

export default SaveBtn;
