import React from 'react';
import { inject, observer } from 'mobx-react';

import { Form, Modal, Input } from 'antd';
import notify from '../../../utils/notify';
import { Btn } from '../../../components/button';

class ChangePhoneNoForm extends React.Component {
  handleOnCancel = () => {
    this.props.form.resetFields();
    this.props.user.setShowChangePhoneForm(false);
  };

  render() {
    const { getFieldDecorator, getFieldsError, validateFields } = this.props.form;
    const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);

    const formInput = (
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            const { phoneNo } = await validateFields();
            if (phoneNo[0] !== '0') {
              notify.error({
                title: 'Change phone number failed',
                message: 'Invalid phone number',
              });
              return;
            }
            if (phoneNo.length !== 10) {
              notify.error({
                title: 'Change phone number failed',
                message: 'Phone number must have 10 digit',
              });
              return;
            }
            if (isNaN(phoneNo)) {
              notify.error({
                title: 'Change phone number failed',
                message: 'Phone number must contain only number',
              });
              return;
            }
            await this.props.user.changePhoneNo(phoneNo);
            notify.success({
              title: 'Change phone success',
            });
            this.props.user.resetOtpProcess();
          } catch (error) {
            notify.error({
              title: 'Change phone number failed',
              message: error.message,
            });
          }
        }}
      >
        <Form.Item style={{ marginBottom: '0px' }} label="NEW PHONE NO." colon={false}>
          {getFieldDecorator('phoneNo', {
            rules: [{ required: true, message: 'phone number is required' }],
          })(<Input placeholder="Please enter new phone number" />)}
        </Form.Item>
        <Form.Item>
          <Btn text="CHANGE PHONE" htmlType="submit" disabled={hasErrors(getFieldsError())} />
        </Form.Item>
      </Form>
    );

    return (
      <Modal
        title="CHANGE PHONE NO:"
        visible={this.props.user.showChangePhoneForm}
        onCancel={this.handleOnCancel}
        destroyOnClose
        footer={null}
      >
        {formInput}
      </Modal>
    );
  }
}

const WrappedChangePhoneNoForm = Form.create({ name: 'change_phone_no_form' })(observer(ChangePhoneNoForm));

export default inject('user')(WrappedChangePhoneNoForm);
