import React, { Component } from 'react';
import styled from 'styled-components';
import { Form, Row, Col } from 'antd';

import { InputInt, InputPoint, TimePicker } from '../../../../components/forms';
import { RemoveBtn } from '../../../../components/button';
import { timer } from '../../../../utils/timer';

const { Item } = Form;

export default class GroupItem extends Component {
  onChange = (val, name) => {
    const { item = {}, index, onChange } = this.props;
    item[name] = val;

    if (onChange) onChange(index, item);
  };

  onRemove = () => {
    const { onRemove, index = 0 } = this.props;
    if (onRemove) onRemove(index);
  };

  render() {
    const { item = {}, validate = {} } = this.props;
    const span = 4;

    return (
      <Page>
        <Row>
          <Col span={span}>
            <Item
              label="Start Time"
              validateStatus={validate.start_at ? 'error' : ''}
              help={validate.start_at ? 'Please change start time!' : ''}
            >
              <TimePicker name="start_at" value={timer.toDatetime(item.start_at)} onChange={this.onChange} />
            </Item>
          </Col>

          <Col span={span} offset={1}>
            <Form.Item
              label="End Time"
              validateStatus={validate.end_at ? 'error' : ''}
              help={validate.end_at ? 'Please change end time!' : ''}
            >
              <TimePicker name="end_at" value={timer.toDatetime(item.end_at)} onChange={this.onChange} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={span}>
            <Item label="Check-In">
              <InputPoint name="eating_check_in" value={item.eating_check_in} onChange={this.onChange} />
            </Item>
          </Col>

          <Col span={span} offset={1}>
            <Form.Item label="Take Photo">
              <InputPoint name="eating_take_photo" value={item.eating_take_photo} onChange={this.onChange} />
            </Form.Item>
          </Col>

          <Col span={span} offset={1}>
            <Form.Item label="Share To Social">
              <InputPoint name="eating_share_to_social" value={item.eating_share_to_social} onChange={this.onChange} />
            </Form.Item>
          </Col>

          <Col span={span} offset={1}>
            <Item label="Max Per Period">
              <InputInt name="max_period" value={item.max_period} text="time" onChange={this.onChange} />
            </Item>
          </Col>

          <Col span={span} offset={1}>
            <Item label=" ">
              <RemoveBtn onClick={this.onRemove} />
            </Item>
          </Col>
        </Row>
      </Page>
    );
  }
}

const Page = styled.div`
  padding: 20px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #d8d8d8;
  margin-bottom: 5px;
`;
