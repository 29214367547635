export const BadgeMap = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
};

export const IsWhitelistMap = {
  false: 'No',
  true: 'Yes',
};

export const IsAgentMap = {
  false: 'No',
  true: 'Yes',
};

export const CategroryMap = {
  active: 'Active Activity',
  passive: 'Passive Activity',
  reward: 'Reward',
  challenge: 'Challenge',
  coupon: 'Coupon',
  expire: 'Expired',
  manual: 'Manual',
  plancode: 'Plancode',
};

export const CategroryMapWithoutActivePassive = {
  activity: 'Activity',
  reward: 'Reward',
  challenge: 'Challenge',
  coupon: 'Coupon',
  expire: 'Expired',
  manual: 'Manual',
  plancode: 'Plancode',
};

export const ActivityCateMap = {
  active: 'Active',
  passive: 'Passive Activity',
};

export const ActivityGroupMap = {
  global: 'Global',
  activity_share: 'Activity Share',
  'active activity': 'Active Activity',
  check_in_eating: 'Check In Eating',
  check_in_traveling: 'Check In Traveling',
  reward: 'Reward',
  check_in_highlight: 'Check In Highlight of the Week',
};

export const ActivityCodeMap = {
  welcome: 'Welcome',
  birthday: 'Birthday',
  email_verification: 'Email Verification',
  fwd_customer: 'Fwd Customer',
  steps: 'Steps',
  running: 'Running',
  swimming: 'Swimming',
  cycling: 'Cycling',
  activity_share_steps: 'Activity Share Steps',
  activity_share_swimming: 'Activity Share Swimming',
  activity_share_running: 'Activity Share Running',
  activity_share_cycling: 'Activity Share Cycling',
  share_reward: 'Share Reward',
  traveling_check_in: 'Traveling Check-in',
  traveling_share_to_social: 'Traveling Share To Social',
  traveling_take_photo: 'Traveling Take Photo',
  eating_check_in: 'Dining Check-in',
  eating_share_to_social: 'Dining Share To Social',
  eating_take_photo: 'Dining Take Photo',
  poll_and_survey: 'Poll and Survey',
  read_knowledge: 'Read knowladge',
  share_knowledge: 'Share knowladge',
  referrer: 'Referrer',
  invitee: 'Invitee',
  highlight_sharing: 'Highlight of the Week Sharing Points',
};

export const StatusMap = {
  active: 'Active',
  inactive: 'Inactive',
};

export const RewardStatusMap = {
  draft: 'Draft',
  active: 'Active',
  inactive: 'Inactive',
};

export const ActivityTypeMap = {
  electronic: 'Electronic',
  physical: 'Physical',
};

export const IsBadgeMap = {
  false: 'No',
  true: 'Yes',
};

export const IsTierMap = {
  false: 'No',
  true: 'Yes',
};
