import React from 'react';
import styled from 'styled-components';
import { format } from '../../utils/format';

export default function IntCol({ minus = false, value = 0, style = {} }) {
  return <Col style={style}>{(minus ? '-' : '') + format.toDigi(value)}</Col>;
}

const Col = styled.div`
  text-align: right;
`;
