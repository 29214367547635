import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Table, Divider, Icon, Button, Avatar } from 'antd';
import { upperFirst } from 'lodash';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, DateCol, IntCol, DisplayStatus } from '../../components/common';
import ChallengeFilter from './ChallengeFilter';

const activities = {
  steps: 'Steps',
  running: 'Running',
  cycling: 'Cycling',
  swimming: 'Swimming',
  dining: 'Eating Check In',
  traveling: 'Traveling Check In',
};

class ChallengeList extends Component {
  columns = [
    {
      title: 'IMAGE',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value) => <Avatar style={{ verticalAlign: 'middle' }} size="large" shape="square" src={value} />,
    },
    {
      title: 'ID',
      dataIndex: 'challengeId',
      key: 'challengeId',
    },
    {
      title: 'NAME',
      dataIndex: 'titleEn',
      key: 'titleEn',
      width: '150px',
    },
    {
      title: 'CATEGORY',
      dataIndex: 'activityCategory',
      key: 'activityCategory',
      render: (value) => upperFirst(value),
    },
    {
      title: 'TYPE',
      dataIndex: 'activityCode',
      key: 'activityCode',
      render: (value) => activities[value],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (value) => <DisplayStatus type={1} status={value} />,
    },
    {
      title: 'SEQ',
      dataIndex: 'countJoin',
      key: 'countJoin',
      render: (value) => <IntCol value={value} />,
    },
    {
      title: 'WIN',
      dataIndex: 'countCollected',
      key: 'countCollected',
      render: (value) => <IntCol value={value} />,
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      render: (value) => <DateCol value={value} />,
    },
    {
      title: 'DATE DISPLAY',
      dataIndex: 'startAt',
      key: 'startAt',
      width: '120px',
      render: (value) => <DateCol value={value} />,
    },
    {
      title: 'EXPIRE',
      dataIndex: 'endAt',
      key: 'endAt',
      width: '120px',
      render: (value) => <DateCol value={value} />,
    },
    {
      title: 'ACTION',
      key: 'operation',
      render: (value, { challengeId }) => {
        return (
          <span>
            <Link to={`/office/challenges/${challengeId}/edit`}>Edit</Link>
            <Divider type="vertical" />
            <Link to={`/office/challenges/${challengeId}/users`}>User</Link>
          </span>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.challenge.getList();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    this.props.challenge.getList({ page });
  };

  render() {
    const { list, loading, pagination } = this.props.challenge;
    return (
      <HeaderMenu title="Challenge list">
        <Page style={css.page}>
          <ChallengeFilter />
          <Table
            rowKey={(record) => record.challengeId}
            dataSource={list}
            columns={this.columns}
            loading={loading}
            pagination={pagination}
            onChange={this.handleTableChange}
            bordered
          />
          <Button style={css.plusBtn} type="primary" shape="circle">
            <Link to="/office/challenges/create">
              <Icon type="plus" />
            </Link>
          </Button>
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  plusBtn: {
    width: '64px',
    height: '64px',
    bottom: '20px',
    right: '20px',
    fontSize: '32px',
    zIndex: '100',
    position: 'fixed',
  },
};

export default inject('challenge')(observer(ChallengeList));
