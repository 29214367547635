import moment from 'moment';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { format } from '../utils/format';

const MAP_CODE_TO_CAT = {
  steps: 'active',
  running: 'active',
  swimming: 'active',
  cycling: 'active',
  eating_check_in: 'passive',
  traveling_check_in: 'passive',
};

const MAP_CODE_TO_DISPLAY = {
  steps: 'steps',
  running: 'running',
  swimming: 'swimming',
  cycling: 'cycling',
  eating_check_in: 'dining',
  traveling_check_in: 'traveling',
};
const INIT_STATE = {
  list: [],
  loading: false,
  detail: null,
  venue: null,
  userList: [],
  pagination: {},
  search: null,
  params: null,
  activityLog: {
    list: [],
    pagination: {},
    loading: false,
  },
};
class ChallengeStore extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  async getList(params = {}) {
    this.loading = true;
    this.params = {
      ...this.params,
      ...params,
    };
    const { title, category, activityCode, status, timeType, startDate, endDate } = this.params;
    const page = params.page || 1;
    const url = `${config.api.max}/v1/office/challenges`;
    const qs = {
      page,
    };
    if (title) {
      qs.title = title;
    }
    if (category !== 'all') {
      qs.category = category;
    }
    if (activityCode !== 'all') {
      qs.activityCode = activityCode;
    }
    if (status !== 'all') {
      qs.status = status;
    }
    if (timeType !== 'all') {
      qs.timeType = timeType;
    }
    if (startDate) {
      qs.startDate = startDate;
    }
    if (endDate) {
      qs.endDate = endDate;
    }

    const res = await http.get(url, { token: true, qs });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }
    const { data, count } = res.body;
    this.list = this._transformList(data);
    this.pagination.total = count;
    this.pagination.current = page;
    this.pagination.position = 'both';
    this.pagination.showQuickJumper = true;
    this.pagination.showTotal = (total) => `Total ${format.toDigi(total)}`;
    this.loading = false;
  }

  _transformDetail(data) {
    if (data.rewardType === 'point') {
      delete data.rewardChallenge;
      data.rewardChallengeId = null;
    }

    if (data.dateRanges && data.dateRanges.length) {
      const [startAt, endAt] = data.dateRanges;
      data.startAt = startAt;
      data.endAt = endAt;
    }
    return data;
  }

  _transformList(list) {
    return list.map((current) => {
      const activityCategory = MAP_CODE_TO_CAT[current.activityCode];
      const activityCode = MAP_CODE_TO_DISPLAY[current.activityCode];
      return {
        ...current,
        activityCategory,
        activityCode,
      };
    });
  }

  _asJS(data) {
    const { startAt, endAt, redeemExpiredAt, createdAt, updatedAt } = data;
    const dateRanges = new Array(2);

    if (startAt) {
      dateRanges[0] = moment(startAt);
    }

    if (endAt) {
      dateRanges[1] = moment(endAt);
    }

    return {
      ...data,
      redeemExpiredAt: redeemExpiredAt ? moment(redeemExpiredAt) : null,
      createdAt: createdAt ? moment(createdAt) : null,
      updatedAt: updatedAt ? moment(updatedAt) : null,
      dateRanges,
    };
  }

  async _fetchUserList(challengeId) {
    const items = [];
    const limit = 1000;
    const { total } = this.toJS().pagination;
    for (let page = 1; items.length < total; page++) {
      const url = `${config.api.max}/v1/office/challenges/${challengeId}/users`;
      const qs = {
        ...this.search,
        page,
        limit,
      };
      const resp = await http.get(url, { qs, token: true });
      items.push(...resp.body.data);
    }
    return items;
  }

  async create(data) {
    const url = `${config.api.max}/v1/office/challenges`;
    const json = this._transformDetail(data);
    const resp = await http.post(url, { token: true, json });

    return resp.body;
  }

  async get(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/challenges/${id}`;
    const resp = await http.get(url, { token: true });
    this.detail = this._asJS(resp.body);
    this.loading = false;
  }

  async update(id, data) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/challenges/${id}`;
    const json = this._transformDetail(data);
    const res = await http.put(url, { token: true, json });
    if (res.statusCode !== 200) {
      this.loading = false;
      error.launch({ message: res.body.message });
      return;
    }
    this.loading = false;
  }

  async getVenueById(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/foursquare/venues/${id}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      this.loading = false;
      error.launch({ message: res.body.message });
      return;
    }
    this.venue = res.body;
    this.loading = false;
  }

  async getUserList(challengeId, opts = {}) {
    this.loading = true;
    const page = opts.page || 1;
    const url = `${config.api.max}/v1/office/challenges/${challengeId}/users`;
    const qs = {
      ...this.search,
      page,
    };
    if (opts.status) {
      qs.status = opts.status;
    }

    if (opts.startAt) {
      qs.startAt = opts.startAt;
    }

    if (opts.endAt) {
      qs.endAt = opts.endAt;
    }

    const res = await http.get(url, { qs, token: true });
    if (res.statusCode !== 200) {
      this.loading = false;
      console.error(res.body.message);
      return;
    }
    const { data, count } = res.body;
    this.userList = data;
    this.pagination.total = count;
    this.pagination.current = page;
    this.loading = false;
  }

  async searchUserList(challengeId, opts = {}) {
    this.search = opts;
    this.getUserList(challengeId, opts);
  }

  async exportUserListToExcel(challengeId) {
    this.loading = true;
    const items = await this._fetchUserList(challengeId);
    this.loading = false;
    return items;
  }

  async updateShippingAddress(challengeId, items) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/challenges/${challengeId}/users`;

    const resp = await http.put(url, { json: items, token: true });
    this.loading = false;
    if (resp.statusCode !== 200) {
      console.error(resp.body.message);
      error.launch({ message: resp.body.message });
    }
  }

  reloadUserList(id) {
    this.reset();
    this.getUserList(id);
  }

  reset() {
    Object.keys(INIT_STATE).forEach((key) => (this[key] = INIT_STATE[key]));
  }

  async getuserActivityList(userId, date = {}, page = 1, name = '', type = '', status = '') {
    const { start, end } = date;
    const qs = { userId, page, name, limit: 10, type, status };
    const url = `${config.api.max}/v1/office/challenges/userActivity`;
    if (start && end) {
      qs.start = start.format('YYYY-MM-DD');
      qs.end = end.format('YYYY-MM-DD');
    }
    this.activityLog.loading = true;
    const res = await http.get(url, { token: true, qs });
    this.activityLog.loading = false;
    if (res.statusCode !== 200) {
      return;
    }
    const { data, count } = res.body;
    this.activityLog.list = data;
    this.activityLog.pagination = {
      total: count,
      current: page,
    };
  }
}

const challengeStore = new ChallengeStore();

export default challengeStore;
