import React from 'react';
import { inject, observer } from 'mobx-react';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';
import StaffForm from '../../components/forms/StaffForm';

const AddStaffPage = (props) => {
  const userRole = props.member.getRole();

  return (
    <HeaderMenu title="STAFF LIST">
      <Page style={css.page}>
        <Loading loading={false} />
        <StaffForm currentStaff={{}} backofficeUserRole={userRole} />
      </Page>
    </HeaderMenu>
  );
};

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('member')(observer(AddStaffPage));
