import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js';
import { Row, Col } from 'antd';
import { Title } from '../../../components/common';

const LineGraph = (props) => {
  const { title, labels, data } = props;
  const canvasRef = useRef(null);
  const cRef = useRef(null);
  const [chartData, setChartData] = useState([]);

  Chart.scaleService.updateScaleDefaults('linear', { ticks: { min: 0 } });
  Chart.defaults.global.defaultFontColor = '#666';
  Chart.defaults.global.defaultFontSize = 16;

  useEffect(() => {
    /** @type ChartData[] */
    const gData = [];
    const colors = ['#a40e4c', '#f49d6e', '#acc3a6', '#2c2c54', '#f48d33', '#000054'];
    for (let i = 0; i < data.length; i++) {
      /**
       * @type ChartData
       */
      const d = {
        steppedLine: false,
        label: data[i].legend,
        fill: false,
        backgroundColor: colors[i],
        borderColor: colors[i],
        borderWidth: 0,
        data: data[i].list,
        lineTension: 0.05,
      };

      gData.push(d);
    }

    setChartData(gData);
  }, [data]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    cRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels,
        datasets: chartData,
      },
      options: {
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        legend: {
          display: true,
          position: 'top',
          onClick: null,
          onHover: null,
          labels: {
            boxWidth: 20,
            fontSize: 18,
            padding: 30,
          },
        },
        layout: {
          padding: {
            left: 30,
            right: 30,
            top: 50,
            bottom: 30,
          },
        },
        scales: {
          scaleLabel: {
            display: true,
            fontColor: '#00ff00',
            padding: 10,
          },
          yAxes: [
            {
              type: 'linear',
              position: 'left',
              gridLines: { display: true }, // Hide gridlines
              scaleLabel: {
                display: true,
                fontSize: 20,
                labelString: 'User Counts',
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                scaleLabel: { display: true, fontColor: '#ff0000' },
              },
            },
          ],
          xAxes: [
            {
              gridLines: { display: true },
              ticks: { beginAtZero: true },
              scaleLabel: {
                display: true,
                fontSize: 20,
                labelString: 'Date',
              },
              position: 'bottom',
              type: 'time',
              distribution: 'series',
              time: {
                displayFormats: {
                  day: 'DD MMM YY',
                  month: 'MMM YY',
                  hour: 'DD MMM YY HH:00',
                },
                stepSize: 1,
                // @see https://www.chartjs.org/docs/latest/axes/cartesian/time.html#time-units
                unit: 'hour',
                minUnit: 'hour',
              },
            },
          ],
        },
      },
    });

    /* eslint-disable */
  }, [chartData]);

  return (
    <Row>
      <StyledRow type="flex" align="middle" justify="space-between">
        <Title>{title}</Title>
      </StyledRow>
      <Row>
        <Col>
          <Canvas id="myChart" ref={canvasRef}>
            <p>Your browser do not support canvas.</p>
          </Canvas>
        </Col>
      </Row>
    </Row>
  );
};

const StyledRow = styled(Row)`
  background: #fff;
  padding: 10px 10px 0 10px;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 500px;
  min-height: 300px;
  max-height: 500px;
  padding: 10px;
  background-color: white;
`;

export default LineGraph;
