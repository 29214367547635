import React, { useState, useImperativeHandle, forwardRef } from 'react';

import { GroupItem, DropdownList } from '../../../components/forms';
import { Cols } from '../../../components/common';

const FilterMoreItem = (props, ref) => {
  const [type, setType] = useState('all');
  const [status, setStatus] = useState('all');
  const [singleChallenge, setSingleChallenge] = useState('all');
  const [activeInactive, setActiveInactive] = useState('all');

  const challengeActiveInactiveMenu = [
    { name: 'All', value: 'all' },
    { name: 'Active', value: 'active' },
    { name: 'Inactive', value: 'inactive' },
  ];

  const challengeTypeMenu = [
    {
      name: 'All',
      value: 'all',
    },
    {
      name: 'Cycling',
      value: 'cycling',
    },
    {
      name: 'Eating check in',
      value: 'eating_check_in',
    },
    {
      name: 'Running',
      value: 'running',
    },
    {
      name: 'Steps',
      value: 'steps',
    },
    {
      name: 'Swimming',
      value: 'swimming',
    },
    {
      name: 'Traveling check in',
      value: 'traveling_check_in',
    },
  ];

  const challengeStatusMenu = [
    {
      name: 'All',
      value: 'all',
    },
    {
      name: 'Cancel',
      value: 'cancelled',
    },
    {
      name: 'Collect',
      value: 'collected',
    },
    {
      name: 'Complete',
      value: 'completed',
    },
    {
      name: 'Join',
      value: 'inprogress',
    },
  ];

  useImperativeHandle(ref, () => ({
    get: () => {
      return getData();
    },
  }));

  const getData = () => {
    return {
      type,
      status,
      singleChallenge,
      activeInactive,
    };
  };

  const onChangeChallengeType = (value) => {
    props.fetchChoices(value);
    setType(value);
    setSingleChallenge('all');
  };

  const onChangeChallengeStatus = (value) => {
    setStatus(value);
  };

  const onChangeSingleChallenge = (value) => {
    setSingleChallenge(value);
  };

  const onChangeActiveInactive = (value) => {
    setActiveInactive(value);
  };

  return (
    <Cols span={24} style={{ padding: '0 4px' }}>
      <Cols span={5}>
        <GroupItem label="Challenge Type">
          <DropdownList
            name="challengeType"
            menus={challengeTypeMenu}
            value={type}
            allowClear={false}
            onChange={onChangeChallengeType}
          />
        </GroupItem>
      </Cols>
      {props.challenges && (
        <Cols span={5}>
          <GroupItem label="Challenges">
            <DropdownList
              name="viewSingleChallenge"
              menus={[{ name: 'All', value: 'all' }].concat(props.challenges.toJS())}
              allowClear={false}
              onChange={onChangeSingleChallenge}
              value={singleChallenge}
              loading={props.loading}
            />
          </GroupItem>
        </Cols>
      )}
      <Cols span={3}>
        <GroupItem label="Status">
          <DropdownList
            name="challengeStatus"
            menus={challengeStatusMenu}
            allowClear={false}
            onChange={onChangeChallengeStatus}
            value={status}
          />
        </GroupItem>
      </Cols>
      <Cols span={3}>
        <GroupItem label="Active/Inactive">
          <DropdownList
            name="challengeActiveInactive"
            menus={challengeActiveInactiveMenu}
            allowClear={false}
            onChange={onChangeActiveInactive}
            value={activeInactive}
          />
        </GroupItem>
      </Cols>
    </Cols>
  );
};

export default forwardRef(FilterMoreItem);
