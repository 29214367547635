import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

class AppPageStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      list: [],
    });
  }

  _transformRewardList(list) {
    return list.map(({ reward_id: id, name_en: name }) => {
      return {
        id,
        name,
      };
    });
  }

  _transformChallengeList(list) {
    return list.map(({ challengeId: id, titleEn: name }) => {
      return {
        id,
        name,
      };
    });
  }

  async getRewardList() {
    this.loading = true;
    const url = `${config.api.max}/v1/office/reward?status=active,inactive&&order=sequence`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const display = res.body.list.filter((l) => l.sequence > 0);

    this.list = this._transformRewardList(display);
    this.loading = false;
  }

  async getChallengeList() {
    this.loading = true;
    const url = `${config.api.max}/v1/office/challenges`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    this.list = this._transformChallengeList(res.body.data);
  }
}

const appPageStore = new AppPageStore();

export default appPageStore;
