import moment from 'moment';

export class SelectTime {
  getMenu() {
    const menu = [
      { name: 'Today', value: 'Today' },
      { name: 'Yesterday', value: 'Yesterday' },
      { name: 'This Month', value: 'This Month' },
      { name: 'This Year', value: 'This Year' },
      { name: 'Custom Date', value: 'Custom Date' },
    ];

    return menu;
  }

  getValue({ type }) {
    let start;
    let end;
    end = moment();
    start = end.clone();
    switch (type) {
      case 'Yesterday':
        start = start.subtract(1, 'days');
        end = start.clone();
        break;
      case 'This Month':
        start = moment()
          .startOf('month')
          .startOf('day');
        break;
      case 'This Year':
        start = moment()
          .startOf('year')
          .startOf('day');
        break;
      default:
    }

    return { start, end };
  }
}

export const selectTime = new SelectTime();
export default selectTime;
