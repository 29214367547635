import React, { Component } from 'react';

import ConfigBase from './MarkdownBase';

export default class ConfigConsentType extends Component {
  render() {
    return <ConfigBase title="CONSENT CONFIGURATION" type="consent" menus={menus} />;
  }
}

const menus = [{ name: 'Consent TH', value: 'th' }, { name: 'Consent ENG', value: 'eng' }];
