import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker } from 'antd';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SurveyScheduleBackButton from './SurveyScheduleBackButton';
import notify from '../../../utils/notify';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, Loading, SaveSection } from '../../../components/common';
import './survey-template.css';

const { TextArea } = Input;

const datepickerFormat = 'YYYY-MM-DD HH:mm';

const SurveyTemplateAdd = (props) => {
  const history = useHistory();
  const currentTemplate = {
    formId: '',
    scheduleAt: new Date(),
    titleEn: '',
    messageEn: '',
    titleTh: '',
    messageTh: '',
  };

  const { form, surveyTemplate } = props;
  const { getFieldDecorator } = form;
  const jsonData = surveyTemplate.toJS();
  const [loading, setLoading] = useState(jsonData.loading);
  const [scheduleError, setScheduleError] = useState({ validateStatus: '', message: '' });

  useEffect(() => {
    setLoading(jsonData.loading);
  }, [jsonData.loading]);

  const renderSurveyForm = (template) => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          setLoading(true);
          const scheduledAtAsString = values.schedule_at.toDate();
          const postData = {
            ...values,
            schedule_at: scheduledAtAsString,
          };

          surveyTemplate
            .create(postData)
            .then(() => {
              notify.success({
                title: 'Survey created',
                message: 'Survey Template created successfully.',
              });
              history.push('/office/config/survey-schedule');
            })
            .catch((e) => {
              notify.error({
                title: 'Error',
                message: e.message,
              });
            })
            .finally(() => setLoading(false));
        }
      });
    };

    const scheduleAt = moment(template.scheduleAt).format(datepickerFormat);
    const disabledDate = (current) => {
      const m = moment();
      const check = current && current.isBefore(m, 'day');

      return check;
    };

    const onScheduleAtChange = (value) => {
      if (value.isBefore(moment())) {
        setScheduleError({
          validateStatus: 'error',
          message: 'Survey cannot be scheduled in past date.',
        });
      } else {
        setScheduleError({
          validateStatus: 'success',
          message: '',
        });
      }
    };

    return (
      <Form {...formItemLayout} className="page-survey">
        <Form.Item
          label="Schedule At"
          validateStatus={scheduleError.validateStatus}
          help={scheduleError.message || 'Push notification will be sent on this date.'}
        >
          {getFieldDecorator('schedule_at', {
            initialValue: moment(scheduleAt, datepickerFormat),
            rules: [
              {
                required: true,
              },
            ],
          })(
            <DatePicker
              format={datepickerFormat}
              showTime={{ format: 'HH:mm' }}
              placeholder="Select schedule date"
              disabledDate={disabledDate}
              onChange={onScheduleAtChange}
            />
          )}
        </Form.Item>
        <Form.Item label="Title (TH)">
          {getFieldDecorator('title_th', {
            initialValue: template.titleTh,
            rules: [
              {
                required: true,
                message: 'Please enter Thai title',
              },
            ],
          })(<TextArea rows={4} placeholder="Title in Thai" />)}
        </Form.Item>
        <Form.Item label="Message (TH)">
          {getFieldDecorator('message_th', {
            initialValue: template.messageTh,
            rules: [
              {
                required: true,
                message: 'Please enter Thai message',
              },
            ],
          })(<TextArea rows={4} placeholder="Message in Thai" />)}
        </Form.Item>
        <SaveSection onClick={handleSubmit} disabled={scheduleError.message !== ''} />
      </Form>
    );
  };

  return (
    <HeaderMenu title="SURVEY SCHEDULE - ADD">
      <Page style={css.page}>
        <Loading loading={loading} />
        <StyledPageActionBar>
          <SurveyScheduleBackButton />
        </StyledPageActionBar>
        {renderSurveyForm(currentTemplate)}
      </Page>
    </HeaderMenu>
  );
};

const StyledPageActionBar = styled.div`
  text-align: right;
  margin-bottom: 15px;
  font-family: tahoma, verdana, roboto;
  color: #fff;
  width: 100%;
  clear: both;
  overflow: auto;
`;

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

const SurveyTemplateAddForm = Form.create({ name: 'survey_template_add' })(SurveyTemplateAdd);

export default inject('surveyTemplate')(observer(SurveyTemplateAddForm));
