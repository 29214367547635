import React, { Component } from 'react';
import styled from 'styled-components';

export default class SectionItem extends Component {
  render() {
    const { title = '' } = this.props;
    return (
      <Section>
        <Title>{title}</Title>
        <hr />
        {this.props.children}
      </Section>
    );
  }
}

const Section = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h3``;
