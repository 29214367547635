import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import XLSX from 'xlsx';
import { Col, Table, Modal, Button } from 'antd';

import { format } from '../../utils/format';
import { notify } from '../../utils/notify';
import { Title, RowItem } from '../../components/common';
import { ExportButton } from '../../components/button';

class Summary extends Component {
  state = {
    title: 'Summary',
    modalVisible: false,
  };

  doExport = async (fileName, dataSource) => {
    try {
      const output = dataSource.map((d) => {
        delete d.key;
        return d;
      });

      output.unshift({
        userId: 'MAX USERID',
        name: 'USER FULLNAME',
        customerType: 'CUSTOMER TYPE',
        telephone: 'TELEPHONE',
        date: 'DATE',
      });
      const sheetName = '';
      const ws = XLSX.utils.json_to_sheet([...output], { skipHeader: true });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  render() {
    const colName = 8;
    const colValue = 6;
    const list = this.props.list || [];
    const { loading } = this.props;

    let startDate;
    let endDate;
    if (this.props.filterMoreRef && this.props.filterMoreRef.current) {
      const d = this.props.filterMoreRef.current.getDate();
      startDate = d.start.format('YYYY-MM-DD');
      endDate = d.end.format('YYYY-MM-DD');
    }

    const dataSource = this.props.source || [];
    const { onCountClick } = this.props;
    const content = list.map((item, index) => {
      let percent = <Col span={colValue} />;
      if (item.percent !== undefined) {
        percent = (
          <Col span={colValue}>
            <PercentValue>{format.toPercent(item.percent)}</PercentValue>
          </Col>
        );
      }
      return (
        <RowItem style={rowCss} key={index} index={index}>
          <Col span={colName}>
            <TagName>{item.name}</TagName>
          </Col>
          {percent}
          <Col span={colValue}>
            {item.value === 0 && (
              <Button type="link" disabled style={{ width: '100%', textDecoration: 'none', color: '#444' }}>
                <LinkValue>{format.toDigi(item.value)}</LinkValue>
              </Button>
            )}
            {item.value > 0 && (
              <Button
                type="link"
                style={{ width: '100%', textDecoration: 'none', color: '#40a9ff' }}
                onClick={async () => {
                  this.setState({
                    title: `${item.name} : ${item.value}`,
                    modalVisible: true,
                  });
                  await onCountClick({
                    name: item.name,
                    start: startDate,
                    end: endDate,
                  });
                }}
              >
                <LinkValue>{format.toDigi(item.value)}</LinkValue>
              </Button>
            )}
          </Col>
        </RowItem>
      );
    });
    return (
      <div>
        <StyledTitle>{this.props.title || ''}</StyledTitle>
        {this.state.modalVisible && (
          <Modal
            closeable
            width={1024}
            height={520}
            bodyStyle={{ padding: '20px' }}
            centered
            footer={null}
            title={
              <ModalTitle
                title={this.state.title}
                handleClick={() => this.doExport('summary-export', dataSource)}
                disableExport={loading}
              />
            }
            visible={this.state.modalVisible}
            destroyOnClose
            onCancel={() => this.setState({ modalVisible: false })}
          >
            <Table
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              pagination={{
                hideOnSinglePage: true,
                size: 'small',
                pageSize: 10,
              }}
            />
          </Modal>
        )}
        {content}
      </div>
    );
  }
}

const columns = [
  {
    title: 'MAXID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    render: (name) => {
      if (!name) {
        return '-';
      }

      return name;
    },
  },
  {
    title: 'CUSTOMER TYPE',
    dataIndex: 'customerType',
    key: 'customerType',
  },
  {
    title: 'TELEPHONE',
    dataIndex: 'telephone',
    key: 'telephone',
    render: (telephone) => {
      if (!telephone) {
        return '-';
      }

      return telephone;
    },
  },
  {
    title: 'DATE',
    dataIndex: 'date',
    key: 'date',
  },
];

const ModalTitle = ({ title, handleClick, disableExport }) => {
  const StyledHeader = styled.h1`
    font-size: 18px;
    font-weight: 500;
    float: left;
  `;

  return (
    <div style={{ overflow: 'auto' }}>
      <StyledHeader>{title}</StyledHeader>
      <ExportButton type="primary" onClick={handleClick} disabled={disableExport}>
        Export
      </ExportButton>
    </div>
  );
};

const TagName = styled.label`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;

const LinkValue = styled.div`
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: right;
`;

const PercentValue = styled.div`
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
`;

const StyledTitle = styled(Title)`
  margin-top: 10px;
`;

const rowCss = {
  padding: '10px 25px',
};

export default inject('dashboard')(observer(Summary));
