import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';

const origin_user = {
  id: '',
  profile: {
    name: '',
    email: '',
  },
};

export class Menu extends BaseStore {
  constructor() {
    super();
    this.observable({
      user: cloneDeep(origin_user),
      openning: true,
    });
  }

  toggleMenu() {
    this.openning = this.openning !== true;
  }
}

export default new Menu();
