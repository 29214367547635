import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import Colors from '../../assets/Colors';

const statusColor = {
  // Challenge, Reward
  '1': {
    active: {
      color: Colors.green2,
      label: 'Active',
    },
    draft: {
      color: Colors.orange1,
      label: 'Draft',
    },
    inactive: {
      color: 'red',
      label: 'Inactive',
    },
  },

  // Community
  '2': {
    approved: {
      color: Colors.green2,
      label: 'Approved Ban',
    },
    pending: {
      color: Colors.orange1,
      label: 'Waiting for Approve',
    },
    cancelled: {
      color: 'red',
      label: 'Cancelled Ban',
    },
  },

  // User Challenge List
  '3': {
    collected: {
      color: Colors.green2,
      label: 'Collected',
    },
    completed: {
      color: Colors.green3,
      label: 'Completed',
    },
    inprogress: {
      color: Colors.orange1,
      label: 'In Progress',
    },
    expired: {
      color: '',
      label: 'Expired',
    },
    cancelled: {
      color: 'red',
      label: 'Cancelled',
    },
  },

  // Point Adjustments
  '4': {
    approved: {
      color: Colors.green2,
      label: 'Approved',
    },
    inprogress: {
      color: Colors.orange1,
      label: 'Waiting for approve',
    },
    cancelled: {
      color: 'red',
      label: 'Rejected',
    },
  },

  // Banners
  '5': {
    active: {
      color: Colors.green2,
      label: 'Active',
    },
    draft: {
      color: Colors.orange1,
      label: 'Draft',
    },
    inactive: {
      color: 'red',
      label: 'Inactive',
    },
    expired: {
      color: '',
      label: 'Expired',
    },
  },
};

const DisplayStatus = ({ type = 1, status }) => {
  const record = statusColor[`${type}`];
  if (!record) {
    return <div></div>;
  }

  const { color = '', label } = record[status];
  return (
    <TagContainer>
      <Tag color={color}>{label}</Tag>
    </TagContainer>
  );
};

const TagContainer = styled.div`
  font-size: 14px;
  text-align: center;
`;

export default DisplayStatus;
