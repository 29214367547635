import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Button } from 'antd';

export default class RemoveBtn extends Component {
  render() {
    const { onClick, style = {}, text = 'Remove', disabled = false } = this.props;
    const css = cloneDeep(style);
    const { textAlign, width, border, color, backgroundColor } = style;

    css.textAlign = textAlign || 'center';
    css.width = width || '100%';
    css.color = color || '#FC6B6B';
    css.border = border || '1px solid #FC6B6B';

    if (disabled) {
      css.backgroundColor = '#D9D8D8';
    } else {
      css.backgroundColor = backgroundColor || 'white';
    }

    return (
      <Button style={css} onClick={onClick} disabled={disabled}>
        {text}
      </Button>
    );
  }
}
