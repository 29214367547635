import React from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';

const SwitchButton = React.forwardRef((props, ref) => {
  const { onChange, children, ...rest } = props;

  return (
    <StyledSwitch {...rest} onChange={onChange} ref={ref}>
      {children}
    </StyledSwitch>
  );
});

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #ea7122;
  }
`;

export default SwitchButton;
