import React, { useEffect, useState } from 'react';
import { Input, message, Alert, Button, Modal, Icon, Tooltip } from 'antd';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Page, IntCol } from '../../../components/common';
import { SwitchButton } from '../../../components';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { BlockMapBuilder } from 'draft-js';
// import './SSOConfig.css';

const Header = ({ children }) => (
  <div style={{ padding: 20 }}>
    <span style={{ fontSize: 20 }}>{children}</span>
  </div>
);

const Message = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
    <span style={{ fontSize: 20 }}>{children}</span>
  </div>
);

const Description = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
    <span style={{ textAlign: 'center' }}>{children}</span>
  </div>
);

const UrlEditList = ({ onClickEdit, children }) => (
  <div style={{ display: 'block' }}>
    <span style={{ marginRight: 20 }}>{children}</span>
    <Tooltip title="edit">
      <Button type="link" onClick={onClickEdit} style={{ color: 'rgb(242, 99, 35)' }}>
        <Icon type="edit" />
      </Button>
    </Tooltip>
  </div>
);

const ConfirmModal = (props) => (
  <Modal visible={props.visible} title="Update SSO Config" footer={null} closable={false} destroyOnClose width={270}>
    <Message>{props.confirmMessage}</Message>
    <Description>การแก้ไขนี้จะมีผลทันทีหลังจากกดยืนยัน ต้องการทำต่อหรือไม่</Description>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          // setUpdateError('');
          props.onConfirm();
        }}
      >
        ยืนยัน
      </Button>
      <Button
        type="warning"
        size="large"
        style={{ marginLeft: 40 }}
        onClick={() => {
          // setUpdateError('');
          props.onCancel();
        }}
      >
        ยกเลิก
      </Button>
    </div>
  </Modal>
);

const UrlEditModal = (props) => (
  <Modal
    visible={props.visible}
    title="Edit Customer Portal URL"
    footer={null}
    closable={false}
    destroyOnClose
    width={500}
  >
    <Input value={props.value} onChange={(event) => props.onValueChange(event.target.value)} placeholder="URL" />
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          props.onConfirm(props.value);
        }}
      >
        บันทึก
      </Button>
      <Button
        type="warning"
        size="large"
        style={{ marginLeft: 40 }}
        onClick={() => {
          props.onCancel();
        }}
      >
        ยกเลิก
      </Button>
    </div>
  </Modal>
);

const SSOConfigForm = (props) => {
  const { SSOConfig } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [configs, setConfigs] = useState({});
  const [modifiedConfigs, setModifiedConfigs] = useState({});
  const [modalActive, setModalActive] = useState(false);
  const [url, setUrl] = useState();
  const [editModalActive, setEditModalActive] = useState(false);

  useEffect(() => {
    setLoading(true);

    SSOConfig.fetchSSOConfigs()
      .then(() => {
        const configs = SSOConfig.list;
        // console.log(configs);
        setConfigs(configs);
        setModifiedConfigs(configs);
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [SSOConfig]);

  const onSubmitHandler = (modifiedConfigs) => {
    setLoading(true);
    setError('');

    // console.log('save', modifiedConfigs);

    SSOConfig.updateSSOConfigs(modifiedConfigs)
      .then(() => {
        if (SSOConfig.errors.length) {
          setError(SSOConfig.errors.join('<br/><br/>'));
        } else {
          message.success('SSO config updated successfully.', 2);
          setModalActive(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCancel = () => {
    setModifiedConfigs(configs);
    setModalActive(false);
  };

  const onEditCancel = () => {
    setEditModalActive(false);
  };

  return (
    <HeaderMenu title="SSO Config">
      <div style={{ height: 'calc(100vh - 84px)' }}>
        {error && <Alert type="error" message={error} />}
        <Header>SSO Config</Header>
        <StyledPage>
          <span style={{ marginRight: 20 }}>หน้า OTP สำหรับ Single sign on</span>
          <SwitchButton
            checked={modifiedConfigs.turnOn}
            onChange={(value) => {
              setModifiedConfigs({
                ...modifiedConfigs,
                turnOn: value,
              });
              // console.log(value);
              setModalActive(true);
            }}
          />
          <br />
          <h4 style={{ fontWeight: 'bold', margin: '20px 0 0 0' }}>Customer Portal URL</h4>
          <UrlEditList
            onClickEdit={() => {
              setUrl(modifiedConfigs.customerPortalUrl);
              setEditModalActive(true);
            }}
          >
            {modifiedConfigs.customerPortalUrl}
          </UrlEditList>

          <UrlEditModal
            visible={editModalActive}
            value={url}
            onValueChange={setUrl}
            onConfirm={(value) => {
              setModifiedConfigs({
                ...modifiedConfigs,
                customerPortalUrl: value,
              });
              onSubmitHandler({
                ...modifiedConfigs,
                customerPortalUrl: value,
              });
              setEditModalActive(false);
            }}
            onCancel={() => {
              onEditCancel();
            }}
          />
        </StyledPage>
        <ConfirmModal
          visible={modalActive}
          confirmMessage={'กรุณายืนยันการแก้ไข'}
          onConfirm={() => {
            onSubmitHandler(modifiedConfigs);
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      </div>
    </HeaderMenu>
  );
};

const StyledPage = styled(Page)`
  background-color: white;
  border-radius: 5px;
`;

const WrappedSSOConfig = SSOConfigForm;

export default inject('SSOConfig')(observer(WrappedSSOConfig));
