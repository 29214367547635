export const Colors = {
  green1: '#2b821d',
  green2: '#87d068',
  green3: '#99c761',
  red1: '#bf0c0c',
  red2: '#ff0000',
  orange1: '#f26323',
  orange2: '#f9bea1',
  orange3: '#fa7c25',
  orange4: '#e87722',
  grey: '#FAFAFA',

  borderTable: '#e8e8e8',
};

export default Colors;
