import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';

const origin_config = {
  single: {
    code: '',
  },
  increment: {
    prefix: '',
  },
  random: {
    code: '',
    prefix: '',
    postfix: '',
    character: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    len: 5,
  },
  import: {
    list: [],
  },
};
export class Generator extends BaseStore {
  constructor() {
    super();
    this.observable({
      config: cloneDeep(origin_config),
    });
  }

  resetConfig() {
    this.config = cloneDeep(origin_config);
  }

  initialConfig(type, val) {
    console.log('this.config', this.config);
    this.resetConfig();
    switch (type) {
      case 'single':
        this.config[type].code = val.code;
        break;
      case 'random':
        this.config[type].code = val.code;
        this.config[type].prefix = val.prefix;
        this.config[type].postfix = val.postfix;
        this.config[type].character = val.character;
        this.config[type].len = val.len;
        break;
      case 'import':
        this.config[type].list = val.list;
        break;
    }
  }

  setConfig(val) {
    this.config = val;
  }
}

export default new Generator();
