import moment from 'moment';

export class Timer {
  get(init) {
    if (init) return moment(init);
    return moment();
  }

  toDatetime(init) {
    if (init) return moment(init);
    return undefined;
  }

  toTime(init) {
    if (init) {
      const txt = moment(init).format('HH:mm:ss');
      return moment(txt, 'HH:mm:ss');
    }
    return undefined;
  }

  getNoMinute() {
    return moment()
      .minute(0)
      .second(0);
  }

  format() {
    return moment().format();
  }
}

export const timer = new Timer();
export default timer;
