import React from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import styled from 'styled-components';
import SwitchButton from '../switch-button';
import { SecondaryButton, PrimaryButton } from '../button';

/**
 * This constant is defined on max-api
 */
const POINT_ADJUST = 'point_adjust';

const notificationAction = {
  collect_step: 'Collect step',
  running: 'Running',
  swimming: 'Swimming',
  cycling: 'Cycling',
  email_verification: 'Email verification',
  fwd_customer: 'Fwd customer',
  redeem: 'Redeem',
  welcome: 'Welcome',
  challenge: 'Challenge',
  referrer_earn_point: 'Referrer earn point',
  invitee_earn_point: 'Invitee earn point',
  paid_policy: 'Paid policy',
};

const { TextArea } = Input;
const { Option } = Select;

const PushNotificationTemplateUpdateForm = React.forwardRef((props, ref) => {
  const { notificationTemplate, onSubmit, onCancel, onUpdateProgress } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const { getFieldDecorator } = props.form;

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      onSubmit({ ...values, ...{ id: notificationTemplate.id } });
    });
  };

  const disableMessageEdit = notificationTemplate.action === POINT_ADJUST;

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} ref={ref} colon={false}>
      <Form.Item label="Action">
        {getFieldDecorator('action', {
          initialValue: notificationTemplate.action,
        })(
          <Select style={{ width: 220 }} disabled>
            {Object.keys(notificationAction).map((a) => (
              <Option key={a} value={a}>
                {notificationAction[a]}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Row gutter={16}>
        <Col span={24}>
          <Col span={8} offset={8}>
            <StyledFormItem label="Is Push">
              {getFieldDecorator('push', {
                initialValue: notificationTemplate.is_push,
              })(<SwitchButton defaultChecked={notificationTemplate.is_push} />)}
            </StyledFormItem>
          </Col>
          <Col span={8}>
            <StyledFormItem label="Is Noti">
              {getFieldDecorator('noti', {
                initialValue: notificationTemplate.is_noti,
              })(<SwitchButton defaultChecked={notificationTemplate.is_noti} />)}
            </StyledFormItem>
          </Col>
        </Col>
      </Row>
      <Form.Item label="Title (TH)">
        {getFieldDecorator('title_th', {
          initialValue: notificationTemplate.title_th,
        })(<TextArea disabled={disableMessageEdit} />)}
      </Form.Item>
      <Form.Item label="Message (TH)">
        {getFieldDecorator('message_th', {
          initialValue: notificationTemplate.message_th,
        })(<TextArea disabled={disableMessageEdit} />)}
      </Form.Item>
      <Form.Item label="Title (EN)">
        {getFieldDecorator('title_en', {
          initialValue: notificationTemplate.title_en,
        })(<TextArea rows={4} disabled={disableMessageEdit} />)}
      </Form.Item>
      <Form.Item label="Message (EN)">
        {getFieldDecorator('message_en', {
          initialValue: notificationTemplate.message_en,
        })(<TextArea rows={4} disabled={disableMessageEdit} />)}
      </Form.Item>
      <Row style={{ width: '100%' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item {...tailFormItemLayout}>
            <SecondaryButton align="left" title="Cancel" disabled={onUpdateProgress} onClick={onCancel} />
            <PrimaryButton
              title="Update"
              loading={onUpdateProgress}
              disabled={onUpdateProgress}
              style={{ marginLeft: 8 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

const StyledFormItem = styled(Form.Item)`
  .ant-switch {
    margin-top: -11px;
  }
`;

const WrappedPushNotificationTemplateUpdateForm = Form.create({ name: 'push_notification_template_update' })(
  PushNotificationTemplateUpdateForm
);

export default WrappedPushNotificationTemplateUpdateForm;
