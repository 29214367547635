import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import { storage } from '../utils/storage';

const initialData = {
  list: [],
  pagination: {
    limit: 1,
    offset: 0,
    totalRows: -1,
  },
  loading: false,
  currentStaffRecord: null,
  checkingUsername: false,
};

export class Staff extends BaseStore {
  constructor() {
    super();
    this.observable(cloneDeep(initialData));
  }

  async save(data) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs`;
    const res = await http.post(url, { json: data, token: true });

    if (res.statusCode !== 200) {
      this.loading = false;
      return Promise.reject(res.body);
    }

    this.loading = false;
  }

  async update(id, data) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs/${id}`;
    const res = await http.patch(url, { json: data, token: true });

    if (res.statusCode !== 200) {
      this.loading = false;
      return Promise.reject(res.body);
    }

    this.loading = false;
  }

  async delete(id) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs/${id}`;
    const res = await http.delete(url, { token: true });

    if (res.statusCode !== 204) {
      this.loading = false;
      return Promise.reject(new Error({ data: 'Something went wrong. Cannot delete.' }));
    }

    await this.fetchAll({ offset: this.pagination.offset, limit: this.pagination.limit });

    this.loading = false;
  }

  async fetchAll({ offset = 0, limit = 10 }) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs?limit=${limit}&offset=${offset}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      this.loading = false;
      return Promise.reject(res.body);
    }

    this.list = res.body.data.list;
    this.pagination = {
      limit: res.body.data.limit,
      offset: res.body.data.offset,
      totalRows: res.body.data.totalRows,
    };
    this.loading = false;
  }

  async fetchStaffUserProfile(id) {
    this.loading = true;
    this.currentStaffRecord = {};

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs/${id}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      this.loading = false;
      return Promise.reject(res.body);
    }

    this.currentStaffRecord = res.body.data;

    this.loading = false;
  }

  async checkUsernameAvailability({ mode, id, username }) {
    this.checkingUsername = true;
    this.validationError = null;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs/isUsernameAvailable`;
    const res = await http.post(url, { json: { mode, id, username }, token: true });

    if (res.statusCode !== 200) {
      this.checkingUsername = false;
      return Promise.reject(res.body);
    }

    this.checkingUsername = false;
  }
}

const staff = new Staff();

export default staff;
