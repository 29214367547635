import React from 'react';
import { Radio, Button, Typography, Modal, Input } from 'antd';
import styled from 'styled-components';
import { InputInt } from '../../../components/forms';
import './styles.css';

const { Text } = Typography;

class PointAdjustModal extends React.Component {
  state = {
    pointAdjustModal: false,
    errorMessage: '',
    titleEn: '',
    titleTh: '',
    descEn: '',
    descTh: '',
    transType: 'dr',
    amount: null,
  };

  reset = () => {
    this.setState({
      pointAdjustModal: false,
      errorMessage: '',
      titleEn: '',
      titleTh: '',
      descEn: '',
      descTh: '',
      amount: null,
    });
  };

  setErrorMessage = (errorMessage) => {
    this.setState({ errorMessage });
  };

  toggleModalPointAdjust = () => {
    this.setState((prevState) => ({ pointAdjustModal: !prevState.pointAdjustModal }));
  };

  onChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  onChangeAmount = (value) => {
    this.setState({ amount: value });
  };

  addAdjustPoint = () => {
    this.props.addAdjustPoint(this.state);
  };

  render() {
    return (
      <Modal
        title="REBALANCE POINTS:"
        visible={this.state.pointAdjustModal}
        onCancel={this.toggleModalPointAdjust}
        footer={null}
      >
        <Container>
          <Text type="secondary">Title English</Text>
          <Input style={css.inputStyle} onChange={this.onChange('titleEn')} value={this.state.titleEn} />
          <Text type="secondary">Description english</Text>
          <Input style={css.inputStyle} onChange={this.onChange('descEn')} value={this.state.descEn} />
          <Text type="secondary">Title Thai</Text>
          <Input style={css.inputStyle} onChange={this.onChange('titleTh')} value={this.state.titleTh} />
          <Text type="secondary">Description Thai</Text>
          <Input style={css.inputStyle} onChange={this.onChange('descTh')} value={this.state.descTh} />
          <Text type="secondary">Point</Text>
          <PointInput>
            <InputInt style={css.inputStyle} onChange={this.onChangeAmount} value={this.state.amount} />
            <span>{this.state.transType === 'dr' ? '+' : '-'}</span>
          </PointInput>

          <Footer>
            <Radio.Group onChange={this.onChange('transType')} value={this.state.transType}>
              <Radio value="dr">Increase (+)</Radio>
              <Radio value="cr">Decrease (-)</Radio>
            </Radio.Group>

            <Button style={css.buttonStyle} type="primary" shape="round" onClick={this.addAdjustPoint}>
              Execute
            </Button>
          </Footer>

          {this.state.errorMessage && <ErrorText>{this.state.errorMessage}</ErrorText>}
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PointInput = styled.div`
  position: relative;
  > span {
    position: relative;
    left: 4px;
    top: -27px;
    width: 10px;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const css = {
  inputStyle: {
    marginTop: 5,
    marginBottom: 10,
    width: '100%',
  },
  buttonStyle: {
    backgroundColor: '#E87722',
    borderColor: '#E87722',
  },
};

export default PointAdjustModal;
