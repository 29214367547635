import React, { useState, useEffect } from 'react';
import { Row, Modal, Button, Alert, Icon, Form, Input, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { notify } from '../../utils/notify';
import { userRoleMap } from '../../config/user-roles';

const SettingsForm = (props) => {
  const staff = props.member.user.profile;
  const { getFieldDecorator } = props.form;
  const [currentStaff, setCurrentStaff] = useState(staff);
  const [error, setError] = useState('');

  useEffect(() => {
    props.member
      .getUserProfile()
      .then(() => {
        const userProfile = props.member.user.profile;
        setCurrentStaff(() => userProfile);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      });
  }, [props.member]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  // NOTE: Set directly for debug purpose
  // const userRole = 'call_center';
  const userRole = currentStaff.role_name;

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const isAdminUser = ['super_admin', 'admin'].includes(userRole) === true;
        let postData = {};

        if (isAdminUser) {
          postData = {
            ...values,
          };
        } else {
          postData = {
            telephone: values.telephone,
            password: values.password,
          };
          if (values.password === '***') {
            delete postData.password;
          }
        }

        props.member
          .saveUserProfile(currentStaff.backoffice_user_id, postData)
          .then(() => {
            notify.success({
              title: 'Settings',
              message: 'Settings updated successfully.',
            });
            props.member.getUserProfile();
          })
          .catch((er) => {
            console.error(er);

            notify.error({
              title: 'Error occured',
              message: `Error: ${er.message}`,
            });
          });
      }
    });
  };

  const disableField = ['super_admin', 'admin'].includes(userRole) === false;
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordVal, setPasswordVal] = useState('');

  const showPasswordChangePopup = () => {
    setPasswordModal(() => true);
  };

  const sendPasswordChangeRequest = () => {
    // Send request ot change passsword
    props.member
      .saveUserProfile(currentStaff.backoffice_user_id, {
        password: passwordVal,
      })
      .then(() => {
        setPasswordModal(false);
        setPasswordVal('');
        notify.success({
          title: 'Password Changed',
          message: 'Password has been changed successfully.',
        });
      })
      .catch((e) => {
        setError(e.message);
        console.error(e);
      });
  };

  const changePasswordValue = (value) => {
    setPasswordVal(value);
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} className="staff-form">
      <Modal
        title="Enter new password"
        icon="key"
        width={310}
        okText="Change password"
        visible={passwordModal}
        closable
        onCancel={() => setPasswordModal(false)}
        onOk={() => sendPasswordChangeRequest()}
      >
        <Input type="password" onChange={(e) => changePasswordValue(e.currentTarget.value)} />
      </Modal>

      {error && <Alert type="error">{error}</Alert>}

      <Row type="flex" gutter={8}>
        <Col span={12}>
          <Form.Item label="First Name">
            {getFieldDecorator('firstname', { initialValue: currentStaff.firstname })(
              <Input disabled={disableField} />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last Name">
            {getFieldDecorator('lastname', { initialValue: currentStaff.lastname })(<Input disabled={disableField} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={8} span={24}>
        <Col span={12}>
          <Form.Item label="Username">
            {getFieldDecorator('username', {
              initialValue: currentStaff.username,
              rules: [{ required: true, message: 'Username is required field.' }],
            })(<Input disabled={disableField} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              initialValue: currentStaff.email,
              rules: [{ type: 'email', required: true, message: 'Please enter valid email' }],
            })(<Input type="email" disabled={disableField} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={8} span={24}>
        <Col span={12}>
          <Form.Item label="Telephone">
            {getFieldDecorator('telephone', { initialValue: currentStaff.telephone })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Password" colon={false}>
            {getFieldDecorator('password')(
              <Button type="link" onClick={() => showPasswordChangePopup()}>
                Change password
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={8} span={24}>
        <Col span={12}>
          <Form.Item label="Role">{userRoleMap[currentStaff.role_name]}</Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 8, height: '48px' }}>
              <Icon type="save" /> Save settings
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const StaffSettingsForm = Form.create('staff_settings_form')(SettingsForm);

export default inject('member')(observer(StaffSettingsForm));
