/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Loading, SaveSection } from '../../components/common';
import notify from '../../utils/notify';
import Colors from '../../assets/Colors';
import './style.css';

const { Text } = Typography;

function getErrorMessage(errorTag, currentLength) {
  return `${errorTag} tag has ${currentLength} length, it must less than 256 character,`;
}

function RenderItem({ title, item, set }) {
  const [inputText, setInputText] = useState('');
  function deleteTag(tag) {
    const filtered = item.value.filter((v) => v !== tag);
    set({ value: filtered, error: '' });
  }

  function addTag(e) {
    const { value } = e.target;
    if (!item.value.includes(value)) {
      set({ value: item.value.concat(value), error: item.error });
    }
    setInputText('');
  }

  function onChange(e) {
    const { value } = e.target;
    if (value[value.length - 1] !== ',') {
      setInputText(e.target.value);
    }
  }
  const classInput = item.error !== '' ? 'has-error' : '';
  return (
    <div className="content-config" style={css.container}>
      <div style={css.titleContainer}>
        <Text style={css.title}>Personalize</Text>
        <Text strong>{title}</Text>
      </div>
      <div style={css.valueContainer}>
        <div style={css.tagTitleContainer}>
          <Text style={css.title}>Tag/Pillar</Text>
          <Text style={css.tagError}>{item.error}</Text>
        </div>
        <Input
          value={inputText}
          onChange={onChange}
          style={css.input}
          allowClear
          placeholder="Add more tag here"
          onPressEnter={addTag}
          className={`ant-input-affix-wrapper ${classInput}`}
        />

        <div style={css.tagContainer}>
          {item.value.map((v) => (
            <div key={v} style={css.tag}>
              <Text style={{ color: 'black', marginRight: 15 }}>{v}</Text>
              <i className="far fa-times-circle" onClick={() => deleteTag(v)}></i>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function PersonalContentConfig(props) {
  const [step, setStep] = useState({ value: [], error: '' });
  const [run, setRun] = useState({ value: [], error: '' });
  const [swim, setSwim] = useState({ value: [], error: '' });
  const [cycle, setCycle] = useState({ value: [], error: '' });
  const [travel, setTravel] = useState({ value: [], error: '' });
  const [dining, setDining] = useState({ value: [], error: '' });

  const fetchApi = useCallback(async () => {
    try {
      await props.appConfig.getList();
    } catch (e) {
      notify.error({ title: 'Get Config Failed', message: e.message });
    }
  }, []);

  useEffect(() => {
    fetchApi();
  }, []);

  function validateTag(stepLen, runLen, swimLen, cycleLen, travelLen, diningLen) {
    if (stepLen > 255) {
      const msg = getErrorMessage('steps', stepLen);
      setStep({ value: step.value, error: msg });
      throw new Error(msg);
    } else if (runLen > 255) {
      const msg = getErrorMessage('run', runLen);
      setRun({ value: step.value, error: msg });
      throw new Error(msg);
    } else if (swimLen > 255) {
      const msg = getErrorMessage('swim', swimLen);
      setSwim({ value: step.value, error: msg });
      throw new Error(msg);
    } else if (cycleLen > 255) {
      const msg = getErrorMessage('cycle', cycleLen);
      setCycle({ value: step.value, error: msg });
      throw new Error(msg);
    } else if (travelLen > 255) {
      const msg = getErrorMessage('travel', travelLen);
      setTravel({ value: step.value, error: msg });
      throw new Error(msg);
    } else if (diningLen > 255) {
      const msg = getErrorMessage('dining', diningLen);
      setDining({ value: step.value, error: msg });
      throw new Error(msg);
    }
  }

  async function save() {
    try {
      const stepStr = step.value.toString();
      const runStr = run.value.toString();
      const swimStr = swim.value.toString();
      const cycleStr = cycle.value.toString();
      const travelStr = travel.value.toString();
      const diningStr = dining.value.toString();

      validateTag(stepStr.length, runStr.length, swimStr.length, cycleStr.length, travelStr.length, diningStr.length);

      await props.appConfig.updateList([
        {
          key: 'pNexgenSteps',
          value: stepStr,
        },
        {
          key: 'pNexgenRunning',
          value: runStr,
        },
        {
          key: 'pNexgenSwimming',
          value: swimStr,
        },
        {
          key: 'pNexgenCycling',
          value: cycleStr,
        },
        {
          key: 'pNexgenTraveling',
          value: travelStr,
        },
        {
          key: 'pNexgenDining',
          value: diningStr,
        },
      ]);
      notify.success({ title: 'Save Config Success' });
    } catch (e) {
      notify.error({ title: 'Save Config Failed', message: e.message });
    }
  }

  useEffect(() => {
    const {
      pNexgenSteps,
      pNexgenRunning,
      pNexgenSwimming,
      pNexgenCycling,
      pNexgenTraveling,
      pNexgenDining,
    } = props.appConfig.general;

    if (pNexgenSteps && pNexgenSteps !== '') {
      setStep({ value: pNexgenSteps.toString().split(','), error: '' });
    }
    if (pNexgenRunning && pNexgenRunning !== '') {
      setRun({ value: pNexgenRunning.toString().split(','), error: '' });
    }
    if (pNexgenSwimming && pNexgenSwimming !== '') {
      setSwim({ value: pNexgenSwimming.toString().split(','), error: '' });
    }
    if (pNexgenCycling && pNexgenCycling !== '') {
      setCycle({ value: pNexgenCycling.toString().split(','), error: '' });
    }
    if (pNexgenTraveling && pNexgenTraveling !== '') {
      setTravel({ value: pNexgenTraveling.toString().split(','), error: '' });
    }
    if (pNexgenDining && pNexgenDining !== '') {
      setDining({ value: pNexgenDining.toString().split(','), error: '' });
    }
  }, [props.appConfig.general]);

  return (
    <HeaderMenu title="PERSONALIZE MATCH">
      <div style={css.page}>
        <Loading loading={props.appConfig.loading} />
        <RenderItem title="Steps" item={step} set={setStep} />
        <RenderItem title="Runner" item={run} set={setRun} />
        <RenderItem title="Swimming" item={swim} set={setSwim} />
        <RenderItem title="Cycling" item={cycle} set={setCycle} />
        <RenderItem title="Traveling" item={travel} set={setTravel} />
        <RenderItem title=" Dining" item={dining} set={setDining} />
        <SaveSection onClick={save} />
      </div>
    </HeaderMenu>
  );
}

const css = {
  page: {
    marginBottom: 100,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    marginBottom: 5,
    borderRadius: 2,
    padding: '20px 50px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
  },
  title: {
    marginBottom: 5,
  },
  tagError: {
    color: Colors.red2,
  },
  tagTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 600,
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    width: 600,
  },
  tag: {
    display: 'flex',
    backgroundColor: Colors.orange2,
    borderRadius: 5,
    padding: '3px 10px',
    alignItems: 'center',
    marginRight: 5,
    marginTop: 10,
  },
  iconClose: {},
};

export default inject('appConfig')(observer(PersonalContentConfig));
