/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Row, Col, InputNumber } from 'antd';

import HeaderMenu from '../../../components/main/HeaderMenu';

import { Loading, SaveSection } from '../../../components/common';
import { InputInt } from '../../../components/forms';
import { notify } from '../../../utils/notify';

const NAME = 'Point Global';
const SPAN = 8;

function PointGlobal(props) {
  const { form, point } = props;
  const { global: doc } = point;
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [bdayPointLevel3, setBdayPointLevel3] = useState(0);
  const [bdayPointLevel4, setBdayPointLevel4] = useState(0);
  const [bdayPointLevel5, setBdayPointLevel5] = useState(0);

  useEffect(() => {
    onFind();
  }, []);

  useEffect(() => {
    const { bdayPointLevel3: level3, bdayPointLevel4: level4, bdayPointLevel5: level5 } = props.appConfig.general;
    setBdayPointLevel3(level3);
    setBdayPointLevel4(level4);
    setBdayPointLevel5(level5);
  }, [props.appConfig.general]);

  async function onFind() {
    try {
      setLoading(true);
      const tasks = [props.appConfig.getList(), props.point.getGlobal()];
      await Promise.all(tasks);
    } catch (e) {
      notify.error({ title: `Load ${NAME} Fail`, message: e.message });
    } finally {
      setLoading(false);
    }
  }

  async function onSave() {
    try {
      await props.form.validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }

        setLoading(true);
        const { doc, bdayPointLevel3, bdayPointLevel4, bdayPointLevel5 } = fieldsValue;
        const tasks = [];
        tasks.push(props.point.saveGlobal(doc));
        tasks.push(
          props.appConfig.updateList([
            {
              key: 'bdayPointLevel3',
              value: bdayPointLevel3,
              status: 'active',
            },
            {
              key: 'bdayPointLevel4',
              value: bdayPointLevel4,
              status: 'active',
            },
            {
              key: 'bdayPointLevel5',
              value: bdayPointLevel5,
              status: 'active',
            },
          ])
        );
        await Promise.all(tasks);
        setLoading(false);
        notify.success({ title: `Save ${NAME} Complete` });
      });
    } catch (e) {
      notify.error({ title: `Save ${NAME} Fail`, message: e.message });
    }
  }

  function renderFormItem({ label, field, value }) {
    return (
      <Form.Item label={label}>
        {getFieldDecorator(field, {
          initialValue: value,
          rules: [{ required: true, message: `${label} is required.` }],
        })(<InputInt />)}
      </Form.Item>
    );
  }

  return (
    <HeaderMenu title="GLOBAL">
      <Page>
        <Loading loading={loading} />
        <Form {...formLayout}>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({ label: 'Welcome', field: 'doc.welcome.point', value: doc.welcome.point })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({
                label: 'Email Verification',
                field: 'doc.email_verification.point',
                value: doc.email_verification.point,
              })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({
                label: 'FWD Customer',
                field: 'doc.fwd_customer.point',
                value: doc.fwd_customer.point,
              })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({
                label: 'Max Point per Day',
                field: 'doc.user_limit_point_per_day.point',
                value: doc.user_limit_point_per_day.point,
              })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({
                label: 'Poll and Survey',
                field: 'doc.poll_and_survey.point',
                value: doc.poll_and_survey.point,
              })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({ label: 'Point Referrer Get', field: 'doc.referrer.point', value: doc.referrer.point })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({ label: 'Point Invitee Get', field: 'doc.invitee.point', value: doc.invitee.point })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({ label: 'Birthday Badge 3', field: 'bdayPointLevel3', value: bdayPointLevel3 })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({ label: 'Birthday Badge 4', field: 'bdayPointLevel4', value: bdayPointLevel4 })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <Row>
            <Col span={SPAN}>
              {renderFormItem({ label: 'Birthday Badge 5', field: 'bdayPointLevel5', value: bdayPointLevel5 })}
            </Col>
            <Col span={1}>
              <TextDiv>Points</TextDiv>
            </Col>
          </Row>
          <SaveSection onClick={onSave} />
        </Form>
      </Page>
    </HeaderMenu>
  );
}

const Page = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: white;
`;

const TextDiv = styled.div`
  padding: 10px;
`;

const formLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const inputCss = {
  width: '100%',
};

const PointGlobalForm = Form.create({ name: 'global_point_form' })(PointGlobal);
export default inject('point', 'appConfig')(observer(PointGlobalForm));
