import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Button } from 'antd';

export default class Btn extends Component {
  render() {
    const { onClick, style = {}, text = 'Click', disabled = false, htmlType = 'button' } = this.props;
    const css = cloneDeep(style);
    const { textAlign, width, border, color, backgroundColor } = style;

    css.textAlign = textAlign || 'center';
    css.width = width || '100%';
    css.color = color || '#e87722';
    css.border = border || '1px solid #e87722';
    css.backgroundColor = backgroundColor || 'white';
    if (disabled) css.opacity = 0.5;

    return (
      <Button style={css} onClick={onClick} disabled={disabled} htmlType={htmlType}>
        {text}
      </Button>
    );
  }
}
