import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Row, Col, Form, Icon, Modal, Button, Select, Typography } from 'antd';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading, Progress } from '../../components/common';
import { DropdownList, Input, TextArea, DatePicker, AppPage, AppPageParam } from '../../components/forms';
import { SaveBtn } from '../../components/button';
import moment from 'moment';

const Key = styled.span`
  color: #e77822;
`;

const sendTypeOptions = [{ name: 'Send To All', value: 'All' }];
const deliveryScheduleOptions = [
  { name: 'Immediately', value: 'immediately' },
  { name: 'Specific time', value: 'specific_time' },
];
const perUserOptimizeOptions = [
  { name: 'Send to everyone at the same time', value: 'all' },
  { name: 'Inteligent delivery (Recommend)', value: 'int' },
  // { name: 'Send to everyone at the same time', value: 'all'},
];

const getOptionNameByVal = (val, opts = []) => {
  const found = opts.find((e) => e.value === val);
  if (found) {
    return found.name;
  }
  return undefined;
};

const AndroidExample = ({ title, message }) => (
  <div
    style={{
      marginTop: 30,
      fontFamily: 'Roboto, Open Sans, Arial, Helvetica, sans-serif',
      padding: 16,
      borderRadius: 2,
      backgroundColor: 'rgb(254, 254, 254)',
      boxShadow:
        'rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
      width: 350,
      overflowY: 'hidden',
      transition: 'max-height 0.3s ease-out 0s',
      boxSizing: 'content-box',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div style={{ color: 'rgb(99, 99, 99)' }}>
      <Icon theme="filled" type="bell" style={{ marginRight: 10 }} />
      FWD MAX
    </div>
    <div
      style={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span style={{ color: 'rgb(83, 83, 83)' }}>{title}</span>
      <span style={{ color: 'rgb(117, 117, 117)' }}>{message || 'Default message'}</span>
    </div>
  </div>
);

const IosExample = ({ title, subTitle, message }) => (
  <div
    style={{
      marginTop: 30,
      fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      padding: 16,
      borderRadius: 10,
      backgroundColor: 'rgb(254, 254, 254)',
      boxShadow: 'rgb(212 212 213) 0px 1px 3px 0px',
      width: 350,
      overflowY: 'hidden',
      transition: 'max-height 0.3s ease-out 0s',
      boxSizing: 'content-box',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ color: 'rgb(99, 99, 99)' }}>
        <Icon theme="filled" type="apple" style={{ marginRight: 10 }} />
        FWD MAX
      </div>
      <span>now</span>
    </div>
    <div
      style={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span style={{ fontWeight: 'bold', fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif' }}>{title}</span>
      <span style={{ fontWeight: 'bold', fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif' }}>
        {subTitle}
      </span>
      <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif' }}>
        {message || 'Default message'}
      </span>
    </div>
  </div>
);

const ConfirmChangeStatusModal = ({ title, visible, onCancel, onConfirm, message, children }) => (
  <Modal
    title={title}
    visible={visible}
    onCancel={onCancel}
    width={600}
    footer={[
      <Button key="back" onClick={onCancel}>
        Close
      </Button>,
      <Button type="primary" onClick={onConfirm}>
        Confirm and Send
      </Button>,
    ]}
  >
    <Key>{message}</Key>
    {children}
  </Modal>
);

export class Broadcast extends Component {
  state = {
    loading: false,
    processing: false,
    modalVisible: false,
  };

  getPageName(pageName) {
    const [name, type = null] = pageName.split('_');
    return {
      name,
      type,
    };
  }

  onSend = async (evt) => {
    const { form } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
      this.setState({ modalVisible: true });
    } catch (e) {
      console.error(e);
    }
  };

  onConfirm = async (evt) => {
    console.log(this.props);
    const { form, notification } = this.props;
    // const { broadcast } = notification;
    try {
      console.log('clicked');
      this.setState({ loading: true });
      evt.preventDefault();
      await form.validateFields();
      if (notification.broadcast) {
        await notification.broadcast(form.getFieldsValue());
      }
      this.setState({ modalVisible: false });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  isInApp = () => {
    return this.props.form.getFieldValue('uriType') === 'inapp';
  };

  renderPageParam = () => {
    const { getFieldDecorator } = this.props.form;
    const { isRequired } = this.props;
    const { pageName } = this.props.form.getFieldsValue();

    if (!pageName) return;
    const { name, type } = this.getPageName(pageName);
    const isValidPageParam = name === 'reward' || (name === 'challenges' && type === 'item');
    if (!isValidPageParam) return;
    const pageParamFormItem = (
      <Form.Item label="PageParam">
        {getFieldDecorator('pageParam', {
          initialValue: undefined,
          preserve: true,
          rules: [
            {
              required: isRequired,
            },
          ],
        })(<AppPageParam onChange={this.handlePageParam} type={name} />)}
      </Form.Item>
    );

    return pageParamFormItem;
  };

  handlePageName = (pageName) => {
    const { name } = this.getPageName(pageName);

    this.props.form.setFieldsValue({
      inAppLink: name,
      pageParam: undefined,
    });
  };

  render() {
    const { modalVisible, loading } = this.state;
    const { form, notification } = this.props;
    const { getFieldDecorator, getFieldsValue } = form;
    const required = true;
    console.log('notification.loading', notification, loading);
    const { isRequired } = this.props;

    const pageNameFormItem = (
      <Form.Item label="PageName">
        {getFieldDecorator('pageName', {
          initialValue: undefined,
          preserve: true,
          rules: [
            {
              required: isRequired && this.props.form.getFieldValue('uriType') === 'inapp',
            },
          ],
        })(<AppPage onChange={this.handlePageName} type={this.props.form.getFieldValue('uriType')} />)}
      </Form.Item>
    );

    return (
      <HeaderMenu title="Notification Broadcast">
        <Loading loading={loading} />
        <Page>
          <Form>
            <Row>
              <Col span={6}>
                <Form.Item label="Audience">
                  {getFieldDecorator('audience', {
                    initialValue: 'All',
                    rules: [{ required, message: 'Please choose audience!' }],
                  })(<DropdownList menus={sendTypeOptions} allowClear={false} />)}
                </Form.Item>
              </Col>
              <Col span={16} offset={1}>
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  <AndroidExample
                    title={getFieldsValue().title}
                    subTitle={getFieldsValue().subTitle}
                    message={getFieldsValue().message}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Title">{getFieldDecorator('title', {})(<Input text="title" />)}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Sub Title">{getFieldDecorator('subTitle', {})(<Input text="sub title" />)}</Form.Item>
              </Col>
              <Col span={16} offset={1}>
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  <IosExample
                    title={getFieldsValue().title}
                    subTitle={getFieldsValue().subTitle}
                    message={getFieldsValue().message}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Message">
                  {getFieldDecorator('message', {
                    rules: [{ required, message: 'Please fill message!' }],
                  })(<TextArea text="message" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Push notification link">
                  {getFieldDecorator('launchUrl', {})(<Input text="Launch Url" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Delivery Schedule">
                  {getFieldDecorator('sendAfter', {
                    initialValue: 'immediately',
                  })(<DropdownList menus={deliveryScheduleOptions} allowClear={false} />)}
                </Form.Item>
              </Col>
              {getFieldsValue().sendAfter === 'specific_time' && (
                <Col span={6} offset={1}>
                  <Form.Item label="Delivery Time">
                    {getFieldDecorator('sendSchedule', {})(<DatePicker showTime />)}
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Per User Optimization">
                  {getFieldDecorator('perUserOptimize', {
                    initialValue: 'all',
                  })(<DropdownList menus={perUserOptimizeOptions} allowClear={false} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <Typography.Title level={4}>In-app notification link</Typography.Title>
                <Form.Item label="UriType">
                  {getFieldDecorator('uriType', {
                    initialValue: 'webview',
                  })(
                    <Select onChange={this.handleUriType} placeholder="Please select a status">
                      <Select.Option value="webview">webview</Select.Option>
                      <Select.Option value="inapp">inapp</Select.Option>
                    </Select>
                  )}
                </Form.Item>

                {this.isInApp() && pageNameFormItem}

                {this.renderPageParam()}

                <Form.Item label="Uri" hasFeedback>
                  {getFieldDecorator('inAppLink', {
                    initialValue: undefined,
                    preserve: true,
                    rules: [{ required: isRequired }],
                  })(<Input placeholder="Please input uri" />)}
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SaveBtn text="Send" icon="bell" onClick={this.onSend} style={{ width: 200 }} />
            </div>
          </Form>
        </Page>
        <ConfirmChangeStatusModal
          message="Notification Review"
          visible={modalVisible}
          onConfirm={this.onConfirm}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AndroidExample
              title={getFieldsValue().title}
              subTitle={getFieldsValue().subTitle}
              message={getFieldsValue().message}
            />
            <IosExample
              title={getFieldsValue().title}
              subTitle={getFieldsValue().subTitle}
              message={getFieldsValue().message}
            />
            <div style={{ width: 500, display: 'flex', flexDirection: 'column' }}>
              <span style={{ marginTop: 30 }}>
                <Key>Audience:</Key> {getOptionNameByVal(getFieldsValue().audience, sendTypeOptions)}
              </span>
              <span style={{ marginTop: 10 }}>
                <Key>Push notification link:</Key> {getFieldsValue().launchUrl}
              </span>
              <span style={{ marginTop: 10 }}>
                <Key>Delivery Schedule:</Key>{' '}
                {getFieldsValue().sendAfter === 'immediately'
                  ? 'Immediately'
                  : moment(getFieldsValue().sendSchedule).format()}
              </span>
              <span style={{ marginTop: 10 }}>
                <Key>Per User Optimization:</Key>{' '}
                {getOptionNameByVal(getFieldsValue().perUserOptimize, perUserOptimizeOptions)}
              </span>
              <span style={{ marginTop: 10 }}>
                <Key>In-app notification Link:</Key> {getFieldsValue().inAppLink}
              </span>
            </div>
          </div>
        </ConfirmChangeStatusModal>
      </HeaderMenu>
    );
  }
}
const WarppedBroadcast = Form.create()(Broadcast);
export default inject('notification')(observer(WarppedBroadcast));
