/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Button, Select } from 'antd';
import PointAdjustModal from './PointAdjustModal';
import isAdjustPointValueValid from '../../../validator/isAdjustPointValueValid';
import { IntCol, DateCol, Cols } from '../../../components/common';
import { notify } from '../../../utils/notify';
import UserInfo from '../UserInfo';
import { generalOrSuperAdmin } from '../../../utils/acl';
import { CategroryMap, ActivityCodeMap, CategroryMapWithoutActivePassive } from '../../../definition/mapping';
import { format } from '../../../utils/format';
import { config } from '../../../config';
import DateFilter from '../../../components/filter/DateFilter';
import { GroupItem } from '../../../components/forms';

export class UserPoint extends Component {
  constructor() {
    super();
    this.boardFilterRef = React.createRef();
    this.modalRef = React.createRef();
    this.state = {
      loading: false,
      category: '',
      pageSize: config.limit.page,
      columns: [
        {
          title: 'CATEGORY',
          dataIndex: 'category',
          key: 'category',
        },
        {
          title: 'TYPE',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'CODE',
          dataIndex: 'code',
          key: 'code',
          render: (value) => {
            if (!value) {
              return '-';
            }
            return value;
          },
        },
        {
          title: 'VALUE',
          dataIndex: 'amount',
          key: 'amount',
          render: (value, record) => <IntCol minus={record.minus} value={value} />,
        },
        {
          title: 'START\nBALANCE',
          dataIndex: 'startBalance',
          key: 'startBalance',
          width: '120px',
          render: (value) => <IntCol value={value} />,
        },
        {
          title: 'END\nBALANCE',
          dataIndex: 'endBalance',
          key: 'endBalance',
          width: '120px',
          render: (value) => <IntCol value={value} />,
        },
        {
          title: 'DATE',
          dataIndex: 'date',
          key: 'date',
          width: '120px',
          render: (value) => <DateCol value={value} />,
        },
      ],
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      const { id } = this.props.match.params;
      const { pageSize: limit, category } = this.state;
      const date = this.boardFilterRef.current.getDate();
      const promise = [
        this.props.user.getPersonal(id),
        this.props.user.getPointUser({ id, offset: params.offset, limit, category, date }),
      ];
      await Promise.all(promise);
    } catch (e) {
      notify.error({ title: 'Load User Point Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onPagin = (offset, limit) => {
    this.state.pageSizet = limit;
    this.onLoad({ offset });
  };

  onShowSizeChange = (current, size) => {
    this.state.pageSize = size;
    this.onLoad({ offset: current });
  };

  onCategoryChange = (cat) => {
    this.state.category = cat;
  };

  openModalPointAdjust = () => {
    this.modalRef.current.toggleModalPointAdjust();
  };

  addAdjustPoint = async (values) => {
    const adjustPointValues = { userId: this.props.match.params.id, ...values };
    const errorMessage = isAdjustPointValueValid(adjustPointValues);
    if (errorMessage === '') {
      try {
        this.setState({ loading: true });
        await this.props.user.addAdjustPoint(adjustPointValues);
        this.modalRef.current.reset();
        this.setState({ loading: false });
      } catch (e) {
        this.modalRef.current.setErrorMessage('');
        this.setState({ loading: false });
        notify.error({ title: 'Rebalance Point Fail', message: e.message });
      }
    } else {
      this.modalRef.current.setErrorMessage(errorMessage);
    }
  };

  getDataSource(list) {
    return list.map((item) => {
      let type = '';
      let category = '';
      let code = '';
      const minus = item.trans_type === 'cr';
      switch (item.ref_type) {
        case 'deposit':
          if (item.activity_id) {
            category = CategroryMap[item.activity_cat] || item.activity_cat;
            type = ActivityCodeMap[item.activity_code] || item.activity_code;
            code = '';
          } else if (item.coupon_item_id) {
            category = CategroryMap.coupon;
            type = item.coupon_name;
            code = item.coupon_item_code;
          } else if (item.plan_id) {
            category = CategroryMap.plancode;
            type = item.plancode_group;
            code = item.plancode;
          } else if (item.challenge_item_id) {
            category = CategroryMap.challenge;
            type = ActivityCodeMap[item.activity_code] || item.activity_code;
            code = item.challenge_name;
          }
          break;
        case 'pay':
          if (item.reward_item_id) {
            category = CategroryMap.reward;
            type = item.reward_name;
            code = item.digi_code;
          }
          break;
        case 'expire':
          category = CategroryMap.expire;
          break;
        case 'manual':
          category = CategroryMap.manual;
          break;
        default:
          break;
      }

      return {
        keyId: item.wallet_trans_id,
        category,
        type,
        code,
        minus,
        amount: item.amount,
        startBalance: item.start_balance,
        endBalance: item.end_balance,
        date: item.created_at,
      };
    });
  }

  renderBoarderFilterMoreItem = () => {
    const category = Object.entries(CategroryMapWithoutActivePassive).map(([key, value]) => (
      <Select.Option value={key} key={key}>
        {value}
      </Select.Option>
    ));
    return (
      <>
        <Cols>
          <GroupItem label="Category">
            <Select placeholder="Select Category" style={{ width: 180 }} onChange={this.onCategoryChange}>
              <Select.Option value="">All</Select.Option>
              {category}
            </Select>
          </GroupItem>
        </Cols>
      </>
    );
  };

  render() {
    const member = this.props.member.toJS();
    const user = this.props.user.toJS();
    const isAdmin = generalOrSuperAdmin(member.user.profile.role_name);
    const { page, list } = user.points;
    const datasource = this.getDataSource(list);

    return (
      <UserInfo title={`USERS-${this.props.user.personal.tmp_fname}`} name="POINTS">
        <DateFilter
          onFind={this.onLoad}
          ref={this.boardFilterRef}
          moreItem={this.renderBoarderFilterMoreItem()}
          dontLoadAtStart
        />

        <Table
          dataSource={datasource}
          columns={this.state.columns}
          loading={this.state.loading}
          bordered
          pagination={{
            current: page.offset,
            position: 'both',
            total: page.total,
            pageSizeOptions: ['10', '100', '1000'],
            onChange: this.onPagin,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
            showQuickJumper: true,
            showTotal: (total) => `Total ${format.toDigi(total)}`,
            hideOnSinglePage: false,
          }}
        />

        {isAdmin && (
          <Button style={css.btn} type="primary" shape="circle" onClick={this.openModalPointAdjust}>
            <i className="fas fa-plus" />
          </Button>
        )}

        {isAdmin && <PointAdjustModal ref={this.modalRef} addAdjustPoint={this.addAdjustPoint} />}
      </UserInfo>
    );
  }
}

const css = {
  title: {
    color: 'rgba(62, 130, 247, 0.87)',
    borderBottomWidth: '2px',
    padding: '25px 15px 10px 20px',
    textAlign: 'center',
    fontSize: '14px',
  },
  left: { textAlign: 'left' },
  img: {
    width: '100%',
    height: '40px',
    padding: '0px 5px',
    borderRadius: '8px',
  },
  btn: {
    width: '64px',
    height: '64px',
    bottom: '20px',
    right: '20px',
    fontSize: '32px',
    zIndex: '100',
    position: 'fixed',
  },
};

export default inject('user', 'member')(observer(UserPoint));
