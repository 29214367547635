import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import Colors from '../../assets/Colors';

const DraggableTableHeader = ({ children }) => {
  return <RowHeaderStyle>{children}</RowHeaderStyle>;
};

const RowHeaderStyle = styled(Row)`
  border: 1px solid ${Colors.borderTable};
  background-color: ${Colors.grey};
`;

export default DraggableTableHeader;
