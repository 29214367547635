import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, DatePicker } from 'antd';

import { Filter, GroupItem } from '../../../components/forms';
import { FindBtn } from '../../../components/button';

export default class CouponFilter extends Component {
  state = {
    start: undefined,
    end: undefined,
  };

  componentDidMount() {
    this.onFind();
  }

  onStartDate = (val) => {
    this.setState({ start: val });
  };

  onEndDate = (val) => {
    this.setState({ end: val });
  };

  onChange = (val, name) => {
    const { state } = this;
    state[name] = val;
    this.setState(state);
  };

  onFind = (evt) => {
    if (evt) evt.preventDefault();

    const { onFind } = this.props;
    if (onFind) onFind(this.state);
  };

  render() {
    const { start, end } = this.state;
    return (
      <Filter>
        <FilterPage>
          <Row>
            <Col span={3} style={css.col}>
              <GroupItem label="Start Date">
                <DatePicker style={css.item} value={start} onChange={this.onStartDate} />
              </GroupItem>
            </Col>
            <Col span={3} style={css.col}>
              <GroupItem label="End Date">
                <DatePicker style={css.item} value={end} onChange={this.onEndDate} />
              </GroupItem>
            </Col>

            <Col span={3} style={css.col}>
              <GroupItem>
                <FindBtn onClick={this.onFind} />
              </GroupItem>
            </Col>
          </Row>
        </FilterPage>
      </Filter>
    );
  }
}

const css = {
  item: {
    width: '98%',
  },
  col: {
    marginRight: '4px',
  },
};

const FilterPage = styled.div`
  padding: 10px;
`;
