import validator from 'validator';
import request from 'then-request';
import { error } from './error';
import { helper } from './helper';

const opt = { authorization: false };

export class Http {
  constructor() {
    this.httpReq = request;
    this.token = undefined;
  }

  setToken(auth) {
    this.token = auth;
  }

  haveToken() {
    return this.token !== undefined && this.token !== '';
  }

  setOptions(options) {
    const setting = {
      headers: {},
    };

    if (options.form) {
      setting.form = options.form;
    } else if (options.json) {
      setting.json = options.json;
    }

    if (options.qs) {
      setting.qs = options.qs;
    }

    if (options.headers) {
      setting.headers = options.headers;
    }
    setting.qs = {
      ...options.qs,
      random_number: +new Date().getTime(),
    };

    if (options.token && this.token) setting.headers.office_token = this.token;
    return setting;
  }

  async parseBody(res) {
    const isJson = validator.isJSON(res.body);
    if (isJson) res.body = JSON.parse(res.body);
    else if (res.statusCode === 0) res.body = { message: 'server is down' };
    else res.body = {};

    error.handle(res);
    return res;
  }

  async get(url, options = opt) {
    const config = this.setOptions(options);
    const response = await this.httpReq('GET', url, config);
    return this.parseBody(response);
  }

  async post(url, options = opt) {
    const config = this.setOptions(options);
    const res = await this.httpReq('POST', url, config);
    return this.parseBody(res);
  }

  async put(url, options = opt) {
    const config = this.setOptions(options);
    const res = await this.httpReq('PUT', url, config);
    return this.parseBody(res);
  }

  async patch(url, options = opt) {
    const config = this.setOptions(options);
    const res = await this.httpReq('PATCH', url, config);
    return this.parseBody(res);
  }

  async delete(url, options = opt) {
    const config = this.setOptions(options);
    const res = await this.httpReq('DELETE', url, config);
    return this.parseBody(res);
  }
}

export const http = new Http();
export default http;
