import React, { Component } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';

export default class Filter extends Component {
  state = {
    display: true,
  };

  onToggle = () => {
    this.setState((pre) => ({ display: !pre.display }));
  };

  render() {
    const { style, children } = this.props;
    return (
      <Page style={style}>
        <HeaderRow type="flex" justify="space-between" onClick={this.onToggle}>
          <Title>Filter</Title>
          <Icon>
            <i className="fas fa-filter" />
          </Icon>
        </HeaderRow>
        <BodyRow display={this.state.display ? '' : 'none'}>{children}</BodyRow>
      </Page>
    );
  }
}

const Page = styled.div`
  background-color: white;
  border-radius: 5px;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  border: 1px solid #e7e6e6;
  padding: 0px;
  margin-bottom: 15px;
  min-width: 220px;
`;

const Title = styled.h3`
  margin: 10px;
`;

const Icon = styled.div`
  padding: 10px;
  font-size: 18px;
  color: #e87722;
  text-align: center;
`;

const HeaderRow = styled(Row)`
  cursor: pointer;
`;

const BodyRow = styled(Row)`
  display: ${(props) => props.display};
  border: 0px solid #cacac9;
  border-top-width: 1px;
`;
