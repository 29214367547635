/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import './css/TagSelector.css';

const options = [
  { label: 'Steps', value: 'steps' },
  { label: 'Running', value: 'running' },
  { label: 'Swimming', value: 'swimming' },
  { label: 'Cycling', value: 'cycling' },
  { label: 'Dining', value: 'eating_check_in' },
  { label: 'Traveling', value: 'traveling_check_in' },
];

function PersonalTagCheckbox(props) {
  const [tags, setTags] = useState([]);
  const cleanup = () => setTags([]);
  function onChange(selectedItems) {
    setTags(selectedItems);
    props.onChangePersonalTag(selectedItems);
  }

  useEffect(() => {
    if (props.tags !== null) {
      const tagList = props.tags.split(',');
      setTags(tagList);
      props.onChangePersonalTag(tagList);
    }

    return cleanup;
  }, [props.tags]);

  return (
    <div className="tag-selector">
      <Checkbox.Group options={options} value={tags} onChange={onChange} />
    </div>
  );
}

export default PersonalTagCheckbox;
