/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import { Table, Input, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { Cols, DisplayStatus, DateCol, UserActivity } from '../../../components/common';
import format from '../../../utils/format';
import DateFilter from '../../../components/filter/DateFilter';
import { GroupItem } from '../../../components/forms';
import notify from '../../../utils/notify';
import UserInfo from '../UserInfo';

const columns = [
  {
    title: 'CHALLENGE NAME',
    dataIndex: 'title_en',
    key: 'title_en',
  },
  {
    title: 'REWARD',
    dataIndex: 'reward_title_en',
    key: 'reward_title_en',
  },
  {
    title: 'TYPE',
    dataIndex: 'activity_code',
    key: 'activity_code',
    render: (value) => <UserActivity value={value} />,
  },
  {
    title: 'CHALLENGE PROGRESS STATUS',
    dataIndex: 'challenge_item_status',
    key: 'challenge_item_status',
    render: (status) => <DisplayStatus type={3} status={status} />,
  },
  {
    title: 'CHALLENGE PROGRESS',
    dataIndex: 'progress',
    key: 'progress',
    render: (point) => {
      let currentPercent = (point / 100) * 100;
      if (currentPercent > 100) {
        currentPercent = 100;
      }
      return <span>{currentPercent.toFixed(0)}%</span>;
    },
  },
  {
    title: 'JOINED DATE',
    dataIndex: 'created_at',
    width: 120,
    key: 'created_at',
    render: (value) => <DateCol value={value} />,
  },
  {
    title: 'CANCEL DATE',
    dataIndex: 'cancelled_at',
    width: 120,
    key: 'cancelled_at',
    render: (value) => <DateCol value={value} />,
  },
  {
    title: 'COMPLETE DATE',
    dataIndex: 'completed_at',
    width: 120,
    key: 'completed_at',
    render: (value) => <DateCol value={value} />,
  },
  {
    title: 'COLLECT DATE',
    dataIndex: 'collected_at',
    width: 120,
    key: 'collected_at',
    render: (value) => <DateCol value={value} />,
  },
];

function UserChallenge(props) {
  const boardFilterRef = useRef(null);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');

  const fetchApi = async ({ date = {}, page = 1 }) => {
    try {
      const { id } = props.match.params;
      await props.challenge.getuserActivityList(id, date, page, name, type, status);
    } catch (e) {
      notify.error({ title: 'Load Challenge Activity Fail', message: e.message });
    }
  };

  function onFilterDate(date) {
    fetchApi({ date, page: 1 });
  }

  function onNameChange(e) {
    setName(e.target.value);
  }
  function onStatusChange(newStatus) {
    setStatus(newStatus);
  }

  function onTypeChange(newType) {
    setType(newType);
  }

  function onChangePage(page) {
    fetchApi({ date: boardFilterRef.current.getDate(), page: page.current });
  }

  function renderBoarderFilterMoreItem() {
    return (
      <>
        <Cols>
          <GroupItem label="Type">
            <Select placeholder="Select type" style={{ width: 180 }} onChange={onTypeChange}>
              <Select.Option value="">All</Select.Option>
              <Select.Option value="steps">Steps</Select.Option>
              <Select.Option value="running">Running</Select.Option>
              <Select.Option value="cycling">Cycling</Select.Option>
              <Select.Option value="swimming">Swimming</Select.Option>
              <Select.Option value="eating_check_in">Eating Check In</Select.Option>
              <Select.Option value="traveling_check_in">Traveling Check In</Select.Option>
            </Select>
          </GroupItem>
        </Cols>
        <Cols>
          <GroupItem label="Name">
            <Input onChange={onNameChange} />
          </GroupItem>
        </Cols>
        <Cols>
          <GroupItem label="Status">
            <Select placeholder="Select status" style={{ width: 180 }} onChange={onStatusChange}>
              <Select.Option value="">All</Select.Option>
              <Select.Option value="inprogress">In progress</Select.Option>
              <Select.Option value="cancelled">Cancelled</Select.Option>
              <Select.Option value="completed">Completed</Select.Option>
              <Select.Option value="collected">Collected</Select.Option>
              <Select.Option value="expired">Expired</Select.Option>
            </Select>
          </GroupItem>
        </Cols>
      </>
    );
  }

  const { list, loading, pagination } = props.challenge.activityLog;
  const paginationAttributes = {
    ...pagination,
    position: 'both',
    showQuickJumper: true,
    hideOnSinglePage: false,
    showTotal: (total) => `Total ${format.toDigi(total)}`,
  };

  return (
    <UserInfo title="User Challenge Activity Log" name="CHALLENGES">
      <DateFilter onFind={onFilterDate} ref={boardFilterRef} moreItem={renderBoarderFilterMoreItem()} />
      <Table
        rowKey="challenge_item_id"
        bordered
        columns={columns}
        dataSource={list.toJS()}
        loading={loading}
        onChange={onChangePage}
        pagination={paginationAttributes}
      />
    </UserInfo>
  );
}

export default inject('challenge')(observer(UserChallenge));
