import React, { Component } from 'react';
import { Table } from 'antd';
import { inject, observer } from 'mobx-react';

import { notify } from '../../../utils/notify';
import { format } from '../../../utils/format';
import { IntCol, DateCol } from '../../../components/common';
import UserInfo from '../UserInfo';
import ActivityFilter from './ActivityFilter';
import { ActivityGroupMap, ActivityCodeMap, ActivityCateMap } from '../../../definition/mapping';

export class UserActivity extends Component {
  state = {
    loading: false,
  };

  params = {};

  columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'GROUP',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'POINTS',
      dataIndex: 'points',
      key: 'points',
      render: (value) => <IntCol value={value} />,
    },
    {
      title: 'START TIME',
      dataIndex: 'startTime',
      key: 'startTime',
      width: '120px',
      render: (value) => <DateCol value={value} />,
    },
    {
      title: 'END TIME',
      dataIndex: 'endTime',
      key: 'endTime',
      width: '120px',
      render: (value) => <DateCol value={value} />,
    },
  ];

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      const { id } = this.props.match.params;
      const promise = [this.props.user.getPersonal(id), this.props.user.getActivityUser({ id, ...params })];
      await Promise.all(promise);
    } catch (e) {
      notify.error({ title: 'Load Activity User Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onFilter = async (params = {}) => {
    params.offset = 1;
    this.params = params;
    this.onLoad(this.params);
  };

  onPagin = (offset) => {
    this.params.offset = offset;
    this.onLoad(this.params);
  };

  onShowSizeChange = (current, size) => {
    this.params.offset = current;
    this.params.limit = size;

    this.onLoad(this.params);
  };

  toString = (item) => {
    let text = '';
    try {
      const keys = Object.keys(item);
      for (const key of keys) {
        switch (key) {
          case 'image':
          case 'imageList':
          case 'location':
          case 'laps':
          case 'userId':
          case 'samples':
            break;
          case 'points':
            const points = item[key];
            text += this.toString(points);
            break;
          default:
            text += `${key} = ${item[key]} `;
        }
      }
    } catch (e) {}

    return text;
  };

  render() {
    const { loading } = this.state;
    const { offset: currentPage } = this.params;
    const doc = this.props.user.toJS();
    const { personal } = doc;
    const { page, list } = doc.activity;

    const dataSource = list.map((item) => {
      const group = item['ActivityConfig.activity_group'];
      let start_time;

      if (group === 'active activity') start_time = item.start_time;
      else start_time = item.createdAt;

      const category = item['ActivityConfig.activity_cat'];

      return {
        name: ActivityCodeMap[item.activity_code] || item.activity_code,
        group: ActivityGroupMap[group] || group,
        category: ActivityCateMap[category] || category,
        points: item['Point.point'],
        startTime: start_time,
        endTime: item.end_time,
      };
    });

    return (
      <UserInfo title={`ACTIVITY-${personal.tmp_fname}`} name="ACTIVITY">
        <ActivityFilter onFind={this.onFilter} />
        <Table
          bordered
          loading={loading}
          dataSource={dataSource}
          columns={this.columns}
          pagination={{
            current: currentPage || 1,
            position: 'both',
            total: page.total,
            pageSizeOptions: ['10', '100', '1000'],
            onChange: this.onPagin,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
            showQuickJumper: true,
            showTotal: (total) => `Total ${format.toDigi(total)}`,
            hideOnSinglePage: false,
          }}
        />
      </UserInfo>
    );
  }
}

export default inject('user')(observer(UserActivity));
