import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import HeaderMenu from '../../../../components/main/HeaderMenu';

const PageRedirect = () => {
  return (
    <HeaderMenu title="Redirecting">
      <StyledPage>
        Redirecting <Icon type="loading" />
      </StyledPage>
    </HeaderMenu>
  );
};

const StyledPage = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  padding: 60px;
  width: 100%;
  text-align: center;
`;

export default PageRedirect;
