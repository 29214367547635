import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Row, Col } from 'antd';
import moment from 'moment';
import { isEqual } from 'lodash';

import FileSaver from 'file-saver';
import HeaderMenu from '../../../components/main/HeaderMenu';

import { Loading, SaveSection } from '../../../components/common';
import { notify } from '../../../utils/notify';
import { timer } from '../../../utils/timer';

import Defaulter from './Defaulter';
import Config from './Config';
import { Btn, SelectFile } from '../../../components/button';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const NAME = 'Paid Policy';
export class PointPaidPolicy extends Component {
  state = {
    loading: false,
    validate: {},
  };

  componentDidMount() {
    this.onFind();
  }

  onFind = async () => {
    try {
      this.setState({ loading: true });
      await this.props.point.getPlancode();
      await this.props.point.getConfigPlancode();
    } catch (e) {
      notify.error({ title: `Load ${NAME} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  inRange({ index = 0, code, value, list = [] }) {
    let i = 0;
    const val = timer.toDatetime(value).unix();

    for (const item of list) {
      if (i !== index && code === item.plancode) {
        const start_at = timer.toDatetime(item.start_at).unix();
        const end_at = timer.toDatetime(item.end_at).unix();

        if (val >= start_at && val <= end_at) return true;
      }
      i++;
    }

    return false;
  }

  validate = () => {
    try {
      const validate = {};
      const { doc } = this;
      const defaulter = doc.default;
      const { config } = doc;
      if (defaulter.baht === 0 || defaulter.baht === null) {
        validate.default = { baht: true };
      }
      if (defaulter.point === null) {
        validate.default = { point: true };
      }
      if (config.loyaltyHoldPeriod === null) {
        validate.config = { loyaltyHoldPeriod: true };
      }
      if (config.loyaltyPastYears === null) {
        validate.config = { loyaltyPastYears: true };
      }
      if (config.loyaltyMaxPointPerYear === null) {
        validate.config = { loyaltyMaxPointPerYear: true };
      }

      let index = 0;
      const { list } = doc;
      for (const item of list) {
        const code = item.plancode;
        const value = {};
        if (item.plancode === '') value.plancode = true;
        if (this.inRange({ index, code, value: item.start_at, list })) value.start_at = true;
        if (this.inRange({ index, code, value: item.end_at, list })) value.end_at = true;

        if (isEqual({}, value) === false) validate[index] = value;
        index++;
      }

      this.setState({ validate });
      if (isEqual({}, validate)) return true;
    } catch (e) {}

    return false;
  };

  onSave = async () => {
    if (this.validate() === false) return;

    try {
      this.setState({ loading: true });
      await this.props.point.savePlancode();
      await this.props.point.saveConfigPlancode();

      notify.success({ title: `Save ${NAME} Complete` });
    } catch (e) {
      notify.error({ title: `Save ${NAME} Fail`, message: e.message });
    }
    await this.props.point.getPlancode();
    await this.props.point.getConfigPlancode();
    this.setState({ loading: false });
  };

  onAdd = () => {
    this.doc.list.push({
      plancode: '',
      baht: 0,
      point: 1,
      multiply: 1,
      plancode_point_calculation_type: 'multiply',
      is_default: false,
      status: 'active',
      start_at: moment().startOf('day'),
      end_at: moment().startOf('day'),
    });
    this.props.point.setPlancode(this.doc);
  };

  onRemove = (index) => {
    this.doc.list.splice(index, 1);
    this.props.point.setPlancode(this.doc);
  };

  onChange = (index, item) => {
    this.doc.list[index] = item;
    this.props.point.setPlancode(this.doc);
  };

  onExport = async () => {
    try {
      const blob = await this.props.point.exportPlancode();
      if (blob !== undefined) {
        const name = `plancode_${moment().format('YYYYMMDDHHmmss')}.csv`;
        FileSaver.saveAs(blob, name);
        notify.success({ title: 'Export Plancode Success' });
      }
    } catch (e) {
      notify.error({ title: 'Export Plancode Fail', message: e.message });
    }
  };

  onImport = async () => {
    try {
      const { importList } = this.doc;
      if (importList.length === 0) {
        notify.error({ title: 'Please select file for import plancode' });
        return;
      }
      this.setState({ loading: true });
      await this.props.point.importPlancode({ list: importList });
      this.doc.importList = [];
      this.props.point.setPlancode(this.doc);
      notify.success({ title: 'Import Plancode Success' });
    } catch (e) {
      notify.error({ title: 'Import Plancode Fail', message: e.message });
    }
    await this.props.point.getPlancode();
    this.setState({ loading: false });
  };

  convertDateTime = (val) => {
    return moment(val).format(DATE_FORMAT);
  };

  onFile = (file) => {
    const list = [];
    let lines = file.split('\n');
    lines = lines.splice(1, lines.length - 1);
    for (let item of lines) {
      item = item.replace('\r', '');
      const cols = item.split(',');
      if (cols.length > 0) {
        list.push({
          plancode: cols[1],
          plancode_group: cols[2] !== '-' && cols[2] !== '' ? cols[2] : null,
          baht: 0,
          point: 1,
          multiply: cols[3] !== '-' && cols[3] !== '' ? cols[3] : null,
          plancode_point_calculation_type: 'multiply',
          status: 'active',
          is_default: false,
          start_at: this.convertDateTime(cols[4]),
          end_at: this.convertDateTime(cols[5]),
        });
      }
    }
    this.doc.importList = list;
    if (this.validateImport() === false) this.doc.importList = [];
    this.props.point.setPlancode(this.doc);
  };

  validateImport = () => {
    const { doc } = this;
    const plancodeList = [];
    let index = 0;
    const { importList: list } = doc;
    let errorFlag = false;
    for (const item of list) {
      if (!item.plancode || item.plancode.length > 45) errorFlag = true;
      if (item.plancode_group && item.plancode_group.length > 45) errorFlag = true;
      // if (isNaN(Number(item.point)) || Number(item.point) < 0) errorFlag = true
      if (item.multiply !== null && (isNaN(Number(item.multiply)) || Number(item.multiply) < 0)) errorFlag = true;
      if (!moment(item.start_at).isValid() || !moment(item.end_at).isValid()) errorFlag = true;
      if (!moment(item.start_at).isBefore(moment(item.end_at))) errorFlag = true;
      index++;
      if (errorFlag || plancodeList.indexOf(item.plancode) > -1) {
        errorFlag = true;
        notify.error({ title: `Import Plancode Fail: Invalid or plancode duplicated inline ${index}` });
        break;
      }
      plancodeList.push(item.plancode);
    }
    return !errorFlag;
  };

  render() {
    const { loading, validate } = this.state;
    const doc = this.props.point.toJS().plancode;
    this.doc = doc;

    return (
      <HeaderMenu title={NAME}>
        <Loading loading={loading} />
        <Page>
          <Config validate={validate.config} />
        </Page>
        <Page>
          <Form colon={false}>
            <Defaulter validate={validate.default} />
          </Form>
        </Page>
        <Page>
          <Form colon={false}>
            <Row>
              <Title>Special Plan</Title>
              <Col span={3} style={css.col}>
                <Btn style={css.btn} text={`EXPORT ${this.doc.list.length} ITEMS`} onClick={this.onExport} />
              </Col>
              <Col span={3} style={css.col}>
                <Btn style={css.btn} text={`IMPORT ${this.doc.importList.length} ITEMS`} onClick={this.onImport} />
              </Col>
              <Col span={3} style={css.col}>
                <SelectFile disabled={false} accept=".csv" onChange={this.onFile} />
              </Col>
            </Row>
          </Form>
        </Page>
        <br />
        <SaveSection onClick={this.onSave} />
      </HeaderMenu>
    );
  }
}

const Page = styled.div`
  padding: 20px 0px 20px 20px;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 30px;
`;

const css = {
  btn: {
    borderRadius: '18px',
    height: '36px',
  },
  col: {
    marginRight: '20px',
  },
};

const Title = styled.h2`
  padding: 10px 0px;
`;

export default inject('point')(observer(PointPaidPolicy));
