import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { history } from '../../../utils/history';

const SurveyScheduleBackButton = () => {
  return (
    <StyledBackButton type="ghost" onClick={() => history.push('/office/config/survey-schedule')} icon="backward">
      Go Back
    </StyledBackButton>
  );
};

const StyledBackButton = styled(Button)`
  float: left;
  height: 48px;
  width: 120px;
  font-size: 14px;
  text-transform: uppercase;
  border-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  :focus,
  :active,
  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default SurveyScheduleBackButton;
