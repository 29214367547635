import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Icon, Input, Button } from 'antd';
import styled from 'styled-components';

import { LoadingBtn } from '../../components/button';

const { Item } = Form;

const logo = require('../../assets/img/maxlogo.svg');

const cssBtn = {
  color: '#ECECEC',
  backgroundColor: '#575756',
  borderRadius: '15px',
  width: '100%',
};

const cssItem = {
  marginBottom: '5px',
};

export class Login extends Component {
  state = {
    loading: false,
    status: '',
    message: '',
  };

  onSubmit = async (evt) => {
    let form;
    try {
      evt.preventDefault();
      form = await this.props.form.validateFields();
    } catch (e) {
      return;
    }

    try {
      this.setState({ loading: true });
      await this.props.member.login(form);
      this.setState({ loading: false, status: '', message: '' });
    } catch (e) {
      this.setState({ loading: false, status: 'error', message: e.message });
    }
  };

  render() {
    const { loading, status, message } = this.state;
    const { getFieldDecorator } = this.props.form;
    const colorCss = {
      color: 'rgba(0,0,0,.25)',
    };

    let textErr = <div />;
    if (status === 'error') {
      textErr = (
        <TextError>
          <i className="fas fa-exclamation-circle" />
          {message}
        </TextError>
      );
    }

    let btn;
    if (loading) {
      btn = <LoadingBtn loading={loading} />;
    } else {
      btn = (
        <Button style={cssBtn} htmlType="submit">
          LOGIN
        </Button>
      );
    }

    return (
      <LoginSection>
        <Logo src={logo} />
        <Form onSubmit={this.onSubmit} className="login-form">
          <Item style={cssItem}>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username!' }],
            })(<Input disabled={loading} prefix={<Icon type="user" style={colorCss} />} placeholder="Username" />)}
          </Item>
          <Item style={cssItem}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your password!' }],
            })(
              <Input
                disabled={loading}
                prefix={<Icon type="lock" style={colorCss} />}
                type="password"
                placeholder="Password"
              />
            )}
          </Item>
          <Item style={cssItem}>{textErr}</Item>
          <Item style={cssItem}>{btn}</Item>
        </Form>
      </LoginSection>
    );
  }
}

const LoginSection = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 40px 40px 10px 40px;
`;

const Logo = styled.img`
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: #f14338;
  text-align: center;
  margin-bottom: 0px;
  i {
    margin-right: 4px;
  }
`;

const LoginForm = Form.create({ name: 'normal_login' })(Login);
export default inject('member')(observer(LoginForm));
