import React, { Component } from 'react';
import { Input } from 'antd';

export default class TextArea extends Component {
  onChange = (evt) => {
    const { name, onChange } = this.props;
    if (onChange) {
      onChange(evt.target.value, name);
    }
  };

  render() {
    const { readOnly = false, disabled = false, value = '', rows = '2' } = this.props;
    const css = { width: '100%' };
    if (readOnly) {
      css.backgroundColor = '#DADAD9';
    }
    return (
      <Input.TextArea
        readOnly={readOnly}
        disabled={disabled}
        style={css}
        value={value}
        rows={rows}
        onChange={this.onChange}
      />
    );
  }
}
