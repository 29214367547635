import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';

import { TabMenu } from '../../components/forms';
import { history } from '../../utils/history';

import { notify } from '../../utils/notify';

const name = 'BADGES';
class BadgeTab extends Component {
  onChange = (item = {}) => {
    const { url } = item.params;
    if (url) history.push(url);
  };

  componentDidMount() {
    this.onFind();
  }

  onFind = async () => {
    try {
      this.setState({ loading: true });
      await this.props.badge.getBadgeLevel();
    } catch (e) {
      notify.error({ title: `Load ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  trasnformBadgeLevelToMenu = (badgeConfig) => {
    const menus = [];
    for (const [key, value] of Object.entries(badgeConfig)) {
      if (key.indexOf('Amount') === -1) {
        const level = key.replace('badgeLv', '');
        const name = `LV.${level} ${value}`;
        const params = { url: `/office/badge/${level}` };
        menus.push({ name, params });
      }
    }
    return menus;
  };

  render() {
    const { defaultIndex = 0, loading, children } = this.props;
    const doc = this.props.badge.toJS().badgeConfig;
    const menus = this.trasnformBadgeLevelToMenu(doc);
    return (
      <HeaderMenu title={name}>
        <Loading loading={loading} />
        <Page style={pageCss}>
          <TabMenu defaultIndex={defaultIndex} menus={menus} onChange={this.onChange} />
          <br />
          {children}
        </Page>
      </HeaderMenu>
    );
  }
}

const pageCss = {
  backgroundColor: 'white',
  borderRadius: '5px',
};

export default inject('badge')(observer(BadgeTab));
