// These field options are used for dropdown field on survey filters
const surveyFieldOptions = {
  gender: [{ name: 'ทั้งหมด', value: 'all' }, { name: 'ชาย', value: 'ชาย' }, { name: 'หญิง', value: 'หญิง' }],
  income: [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'ต่ำกว่า 15,000', value: 'ต่ำกว่า 15,000' },
    { name: '15,000 - 25,000', value: '15,000 - 25,000' },
    { name: '25,001 - 50,000', value: '25,001 - 50,000' },
    { name: '50,001 - 100,000', value: '50,001 - 100,000' },
    { name: '100,001 - 200,000', value: '100,001 - 200,000' },
    { name: 'มากกว่า 200,000', value: 'มากกว่า 200,000' },
  ],
  education: [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'ต่ำกว่ามัธยมศึกษา', value: 'ต่ำกว่ามัธยมศึกษา' },
    { name: 'มัธยมศึกษา/ปวช.', value: 'มัธยมศึกษา/ปวช.' },
    { name: 'อนุปริญญา/ปวส.', value: 'อนุปริญญา/ปวส.' },
    { name: 'ปริญญาตรีหรือสูงกว่า', value: 'ปริญญาตรีหรือสูงกว่า' },
  ],
  occupation: [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'นักเรียน, นักศึกษา', value: 'นักเรียน, นักศึกษา' },
    { name: 'ข้าราชการ, พนักงานรัฐวิสาหกิจ', value: 'ข้าราชการ, พนักงานรัฐวิสาหกิจ' },
    { name: 'พนักงานบริษัท, ลูกจ้าง', value: 'พนักงานบริษัท, ลูกจ้าง' },
    { name: 'ประกอบการธุรกิจส่วนตัว', value: 'ประกอบการธุรกิจส่วนตัว' },
    { name: 'อาชีพอิสระ, รับจ้าง', value: 'อาชีพอิสระ, รับจ้าง' },
    { name: 'เกษียณ, ว่างงาน', value: 'เกษียณ, ว่างงาน' },
    { name: 'อื่นๆ', value: 'อื่นๆ' },
  ],
  maritalStatus: [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'โสด', value: 'โสด' },
    { name: 'สมรส (จดทะเบียน และ ไม่จดทะเบียน)', value: 'สมรส (จดทะเบียน และ ไม่จดทะเบียน)' },
    { name: 'หย่าร้าง', value: 'หย่าร้าง' },
    { name: 'หม้าย', value: 'หม้าย' },
  ],
};

export default surveyFieldOptions;
