import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { notify } from '../utils/notify';
import UserFilterUtilities from '../utils/user-filter-utilities';

export class Whitelist extends BaseStore {
  constructor() {
    super();
    this.observable({
      loading: false,
      whitelists: [],
      whitelistUsers: [],
      allWhitelistUsers: [],
      total: 0,
      pagination: {},
      allPagination: {},
      status_list: [],
    });
  }

  processUsers(jsonArr) {
    if (jsonArr === undefined) return undefined;
    if (!jsonArr) return [];
    const items = jsonArr.map((v) => {
      return {
        tmp_phone: v['Mobile Number'],
        is_agent: v['Is Whitelist (Yes/No)'],
      };
    });
    return items;
  }

  async getWhitelistList() {
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist`;
    const res = await http.get(url, { token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    console.log(res.body)
    this.whitelists = res.body;
  }

  async saveWhitelist(data = {}) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist`;
    const res = await http.put(url, { json: { ...data, users: this.processUsers(data.users) }, token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    } else {
      notify.success({ title: 'Update Whitelist Successfully' });
    }
    console.log(res.body)
    this.getAllWhitelistUsers(1);
  }

  async createWhitelist(data = {}) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist`;
    const res = await http.post(url, { json: { ...data, users: this.processUsers(data.users) }, token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    console.log(res.body)
    await this.getAllWhitelistUsers(1);
    await this.getWhitelistList();
  }

  async deleteWhitelist(data = {}) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist`;
    const res = await http.delete(url, { json: { ...data }, token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    } else {
      notify.success({ title: 'Delete Whitelist Successfully' });
    }
    console.log(res.body)
    this.getAllWhitelistUsers(1);
  }

  async getWhitelistUsers(whitelistId, page = 1) {
    this.whitelistUsers = [];
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist/users/${whitelistId}`;
    const qs = { page, limit: 10 };
    const res = await http.get(url, { qs, token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    console.log(res.body);
    this.whitelistUsers = res.body.list;
    // this.whitelistTotal = res.body.total;
    this.pagination = {
      total: res.body.total,
      current: page,
    };
  }

  async getAllWhitelistUsers(page, mobileNumber, name, lastName) {
    const { status_list } = this.toJS();
    this.whitelistUsers = [];
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist/users`;
    const qs = { mobileNumber, name, lastName, page, limit: 10 };
    const res = await http.get(url, { qs, token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    console.log(res.body);
    this.allWhitelistUsers = [...res.body.list];
    this.total = res.body.total
    this.allPagination = {
      total: res.body.total,
      current: page,
    };

    const newUserStatus = status_list.reduce((mem, cur) => {
      mem[cur.user_id] = cur.status;
      return mem
    }, {})

    this.allWhitelistUsers.forEach(user => {
      if (user.user_id in newUserStatus) {
        user.status = newUserStatus[user.user_id ];
      }
    });
  }

  changeStatus({ index, user_id, status }) {
    const { allWhitelistUsers, status_list } = this.toJS();
    const newStatus = status === 'active' ? 'inactive' : 'active';

    const i = status_list.findIndex((item) => item.user_id === user_id);
    if (i < 0) {
      status_list.push({ user_id, status: newStatus });
    } else {
      status_list.splice(i, 1);
    }

    allWhitelistUsers.forEach(user => {
      if (user.user_id === user_id) {
        user.status = newStatus;
      }
    });

    this.allWhitelistUsers = allWhitelistUsers;
    this.status_list = status_list;
  }

  async saveChangeStatus() {
    const { status_list } = this.toJS();
    const active_list = [];
    const inactive_list = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of status_list) {
      if (item.status === 'active') active_list.push(item.user_id);
      else inactive_list.push(item.user_id);
    }

    const url = `${config.api.max}/v1/office/user/status/update`;
    const res = await http.post(url, {
      json: { active_list, inactive_list },
      token: true,
    });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.status_list = [];
  }

  async exportWhitelist(whitelistId) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/whitelist/users/${whitelistId}`;
    const qs = { limit: 100 };
    const items = [];
    let total = 1;
    let firstFetch = true;
    for (let page = 1; items.length < total; page++) {
      qs.page = page;
      const resp = await http.get(url, { qs, token: true });
      if (resp.statusCode !== 200) {
        this.loading = false;
        error.launch({ message: resp.body.message });
        return null;
      }
      if (firstFetch) {
        firstFetch = false;
        total = resp.body.total;
        // this.agent.pagination.total = total;
      }
      items.push(...resp.body.list);
    }

    this.loading = false;
    return items;
  }
}

export default new Whitelist();
