import React from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import styled from 'styled-components';
import SwitchButton from '../switch-button';
import { SecondaryButton, PrimaryButton, RemoveBtn } from '../button';
import TimePicker from './TimePicker'
import moment from 'moment'

/**
 * This constant is defined on max-api
 */
const POINT_ADJUST = 'point_adjust';

const reminderType = {
  constant: 'แจ้งเตือนทุกวันที่',
  relative: 'แจ้งเตือนล่วงหน้า',
};

// const { Input } = Input;
const { Option } = Select;

const PointExpireReminderForm = React.forwardRef((props, ref) => {
  const { notificationTemplate, onSubmit, onCancel, onUpdateProgress, onDelete } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const { getFieldDecorator } = props.form;

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      console.log(err)
      if (!err) {
        onSubmit({ ...values, ...{ id: notificationTemplate.id }, time: values.time.format("HH:mm") });
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} ref={ref} colon={false}>
      <StyledFormItem label="Active">
        {getFieldDecorator('is_active', {
          initialValue: notificationTemplate.is_active,
        })(<SwitchButton defaultChecked={notificationTemplate.is_active} />)}
      </StyledFormItem>
      <Form.Item label="Type" >
        {getFieldDecorator('type', {
          initialValue: notificationTemplate.type,
          getValueFromEvent: (e) => {
            setTimeout(() => props.form.validateFields());
            return e;
          },
          rules:[{ required: true, message: 'กรุณาเลือกประเภทการแจ้งเตือน' }]
        })(
          <Select style={{ width: 220 }}>
            {Object.keys(reminderType).map((a) => (
              <Option key={a} value={a}>
                {reminderType[a]}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={props.form.getFieldValue("type") === 'relative' ? "ล่วงหน้า(วัน)" : "วันที่"}>
        {getFieldDecorator('date', {
          initialValue: notificationTemplate.date,
          getValueFromEvent: (e) => {
            const convertedValue = Number(e.currentTarget.value);
            if (isNaN(convertedValue)) {
              return Number(props.form.getFieldValue("date"));
            } else {
              return convertedValue;
            }
          },
          rules: [{ type: "number", min: props.form.getFieldValue("type") === 'relative' ? 0 : 1, max: props.form.getFieldValue("type") === 'relative' ? 30 : 28 , required: true, message: props.form.getFieldValue("type") === 'relative' ? 'วันล่วงหน้าต้องอยู่ระหว่าง 0 - 30​' : 'วันที่ต้องอยู่ระหว่าง 1 - 28​'}, 
          // {
          //   type: "number",
          //   max: 28,
          //   message: 'แจ้งเตือนไม่ควรใส่เกินวันที่ 28​',
          // }
        ]
        })(<Input style={{ width: 220 }} />)}
      </Form.Item>
      <Form.Item label="เวลา">
        {getFieldDecorator('time', {
          initialValue: moment(notificationTemplate.time, "HH:mm"),
          rules:[{ required: true, message: 'กรุณาเลือกเวลาที่จะแจ้งเตือน' }]
        })(<TimePicker style={{ width: 220 }} format="HH:mm" />)}
      </Form.Item>
      <Row style={{ width: '100%' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item {...tailFormItemLayout}>
            <SecondaryButton align="left" title="Cancel" disabled={onUpdateProgress} onClick={onCancel} />
            <RemoveBtn align="left" title="Delete" disabled={onUpdateProgress} onClick={() => onDelete(notificationTemplate.id)} style={{ width: "90px", height: "40px" }}/>
            <PrimaryButton
              title="Update"
              loading={onUpdateProgress}
              disabled={onUpdateProgress}
              style={{ marginLeft: 8 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

const StyledFormItem = styled(Form.Item)`
  .ant-switch {
    margin-top: -11px;
  }
`;

const WrappedPointExpireReminderForm = Form.create({ name: 'push_notification_template_update' })(
  PointExpireReminderForm
);

export default WrappedPointExpireReminderForm;
