import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Avatar, Table, Row, Col, Modal } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { BadgeMap } from '../../../definition/mapping';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { UserExportButton } from '../../../components/button';
import { notify } from '../../../utils/notify';
import { helper } from '../../../utils/helper';
import { SaveSection, IntCol, DateCol, Loading } from '../../../components/common';
import UserFilter from './UserFilter';
import { SwitchButton, CustomerType } from '../../../components';
import { PERM_ACCESS_MY_DOWNLOAD } from '../../../config/permissions';
import { can } from '../../../utils/acl';

const { confirm } = Modal;

export class UserList extends Component {
  params = {};

  constructor() {
    super();
    this.filterRef = React.createRef();
    this.state = {
      loading: false,
      limit: 10,
      modalLoading: false,
      columns: [
        {
          title: 'MAX ID',
          dataIndex: 'maxId',
          key: 'maxId',
          width: '90px',
        },
        {
          title: 'NAME',
          dataIndex: 'fullname',
          key: 'fullname',
          width: '260px',
          render: (fullname, record) => {
            return (
              <div>
                <Link to={record.link}>
                  <Avatar size={50} icon="user" src={record.url} />
                </Link>
                <Link style={{ color: '#686666' }} to={record.link}>
                  <span style={{ marginLeft: '10px' }}>{fullname}</span>
                </Link>
              </div>
            );
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          render: (status, record) => {
            return (
              <SwitchButton
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={status === 'active'}
                onChange={() => {
                  this.onStatus({
                    index: record.index,
                    user_id: record.maxId,
                    status: record.status,
                  });
                }}
              />
            );
          },
        },
        {
          title: 'CUSTOMER TYPE',
          dataIndex: 'customerType',
          key: 'customerType',
          render: (customerType) => <CustomerType title={customerType} />,
        },
        {
          title: 'BALANCE',
          dataIndex: 'balance',
          key: 'balance',
          render: (balance) => <IntCol value={balance} />,
          width: '120px',
        },
        {
          title: 'BADGE',
          dataIndex: 'badge',
          key: 'badge',
          render: (value) => BadgeMap[value],
        },
        {
          title: 'TIER',
          dataIndex: 'tier',
          key: 'tier',
          render: (value) => console.log('value', value) || value,
        },
        {
          title: 'JOIN',
          dataIndex: 'join',
          key: 'join',
          width: '120px',
          render: (join) => <DateCol value={join} />,
        },
        {
          title: 'MOBILE',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          title: 'ACTION',
          key: 'action',
          render: (_, record) => {
            return <Link to={record.link}>View</Link>;
          },
        },
      ],
    };
  }

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      params.limit = this.state.limit;
      await this.props.user.getList(params);
    } catch (e) {
      notify.error({ title: 'Load User Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onFilter = async (params = {}) => {
    params.offset = 1;
    this.state.limit = 10;
    this.params = params;

    this.onLoad(this.params);
  };

  onShowSizeChange = (current, size) => {
    this.params.offset = current;
    this.state.limit = size;

    this.onLoad(this.params);
  };

  onPagin = (offset, limit) => {
    this.params.offset = offset;
    this.state.limit = limit;

    this.onLoad(this.params);
  };

  onStatus = ({ index, user_id, status }) => {
    this.props.user.changeStatus({ index, user_id, status });
  };

  onSaveStatus = async () => {
    try {
      this.setState({ loading: true });
      await this.props.user.saveChangeStatus();
      notify.success({ title: 'Save User Status Success' });
    } catch (e) {
      notify.error({ title: 'Save User Status Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onExportUser = async () => {
    Modal.destroyAll();

    const exportModal = confirm({
      title: 'Confirm Export',
      content: 'Export can take long time to complete. Please confirm to begin export.',
      okText: 'Yes, Export',
      icon: <ExportOutlined />,
      cancelText: 'No',
      closable: true,
      onOk: async () => {
        exportModal.update({
          content: `When ready, you will get a report file to download. Please do not close this browser window.`,
          confirmLoading: true,
          okButtonProps: {
            disabled: true,
          },
          cancelButtonProps: {
            disabled: true,
          },
        });
        const params = this.filterRef.current.getFilter();
        try {
          const fileName = await this.props.report.exportUserList(params);
          await this.props.report.downloadFile({ path: 'userReports', fileName });
        } catch (error) {
          notify.error({ title: 'Export Failed', message: error.message });
        }
      },
    });
  };

  render() {
    const { loading, modalLoading, columns } = this.state;
    const { user, member } = this.props;
    const roleName = member.user.profile.role_name;
    const doc = user.user;

    const { page, list } = doc;

    const sources = list.map((item, index) => {
      let name = item.tmp_fname;
      if (item.tmp_lname) {
        name = `${item.tmp_fname} ${item.tmp_lname}`;
      }

      return {
        key: item.user_id,
        maxId: item.user_id,
        fullname: name,
        status: item.status,
        customerType: helper.getLabelForFwdCustomerOrLead(item.is_fwd),
        balance: item.balance,
        badge: BadgeMap[item.level],
        tier: item.tier,
        join: item.createdAt,
        mobile: item.tmp_phone,
        url: helper.getUrl(item.image_path),
        link: `/office/user/${item.user_id}/personal`,
        index,
      };
    });

    const renderExportButton = () => {
      const hasPermission = can(PERM_ACCESS_MY_DOWNLOAD, roleName);
      if (!hasPermission) {
        return null;
      }

      return (
        <ExportButtonContainer>
          <Col span={4}>
            <StyledUserExportButton onClick={this.onExportUser} title="EXPORT USER" />
          </Col>
        </ExportButtonContainer>
      );
    };

    return (
      <HeaderMenu title="USERS">
        <Loading loading={modalLoading} />
        <StyledPage>
          <UserFilter onFind={this.onFilter} ref={this.filterRef} />
          <Row style={{ position: 'relative' }}>
            {renderExportButton()}
            <Table
              style={{ marginTop: Number(page.total) === 0 ? '60px' : 0 }}
              dataSource={sources}
              columns={columns}
              loading={loading}
              bordered
              rowClassName={(_, index) => {
                if (index % 2 === 0) {
                  return 'even-row';
                }

                return 'odd-row';
              }}
              pagination={{
                position: 'both',
                total: page.total,
                pageSizeOptions: ['10', '100', '1000'],
                onChange: this.onPagin,
                showSizeChanger: true,
                onShowSizeChange: this.onShowSizeChange,
                showQuickJumper: true,
                showTotal: (total) => `Total ${total}`,
                hideOnSinglePage: false,
              }}
            />
          </Row>
          <SaveSection display={user.status_list.length > 0} onClick={this.onSaveStatus} />
        </StyledPage>
      </HeaderMenu>
    );
  }
}

const ExportButtonContainer = styled(Row)`
  position: relative;
  z-index: 1;
  clear: both;
`;

const StyledUserExportButton = styled(UserExportButton)`
  position: absolute;
  width: auto;
  margin-top: 15px;
`;

const StyledPage = styled.div`
  padding: 20px 20px;
  background-color: #fff;
  font-family: Tahoma, Geneva, Verdana, sans-serif !important;
`;

export default inject('user', 'report', 'member')(observer(UserList));
