import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'antd';

import SectionItem from './SectionItem';
import RowItem from './RowItem';
import { SaveSection } from '../../../../components/common';

export class PointActivity extends Component {
  onChange = (name, tag, val) => {
    this.doc[name][tag] = val;
    this.props.point.setActivity(this.doc);
  };

  render() {
    const doc = this.props.point.toJS().activity;
    this.doc = doc;
    return (
      <>
        <Form>
          <SectionItem title="Steps">
            <RowItem title="Steps" name="step_1" point="Max Step per Day" item={doc.step_1} onChange={this.onChange} />
            <RowItem title="Steps" point="Max Step per Day" name="step_2" item={doc.step_2} onChange={this.onChange} />
          </SectionItem>

          <SectionItem title="Running">
            <RowItem title="Distance (m)" name="running" item={doc.running} onChange={this.onChange} />
          </SectionItem>

          <SectionItem title="Cycling">
            <RowItem title="Distance (m)" name="cycling" item={doc.cycling} onChange={this.onChange} />
          </SectionItem>

          <SectionItem title="Swimming">
            <RowItem title="Distance (m)" name="swimming" item={doc.swimming} onChange={this.onChange} />
          </SectionItem>
          <SaveSection onClick={this.props.onSave} />
        </Form>
      </>
    );
  }
}

export default inject('point')(observer(PointActivity));
