import React, { useState } from 'react';
import { Form, Modal, Icon, Button, Select, Input, Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import notify from '../../utils/notify';
import history from '../../utils/history';
import { superAdminOnly, generalOrSuperAdmin } from '../../utils/acl';

const { Option } = Select;

const AddStaffForm = (props) => {
  const { form, backofficeUserRole, currentStaff } = props;
  const { getFieldDecorator } = form;
  const editMode = !!currentStaff.backoffice_user_id;
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordVal, setPasswordVal] = useState('');
  const [usernameError, setUsernameError] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState('');
  const [saving, setSaving] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const save = (values) => {
    props.staff
      .save(values)
      .then(() => {
        notify.success({
          title: 'Staff created',
          message: 'Backoffice Staff created successfully.',
        });

        // Redirect to staff listing page
        history.push('/office/staff');
      })
      .catch((er) => {
        notify.error({
          title: 'Cannot Create staff',
          message: er.message,
        });
      });
  };

  const update = async (id, values) => {
    props.staff
      .update(id, values)
      .then(() => {
        notify.success({
          title: 'Staff updated',
          message: 'Backoffice Staff updated successfully.',
        });

        // Redirect to staff listing page
        history.push('/office/staff');
      })
      .catch((er) => {
        notify.error({
          title: 'Cannot Update staff',
          message: er.message,
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (editMode) {
          update(currentStaff.backoffice_user_id, values);
        } else {
          save(values);
        }
      } else {
        setSaving(false);
      }
    });
  };

  const sendPasswordChangeRequest = async () => {
    // Send request ot change passsword
    return props.staff
      .update(currentStaff.backoffice_user_id, { password: passwordVal })
      .then(() => {
        notify.success({
          title: 'Staff updated',
          message: 'Backoffice Staff updated successfully.',
        });
      })
      .catch((er) => {
        notify.error({
          title: 'Cannot Update staff',
          message: er.message,
        });
      });
  };

  const changePasswordValue = (value) => {
    setPasswordVal(value);
  };

  const onChangeUsername = (value) => {
    setUsernameError(null);
    setUsernameAvailable('checking username');

    props.staff
      .checkUsernameAvailability({
        mode: editMode,
        id: currentStaff.backoffice_user_id,
        username: value,
      })
      .then(() => {
        setUsernameAvailable('Username is available');
      })
      .catch((e) => {
        if (e.available === false) {
          setUsernameError(e.message);
        }
      });
  };

  const showPasswordChangePopup = () => {
    setPasswordModal(() => true);
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} className="staff-form">
      <Modal
        title="Enter new password"
        icon="key"
        width={310}
        okText="Change password"
        destroyOnClose
        visible={passwordModal}
        closable
        onCancel={() => setPasswordModal(false)}
        onOk={async () => {
          await sendPasswordChangeRequest();
          setPasswordModal(false);
        }}
      >
        <Input type="password" onChange={(e) => changePasswordValue(e.currentTarget.value)} />
      </Modal>
      <Row type="flex" gutter={8}>
        <Col span={12}>
          <Form.Item label="First Name">
            {getFieldDecorator('firstname', {
              initialValue: currentStaff.firstname,
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last Name">
            {getFieldDecorator('lastname', { initialValue: currentStaff.lastname })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={12}>
          <Form.Item label="Username">
            {getFieldDecorator('username', {
              initialValue: currentStaff.username,
              rules: [{ required: true, message: 'Please enter username' }],
            })(<Input onBlur={(e) => onChangeUsername(e.currentTarget.value)} />)}
            {usernameError && <span style={{ color: 'red' }}>{usernameError}</span>}
            {!usernameError && usernameAvailable && <span style={{ color: 'green' }}>{usernameAvailable}</span>}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              initialValue: currentStaff.email,
              rules: [{ type: 'email', required: true, message: 'Please enter valid email' }],
            })(<Input type="email" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={8} span={24}>
        <Col span={12}>
          <Form.Item label="Telephone">
            {getFieldDecorator('telephone', { initialValue: currentStaff.telephone })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          {editMode && (
            <Form.Item label=" " colon={false}>
              {getFieldDecorator(
                'password',
                { initialValue: '' },
                {
                  rules: [{ required: true, message: 'Password is required field.' }],
                }
              )(
                <Button type="link" onClick={() => showPasswordChangePopup()}>
                  Change password
                </Button>
              )}
            </Form.Item>
          )}
          {!editMode && (
            <Form.Item label="Password">
              {getFieldDecorator('password', {
                initialValue: currentStaff.password,
                rules: [{ required: true, message: 'Password is required field.' }],
              })(<Input type="password" />)}
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row type="flex" gutter={8} span={24}>
        <Col span={12}>
          <Form.Item label="Role">
            {getFieldDecorator('role_name', {
              initialValue: currentStaff.role_name,
              rules: [{ required: true, message: 'Please choose role' }],
            })(
              <Select placeholder="Select User Role">
                {superAdminOnly(backofficeUserRole) && <Option value="super_admin">Super Admin</Option>}
                {superAdminOnly(backofficeUserRole) && <Option value="admin">Admin</Option>}
                {generalOrSuperAdmin(backofficeUserRole) && <Option value="purchase">Purchase</Option>}
                {generalOrSuperAdmin(backofficeUserRole) && <Option value="call_center">Call Center</Option>}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item {...tailFormItemLayout}>
            {!editMode && (
              <Button
                type="primary"
                disabled={usernameError !== null || saving}
                htmlType="submit"
                style={{ marginLeft: 8 }}
              >
                <Icon type={saving ? 'loading' : 'plus'} /> Add New User
              </Button>
            )}
            {editMode && (
              <Button
                type="primary"
                disabled={usernameError !== null || saving}
                htmlType="submit"
                style={{ marginLeft: 8 }}
              >
                <Icon type="save" /> Update User
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const StaffForm = Form.create('staffform')(AddStaffForm);

export default inject('staff')(observer(StaffForm));
