import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'antd';

import { NoData } from '../../../components/common';
import { SaveBtn } from '../../../components/button';
import { notify } from '../../../utils/notify';
import { can } from '../../../utils/acl';

import RewardTab from '../RewardTab';
import RewardRow from './RewardRow';
import Colors from '../../../assets/Colors';

const HeaderComp = () => {
  return (
    <RowHeaderStyle>
      <ColStyle span={3}>IMAGE</ColStyle>
      <ColStyle span={4}>NAME</ColStyle>
      <ColStyle span={3}>CATEGORY</ColStyle>
      <ColStyle span={2}>POINTS</ColStyle>
      <ColStyle span={2}>BADGE</ColStyle>
      <ColStyle span={2}>STATUS</ColStyle>
      <ColStyle span={3}>START</ColStyle>
      <ColStyle span={3}>EXPIRE</ColStyle>
      <ColStyle span={2} />
    </RowHeaderStyle>
  );
};
export class RewardApplication extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      await this.props.reward.getReadyList(params);
    } catch (e) {
      notify.error({ title: 'Load Reward Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onDragEnd = (evt) => {
    const { source, destination } = evt;
    if (destination) {
      this.props.reward.moveItem({
        source: { name: source.droppableId, index: source.index },
        destination: { name: destination.droppableId, index: destination.index },
      });
    }
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      await this.props.reward.saveActive();
      notify.success({ title: 'Save Reward Success' });
    } catch (e) {
      notify.error({ title: 'Save Reward Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    const doc = this.props.reward.toJS().active;
    const { userRole } = this.props;

    let content = <NoData />;
    if (doc.display.length !== 0 || doc.waiting.length !== 0) {
      content = (
        <>
          <HeaderComp />
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="waiting">
              {(provided0) => (
                <DroppableStyle {...provided0.droppableProps} ref={provided0.innerRef}>
                  {doc.waiting.map((item, index) => (
                    <Draggable key={item.reward_id} draggableId={item.reward_id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <RewardRow item={item} index={index} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </DroppableStyle>
              )}
            </Droppable>

            <DisplayTitle>Rewards will be displayed to users in that order:</DisplayTitle>
            <HeaderComp />

            <Droppable droppableId="display">
              {(provided0) => (
                <DroppableStyle {...provided0.droppableProps} ref={provided0.innerRef}>
                  {doc.display.map((item, index) => (
                    <Draggable key={item.reward_id} draggableId={item.reward_id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <RewardRow item={item} index={index} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </DroppableStyle>
              )}
            </Droppable>
          </DragDropContext>
        </>
      );
    }

    return (
      <RewardTab defaultIndex={1} loading={loading}>
        {content}

        <br />
        <Row>
          <Col span={4} offset={20}>
            {can('add_inapp_reward', userRole) && <SaveBtn onClick={this.onSave} />}
          </Col>
        </Row>
      </RewardTab>
    );
  }
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: isDragging ? '2px' : 0,
  borderBottom: isDragging ? `2px solid ${Colors.orange3}` : 'none',
  overflow: 'hidden',
  margin: 0,
  background: isDragging ? Colors.orange3 : 'transparent',
  ...draggableStyle,
});

const ColStyle = styled(Col)`
  border-right: 1px solid ${Colors.borderTable};
  padding: 16px 16px 16px 16px;
  overflow: hidden;
`;

const RowHeaderStyle = styled(Row)`
  border: 1px solid ${Colors.borderTable};
  background-color: ${Colors.grey};
`;

const DisplayTitle = styled.div`
  border-radius: 5px;
  background: #dad8d7;
  padding: 10px;
  width: 370px;
  margin: 10px 0 10px 0;
`;

const DroppableStyle = styled.div`
  border: 1px solid #e5e4e3;
  background: transparent;
  width: 100%;
  min-height: 200px;
`;

export default inject('reward')(observer(RewardApplication));
