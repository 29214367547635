import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { notify } from '../utils/notify';
import UserFilterUtilities from '../utils/user-filter-utilities';

export class Notification extends BaseStore {
  constructor() {
    super();
    this.observable({
      loading: false,
    });
  }

  async broadcast(params = {}) {
    try {
        const url = `${config.api.max}/v1/office/notification/broadcast`;
        this.loading = true;
        const res = await http.post(url, { json: params, token: true });
        if (res.statusCode !== 200) {
            error.launch({ message: res.body.message });
        } else {
            notify.success({ title: 'Send notification successfully' });
        }
    } catch (err) {
        notify.error({ title: 'Send notification failed', message: err.message })
    } finally {
        this.loading = false;
        console.log('this.loading', this.loading)
    }
  }
}

export default new Notification();