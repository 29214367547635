/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import './css/TagSelector.css';

function CheckBox(props) {
  return (
      <div className="tag-selector">
        <Checkbox.Group options={props.options} {...props} />
      </div>
  );
}

export default CheckBox;
