import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Layout, Icon, Row, Col, Dropdown, Avatar } from 'antd';
import styled from 'styled-components';
import { UserDropDownMenu } from '../menu';
import ErrorBoundary from '../error-boundary';
import { userRoleMap } from '../../config/user-roles';

const { Header, Content } = Layout;

class HeaderMenu extends Component {
  toggle = () => {
    this.props.menu.toggleMenu();
  };

  render() {
    const { openning } = this.props.menu.toJS();
    const roleName = this.props.member.getRoleName();
    const userRole = userRoleMap[roleName] || '';

    return (
      <Layout>
        <StyledHeader className="header">
          <Row type="flex" gutter={16}>
            <Col span={1}>
              <Icon className="trigger" type={openning ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} />
            </Col>
            <Col span={7}>
              <Title>{this.props.title || ''}</Title>
            </Col>
            <Col span={14} style={css.menu}>
              <StyledUserInfo>
                {this.props.member.getUserFullName()}
                {userRole && (
                  <>
                    <br />
                    <span style={{ fontSize: '14px' }}>({userRole})</span>
                  </>
                )}
              </StyledUserInfo>
            </Col>
            {this.props.member.getRoleName() && (
              <Col span={2}>
                <ErrorBoundary>
                  <StyledProfileMenu>
                    <Dropdown overlay={<UserDropDownMenu userRole={this.props.member.getRoleName()} />}>
                      <Avatar size="large" icon="user" style={{ margin: '0 10px 0 10px' }} />
                    </Dropdown>
                  </StyledProfileMenu>
                </ErrorBoundary>
              </Col>
            )}
          </Row>
        </StyledHeader>
        <Content style={{ ...css.content, ...this.props.style }}>{this.props.children}</Content>
      </Layout>
    );
  }
}

const StyledProfileMenu = styled.div`
  overflow: auto;
  margin-top: 15px;
  text-align: right;
  float: left;
  cursor: pointer;
  user-select: none;
`;

const StyledUserInfo = styled('span')`
  user-select: none;
  line-height: 19px;
  text-align-last: left;
  width: 200px;
  display: inline-block;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 15px;
`;

const StyledHeader = styled(Header)`
  line-height: 15px;
`;

const Title = styled.h2`
  color: white;
  line-height: 58px;
  margin-left: 10px;
  user-select: none;
  text-transform: uppercase;
`;

const css = {
  menu: {
    textAlign: 'right',
  },
  content: {
    padding: '10px',
    backgroundColor: '#eeeeee',
  },
};

export default inject('menu', 'member')(observer(HeaderMenu));
