import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { notify } from '../utils/notify';
import UserFilterUtilities from '../utils/user-filter-utilities';

const origin_personal = {
  user_id: '',
  status: '',
  tmp_fname: '',
  tmp_lname: '',
  is_fwd: 0,
  citizen_id: '',
};

const LIMIT = config.limit.page;
const DAY_FORMAT = 'YYYY-MM-DD';

const origin = {
  page: { offset: 1, total: 0, limit: LIMIT },
  list: [],
};

export class User extends BaseStore {
  constructor() {
    super();
    this.observable({
      personal: cloneDeep(origin_personal),
      points: cloneDeep(origin),
      rewards: cloneDeep(origin),
      coupons: cloneDeep(origin),
      activity: cloneDeep(origin),
      user: cloneDeep(origin),
      status_list: [],
      showChangeEmailForm: false,
      showChangePhoneForm: false,
      showVerifyOtpForm: false,
      otp: null,
      loading: false,
      surveyAnswers: undefined,
      surveyCompleted: false,
      personalization: {
        pValue: '',
        pCount: '',
      },
      agent: {
        list: [],
        pagination: {},
        loading: false,
      },
      badgeCounter: {
        list: [],
        total: 0,
      },
      whitelist: [],
    });
  }

  reset() {
    this.personal = cloneDeep(origin_personal);
  }

  async getPersonalUser(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/user/${id}/personal`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.personal = res.body;
    this.loading = false;
  }

  async getPersonal(id) {
    const doc = this.toJS().personal;
    if (doc.user_id !== id) {
      await this.getPersonalUser(id);
    }
  }

  async getPointUser({ id, offset = 1, limit = LIMIT, category, date }) {
    const { start, end } = date;
    let query = '';
    if (start) query += `&start=${start.format(DAY_FORMAT)}`;
    if (end) query += `&end=${end.format(DAY_FORMAT)}`;
    const url = `${config.api.max}/v1/office/user/${id}/point?offset=${offset -
      1}&limit=${limit}&category=${category}${query}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list = [], total = 0 } = res.body;

    this.points = {
      page: { offset, total, limit },
      list,
    };
  }

  async getRewardUser(params = {}) {
    const { id, offset = 1, start, end, limit = LIMIT } = params;
    let query = '';
    if (start) query += `&start=${start.format(DAY_FORMAT)}`;
    if (end) query += `&end=${end.format(DAY_FORMAT)}`;
    const url = `${config.api.max}/v1/office/user/${id}/reward?offset=${offset - 1}&limit=${limit}${query}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list = [], total = 0 } = res.body;

    this.rewards = {
      page: { offset, total, limit },
      list,
    };
  }

  async getCouponUser(params = {}) {
    const { id, offset = 1, start, end } = params;
    let query = '';
    if (start) query += `&start=${start.format(DAY_FORMAT)}`;
    if (end) query += `&end=${end.format(DAY_FORMAT)}`;
    const url = `${config.api.max}/v1/office/user/${id}/coupon?offset=${offset - 1}&limit=${LIMIT}${query}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list = [], total = 0 } = res.body;

    this.coupons = {
      page: { offset, total, limit: LIMIT },
      list,
    };
  }

  async getActivityUser(params = {}) {
    const { id, offset = 1, start, end, code, category, group, limit = LIMIT } = params;
    let query = '';
    if (code !== 'all') query += `&code=${code}`;
    if (category !== 'all') query += `&category=${category}`;
    if (group !== 'all') query += `&group=${group}`;
    if (start) query += `&start=${start.format(DAY_FORMAT)}`;
    if (end) query += `&end=${end.format(DAY_FORMAT)}`;

    const url = `${config.api.max}/v1/office/user/${id}/activity?offset=${offset - 1}&limit=${limit}${query}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list = [], total = 0 } = res.body;

    this.activity = {
      page: { offset, total, limit },
      list,
    };
  }

  getQueryStringFromParams(params) {
    const data = UserFilterUtilities.getQueryStringAsObject(params);
    const queryString = Object.entries(data).reduce((str, param) => {
      const [paramName, paramValue] = param;
      if (paramValue) {
        str += `&${paramName}=${paramValue}`;
      }

      return str;
    }, '');

    return queryString;
  }

  async getList(params = {}) {
    const queryString = this.getQueryStringFromParams(params);
    const { offset = 1, limit } = params;
    const url = `${config.api.max}/v1/office/user?offset=${offset - 1}&limit=${limit}${queryString}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { page } = this.toJS().user;
    const { list = [], total = page.total } = res.body;
    this.user = {
      page: { offset, limit, total },
      list,
    };
  }

  changeStatus({ index, user_id, status }) {
    const { user, status_list } = this.toJS();
    const newStatus = status === 'active' ? 'inactive' : 'active';

    const i = status_list.findIndex((item) => item.user_id === user_id);
    if (i < 0) {
      status_list.push({ user_id, status: newStatus });
    } else {
      status_list.splice(i, 1);
    }

    user.list[index].status = newStatus;

    this.user = user;
    this.status_list = status_list;
  }

  async saveChangeStatus() {
    const { status_list } = this.toJS();
    const active_list = [];
    const inactive_list = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of status_list) {
      if (item.status === 'active') active_list.push(item.user_id);
      else inactive_list.push(item.user_id);
    }

    const url = `${config.api.max}/v1/office/user/status/update`;
    const res = await http.post(url, {
      json: { active_list, inactive_list },
      token: true,
    });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.status_list = [];
  }

  async requestOtpChangePhooneNo(phoneNo) {
    const { personal } = this.toJS();
    this.loading = true;
    const json = { phoneNo };
    const url = `${config.api.max}/v1/office/user/${personal.user_id}/otp/send/changePhoneNo`;
    const res = await http.put(url, {
      json,
      token: true,
    });
    if (res.statusCode !== 200) {
      const e = res.body.error || res.body;
      this.loading = false;
      error.launch({ message: e.message });
      return;
    }
    this.otp = res.body;
    this.showChangePhoneForm = false;
    this.showVerifyOtpForm = true;
    this.loading = false;
  }

  async verifyOtpChnagePhoneNo(otpCode) {
    this.loading = true;
    const { otp, personal } = this.toJS();
    const json = { ...otp, otpCode };
    const url = `${config.api.max}/v1/office/user/${personal.user_id}/otp/verify/changePhoneNo`;
    const res = await http.put(url, {
      json,
      token: true,
    });
    if (res.statusCode !== 200) {
      const e = res.body.error || res.body;
      this.loading = false;
      error.launch({ message: e.message });
      return;
    }
    this.loading = false;
  }

  async changePhoneNo(phoneNo) {
    const { personal } = this.toJS();
    this.loading = true;
    const json = { phoneNo };
    const url = `${config.api.max}/v1/office/user/${personal.user_id}/changePhoneNo`;
    const res = await http.put(url, {
      json,
      token: true,
    });
    if (res.statusCode !== 200) {
      const e = res.body.error || res.body;
      this.loading = false;
      error.launch({ message: e.message });
      return;
    }
    this.loading = false;
  }

  resetOtpProcess() {
    this.otp = null;
    this.showChangePhoneForm = false;
    this.showVerifyOtpForm = false;
    this.reloadPersonal();
  }

  reloadPersonal() {
    this.getPersonalUser(this.personal.user_id);
  }

  async sendChangeEmail(email) {
    const { personal } = this.toJS();
    this.loading = true;
    const json = { email };
    const url = `${config.api.max}/v1/office/user/${personal.user_id}/email/send`;
    const res = await http.put(url, {
      json,
      token: true,
    });
    if (res.statusCode !== 200) {
      const e = res.body.error || res.body;
      this.loading = false;
      error.launch({ message: e.message });
      return;
    }
    this.setShowChangeEmailForm(false);
    this.loading = false;
  }

  async addAdjustPoint({ userId, transType, amount, titleEn, titleTh, descEn, descTh }) {
    const url = `${config.api.max}/v1/office/wallets/adjust`;
    const res = await http.post(url, {
      json: { userId, transType, amount, titleEn, titleTh, descEn, descTh },
      token: true,
    });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { status, data } = res.body;

    if (status === 'inprogress') {
      notify.success({
        title: 'adjust point success',
        message: "your point adjust succesfully add to point-adjust's request list as 'waiting for approve' status",
      });
    } else if (status === 'approved') {
      const newData = [data, ...this.points.list];
      this.points.list = newData;
    }
  }

  setShowChangePhoneForm(visible) {
    this.showChangePhoneForm = visible;
  }

  setShowVerifyOtpForm(visible) {
    this.showVerifyOtpForm = visible;
  }

  setShowChangeEmailForm(visible) {
    this.showChangeEmailForm = visible;
  }

  async getPersonalizationByUserId(id) {
    const url = `${config.api.max}/v1/office/user/${id}/personalization`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.personalization = res.body;
  }

  async getSurveyAnswers(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/user/${id}/survey-answer`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      this.loading = false;
      error.launch({ message: res.body.message });
      return;
    }

    this.surveyAnswers = res.body.data.answers;
    this.surveyCompleted = res.body.data.answers !== null;
    this.loading = false;
  }

  async getAgentList(page = 1, mobileNumber) {
    this.agent.loading = true;
    const url = `${config.api.max}/v1/office/user/agent`;
    const qs = { mobileNumber, page, limit: 10 };
    const res = await http.get(url, { token: true, qs });
    this.agent.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    const { list = [], total = 0 } = res.body;
    this.agent.list = list;
    this.agent.pagination = {
      total,
      current: page,
    };
  }

  async exportAgentList(mobileNumber) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/user/agent`;
    const qs = { mobileNumber, limit: 100 };
    const items = [];
    let total = 1;
    let firstFetch = true;
    for (let page = 1; items.length < total; page++) {
      qs.page = page;
      const resp = await http.get(url, { qs, token: true });
      if (resp.statusCode !== 200) {
        this.loading = false;
        error.launch({ message: resp.body.message });
        return null;
      }
      if (firstFetch) {
        firstFetch = false;
        total = resp.body.total;
        this.agent.pagination.total = total;
      }
      items.push(...resp.body.list);
    }

    this.loading = false;
    return items;
  }

  async importAgentList(items) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/user/agent/update`;
    const res = await http.post(url, {
      json: { items },
      token: true,
    });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async getBadgeCounter(userId) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/user/${userId}/activityCounter?type=active`;
    const res = await http.get(url, {
      token: true,
    });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
    const { steps, running, swimming, cycling } = res.body;
    const list = [
      { name: 'Steps', value: steps },
      { name: 'Running', value: running },
      { name: 'Swimming', value: swimming },
      { name: 'Cycling', value: cycling },
    ];
    const total = list.reduce((acc, cur) => {
      return acc + cur.value;
    }, 0);
    this.badgeCounter = {
      list,
      total,
    };
  }
}

export default new User();
