import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table } from 'antd';

import format from '../../../utils/format';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { notify } from '../../../utils/notify';
import { Loading } from '../../../components/common';
import ImportExportExcel from '../../../components/common/ImportExportExcel';

const COLUMNS = [
  {
    title: 'MAX ID',
    dataIndex: 'userId',
  },
  {
    title: 'FIRSTNAME - LASTNAME',
    dataIndex: 'receiver',
  },
  {
    title: 'ADDRESS',
    dataIndex: 'address',
  },
  {
    title: 'CITY',
    dataIndex: 'city',
  },
  {
    title: 'ZIPCODE',
    dataIndex: 'zipCode',
  },
  {
    title: 'REDEEM DATE-TIME',
    dataIndex: 'createdAt',
    render: (time) => <span>{format.toFullDT(time)}</span>,
  },
  {
    title: 'CARRIER',
    dataIndex: 'carrier',
  },
  {
    title: 'TRACKING NO',
    dataIndex: 'trackingNo',
  },
];

const HEADER = [
  'Shipping Address ID',
  'Max ID',
  'FirstName - LastName',
  'Address',
  'City',
  'Zipcode',
  'Redeem Date-Time',
  'Carrier',
  'Tracking No',
];

export class RewardUserList extends Component {
  componentDidMount() {
    this.fetchApi(1);
  }

  componentWillUnmount() {}

  fetchApi = async (page = 1) => {
    try {
      const { id } = this.props.match.params;
      await this.props.reward.rewardUserList(id, page);
    } catch (e) {
      notify.error({ title: 'Load Friend-Get-Friend Fail', message: e.message });
    }
  };

  onChangePage = (pagination) => {
    this.fetchApi(pagination.current);
  };

  handleExportExcel = async () => {
    const { id } = this.props.match.params;
    const items = await this.props.reward.exportRewardUserList(id, HEADER);
    if (!items) return {};
    const output = items.map((v) => {
      return [
        v.shippingAddressId,
        v.userId,
        v.receiver,
        v.address,
        v.city,
        v.zipCode,
        format.toFullDT(v.createdAt),
        v.carrier,
        v.trackingNo,
      ];
    });
    const fileName = `reward_shipping_address_${id}`;
    const sheetName = 'shipping_address';
    return { output, fileName, sheetName };
  };

  handleImportExcel = async (jsonArr) => {
    const items = jsonArr.map((v) => {
      return {
        shippingAddressId: v['Shipping Address ID'],
        userId: v['Max ID'],
        carrier: v.Carrier,
        trackingNo: v['Tracking No'],
      };
    });
    await this.props.reward.importRewardUserList(items);
    this.fetchApi(1);
  };

  render() {
    const { list, loading, pagination } = this.props.reward.usersShippingAddress;
    return (
      <HeaderMenu title={`REWARD ${this.props.match.params.id} - USER LIST`}>
        <Loading loading={this.props.reward.loading} />
        <ImportExportExcel
          headerImport={HEADER}
          headerExport={HEADER}
          handleExportExcel={this.handleExportExcel}
          handleImportExcel={this.handleImportExcel}
        />

        {list && (
          <Table
            indentSize={2}
            style={css.table}
            columns={COLUMNS}
            dataSource={list}
            bordered
            loading={loading}
            onChange={this.onChangePage}
            pagination={{
              ...pagination,
              position: 'both',
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        )}
      </HeaderMenu>
    );
  }
}

const css = {
  countList: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
  },
  table: {
    background: 'white',
    marginTop: 20,
  },
  exportButton: {
    backgroundColor: '#EF843D',
    borderColor: '#EF843D',
  },
};

export default inject('reward')(observer(RewardUserList));
