import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

export class Trigger extends BaseStore {
  constructor() {
    super();
    this.observable({
      passive: '',
    });
  }

  async runEatingPointJob() {
    const url = `${config.api.max}/v1/jobs/point/passive/eating`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunActivityCounter() {
    const url = `${config.api.max}/v1/max-counter/reset`;

    const res = await http.post(url);
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunDailyDiningActivityCounter() {
    const url = `${config.api.max}/v1/max-counter/reset-dining`;

    const res = await http.post(url);
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunDailyTravelingActivityCounter() {
    const url = `${config.api.max}/v1/max-counter/reset-traveling`;

    const res = await http.post(url);
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunRewardAndCouponCounter() {
    const url = `${config.api.max}/v1/crons/reward-counter/reset`;

    const res = await http.post(url);
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunRewardMonthlyCounter() {
    const url = `${config.api.max}/v1/crons/reward-counter/reset-monthly`;

    const res = await http.post(url);
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async runPlancodeJob() {
    const url = `${config.api.max}/v1/jobs/plancode/process`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async runMigration({ max1UserId, max2UserId }) {
    const json = { max1UserId: +max1UserId, max2UserId: +max2UserId };
    const url = `${config.api.max}/v1/office/migration/points/transfer`;

    const res = await http.post(url, { json, token: true });
    if (res.statusCode !== 200) {
      console.log('migration response:', res.body);
      error.launch({ message: res.body.message });
    }
  }

  async runExpireChallenge() {
    const url = `${config.api.max}/v1/crons/challenges/expire`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunInviteFriendLeader() {
    const url = `${config.api.max}/v1/crons/inviteFriend/leader`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onProcessEarnPointBirthday() {
    const url = `${config.api.max}/v1/crons/earn-point/birthday`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRefreshArticleCache() {
    const url = `${config.api.max}/v1/office/content/refresh`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onRunProcessHighlight() {
    const url = `${config.api.max}/v1/crons/hightlightOfTheWeek/process`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }
  
  async onRunProcessTier() {
    const url = `${config.api.max}/v1/crons/userProfile/syncUserProfile`;

    const res = await http.post(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }
}

export default new Trigger();
