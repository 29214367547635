import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Alert } from 'antd';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';
import StaffForm from '../../components/forms/StaffForm';

const EditStaff = (props) => {
  const [currentStaff, setCurrentStaff] = useState({});
  const [error, setError] = useState('');
  const { id } = props.match.params;

  useEffect(() => {
    const fetchCurrentStaffRecord = (backofficeUserId) => {
      props.staff
        .fetchStaffUserProfile(backofficeUserId)
        .then(() => {
          const user = props.staff.currentStaffRecord;
          setCurrentStaff(user);
        })
        .catch((e) => setError(e.message));
    };

    fetchCurrentStaffRecord(id);
  }, [id, props.staff]);

  const userRole = props.member.getRole();

  return (
    <HeaderMenu title="Staff List">
      <Page style={css.page}>
        {error && <Alert type="error" message={error} />}
        <Loading loading={props.staff.loading} />
        <StaffForm currentStaff={currentStaff} backofficeUserRole={userRole} />
      </Page>
    </HeaderMenu>
  );
};

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('staff', 'member')(observer(EditStaff));
