import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import Colors from '../../assets/Colors';

const DraggableTableRow = ({ children, index, ...props }) => {
  return (
    <StyledRow borderColor={Colors.borderTable} index={index} {...props}>
      {children}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  overflow: auto;
  background-color: ${({ index }) => (index % 2 === 0 ? '#fefcfc' : '#f7f6f6')};
  border-bottom: 1px solid ${(props) => props.borderColor};
`;

export default DraggableTableRow;
