import React from 'react';
import styled from 'styled-components';
import { Form, DatePicker, Row, Col, Input } from 'antd';
import locale from 'antd/es/date-picker/locale/th_TH';
import { FindBtn, Btn } from '../../components/button';
import { Filter, GroupItem, DropdownList } from '../../components/forms';

const ChallengeFilterForm = ({ form, onSearch, onReset }) => {
  const { getFieldDecorator } = form;
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, fieldsValues) => {
      if (!err) {
        const values = {
          ...fieldsValues,
          startAt: fieldsValues.startAt ? fieldsValues.startAt.toDate() : null,
          endAt: fieldsValues.endAt ? fieldsValues.endAt.toDate() : null,
        };
        onSearch(values);
      }
    });
  };

  const handleReset = () => {
    form.resetFields();
    onReset();
  };
  return (
    <Filter style={filterCss}>
      <FilterPage>
        <Form onSubmit={handleSubmit}>
          <Row gutter={12} type="flex">
            <Col>
              <FormItem label="Start Date/Time">
                {getFieldDecorator('startAt', {
                  rules: [
                    {
                      required: false,
                      message: 'Start Date/Time is required',
                    },
                  ],
                })(<DatePicker disabled={false} locale={locale} showTime />)}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="End Date/Time">
                {getFieldDecorator('endAt', {
                  rules: [
                    {
                      required: false,
                      message: 'End Date/Time is required',
                    },
                  ],
                })(<DatePicker disabled={false} locale={locale} showTime style={itemCss} />)}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="STATUS">
                {getFieldDecorator('status', {
                  initialValue: 'all',
                })(<DropdownList name="status" menus={menuList} allowClear={false} style={{ width: 150 }} />)}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="First Name">
                {getFieldDecorator('firstName')(<Input placeholder="input first name" />)}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Last Name">
                {getFieldDecorator('lastName')(<Input placeholder="input last name" />)}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Phone No">
                {getFieldDecorator('phoneNo')(<Input placeholder="input phone no" />)}
              </FormItem>
            </Col>
            <Col>
              <GroupItem>
                <FindBtn style={findBtnCss} htmlType="submit" />
              </GroupItem>
            </Col>
            <Col>
              <GroupItem>
                <Btn text="RESET" style={resetBtnCss} onClick={handleReset}></Btn>
              </GroupItem>
            </Col>
          </Row>
        </Form>
      </FilterPage>
    </Filter>
  );
};

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
  label {
    font-weight: bold;
  }
`;

const menuList = [
  { name: 'All', value: 'all' },
  { name: 'In progress', value: 'inprogress' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Completed', value: 'completed' },
  { name: 'Collected', value: 'collected' },
  { name: 'Expired', value: 'expired' },
];

const filterCss = {
  border: '1px solid #E7E6E6',
  borderRadius: '5px',
  margin: '0 0 10px',
};

const FilterPage = styled.div`
  padding: 10px;
`;

const itemCss = {
  width: '98%',
};

const findBtnCss = {
  fontSize: 'x-small',
};

const resetBtnCss = {
  fontSize: 'x-small',
};

const WrappedChallengeFilterForm = Form.create({
  name: 'challenge_filter_form',
})(ChallengeFilterForm);

export default WrappedChallengeFilterForm;
