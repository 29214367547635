import React from 'react';
import { Upload, Icon, Modal, message, Empty } from 'antd';
import { inject } from 'mobx-react';
import { Loading } from '../common';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
    loading: false,
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = async ({ fileList, file }) => {
    try {
      this.setState({ fileList, loading: true });
      const base64Img = await getBase64(file);
      // call to api
      const uploadData = {
        func: this.props.name,
        data: base64Img,
      };
      const { paths, url } = await this.props.util.uploadImage(uploadData);
      this.props.onChange(paths, url, this.props.url);
      message.success(`${file.name} file uploaded successfully`);
      this.setState({ loading: false, previewImage: url });
    } catch (error) {
      message.error(`${file.name} file upload failed.`);
      this.setState({ loading: false });
    }
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const url = previewImage || this.props.url;
    const { height = 'auto' } = this.props;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Loading loading={this.state.loading} />
        {url ? <img alt="banner" style={{ width: '100%', marginBottom: '10px', height }} src={url} /> : <Empty />}
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={this.handleChange}
          onPreview={this.handlePreview}
          showUploadList={false}
          beforeUpload={(file, fileList) => {
            this.setState({ fileList });
            return false;
          }}
        >
          {uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default inject('util')(PicturesWall);
