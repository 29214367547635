import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Avatar, Form, Row, Col, Input, Icon } from 'antd';
import styled from 'styled-components';

import { Loading } from '../../../components/common';
import { notify } from '../../../utils/notify';
import { helper } from '../../../utils/helper';
import UserInfo from '../UserInfo';
import TextInput from './TextInput';
import ChangeEmailModalForm from './ChangeEmailModalForm';
import ChangePhoneNoForm from './ChangePhoneModalForm';
import VerifyOtpForm from './VerifyOtpModalForm';

export class UserPersonal extends Component {
  componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    try {
      const { id } = this.props.match.params;
      await this.props.user.getPersonalUser(id);
      await this.props.user.getPersonalizationByUserId(id);
    } catch (e) {
      notify.error({ title: 'Load User Fail', message: e.message });
    }
  };

  renderEmailLabel() {
    const { is_verified_email: isVerifyed } = this.props.user.toJS().personal;
    const text = isVerifyed ? 'Verify' : 'Not verify';
    const color = isVerifyed ? 'green' : 'red';
    const Span = styled.span`
      color: ${(props) => props.color};
    `;

    return (
      <Label>
        Email <Span color={color}>{text}</Span>
      </Label>
    );
  }

  render() {
    const { loading, personal: doc, personalization: pl } = this.props.user;
    const personalizeText = pl.pValue && pl.pCount ? `${pl.pValue}: ${pl.pCount}` : '';

    return (
      <UserInfo title={`USERS-${doc.tmp_fname}`} name="PERSONAL">
        <Loading loading={loading} />
        <Form>
          <Row style={rowName}>
            <Col span={8} offset={3}>
              <UserImg>
                <Avatar size={80} icon="user" src={helper.getUrl(doc.image_path)} />
              </UserImg>

              <TextInput label="Name" value={doc.tmp_fname} />
              <Form.Item style={{ marginBottom: 0 }} colon={false}>
                <Label>Telephone</Label>
                <Row gutter={8}>
                  <Col span={22}>
                    <Input value={doc.tmp_phone} readOnly />
                  </Col>
                  <Col span={2}>
                    <Icon type="edit" onClick={() => this.props.user.setShowChangePhoneForm(true)} />
                    <ChangePhoneNoForm />
                    <VerifyOtpForm />
                  </Col>
                </Row>
              </Form.Item>
              <TextInput label="Date of Birth" value={doc.bday} />
            </Col>
            <Col span={8} offset={1}>
              <TextInput label="Display name" value={doc.display_name} />
              <TextInput label="Surname" value={doc.tmp_lname} />
              <Form.Item style={{ marginBottom: 0 }} colon={false}>
                {this.renderEmailLabel()}
                <Row gutter={8}>
                  <Col span={22}>
                    <Input value={doc.tmp_email} readOnly />
                  </Col>
                  <Col span={2}>
                    <Icon type="edit" onClick={() => this.props.user.setShowChangeEmailForm(true)} />
                    <ChangeEmailModalForm />
                  </Col>
                </Row>
              </Form.Item>
              <TextInput label="ID card number" value={doc.citizen_id} />
            </Col>
          </Row>

          <hr />

          <Row style={rowName}>
            <Col span={17} offset={3}>
              <TextInput label="Address" value={doc.address} />
            </Col>
          </Row>

          <Row style={rowName}>
            <Col span={8} offset={3}>
              <TextInput label="Province" value={doc.city} />
            </Col>
            <Col span={8} offset={1}>
              <TextInput label="Zip Code" value={doc.zip_code} />
            </Col>
          </Row>

          <hr />

          <Row style={rowName}>
            <Col span={8} offset={3}>
              <TextInput label="FWD Customer" value={doc.is_fwd ? 'Yes' : 'No'} />
              <TextInput label="PIN Set" value={doc.is_created_pin ? 'Yes' : 'No'} />
              <TextInput label="Personalize" value={personalizeText} />
            </Col>
            <Col span={8} offset={1}>
              <TextInput label="UUID" value={doc.uuid} />
              <TextInput label="Max User ID" value={doc.user_id} />
            </Col>
          </Row>
        </Form>
      </UserInfo>
    );
  }
}

const rowName = {
  padding: '20px',
};

const UserImg = styled.div`
  width: 25%;
  padding: 0px;
  margin: auto;
`;

const Label = styled.p`
  color: #979796;
  margin: 0px;
  padding: 0px 0px;
  line-height: 25px;
`;

export default inject('user')(observer(UserPersonal));
