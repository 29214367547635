import { cloneDeep } from 'lodash';

import { BadgeMap, ActivityCateMap, ActivityCodeMap, RewardStatusMap, IsAgentMap, IsBadgeMap, IsTierMap, IsWhitelistMap } from '../mapping';

const objectToMenu = (obj = {}) => {
  const list = [];
  const keys = Object.keys(obj);
  for (const key of keys) {
    const name = obj[key];
    list.push({ name, value: key });
  }

  return list;
};

const badge = cloneDeep(BadgeMap);
delete badge[0];
export const BadgeMenu = objectToMenu(badge);

export const IsWhitelistMenu = objectToMenu(IsWhitelistMap);

export const IsAgentMenu = objectToMenu(IsAgentMap);

export const ActiviCateMenu = objectToMenu(ActivityCateMap);

export const ActivityCodeMenu = objectToMenu(ActivityCodeMap);

export const RewardStatusMenu = objectToMenu(RewardStatusMap);

export const IsBadgeMenu = objectToMenu(IsBadgeMap);

export const IsTierMenu = objectToMenu(IsTierMap);
