/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Table, Row, Input, Tooltip, Button, Col } from 'antd';
import HeaderMenu from '../../../components/main/HeaderMenu';
import format from '../../../utils/format';
import { notify } from '../../../utils/notify';
import { Loading, Cols, IntCol, DateCol, SaveSection } from '../../../components/common';
import { GroupItem, Filter } from '../../../components/forms';
import { FindBtn } from '../../../components/button';
import { SwitchButton, CustomerType } from '../../../components';
import { BadgeMap } from '../../../definition/mapping';
import { helper } from '../../../utils/helper';
import WhitelistExportImport from '../../../components/common/WhitelistExportImport';

const mapLevel = {
  1: 'FRIEND',
  2: 'FAN',
  3: 'PROMPT',
  4: 'PLUS',
  5: 'PRIDE',
};

const HEADER_EXPORT = ['Mobile Number', 'FirstName', 'LastName', 'Level', 'Is Whitelist', 'Join'];
const HEADER_IMPORT = ['Mobile Number', 'Is Whitelist (Yes/No)'];

function Whitelist(props) {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isShowTable, setIsShowTable] = useState(false);
  const whitelist_column = [
    {
      title: 'WHITELIST',
      dataIndex: 'whitelistName',
      key: 'whitelistName',
      render: (status, record) => {
        console.log('record', record);
        return status
      },
    },
    {
      title: 'MAX ID',
      dataIndex: 'maxId',
      key: 'maxId',
    },
    {
      title: 'NAME',
      key: 'fullname',
      dataIndex: 'fullname',
      render: (fullname, record) => {
        return (
          <div>
            <Link style={{ color: '#686666' }} to={record.link}>
              <span style={{ marginLeft: '10px' }}>{fullname}</span>
            </Link>
          </div>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <SwitchButton
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={status === 'active'}
            onChange={() => {
              onStatus({
                index: record.index,
                user_id: record.maxId,
                status: record.status,
              });
            }}
          />
        );
      },
    },
    {
      title: 'CUSTOMER TYPE',
      dataIndex: 'customerType',
      key: 'customerType',
      render: (customerType) => <CustomerType title={customerType} />,
    },
    // {
    //   title: 'BALANCE',
    //   dataIndex: 'balance',
    //   key: 'balance',
    //   render: (balance) => <IntCol value={balance} />,
    // },
    {
      title: 'BADGE',
      dataIndex: 'badge',
      key: 'badge',
      render: (value) => BadgeMap[value],
    },
    {
      title: 'JOIN',
      dataIndex: 'join',
      key: 'join',
      render: (join) => <DateCol value={join} />,
    },
    {
      title: 'MOBILE',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      render: (_, record) => {
        return <Link to={record.link}>View</Link>;
      },
    },
  ];
  const { allWhitelistUsers, loading, pagination } = props.whitelist;
  const sources = allWhitelistUsers.map((item, index) => {
    let name = item.tmp_fname;
    if (item.tmp_lname) {
      name = `${item.tmp_fname} ${item.tmp_lname}`;
    }
    return {
      key: item.user_id + '-'+ item.whitelist_name,
      maxId: item.user_id,
      fullname: name,
      status: item.status,
      customerType: helper.getLabelForFwdCustomerOrLead(item.is_fwd),
      balance: item.balance,
      badge: BadgeMap[item.level],
      join: item.createdAt,
      mobile: item.tmp_phone,
      url: helper.getUrl(item.image_path),
      link: `/office/user/${item.user_id}/personal`,
      index,
      whitelistName: item.whitelist_name,
    };
  });

  useEffect(() => {
    fetchApi();
  }, []);

  async function fetchApi(page = 1) {
    try {
      await props.whitelist.getWhitelistList();
      await props.whitelist.getAllWhitelistUsers(page, mobileNumber, name, lastName);
    } catch (e) {
      notify.error({ title: 'Whitelist', message: e.message });
    }
  }

  function onStatus({ index, user_id, status }) {
    console.log('index, user_id, status', index, user_id, status)
    props.whitelist.changeStatus({ index, user_id, status });
  }

  async function handleExportExcel(whitelistId) {
    const items = await props.whitelist.exportWhitelist(whitelistId);
    if (!items) return {};
    const output = items.map((v) => {
      return [v.tmp_phone, v.tmp_fname, v.tmp_lname, mapLevel[v.level], 'yes', format.toFullDT(v.created_at)];
    });
    const fileName = `agent_report`;
    const sheetName = 'agent';
    return { output, fileName, sheetName };
  }

  async function handleImportExcel(jsonArr) {
    const items = jsonArr.map((v) => {
      return {
        tmp_phone: v['Mobile Number'],
        is_agent: v['Is Whitelist (Yes/No)'],
      };
    });
    await props.user.importAgentList(items);
    fetchApi();
  }

  function onChangePage(pagination) {
    fetchApi(pagination.current);
  }

  function onChangeName(e) {
    setName(e.target.value);
  }
  function onChangeLastName(e) {
    setLastName(e.target.value);
  }
  function onChangeMobileNumber(e) {
    setMobileNumber(e.target.value);
  }

  async function onSaveStatus (){
    try {
      // this.setState({ loading: true });
      await props.whitelist.saveChangeStatus();
      notify.success({ title: 'Save User Status Success' });
    } catch (e) {
      notify.error({ title: 'Save User Status Fail', message: e.message });
    }

    // this.setState({ loading: false });
  };

  function onSearch() {
    // if (mobileNumber !== '' && mobileNumber.length < 10) {
    //   notify.error({ title: 'Invalid Input', message: 'either no mobile phone or mobile phone must be 10 digit' });
    //   isShowTable(false);
    //   return;
    // }
    fetchApi();
    setIsShowTable(true);
  }

  function handleDownloadExample() {
    const output = [
      ['08111111111', 'yes'],
      ['08111111112', 'no'],
    ];
    const fileName = `Example_template_agent_report`;
    const sheetName = 'agent';
    return { output, fileName, sheetName };
  }

  return (
    <HeaderMenu title="Whitelist">
      <Loading loading={props.user.loading} />

      <Filter style={css.filterCss}>
        <Row style={{ padding: 10 }} type="flex">
          <Cols>
            <GroupItem label="Name">
              <Input value={name} onChange={onChangeName} />
            </GroupItem>
          </Cols>
          <Cols>
            <GroupItem label="Last Name">
              <Input value={lastName} onChange={onChangeLastName} />
            </GroupItem>
          </Cols>
          <Cols>
            <GroupItem label="Mobile Number">
              <Input value={mobileNumber} onChange={onChangeMobileNumber} />
            </GroupItem>
          </Cols>
          <Cols>
            <GroupItem>
              <FindBtn onClick={onSearch} />
            </GroupItem>
          </Cols>
        </Row>
          <Row>
            <Cols>
              <Table
                dataSource={sources}
                columns={whitelist_column}
                loading={loading}
                onChange={onChangePage}
                bordered
                style={css.tableCss}
                pagination={{
                  ...props.whitelist.allPagination,
                  showTotal: (total) => `Total ${total}`,
                  hideOnSinglePage: false,
                  // position: ['none', 'none'],
                }}
              />
            </Cols>
          </Row>
      </Filter>

      <WhitelistExportImport
        headerImport={HEADER_IMPORT}
        headerExport={HEADER_EXPORT}
        handleExportExcel={handleExportExcel}
        handleImportExcel={handleImportExcel}
        handleDownloadExample={handleDownloadExample}
        list={allWhitelistUsers}
        onChangePage={onChangePage}
      />
      <SaveSection display={props.whitelist.status_list.length > 0} onClick={onSaveStatus} />
    </HeaderMenu>
  );
}

const css = {
  filterCss: {
    border: '1px solid #E7E6E6',
    borderRadius: '5px',
  },
  tableCss: {
    margin: 10,
  },
};

export default inject('user', 'whitelist')(observer(Whitelist));
