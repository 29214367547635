import moment from 'moment';

import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';

const INIT_STATE = {
  list: [],
  waitingList: [],
  displayList: [],
  detail: null,
  loading: false,
  pagination: {},
  isRequired: false,
};

class BannerStore extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  async getList(params = {}) {
    this.loading = true;
    const type = params.type || 'home';
    const page = params.page || 1;
    const url = `${config.api.max}/v1/office/banners`;
    const qs = {
      page,
      bannerType: type,
    };
    const headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
    };
    const res = await http.get(url, { token: true, qs, headers });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    const { data, count } = res.body;
    this.list = data;
    this.pagination.total = count;
    this.loading = false;
  }

  async getWaitingList(params = {}) {
    this.loading = true;
    const type = params.type || 'home';
    // Note default 100 because client need to see more result.
    const limit = params.limit || 100;
    const status = 'active';
    const displayType = 'waiting';
    const url = `${config.api.max}/v1/office/banners`;
    const qs = {
      status,
      displayType,
      limit,
      bannerType: type,
    };
    const res = await http.get(url, { token: true, qs });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    const { data } = res.body;
    this.waitingList = data;
    this.loading = false;
  }

  async getDisplayList(params = {}) {
    this.loading = true;
    const type = params.type || 'home';
    const status = 'active';
    const displayType = 'app';
    const url = `${config.api.max}/v1/office/banners`;
    const qs = {
      status,
      displayType,
      bannerType: type,
    };
    const res = await http.get(url, { token: true, qs });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    const { data } = res.body;
    this.displayList = data;
    this.loading = false;
  }

  setItem({ items, key }) {
    this[key] = items;
  }

  setAcrossItem({ watingList, displayList }) {
    this.waitingList = watingList;
    this.displayList = displayList;
  }

  async saveItemList() {
    const { waitingList, displayList } = this.toJS();

    const waitingWithSequenceList = waitingList.map((item) => {
      const sequence = 0;
      return {
        ...item,
        sequence,
      };
    });
    const displayWithSequenceList = displayList.map((item, index) => {
      const sequence = index + 1;
      return {
        ...item,
        sequence,
      };
    });
    const json = [...waitingWithSequenceList, ...displayWithSequenceList];

    const url = `${config.api.max}/v1/office/banners/sequence`;
    const res = await http.put(url, { json, token: true });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
    }
  }

  transform(data) {
    const [startAt, endAt] = data.dateRanges;
    return {
      ...data,
      startAt,
      endAt,
    };
  }

  async create(data) {
    const json = this.transform(data);

    const url = `${config.api.max}/v1/office/banners`;
    const res = await http.post(url, { json, token: true });
    if (res.statusCode !== 201) {
      console.error(res.body.message);
      return;
    }

    return res.body;
  }

  async update(id, data) {
    const json = this.transform(data);

    const url = `${config.api.max}/v1/office/banners/${id}`;
    const res = await http.put(url, { json, token: true });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    return res.body;
  }

  async remove(id) {
    const url = `${config.api.max}/v1/office/banners/${id}`;
    const res = await http.delete(url, { token: true });
    if (res.statusCode !== 204) {
      console.error(res.body.message);
      return;
    }
    return res.body;
  }

  async get(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/banners/${id}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    this.detail = this.asJS(res.body);
    if (this.detail.status === 'active') {
      this.isRequired = true;
    }
    this.loading = false;
  }

  asJS(data) {
    let dateRanges = [];
    if (data.startAt && data.endAt) {
      dateRanges = [moment(data.startAt), moment(data.endAt)];
    }

    return {
      ...data,
      dateRanges,
    };
  }

  reset() {
    Object.keys(INIT_STATE).forEach((key) => (this[key] = INIT_STATE[key]));
  }

  setStatus(status) {
    if (status === 'active') {
      this.isRequired = true;
    } else {
      this.isRequired = false;
    }
  }

  async search(values, opts = {}) {
    this.loading = true;
    const { startAt, endAt, status, timeType } = values;
    const bannerType = opts.type || 'home';
    const page = opts.page || 1;
    const qs = {
      page,
      bannerType,
      search: {
        startAt,
        endAt,
      },
    };

    if (status !== 'all') {
      qs.status = status;
    }

    if (timeType !== 'all') {
      qs.search.timeType = timeType;
    }

    const url = `${config.api.max}/v1/office/banners`;

    try {
      const res = await http.get(url, { token: true, qs });
      const { data, count } = res.body;
      this.list = data;
      this.pagination.total = count;
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }
}

const bannerStore = new BannerStore();

export default bannerStore;
