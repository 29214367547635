import React, { Component } from 'react';
import { Row, Col } from 'antd';

export default class OnRight extends Component {
  render() {
    const { span = 4, offset = 20 } = this.props;
    return (
      <Row>
        <Col span={span} offset={offset}>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}
