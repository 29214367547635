import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form } from 'antd';

import HeaderMenu from '../../../components/main/HeaderMenu';

import { Loading } from '../../../components/common';
import { TabMenu } from '../../../components/forms';
import { notify } from '../../../utils/notify';

import ActiveActivity from './ActiveActivity';
import Eating from './Eating';
import Traveling from './Traveling';
import ReadContent from './ReadContent';
import ActivityShare from './ActivityShare';
import Reward from './Reward';
import HighlightOfTheWeek from './HighlightOfTheWeek';

const NAME = 'Point Passive Activity';
const menus = [
  { name: 'ACTIVE ACTIVITY' },
  { name: 'DINING' },
  { name: 'TRAVELING' },
  { name: 'READ CONTENT' },
  { name: 'ACTIVITY SHARE' },
  { name: 'REWARDS' },
  { name: 'HIGHLIGHT OF THE WEEK' },
];

export class PointPassiveActivity extends Component {
  state = {
    loading: false,
    menu: menus[0].name,
  };

  componentDidMount() {
    this.onFind();
  }

  onFind = async () => {
    try {
      this.setState({ loading: true });
      const tasks = [
        this.props.point.getActivity(),
        this.props.point.getPassiveActivity(),
        this.props.config.getPassiveActivity(),
      ];

      await Promise.all(tasks);
    } catch (e) {
      notify.error({ title: `Load ${NAME} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      const tasks = [
        this.props.point.saveActivity(),
        this.props.point.savePassiveActivity(),
        this.props.config.savePassiveActivity(),
      ];

      await Promise.all(tasks);
      notify.success({ title: `Save ${NAME} Complete` });
    } catch (e) {
      notify.error({ title: `Save ${NAME} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onMenu = (item) => {
    this.setState({ menu: item.name });
  };

  render() {
    const { loading, menu } = this.state;
    const doc = this.props.point.toJS().global;

    this.doc = doc;
    let content;
    switch (menu) {
      case 'ACTIVE ACTIVITY':
        content = <ActiveActivity onSave={this.onSave} />;
        break;
      case 'TRAVELING':
        content = <Traveling onSave={this.onSave} />;
        break;
      case 'READ CONTENT':
        content = <ReadContent onSave={this.onSave} />;
        break;
      case 'ACTIVITY SHARE':
        content = <ActivityShare onSave={this.onSave} />;
        break;
      case 'REWARDS':
        content = <Reward onSave={this.onSave} />;
        break;
      case 'HIGHLIGHT OF THE WEEK':
        content = <HighlightOfTheWeek onSave={this.onSave} />;
        break;
      default:
        content = <Eating onSave={this.onSave} />;
    }
    return (
      <HeaderMenu title="ACTIVITIES">
        <Page>
          <Loading loading={loading} />

          <TabMenu menus={menus} onChange={this.onMenu} />
          <Form colon={false}>
            <Body>{content}</Body>
          </Form>
        </Page>
      </HeaderMenu>
    );
  }
}

const Page = styled.div`
  padding: 20px;
  border-radius: 2px;
  background-color: white;
`;

const Body = styled.div`
  padding: 20px;
`;

export default inject('point', 'config')(observer(PointPassiveActivity));
