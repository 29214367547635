import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Col, InputNumber } from 'antd';
import styled from 'styled-components';

import { SaveSection } from '../../../components/common';
import RowInline from './RowInline';

export class ActivityShare extends Component {
  onChange = (name, tag, val) => {
    this.doc[name][tag] = val;
    this.props.point.setPassiveActivity(this.doc);
  };

  render() {
    const doc = this.props.point.toJS().passive;
    this.doc = doc;
    return (
      <div>
        <RowInline
          title="Share Steps"
          name="activity_share_steps"
          item={doc.activity_share_steps}
          onChange={this.onChange}
        />

        <RowInline
          title="Share Running"
          name="activity_share_running"
          item={doc.activity_share_running}
          onChange={this.onChange}
        />

        <RowInline
          title="Share Cycling"
          name="activity_share_cycling"
          item={doc.activity_share_cycling}
          onChange={this.onChange}
        />

        <RowInline
          title="Share Swimming"
          name="activity_share_swimming"
          item={doc.activity_share_swimming}
          onChange={this.onChange}
        />

        <RowInline
          disabled
          title="Finish Challenge"
          name="activity_share_running"
          item={doc.activity_share_running}
          onChange={this.onChange}
        />

        <Row>
          <Col span={4}>
            <Title>TOTAL ACTIVITY SHARE</Title>
            <Span>Once a day</Span>
          </Col>
          <Col span={9}>
            <Form.Item label=" ">
              <InputNumber disabled min={0} style={css.input} value={0} onChange={this.onPoint} />
            </Form.Item>
          </Col>
        </Row>

        <SaveSection onClick={this.props.onSave} />
      </div>
    );
  }
}

const css = {
  input: { width: '100%' },
};

const Title = styled.h3`
  margin-top: 0.4em;
  margin-bottom: 0.1em;
`;

const Span = styled.p`
  font-size: 12px;
`;

export default inject('point')(observer(ActivityShare));
