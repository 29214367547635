/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import SideMenu from './SideMenu';

const MainLayout = (props) => {
  useEffect(() => {
    const fetchUserProfile = async () => {
      await props.member.getUserProfile();
    };

    fetchUserProfile();
  }, []);

  const toggle = () => {
    props.menu.toggleMenu();
  };

  return (
    <Div>
      <SideMenu toggle={toggle} openning={props.menu.openning} />
      <BodyDiv padLeft={props.menu.openning ? '220px' : '90px'}>{props.children}</BodyDiv>
    </Div>
  );
};

const Div = styled(Layout)`
  background-color: #fff;
`;

const BodyDiv = styled('div')`
  padding-left: ${(props) => props.padLeft};
  width: 100vw;
  overflow-x: hidden;
  transition: padding-left 0.2s;
`;

export default inject('menu', 'member')(observer(MainLayout));
