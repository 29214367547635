import React, { Component } from 'react';
import { Form, Row, Col } from 'antd';
import styled from 'styled-components';

import { InputInt, InputPoint } from '../../../components/forms';

export default class RowInline extends Component {
  onPoint = (val) => {
    this.onChange('point', val);
  };

  onMax = (val) => {
    this.onChange('max_count', val);
  };

  onChange = (tag, val) => {
    const { name, onChange } = this.props;
    if (onChange) onChange(name, tag, val);
  };

  render() {
    const { item = {}, span = 4, title = 'Name', point = 'Max per Day', disabled = false } = this.props;
    return (
      <Row>
        <Col span={span}>
          <Title>{title}</Title>
          <Span>Once a day</Span>
        </Col>
        <Col span={span}>
          <Form.Item label={title}>
            <InputPoint disabled={disabled} value={item.point} onChange={this.onPoint} />
          </Form.Item>
        </Col>

        <Col span={span} offset={1}>
          <Form.Item label={point}>
            <InputInt disabled={disabled} value={item.max_count} onChange={this.onMax} text="time" />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

const Title = styled.h3`
  margin-top: 0.4em;
  margin-bottom: 0.1em;
`;

const Span = styled.p`
  font-size: 12px;
`;
