import React, { Component } from 'react';
import { Select } from 'antd';

export default class DropdownList extends Component {
  onSelected = async (selected) => {
    const { onChange, name } = this.props;
    if (onChange) onChange(selected, name);
  };

  render() {
    const {
      menus = [],
      placeHolder = 'Please Select',
      value,
      allowClear = true,
      disabled = false,
      readOnly = false,
      loading = false,
      style,
    } = this.props;
    const choice = menus.map((item) => {
      return <Select.Option key={item.value}>{item.name}</Select.Option>;
    });

    let css = '';
    if (readOnly) {
      css = 'select-readonly';
    }

    return (
      <Select
        disabled={disabled || readOnly}
        style={{ width: '100%', ...style }}
        className={css}
        placeholder={placeHolder}
        value={value}
        allowClear={allowClear}
        showArrow
        onChange={this.onSelected}
        loading={loading}
      >
        {choice}
      </Select>
    );
  }
}
