import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Upload, Icon, Modal, Table, Tooltip, Row, Col, Input, Popover } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SwitchButton } from '../../components';
import XLSX from 'xlsx';
import notify from '../../utils/notify';

const WhitelistTableModal = (props) => {

  const onChangePage = (pagination) => {
    // fetchApi(pagination.current);
    console.log(this)
    props.whitelist.getWhitelistUsers(props.whitelistId, pagination.current)
  }
  const COLUMNS = [
    {
      title: 'MOBILE NUMBER',
      dataIndex: 'tmp_phone',
      width: 160,
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'tmp_fname',
    },
    {
      title: 'LAST NAME',
      dataIndex: 'tmp_lname',
    },
    {
      title: 'LEVEL',
      dataIndex: 'level',
      width: 100,
      // render: (level) => <span>{mapLevel[level]}</span>,
    },
    {
      title: 'IS AGENT',
      dataIndex: 'is_agent',
      width: 120,
      render: () => <span>yes</span>,
    },
    {
      title: 'JOIN',
      dataIndex: 'created_at',
      // render: (time) => <span>{format.toFullDT(time)}</span>,
    },
  ];

  console.log('props.whitelist.pagination', props.whitelist.pagination)

  return (
    <>
      <Modal
        title={props.whitelistName}
        visible={props.tableModalVisible}
        onCancel={props.handleTableModalClose}
        width={1200}
        footer={[
          <Button key="back" onClick={props.handleTableModalClose}>
            Close
          </Button>,
        ]}
      >
        <Table
          indentSize={2}
          rowKey={(record) => record.reward_id}
          columns={COLUMNS}
          dataSource={props.whitelist.whitelistUsers}
          scroll={{ y: 600 }}
          bordered
          loading={props.whitelist.loading}
          onChange={onChangePage}
          pagination={{
            ...props.whitelist.pagination,
            // position: 'both',
            // total: props.whitelist.whitelistTotal,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Modal>
    </>
  );
}

const css = {
  excelButton: {
    // backgroundColor: '#EF843D',
    // borderColor: '#EF843D',
    marginRight: 20,
  },
  disableButton: {
    marginRight: 20,
  },
  row: {
    marginBottom: 20,
  },
};

const Section = styled.div`
  background-color: #fff;
  margin: 20px 0 20px 0;
  border-radius: 5px;
  padding: 20px;
  // display: flex;
  // align-content: center;
  // justify-content: space-between;
`;

export default inject('whitelist')(observer(WhitelistTableModal));
