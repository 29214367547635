import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table } from 'antd';

import { IntCol, DateCol } from '../../../components/common';
import { notify } from '../../../utils/notify';
import { helper } from '../../../utils/helper';
import { format } from '../../../utils/format';

import { BadgeMap } from '../../../definition/mapping';

import RewardFilter from './RewardFilter';
import UserInfo from '../UserInfo';

export class UserReward extends Component {
  columns = [
    {
      title: 'IMAGE',
      dataIndex: 'image_home',
      width: 100,
      render: (value) => <img alt="" style={css.img} src={helper.getUrl(value)} />,
    },
    {
      title: 'NAME',
      dataIndex: 'name_en',
    },
    {
      title: 'CODE',
      dataIndex: 'digi_code',
    },
    {
      title: 'POINTS',
      dataIndex: 'amount',
      render: (value) => <IntCol minus value={value} />,
    },
    {
      title: 'BADGE',
      dataIndex: 'level',
      render: (value) => <IntCol value={BadgeMap[value]} />,
    },
    {
      title: 'REDEEMED DATE/TIME',
      dataIndex: 'created_at',
      render: (value) => <DateCol value={value} />,
    },
  ];

  params = {};

  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      const { id } = this.props.match.params;
      const promise = [this.props.user.getPersonal(id), this.props.user.getRewardUser({ id, ...params })];
      await Promise.all(promise);
    } catch (e) {
      notify.error({ title: 'Load User Reward Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onFilter = async (params = {}) => {
    params.offset = 1;
    this.params = params;
    this.onLoad(this.params);
  };

  onShowSizeChange = (current, size) => {
    this.params.offset = current;
    this.params.limit = size;
    this.onLoad(this.params);
  };

  onPagin = (offset) => {
    this.params.offset = offset;
    this.onLoad(this.params);
  };

  render() {
    const { personal, rewards } = this.props.user;
    const { page, list } = rewards;
    const { offset: currentPage } = this.params;

    return (
      <UserInfo title={`USERS-${personal.tmp_fname}`} name="REWARDS">
        <RewardFilter onFind={this.onFilter} />

        <Table
          indentSize={2}
          columns={this.columns}
          dataSource={list}
          bordered
          loading={this.state.loading}
          pagination={{
            current: currentPage || 1,
            position: 'both',
            total: page.total,
            pageSizeOptions: ['10', '100', '1000'],
            showSizeChanger: true,
            showQuickJumper: true,
            hideOnSinglePage: false,
            onChange: this.onPagin,
            onShowSizeChange: this.onShowSizeChange,
            showTotal: (total) => `Total ${format.toDigi(total)}`,
          }}
        />
      </UserInfo>
    );
  }
}

const css = {
  title: {
    color: 'rgba(62, 130, 247, 0.87)',
    borderBottomWidth: '2px',
    padding: '25px 15px 10px 20px',
    textAlign: 'center',
    fontSize: '14px',
  },
  row: {
    backgroundColor: '#FAF9F9',
    padding: '10px 25px',
    border: '0px solid #E7E6E6',
    borderBottomWidth: '1px',
    textAlign: 'center',
    fontSize: '15px',
  },
  left: { textAlign: 'left' },
  img: {
    height: '40px',
    width: '100px',
    objectFit: 'cover',
  },
};

export default inject('user')(observer(UserReward));
