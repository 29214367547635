import React, { Component } from 'react';
import styled from 'styled-components';

export default class SelectFile extends Component {
  onChange = async (evt) => {
    const { files } = evt.target;
    if (files.length === 0) return;

    const { type, name, onChange } = this.props;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      onChange(reader.result, name);
    });

    if (onChange) {
      if (type === 'buffer') {
        reader.readAsArrayBuffer(files[0]);
      } else {
        reader.readAsText(files[0]);
      }
    }
  };

  render() {
    const { name = 'select file', style, text = 'SELECT FILE', disabled = true, accept = '.docx' } = this.props;
    if (disabled) {
      return <div />;
    }
    return (
      <Section style={style}>
        <input id={name} type="file" accept={accept} style={{ display: 'none' }} onChange={this.onChange} />
        <label htmlFor={name}>
          <Text>{text}</Text>
        </label>
      </Section>
    );
  }
}

const Section = styled.div`
  line-height: 26px;
`;

const Text = styled.div`
  width: 100%;
  background-color: #FDFDFD;
  text-align: center;
  padding 2px;
  border-radius: 5px;
  border: solid 1px #B5B4B4;

  :hover {
    cursor: pointer;
  }
`;
