import React from 'react';
import { Menu, Modal, Icon } from 'antd';
import { storage } from '../../utils/storage';
import history from '../../utils/history';
import appConfig from '../../config/app';
import { userRoleMap } from '../../config/user-roles';

const UserDropDownMenu = ({ userRole }) => {
  const onSettingClick = () => {
    history.push(`${appConfig.prefix}/staff/settings`);
  };

  const onLogoutClick = () => {
    const logoutModal = Modal.confirm({
      title: 'Logout confirmation',
      content: 'Are you sure you want to logout ?',
      icon: 'logout',
      destroyOnClose: true,
      onOk: () => {
        logoutModal.update({
          title: 'Logout confirmation',
          okButtonProps: { disabled: true },
          cancelButtonProps: { disabled: true },
        });

        storage.remove('authentication');

        history.push('/office');
        window.location.reload(true);
        return Promise.resolve(true);
      },
      onCancel: () => {},
      closable: false,
    });
  };

  return (
    <Menu style={{ width: 200 }}>
      {userRole && (
        <Menu.Item key="user_role" disabled title="Logged in user role">
          <Icon type="user" theme="outlined" /> {userRoleMap[userRole]}
        </Menu.Item>
      )}
      <Menu.Item key="account_settings" onClick={onSettingClick}>
        <Icon type="setting" theme="outlined" /> Settings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={onLogoutClick}>
        <Icon type="logout" theme="outlined" /> Logout
      </Menu.Item>
    </Menu>
  );
};

export default UserDropDownMenu;
