import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

export default class NoData extends Component {
  render() {
    const { list = [] } = this.props;
    if (list.length === 0) {
      return (
        <Section>
          <Icon type="smile" />
          <Text>Data Not Found</Text>
        </Section>
      );
    }
    return list;
  }
}

const Section = styled.div`
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Text = styled.p`
  font-size: 18px;
`;
