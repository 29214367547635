import React from 'react';
import styled from 'styled-components';
import { Form, DatePicker, Row, Col } from 'antd';
import locale from 'antd/es/date-picker/locale/th_TH';
import { FindBtn, Btn } from '../../components/button';
import { Filter, GroupItem, DropdownList } from '../../components/forms';
import { RewardStatusFilter } from '../../definition/filter';

class BannderFilterForm extends React.Component {
  state = {
    timeType: 'all',
    startAt: null,
    endAt: null,
    status: 'all',
    isAllTimeType: true,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValues) => {
      if (!err) {
        const values = {
          ...fieldsValues,
          startAt: fieldsValues.startAt ? fieldsValues.startAt.toDate() : null,
          endAt: fieldsValues.endAt ? fieldsValues.endAt.toDate() : null,
        };
        this.props.onSearch(values);
      }
    });
  };

  handleTimeType = (value) => {
    if (value === 'all') {
      this.props.form.setFieldsValue({
        startAt: null,
        endAt: null,
      });
      this.setState({ isAllTimeType: true });
    } else {
      this.setState({ isAllTimeType: false });
    }
  };

  handleReset = () => {
    this.props.form.setFieldsValue({
      timeType: 'all',
      startAt: null,
      endAt: null,
      status: 'all',
      isAllTimeType: true,
    });
    this.props.onReset();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { timeType, status, startAt, endAt, isAllTimeType } = this.state;
    const statusFilters = [...RewardStatusFilter];
    statusFilters.push({
      name: 'Expired',
      value: 'expired',
    });

    return (
      <Filter style={filterCss}>
        <FilterPage>
          <Form className="ant-advanced-search-form" onSubmit={this.handleSubmit}>
            <Row type="flex" gutter={12}>
              <Col>
                <FormItem label="Time Type">
                  {getFieldDecorator('timeType', {
                    initialValue: timeType,
                  })(
                    <DropdownList
                      onChange={this.handleTimeType}
                      name="time_type"
                      menus={timeMenu}
                      allowClear={false}
                      style={{ width: 100 }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col>
                <FormItem label="Start Date/Time">
                  {getFieldDecorator('startAt', {
                    initialValue: startAt,
                    rules: [
                      {
                        required: !isAllTimeType,
                        message: 'Start Date/Time is required',
                      },
                    ],
                  })(<DatePicker disabled={isAllTimeType} locale={locale} showTime style={itemCss} />)}
                </FormItem>
              </Col>
              <Col>
                <FormItem label="End Date/Time">
                  {getFieldDecorator('endAt', {
                    initialValue: endAt,
                    rules: [
                      {
                        required: !isAllTimeType,
                        message: 'End Date/Time is required',
                      },
                    ],
                  })(<DatePicker disabled={isAllTimeType} locale={locale} showTime style={itemCss} />)}
                </FormItem>
              </Col>
              <Col>
                <FormItem label="Status">
                  {getFieldDecorator('status', {
                    initialValue: status,
                  })(<DropdownList name="status" menus={statusFilters} allowClear={false} style={{ width: 150 }} />)}
                </FormItem>
              </Col>
              <Col>
                <GroupItem>
                  <FindBtn htmlType="submit" />
                </GroupItem>
              </Col>
              <Col span={2} style={colCss}>
                <GroupItem>
                  <Btn text="RESET" onClick={this.handleReset} style={{ width: 100 }}></Btn>
                </GroupItem>
              </Col>
            </Row>
          </Form>
        </FilterPage>
      </Filter>
    );
  }
}

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
  label {
    font-weight: bold;
  }
`;

const itemCss = {
  width: '98%',
};

const colCss = {
  marginRight: '4px',
};

const filterCss = {
  border: '1px solid #E7E6E6',
  borderRadius: '5px',
  margin: '0 0 10px',
};

const FilterPage = styled.div`
  padding: 10px;
`;

const timeMenu = [
  { name: 'All', value: 'all' },
  { name: 'Created', value: 'createdAt' },
  { name: 'Started', value: 'startAt' },
  { name: 'Expire', value: 'endAt' },
];

const WrappedBannerForm = Form.create({
  name: 'banner_filter_form',
})(BannderFilterForm);

export default WrappedBannerForm;
