export const MENU = [
  {
    name: 'STEPS',
    link: './steps',
  },
  {
    name: 'RUNNING',
    link: './running',
  },
  {
    name: 'CYCLING',
    link: './cycling',
  },
  {
    name: 'SWIMMING',
    link: './swimming',
  },
  {
    name: 'DINING',
    link: './dining',
  },
  {
    name: 'TRAVELING',
    link: './traveling',
  },
];

export default MENU;
