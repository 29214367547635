import { notification } from 'antd';

export class Notify {
  async success({ title, message }) {
    notification.success({
      key: title,
      style: css.success,
      message: title,
      description: message,
      duration: 4,
    });
  }

  async error({ title, message }) {
    notification.error({
      key: title,
      style: css.error,
      message: title,
      description: message,
      duration: 10,
    });
  }

  async warning({ title, text }) {}
}

const css = {
  success: {
    background: '#E5FCE2',
  },
  error: {
    background: '#FEE1E1',
  },
};
export const notify = new Notify();
export default notify;
