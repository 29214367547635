import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';

export class Util extends BaseStore {
  async uploadImage({ type = 'public', func, uuid, data }) {
    let url = `${config.api.max}/v1/office/utils/image/public/${func}`;
    if (uuid) url += `?uuid=${uuid}`;

    const json = { file: data };
    const res = await http.post(url, { json, token: true });
    if (res.statusCode === 200) {
      return res.body;
    }
  }
}

export default new Util();
