import React, { Component } from 'react';

import ConfigBase from './MarkdownBase';

export default class ConfigFaqType extends Component {
  render() {
    return <ConfigBase title="FAQ CONFIGURATION" type="faq" menus={menus} />;
  }
}

const menus = [{ name: 'FAQ TH', value: 'th' }, { name: 'FAQ ENG', value: 'eng' }];
