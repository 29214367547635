import React, { Component } from 'react';
import { Form, Row, Col, InputNumber } from 'antd';

export default class RowItem extends Component {
  onValue = (val) => {
    this.onChange('value', val);
  };

  onPoint = (val) => {
    this.onChange('point', val);
  };

  onMax = (val) => {
    this.onChange('max_count', val);
  };

  onChange = (tag, val) => {
    const { name, onChange } = this.props;
    if (onChange) onChange(name, tag, val);
  };

  render() {
    const { item = {}, span = 4, title = 'Distance', point = 'Max Points per Day' } = this.props;
    return (
      <Row>
        <Col span={span}>
          <Form.Item label={title}>
            <InputNumber min={0} style={css.input} value={item.value} onChange={this.onValue} />
          </Form.Item>
        </Col>

        <Col span={span} offset={1}>
          <Form.Item label="Points">
            <InputNumber min={0} style={css.input} value={item.point} onChange={this.onPoint} />
          </Form.Item>
        </Col>

        <Col span={span} offset={1}>
          <Form.Item label={point}>
            <InputNumber min={0} style={css.input} value={item.max_count} onChange={this.onMax} />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

const css = {
  input: { width: '100%' },
};
