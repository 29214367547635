import store from '../stores';

export class Errors {
  handle(res) {
    switch (res.statusCode) {
      case 401:
        store.member.reset();
        store.member.removeStorage();
        break;
      default:
    }
  }

  launch({ message }) {
    throw new Error(message);
  }
}

export const error = new Errors();
export default error;
