import { SUPER_ADMIN, ADMIN, CALL_CENTER, PURCHASE } from './user-roles';

export const PERM_ACCESS_DASHBOARD_REGISTERED = 'access_dashboard_registered';
export const PERM_ACCESS_DASHBOARD_ACTIVITY = 'access_dashboard_activity';
export const PERM_ACCESS_DASHBOARD_DOWNLOAD = 'access_dashboard_download';
export const PERM_ACCESS_DASHBOARD_REWARD = 'access_dashboard_reward';
export const PERM_ACCESS_DASHBOARD_CHALLENGE = 'access_dashboard_challenge';
export const PERM_ACCESS_DASHBOARD_FRIEND_GET_FRIEND = 'access_dashboard_friend_get_friend';
export const PERM_ACCESS_STAFF_LIST = 'access_staff_list';
export const PERM_ACCESS_STAFF_OWN_SETTINGS = 'access_staff_own_settings';
export const PERM_ADD_REWARD = 'add_reward';
export const PERM_ADD_INAPP_REWARD = 'add_inapp_reward';
export const PERM_STAFF_ADD_USER = 'staff_add_user';
export const PERM_STAFF_EDIT_USER = 'staff_edit_user';
export const PERM_STAFF_VIEW_USER = 'staff_view_user';
export const PERM_ACCESS_DASHBOARD_USERLIST = 'access_dashboard_userlist';
export const PERM_ACCESS_USER_PERSONAL = 'access_user_personal';
export const PERM_ACCESS_USER_REWARD = 'access_user_reward';
export const PERM_ACCESS_USER_COUPON = 'access_user_coupon';
export const PERM_ACCESS_USER_CHALLENGE = 'access_user_challenge';
export const PERM_ACCESS_USER_POINT = 'access_user_point';
export const PERM_ACCESS_USER_ACTIVITY = 'access_user_activity';
export const PERM_ACCESS_USER_POLL_AND_SURVEY = 'access_user_poll_and_survey';
export const PERM_ACCESS_USER_AGENT = 'access_user_agent';
export const PERM_ACCESS_USER_BADGE_COUNTER = 'access_user_badge_counter';
export const PERM_ACCESS_REWARD_LIST = 'access_reward_list';
export const PERM_ACCESS_REWARD_APPLICATION = 'access_reward_application';
export const PERM_ACCESS_REWARD_CREATE = 'reward_create';
export const PERM_ACCESS_REWARD_EDIT = 'access_reward_edit';
export const PERM_ACCESS_REWARD_VIEW = 'access_reward_view';
export const PERM_ACCESS_REWARD_USERLIST = 'access_reward_userlist';
export const PERM_ACCESS_COUPON_LIST = 'access_coupon_list';
export const PERM_ACCESS_COUPON_CREATE = 'access_coupon_create';
export const PERM_ACCESS_COUPON_EDIT = 'access_coupon_edit';
export const PERM_ACCESS_POINT_GLOBAL = 'access_point_global';
export const PERM_ACCESS_POINT_ACTIVITY = 'access_point_activity';
export const PERM_ACCESS_POINT_PASSIVE_ACTIVITY = 'access_point_passive_activity';
export const PERM_ACCESS_POINT_PAID_POLICY = 'access_point_paid_policy';
export const PERM_ACCESS_POINT_ADJUST = 'access_point_adjust';
export const PERM_ACCESS_POINT_ADJUST_REQUEST = 'access_point_adjust_request';
export const PERM_APPROVE_OR_REJECT_POINT_ADJUST_REQUEST = 'approve_or_reject_point_adjust_request';
export const PERM_ACCESS_CONFIG = 'access_config';
export const PERM_ACCESS_BANNER = 'access_banner';
export const PERM_JOBS_TRIGGER = 'jobs_trigger';
export const PERM_ACCESS_CHALLENGE = 'access_challenge';
export const PERM_ACCESS_BADGE = 'access_badge';
export const PERM_ACCESS_COMMUNINY = 'access_community';
export const PERM_ACCESS_PERSONAL_CONTENT_CONFIG = 'access_personal_content_config';
export const PERM_ACCESS_MY_DOWNLOAD = 'access_my_download';

const permissions = {
  [SUPER_ADMIN]: {
    [PERM_ACCESS_DASHBOARD_REGISTERED]: true,
    [PERM_ACCESS_DASHBOARD_ACTIVITY]: true,
    [PERM_ACCESS_DASHBOARD_DOWNLOAD]: true,
    [PERM_ACCESS_DASHBOARD_REWARD]: true,
    [PERM_ACCESS_DASHBOARD_CHALLENGE]: true,
    [PERM_ACCESS_DASHBOARD_FRIEND_GET_FRIEND]: true,
    [PERM_ACCESS_STAFF_OWN_SETTINGS]: true,
    [PERM_ACCESS_STAFF_LIST]: true,
    [PERM_STAFF_ADD_USER]: true,
    [PERM_STAFF_EDIT_USER]: true,
    [PERM_STAFF_VIEW_USER]: true,
    [PERM_ACCESS_DASHBOARD_USERLIST]: true,
    [PERM_ADD_REWARD]: true,
    [PERM_ADD_INAPP_REWARD]: true,
    [PERM_ACCESS_USER_PERSONAL]: true,
    [PERM_ACCESS_USER_REWARD]: true,
    [PERM_ACCESS_USER_COUPON]: true,
    [PERM_ACCESS_USER_CHALLENGE]: true,
    [PERM_ACCESS_USER_POINT]: true,
    [PERM_ACCESS_USER_ACTIVITY]: true,
    [PERM_ACCESS_USER_POLL_AND_SURVEY]: true,
    [PERM_ACCESS_USER_AGENT]: true,
    [PERM_ACCESS_USER_BADGE_COUNTER]: true,
    [PERM_ACCESS_REWARD_LIST]: true,
    [PERM_ACCESS_REWARD_APPLICATION]: true,
    [PERM_ACCESS_REWARD_CREATE]: true,
    [PERM_ACCESS_REWARD_EDIT]: true,
    [PERM_ACCESS_REWARD_VIEW]: true,
    [PERM_ACCESS_REWARD_USERLIST]: true,
    [PERM_ACCESS_COUPON_LIST]: true,
    [PERM_ACCESS_COUPON_CREATE]: true,
    [PERM_ACCESS_COUPON_EDIT]: true,
    [PERM_ACCESS_POINT_GLOBAL]: true,
    [PERM_ACCESS_POINT_ACTIVITY]: true,
    [PERM_ACCESS_POINT_PASSIVE_ACTIVITY]: true,
    [PERM_ACCESS_POINT_PAID_POLICY]: true,
    [PERM_ACCESS_POINT_ADJUST]: true,
    [PERM_ACCESS_POINT_ADJUST_REQUEST]: true,
    [PERM_APPROVE_OR_REJECT_POINT_ADJUST_REQUEST]: true,
    [PERM_ACCESS_CONFIG]: true,
    [PERM_ACCESS_BANNER]: true,
    [PERM_JOBS_TRIGGER]: true,
    [PERM_ACCESS_CHALLENGE]: true,
    [PERM_ACCESS_BADGE]: true,
    [PERM_ACCESS_COMMUNINY]: true,
    [PERM_ACCESS_PERSONAL_CONTENT_CONFIG]: true,
    [PERM_ACCESS_MY_DOWNLOAD]: true,
  },
  [ADMIN]: {
    [PERM_ACCESS_DASHBOARD_REGISTERED]: true,
    [PERM_ACCESS_DASHBOARD_ACTIVITY]: true,
    [PERM_ACCESS_DASHBOARD_DOWNLOAD]: true,
    [PERM_ACCESS_DASHBOARD_REWARD]: true,
    [PERM_ACCESS_DASHBOARD_CHALLENGE]: true,
    [PERM_ACCESS_DASHBOARD_FRIEND_GET_FRIEND]: true,
    [PERM_ACCESS_STAFF_OWN_SETTINGS]: true,
    [PERM_ACCESS_DASHBOARD_USERLIST]: true,
    [PERM_ACCESS_STAFF_LIST]: true,
    [PERM_STAFF_ADD_USER]: true,
    [PERM_STAFF_EDIT_USER]: true,
    [PERM_STAFF_VIEW_USER]: true,
    [PERM_ADD_REWARD]: true,
    [PERM_ADD_INAPP_REWARD]: true,
    [PERM_ACCESS_USER_PERSONAL]: true,
    [PERM_ACCESS_USER_REWARD]: true,
    [PERM_ACCESS_USER_COUPON]: true,
    [PERM_ACCESS_USER_CHALLENGE]: true,
    [PERM_ACCESS_USER_POINT]: true,
    [PERM_ACCESS_USER_ACTIVITY]: true,
    [PERM_ACCESS_USER_POLL_AND_SURVEY]: true,
    [PERM_ACCESS_USER_AGENT]: true,
    [PERM_ACCESS_USER_BADGE_COUNTER]: true,
    [PERM_ACCESS_REWARD_LIST]: true,
    [PERM_ACCESS_REWARD_APPLICATION]: true,
    [PERM_ACCESS_REWARD_CREATE]: true,
    [PERM_ACCESS_REWARD_EDIT]: true,
    [PERM_ACCESS_REWARD_VIEW]: true,
    [PERM_ACCESS_REWARD_USERLIST]: true,
    [PERM_ACCESS_COUPON_LIST]: true,
    [PERM_ACCESS_COUPON_CREATE]: true,
    [PERM_ACCESS_COUPON_EDIT]: true,
    [PERM_ACCESS_POINT_GLOBAL]: true,
    [PERM_ACCESS_POINT_ACTIVITY]: true,
    [PERM_ACCESS_POINT_PASSIVE_ACTIVITY]: true,
    [PERM_ACCESS_POINT_PAID_POLICY]: true,
    [PERM_ACCESS_POINT_ADJUST]: true,
    [PERM_ACCESS_POINT_ADJUST_REQUEST]: true,
    [PERM_ACCESS_CONFIG]: true,
    [PERM_ACCESS_BANNER]: true,
    [PERM_JOBS_TRIGGER]: true,
    [PERM_ACCESS_CHALLENGE]: true,
    [PERM_ACCESS_BADGE]: true,
    [PERM_ACCESS_COMMUNINY]: true,
    [PERM_ACCESS_PERSONAL_CONTENT_CONFIG]: true,
    [PERM_ACCESS_MY_DOWNLOAD]: true,
  },
  [CALL_CENTER]: {
    [PERM_ACCESS_STAFF_OWN_SETTINGS]: true,
    [PERM_ACCESS_DASHBOARD_USERLIST]: true,
    [PERM_ACCESS_USER_PERSONAL]: true,
    [PERM_ACCESS_USER_REWARD]: true,
    [PERM_ACCESS_USER_COUPON]: true,
    [PERM_ACCESS_USER_CHALLENGE]: true,
    [PERM_ACCESS_USER_POINT]: true,
    [PERM_ACCESS_USER_ACTIVITY]: true,
    [PERM_ACCESS_USER_POLL_AND_SURVEY]: true,
    [PERM_ACCESS_USER_AGENT]: true,
    [PERM_ACCESS_USER_BADGE_COUNTER]: true,
  },
  [PURCHASE]: {
    [PERM_ACCESS_STAFF_OWN_SETTINGS]: true,
    [PERM_ACCESS_DASHBOARD_REWARD]: true,
    [PERM_ACCESS_REWARD_LIST]: true,
    [PERM_ACCESS_REWARD_APPLICATION]: true,
    [PERM_ACCESS_REWARD_VIEW]: true,
    [PERM_ACCESS_REWARD_USERLIST]: true,
  },
};

export default permissions;
