import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Col, Modal, Table } from 'antd';

import XLSX from 'xlsx';
import { inject, observer } from 'mobx-react';
import { format } from '../../../utils/format';
import { Title, RowItem } from '../../../components/common';
import { notify } from '../../../utils/notify';

const BULK_SIZE = 1000;

class Summary extends Component {
  state = {
    personaliseName: '',
    exportPersonaliseName: '',
    personaliseDataSource: [],
    isFetchingPersonalise: false,
    modalVisible: false,
    limit: 10,
    pageNo: 1,
    pValue: null,
    count: 0,
  };

  personaliseColumns = [
    {
      title: 'MAXID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'BADGE',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'TELEPHONE',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  doExport = async (fileName) => {
    let dataSource = [];
    if (this.state.count < BULK_SIZE) {
      await this.fetchPersonaliseByTag(this.state.pValue, { limit: BULK_SIZE });
      const { list = [] } = this.props.dashboard.toJS().personalise;
      dataSource = list;
    } else {
      const loop = Math.ceil(this.state.count / BULK_SIZE);
      for (let i = 1; i <= loop; i++) {
        await this.fetchPersonaliseByTag(this.state.pValue, { limit: BULK_SIZE, pageNo: i });
        const { list = [] } = this.props.dashboard.toJS().personalise;
        dataSource = list.concat(dataSource);
      }
    }
    dataSource.unshift({
      userId: 'MAXID',
      name: 'NAME',
      level: 'BADGE',
      phone: 'TELEPHONE',
    });

    try {
      const output = dataSource.map((d) => {
        delete d.key;

        return d;
      });
      const sheetName = '';
      const ws = XLSX.utils.json_to_sheet([...output], { skipHeader: true });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  fetchPersonaliseByTag = async (pValue, params = {}) => {
    this.setState({ isFetchingPersonalise: true });
    try {
      if (!params.limit) {
        params.limit = this.state.limit;
      }

      await this.props.dashboard.getPersonaliseByTag(pValue, params);
      const { list = [] } = this.props.dashboard.toJS().personalise;
      this.setState({ personaliseDataSource: list });

    } catch (e) {
      notify.error({ title: 'Load Personalize Fail', message: e.message });
    }

    this.setState({ isFetchingPersonalise: false });
  };

  onPersonaliseValueClickHandler = async (e, item, index) => {
    e.preventDefault();

    this.setState({ pageNo: 1 });
    this.setState({ personaliseName: `Personalize - ${item.name} : ${item.value}` });
    this.setState({ exportPersonaliseName: `personalize_${item.p_value}` });
    this.setState({ personaliseDataSource: [] });
    this.setState({ isFetchingPersonalise: true });
    this.setState({ modalVisible: true });
    this.setState({ pValue: item.p_value });
    this.setState({ count: item.value });

    await this.fetchPersonaliseByTag(item.p_value, { pageNo: 1 });
  };

  onPageChange = async (pageNo) => {
    this.setState({ pageNo });
    await this.fetchPersonaliseByTag(this.state.pValue, { pageNo });
  };

  render() {
    const colName = 8;
    const colValue = 6;

    const list = this.props.list || [];
    const content = list.map((item, index) => {
      let percent = <Col span={colValue} />;
      if (item.percent !== undefined) {
        percent = (
          <Col span={colValue}>
            <TagValue style={item.isBold ? textBold : null}>
              {typeof item.percent === 'number' && format.toPercent(item.percent)}
              {typeof item.percent === 'string' && item.percent}
            </TagValue>
          </Col>
        );
      }
      let value = '';
      if ((typeof item.value === 'number' && Number(item.value) === 0) || item.isClickAble === false) {
        value = (
          <TagValue style={item.isBold ? textBold : null}>
            {typeof item.value === 'number' && format.toDigi(item.value)}
            {typeof item.value === 'string' && item.value}
          </TagValue>
        );
      } else {
        value = (
          <a
            href={`/personalise/${item.p_value}/list`}
            onClick={(e) => this.onPersonaliseValueClickHandler(e, item, index)}
          >
            <TagValue style={item.isBold ? textBold : null}>
              {typeof item.value === 'number' && format.toDigi(item.value)}
              {typeof item.value === 'string' && item.value}
            </TagValue>
          </a>
        );
      }
      return (
        <RowItem style={rowCss} key={index} index={index}>
          <Col span={colName}>
            <TagName style={item.isBold ? textBold : null}>{item.name}</TagName>
          </Col>
          {percent}
          <Col span={colValue}>{value}</Col>
        </RowItem>
      );
    });
    return (
      <div>
        <Title>{this.props.title || ''}</Title>
        {content}
        <Modal
          closeable
          width={1024}
          height={520}
          centered
          footer={null}
          title={
            <ModalTitle
              title={this.state.personaliseName}
              handleClick={() => this.doExport(this.state.exportPersonaliseName, this.state.personaliseDataSource)}
              disableExport={this.state.isFetchingPersonalise}
            />
          }
          visible={this.state.modalVisible}
          destroyOnClose
          onCancel={() => this.setState({ modalVisible: false })}
        >
          <Table
            dataSource={this.state.personaliseDataSource}
            columns={this.personaliseColumns}
            loading={this.state.isFetchingPersonalise}
            pagination={{
              onChange: this.onPageChange,
              pageSize: this.state.limit,
              total: this.state.count,
            }}
          />
        </Modal>
      </div>
    );
  }
}

const ModalTitle = ({ title, handleClick, disableExport }) => {
  const StyledHeader = styled.h1`
    font-size: 18px;
    font-weight: 500;
    float: left;
  `;

  const StyledExportButton = styled(Button)`
    float: right;
    margin-right: 40px;
    text-transform: uppercase;
    border-radius: 18px;
    font-weight: 500;
    height: 36px;
    width: 120px;
    background-color: #ef843d;
    border-color: #ef843d
    border-color: #fd9a21;
    color: #fff;
    &:disabled {
      background-color: #cccc;
      border-color: #cccc;
      color: #444;
    }
    &:hover {
      background-color: #ffb732;
      border-color: #ffb732;
      color: #fff;
    }
  `;

  return (
    <div style={{ overflow: 'auto' }}>
      <StyledHeader>{title}</StyledHeader>
      <StyledExportButton type="primary" onClick={handleClick} disabled={disableExport}>
        Export
      </StyledExportButton>
    </div>
  );
};

const TagName = styled.label`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;

const TagValue = styled.div`
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: right;
`;

const rowCss = {
  padding: '10px 25px',
};

const textBold = {
  fontWeight: 600,
};

export default inject('dashboard')(observer(Summary));
