import React, { useEffect, useState } from 'react';
import { Table, message, Alert, Button, Modal, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Page, IntCol } from '../../../components/common';
import { SwitchButton } from '../../../components';
import { PushNotificationTemplateUpdateForm } from '../../../components/forms';
import HeaderMenu from '../../../components/main/HeaderMenu';
import './pushNotification.css';

const PushNotificationTemplateForm = (props) => {
  const { pushNotificationTemplate } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [notificationTemplates, setNotificationTemplates] = useState([]);
  const [editMode, setEditMode] = useState({ id: -1, visible: false });
  const [updateError, setUpdateError] = useState('');

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'PUSH',
      dataIndex: 'is_push',
      key: 'is_push',
      render: (isPush) => <SwitchButton checked={Boolean(isPush)} />,
    },
    {
      title: 'NOTI',
      dataIndex: 'is_noti',
      key: 'is_noti',
      render: (isApp) => <SwitchButton checked={Boolean(isApp)} />,
    },
    {
      title: 'TITLE (TH)',
      dataIndex: 'title_th',
      key: 'title_th',
    },
    {
      title: 'MESSAGE (TH)',
      dataIndex: 'message_th',
      key: 'message_th',
    },
    {
      title: 'TITLE (ENG)',
      dataIndex: 'title_en',
      key: 'title_en',
    },
    {
      title: 'MESSAGE (ENG)',
      dataIndex: 'message_en',
      key: 'message_en',
    },
    {
      title: '',
      dataIndex: 'row_action',
      key: 'row_action',
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setUpdateError('');
            setEditMode({
              id: record.id,
              visible: true,
            });
          }}
        >
          <Icon type="edit" theme="filled" /> Edit
        </Button>
      ),
    },
  ];

  const editModalBox = (mode, onSubmit, onCancel) => {
    if (!mode.visible) {
      return null;
    }

    const record = notificationTemplates.find((r) => r.id === mode.id);

    if (!record) {
      return null;
    }

    return (
      <Modal visible title="Update Notification Template" footer={null} closable={false} destroyOnClose width={728}>
        {updateError && <Alert type="error" message={updateError} style={{ marginBottom: '15px' }} />}
        <PushNotificationTemplateUpdateForm
          notificationTemplate={record}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onUpdateProgress={loading}
        />
      </Modal>
    );
  };

  useEffect(() => {
    setLoading(true);

    pushNotificationTemplate
      .fetchNotificationTemplates()
      .then(() => {
        const notifications = pushNotificationTemplate.list;

        const templates = notifications.reduce((mem, n) => {
            console.log(n.code)
            if (n.code === 'reminder_point_expire')
                return [{
                    key: n.id,
                    id: n.id,
                    action: n.code,
                    is_push: n.isPush,
                    is_noti: n.isApp,
                    title_th: n.titleTh,
                    message_th: n.messageTh,
                    title_en: n.titleEn,
                    message_en: n.messageEn,
                    type: n.type,
                }];
            return mem
        }, []);
        setNotificationTemplates(() => templates);
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [pushNotificationTemplate]);

  const onSubmitHandler = (data) => {
    setLoading(true);
    setUpdateError('');

    pushNotificationTemplate
      .updateNotificationTemplate(data, data.id)
      .then(() => {
        if (pushNotificationTemplate.errors.length) {
          setUpdateError(pushNotificationTemplate.errors.join('<br/><br/>'));
        } else {
          message.success('Notification updated successfully.', 2);

          setEditMode({
            visible: false,
          });

          const pos = notificationTemplates.findIndex((n) => n.id === pushNotificationTemplate.recentUpdate.id);

          const newList = JSON.parse(JSON.stringify(notificationTemplates));
          const notiToUpdate = JSON.parse(JSON.stringify(pushNotificationTemplate.recentUpdate));
          notiToUpdate.key = pushNotificationTemplate.recentUpdate.id;
          const notiData = {
            key: notiToUpdate.id,
            id: notiToUpdate.id,
            action: notiToUpdate.code,
            is_push: Boolean(notiToUpdate.isPush),
            is_noti: Boolean(notiToUpdate.isApp),
            title_th: notiToUpdate.titleTh,
            message_th: notiToUpdate.messageTh,
            title_en: notiToUpdate.titleEn,
            message_en: notiToUpdate.messageEn,
            type: notiToUpdate.type,
          };

          newList.splice(pos, 1, notiData);

          setNotificationTemplates(() => newList);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCancelHandler = () => {
    setEditMode({ id: -1, visible: false });
  };

  return (
      <StyledPage>
        <Table
          loading={loading} 
          bordered
          dataSource={notificationTemplates}
          columns={columns}
          pagination={false}
          className="notification-templates"
        />
        {editModalBox(editMode, onSubmitHandler, onCancelHandler, setNotificationTemplates)}
      </StyledPage>
  );
};

const StyledPage = styled(Page)`
  background-color: white;
  border-radius: 5px;
`;

const WrappedPushNotificationTemplate = PushNotificationTemplateForm;

export default inject('pushNotificationTemplate')(observer(WrappedPushNotificationTemplate));
