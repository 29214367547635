import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';

class AppPageStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      list: [],
      selected: null,
    });
  }

  _transformList(list) {
    return list.map((item) => {
      const value = item.type ? `${item.value}_${item.type}` : item.value;
      const originalValue = item.value;
      return {
        ...item,
        value,
        originalValue,
      };
    });
  }

  async getList(params = {}) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/pages`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    const data = this._transformList(res.body);

    this.list = data;
  }

  setSelected(item) {
    this.selected = item;
  }
}

const appPageStore = new AppPageStore();

export default appPageStore;
