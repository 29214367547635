import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Avatar, Table, Divider, Row, Col, Button, Tooltip } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import moment from 'moment';
import BannerTab from './BannerTab';
import BannerRow from './BannerRow';
import BannerFilterForm from './BannerFilterForm';

import { DateCol, DisplayStatus } from '../../components/common';
import { SaveBtn } from '../../components/button';
import { notify } from '../../utils/notify';
import history from '../../utils/history';
import format from '../../utils/format';
import requireAuth from '../../components/requireAuth';
import { DragAndDropTableZone } from '../../components';
import Colors from '../../assets/Colors';
import 'moment/locale/th';

const MAXIMUM_BANNER_ITEMS = 10;
const Line = styled.hr`
  margin: 10px 0px;
`;

const css = {
  title: {
    color: 'rgba(62, 130, 247, 0.87)',
    borderBottomWidth: '2px',
    padding: '25px 15px 10px 20px',
    textAlign: 'center',
  },
  row: {
    padding: '10px 25px',
    border: '0px solid #E7E6E6',
    borderBottomWidth: '1px',
    fontSize: '16px',
  },
  center: { textAlign: 'center' },
  img: {
    width: '100%',
    height: '40px',
    padding: '0px 5px',
    borderRadius: '8px',
  },
  btn: {
    width: '64px',
    height: '64px',
    bottom: '20px',
    right: '20px',
    fontSize: '32px',
    zIndex: '100',
    position: 'fixed',
  },
};

const mapBanerTypeToIndexTab = {
  home: 0,
  homeapp: 1,
  reward: 2,
  rewardapp: 3,
  challenge: 4,
  challengeapp: 5,
};

const getTabIndex = (type, display) => {
  const key = display ? `${type}${display}` : `${type}`;
  const defaultKey = 'home';
  return mapBanerTypeToIndexTab[key] || mapBanerTypeToIndexTab[defaultKey];
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: 'none',
    padding: isDragging ? '2px' : 0,
    borderBottom: isDragging ? `2px solid ${Colors.orange3}` : 'none',
    overflow: 'hidden',
    margin: 0,
    backgroundColor: isDragging ? `${Colors.orange3}` : 'transparent',
    ...draggableStyle,
  };
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const bannerStatus = ({ date, status }) => {
  const now = moment();
  const _endAt = moment(date);
  const isExpired = _endAt.isBefore(now);

  if (status === 'active' && isExpired) {
    return <DisplayStatus type={5} status="expired" />;
  }

  return <DisplayStatus type={5} status={status.toLowerCase()} />;
};

const BannerList = class BannerList extends React.Component {
  id2List = {
    droppable: 'waitingList',
    droppable2: 'displayList',
  };

  columns = [
    {
      title: 'IMAGE',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text, record) => {
        return <Avatar style={{ verticalAlign: 'middle' }} size="large" shape="square" src={record.imageUrl} />;
      },
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL/VDO DEEPLINK',
      dataIndex: 'uri',
      key: 'uri',
      width: '30%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (text) => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text, { endAt, status }) => bannerStatus({ date: endAt, status }),
    },
    {
      title: 'STARTED',
      dataIndex: 'startAt',
      key: 'startAt',
      width: '120px',
      render: (text, record) => <DateCol value={record.startAt} />,
    },
    {
      title: 'EXPIRE',
      dataIndex: 'endAt',
      key: 'endAt',
      width: '120px',
      render: (text, record) => <DateCol value={record.endAt} />,
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <span>
          <Link to={`/office/banners/${record.bannerId}/edit`}>Edit</Link>
          <Divider type="vertical" />
          <Link to=" " onClick={(e) => this.handleClickRemove(e, record.bannerId)}>
            Delete
          </Link>
        </span>
      ),
    },
  ];

  componentDidMount() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const display = query.get('display');
    if (display !== 'app') {
      this.props.banner.getList({ type: this.props.match.params.type });
    } else {
      this.props.banner.getWaitingList({ type: this.props.match.params.type });
      this.props.banner.getDisplayList({ type: this.props.match.params.type });
    }
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location.key !== prevProps.location.key;
    if (locationChanged) {
      const { location } = this.props;
      const query = new URLSearchParams(location.search);
      const display = query.get('display');
      if (display !== 'app') {
        this.props.banner.getList({ type: this.props.match.params.type });
      } else {
        this.props.banner.getWaitingList({
          type: this.props.match.params.type,
        });
        this.props.banner.getDisplayList({
          type: this.props.match.params.type,
        });
      }
    }
  }

  getList = (id) => this.props.banner[this.id2List[id]];

  onDragEnd = (evt) => {
    const { source, destination } = evt;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(this.getList(source.droppableId), source.index, destination.index);

      let state = { items, key: 'waitingList' };

      if (source.droppableId === 'droppable2') {
        state = { items, key: 'displayList' };
      }
      this.props.banner.setItem(state);
    } else {
      const result = move(this.getList(source.droppableId), this.getList(destination.droppableId), source, destination);

      const state = {
        watingList: result.droppable,
        displayList: result.droppable2,
      };

      if (result.droppable2.length > 10) {
        notify.error({
          title: 'Order banner in app failed',
          message: 'Not allow in app over than 10 items',
        });
        return;
      }

      this.props.banner.setAcrossItem(state);
    }
  };

  onSave = async () => {
    try {
      this.props.banner.saveItemList();
      notify.success({ title: 'Save Banner Success' });
    } catch (error) {
      notify.error({ title: 'Save Banner Failed', message: error.message });
    }
  };

  handleClickRemove = async (e, id) => {
    try {
      e.preventDefault();
      await this.props.banner.remove(id);
      notify.success({ title: 'Remove banner success.' });
      history.push(`/office/banners/all/${this.props.match.params.type}`);
    } catch (error) {
      notify.error({ title: 'Remove banner failed.' });
    }
  };

  onCreateRoute() {
    history.push('/office/banners/create');
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const display = query.get('display');
    const page = pagination.current;
    if (display !== 'app') {
      this.props.banner.getList({ type: this.props.match.params.type, page });
    } else {
      this.props.banner.getWaitingList({
        type: this.props.match.params.type,
        page,
      });
      this.props.banner.getDisplayList({
        type: this.props.match.params.type,
        page,
      });
    }
  };

  handleSearch = (values) => {
    const { type } = this.props.match.params;
    const page = this.props.banner.pagination.current;
    const opts = { type, page };
    this.props.banner.search(values, opts);
  };

  handleReset = () => {
    this.props.banner.getList({ type: this.props.match.params.type });
  };

  renderPreviewContent = () => {
    const { type } = this.props.match.params;
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const display = query.get('display');
    const paginationAttributes = this.props.banner.pagination;
    const pagination = {
      ...paginationAttributes,
      position: 'both',
      showQuickJumper: true,
      showTotal: (total) => `Total ${format.toDigi(total)}`,
    };

    return (
      <BannerTab loading={this.props.banner.loading} defaultIndex={getTabIndex(type, display)}>
        <BannerFilterForm onSearch={this.handleSearch} onReset={this.handleReset} />
        <Table
          bordered
          rowKey={(record) => record.bannerId}
          dataSource={this.props.banner.list}
          columns={this.columns}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
        <Button style={css.btn} type="primary" shape="circle" onClick={this.onCreateRoute}>
          <i className="fas fa-plus" />
        </Button>
      </BannerTab>
    );
  };

  renderInAppContent() {
    const { type } = this.props.match.params;
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const display = query.get('display');
    const { DraggableTableHeader, DraggableTableCol } = DragAndDropTableZone;
    const { displayList, waitingList } = this.props.banner;
    return (
      <BannerTab loading={this.props.banner.loading} defaultIndex={getTabIndex(type, display)}>
        <DraggableTableHeader>
          <DraggableTableCol span={2}>IMAGE</DraggableTableCol>
          <DraggableTableCol span={3}>NAME</DraggableTableCol>
          <DraggableTableCol span={5}>URL/VDO DEEPLINK</DraggableTableCol>
          <DraggableTableCol span={2}>STATUS</DraggableTableCol>
          <DraggableTableCol span={3}>STARTED</DraggableTableCol>
          <DraggableTableCol span={3}>EXPIRE</DraggableTableCol>
        </DraggableTableHeader>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <WaitingDiv {...provided.droppableProps} ref={provided.innerRef}>
                {waitingList.map((item, index) => (
                  <Draggable key={item.bannerId} draggableId={item.bannerId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <BannerRow isDragging={snapshot.isDragging} item={item} index={index} />
                      </div>
                    )}
                  </Draggable>
                ))}
              </WaitingDiv>
            )}
          </Droppable>

          <Line />
          <DisplayTitle>
            Banners will be displayed to users in that order: {displayList.length} / {MAXIMUM_BANNER_ITEMS} items
          </DisplayTitle>

          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <DisplayDiv {...provided.droppableProps} ref={provided.innerRef}>
                <DraggableTableHeader>
                  <DraggableTableCol span={2}>IMAGE</DraggableTableCol>
                  <DraggableTableCol span={3}>NAME</DraggableTableCol>
                  <DraggableTableCol span={5}>URL/VDO DEEPLINK</DraggableTableCol>
                  <DraggableTableCol span={2} style={{ textAlign: 'center' }}>
                    STATUS
                  </DraggableTableCol>
                  <DraggableTableCol span={3}>STARTED</DraggableTableCol>
                  <DraggableTableCol span={3}>EXPIRE</DraggableTableCol>
                </DraggableTableHeader>
                {displayList.map((item, index) => (
                  <Draggable key={item.bannerId} draggableId={item.bannerId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <BannerRow isDragging={snapshot.isDragging} item={item} index={index} />
                      </div>
                    )}
                  </Draggable>
                ))}
              </DisplayDiv>
            )}
          </Droppable>
        </DragDropContext>
        <br />
        <Row>
          <Col span={4} offset={20}>
            <SaveBtn onClick={this.onSave} />
          </Col>
        </Row>
      </BannerTab>
    );
  }

  render() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const display = query.get('display');
    if (display !== 'app') {
      return this.renderPreviewContent();
    }

    return this.renderInAppContent();
  }
};

const WaitingDiv = styled.div`
  border: 1px solid #e5e4e3;
  background: transparent;
  width: 100%;
  min-height: 200px;
`;

const DisplayTitle = styled.div`
  background: #dad8d7;
  padding: 10px;
  width: 50%;
`;

const DisplayDiv = styled.div`
  border: 1px solid #e5e4e3;
  background: transparent;
  margin-top: 10px;
  width: 100%;
  min-height: 200px;
`;

const WarpBanner = inject('banner')(observer(BannerList));

export default requireAuth(WarpBanner);
