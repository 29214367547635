import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import XLSX from 'xlsx';
import moment from 'moment';
import ChartActivity from './ChartActivity';
import { notify } from '../../../utils/notify';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, Loading } from '../../../components/common';
import { TabMenu } from '../../../components/forms';
import { history } from '../../../utils/history';
import DateFilter from './DateFilter';

import MENU from './constants';

const { max, getTimeType, stringToMoment, transformData, getDateFormat } = require('./chartUtils');

const URL_TO_ACTIVITY_CODE = {
  steps: 'steps',
  running: 'running',
  cycling: 'cycling',
  swimming: 'swimming',
  traveling: 'traveling_check_in',
  dining: 'eating_check_in',
};

const ACTIVITY_CODE_CONFIG = {
  steps: {
    name: 'STEPS',
    fields: {
      COUNT: '',
      VALUE: 'Number of steps',
      POINT: 'Number of points',
      EARN_COUNT: 'Number of collects',
      EARN_VALUE: 'Number of steps to points',
      NUM_USER: 'Number of users',
      SHARE_COUNT: 'Number of shares',
      SHARE_POINT: 'Number of points from share',
      PICTURE_COUNT: 'Number of photo uploaded',
      PICTURE_POINT: 'Number of points from uploading photo',
    },
    export: {
      header: ['EARN_COUNT', 'NUM_USER', 'POINT', 'EARN_VALUE', 'VALUE', 'SHARE_COUNT', 'SHARE_POINT'],
    },
    groupList1: {
      yLabel: '',
    },
    groupList2: {
      yLabel: 'Users',
    },
    groupList3: {
      yLabel: 'Points',
    },
    groupList4: {
      yLabel: 'Steps',
    },
    groupList5: {
      yLabel: 'Collects',
    },
    groupList6: {
      yLabel: 'Steps',
    },
    groupList7: {
      yLabel: 'Times',
    },
    groupList8: {
      yLabel: 'Points',
    },
    groupList9: {
      yLabel: 'Times',
    },
    groupList10: {
      yLabel: 'Points',
    },
    formatValue: (value) => {
      return value;
    },
  },
  running: {
    name: 'RUNNING',
    fields: {
      COUNT: 'Number of total runnings',
      VALUE: 'Number of total distances',
      POINT: 'Number of points',
      EARN_COUNT: '',
      EARN_VALUE: '',
      NUM_USER: 'Number of users',
      SHARE_COUNT: 'Number of shares',
      SHARE_POINT: 'Number of points from share',
      PICTURE_COUNT: 'Number of photo uploaded',
      PICTURE_POINT: 'Number of points from uploading photo',
    },
    export: {
      header: ['COUNT', 'NUM_USER', 'POINT', 'VALUE', 'SHARE_COUNT', 'SHARE_POINT'],
    },
    groupList1: {
      yLabel: 'Runnings',
    },
    groupList2: {
      yLabel: 'Users',
    },
    groupList3: {
      yLabel: 'Points',
    },
    groupList4: {
      yLabel: 'Distance (km.)',
    },
    groupList7: {
      yLabel: 'Times',
    },
    groupList8: {
      yLabel: 'Points',
    },
    groupList9: {
      yLabel: 'Times',
    },
    groupList10: {
      yLabel: 'Points',
    },
    formatValue: (value) => {
      return value / 1000;
    },
  },
  cycling: {
    name: 'CYCLING',
    fields: {
      COUNT: 'Number of total cyclings',
      VALUE: 'Number of total distances',
      POINT: 'Number of points',
      EARN_COUNT: '',
      EARN_VALUE: '',
      NUM_USER: 'Number of users',
      SHARE_COUNT: 'Number of shares',
      SHARE_POINT: 'Number of points from share',
      PICTURE_COUNT: 'Number of photo uploaded',
      PICTURE_POINT: 'Number of points from uploading photo',
    },
    export: {
      header: ['COUNT', 'NUM_USER', 'POINT', 'VALUE', 'SHARE_COUNT', 'SHARE_POINT'],
    },
    groupList1: {
      yLabel: 'Cyclings',
    },
    groupList2: {
      yLabel: 'Users',
    },
    groupList3: {
      yLabel: 'Points',
    },
    groupList4: {
      yLabel: 'Distances (km.)',
    },
    groupList7: {
      yLabel: 'Times',
    },
    groupList8: {
      yLabel: 'Points',
    },
    groupList9: {
      yLabel: 'Times',
    },
    groupList10: {
      yLabel: 'Points',
    },
    formatValue: (value) => {
      return value / 1000;
    },
  },
  swimming: {
    name: 'SWIMMING',
    fields: {
      COUNT: 'Number of total swimming',
      VALUE: 'Number of total distances',
      POINT: 'Number of points',
      EARN_COUNT: '',
      EARN_VALUE: '',
      NUM_USER: 'Number of users',
      SHARE_COUNT: 'Number of shares',
      SHARE_POINT: 'Number of points from share',
      PICTURE_COUNT: 'Number of photo uploaded',
      PICTURE_POINT: 'Number of points from uploading photo',
    },
    export: {
      header: ['COUNT', 'NUM_USER', 'POINT', 'VALUE', 'SHARE_COUNT', 'SHARE_POINT'],
    },
    groupList1: {
      yLabel: 'Swimmings',
    },
    groupList2: {
      yLabel: 'Users',
    },
    groupList3: {
      yLabel: 'Points',
    },
    groupList4: {
      yLabel: 'Distances (m)',
    },
    groupList7: {
      yLabel: 'Times',
    },
    groupList8: {
      yLabel: 'Points',
    },
    groupList9: {
      yLabel: 'Times',
    },
    groupList10: {
      yLabel: 'Points',
    },
    formatValue: (value) => {
      return value;
    },
  },
  eating_check_in: {
    name: 'DINING',
    fields: {
      COUNT: 'Number of total dinings',
      VALUE: '',
      POINT: 'Number of points',
      EARN_COUNT: '',
      EARN_VALUE: '',
      NUM_USER: 'Number of users',
      SHARE_COUNT: 'Number of shares',
      SHARE_POINT: 'Number of points from share',
      PICTURE_COUNT: 'Number of photo uploaded',
      PICTURE_POINT: 'Number of points from uploading photo',
    },
    export: {
      header: ['COUNT', 'NUM_USER', 'POINT', 'SHARE_COUNT', 'SHARE_POINT', 'PICTURE_COUNT', 'PICTURE_POINT'],
    },
    groupList1: {
      yLabel: 'Dinings',
    },
    groupList2: {
      yLabel: 'Users',
    },
    groupList3: {
      yLabel: 'Points',
    },
    groupList4: {
      yLabel: '',
    },
    groupList7: {
      yLabel: 'Times',
    },
    groupList8: {
      yLabel: 'Points',
    },
    groupList9: {
      yLabel: 'Times',
    },
    groupList10: {
      yLabel: 'Points',
    },
    formatValue: (value) => {
      return value;
    },
  },
  traveling_check_in: {
    name: 'TRAVELING',
    fields: {
      COUNT: 'Number of total travels',
      VALUE: '',
      POINT: 'Number of points',
      EARN_COUNT: '',
      EARN_VALUE: '',
      NUM_USER: 'Number of users',
      SHARE_COUNT: 'Number of shares',
      SHARE_POINT: 'Number of points from share',
      PICTURE_COUNT: 'Number of photo uploaded',
      PICTURE_POINT: 'Number of points from uploading photo',
    },
    export: {
      header: ['COUNT', 'NUM_USER', 'POINT', 'SHARE_COUNT', 'SHARE_POINT', 'PICTURE_COUNT', 'PICTURE_POINT'],
    },
    groupList1: {
      yLabel: 'Travelings',
    },
    groupList2: {
      yLabel: 'Users',
    },
    groupList3: {
      yLabel: 'Points',
    },
    groupList4: {
      yLabel: '',
    },
    groupList7: {
      yLabel: 'Times',
    },
    groupList8: {
      yLabel: 'Points',
    },
    groupList9: {
      yLabel: 'Times',
    },
    groupList10: {
      yLabel: 'Points',
    },
    formatValue: (value) => {
      return value;
    },
  },
};

export class DashboardActivity extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.state.activityCode = this.getCurrentActivityCode();
  }

  getCurrentActivityCode() {
    const { href } = window.location;
    const start = href.lastIndexOf('/');
    let end = href.indexOf('?');
    if (end < 0) {
      end = href.length;
    }
    const path = href.substring(start + 1, end);
    const activityCode = URL_TO_ACTIVITY_CODE[path];
    return activityCode;
  }

  onTabChange = (item) => {
    const { name } = this.props;
    if (item.link && name !== item.name) history.push(item.link);
    this.state.activityCode = this.getCurrentActivityCode();
    this.getActivity(this.state.filter);
  };

  onFilterChange = (filter = {}) => {
    this.state.filter = filter;
    this.getActivity(filter);
  };

  getActivity = async (params = {}) => {
    try {
      this.setState({ loading: true });
      params.timeType = getTimeType(params);
      params.activityCode = this.state.activityCode;
      await this.props.dashboardActivity.getActivity(params);
    } catch (e) {
      notify.error({ title: 'Load Register Fail', message: e.message });
    }
    this.setState({ loading: false });
  };

  groupList = (list) => {
    const datasets = {};
    const info = { xLabel: 'Date', yLabel: 'User Counts', numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets.COUNT = {};
    datasets.VALUE = {};
    datasets.POINT = {};
    datasets.EARN_COUNT = {};
    datasets.EARN_VALUE = {};
    datasets.NUM_USER = {};
    datasets.SHARE_COUNT = {};
    datasets.SHARE_POINT = {};
    datasets.PICTURE_COUNT = {};
    datasets.PICTURE_POINT = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      datasets.COUNT[time] = element.COUNT;
      maxY = max(maxY, element.COUNT);

      datasets.VALUE[time] = element.VALUE;
      maxY = max(maxY, element.VALUE);

      datasets.POINT[time] = element.POINT;
      maxY = max(maxY, element.POINT);

      datasets.EARN_COUNT[time] = element.EARN_COUNT;
      maxY = max(maxY, element.EARN_COUNT);

      datasets.EARN_VALUE[time] = element.EARN_VALUE;
      maxY = max(maxY, element.EARN_VALUE);

      datasets.NUM_USER[time] = element.NUM_USER;
      maxY = max(maxY, element.NUM_USER);

      datasets.SHARE_COUNT[time] = element.SHARE_COUNT;
      maxY = max(maxY, element.SHARE_COUNT);

      datasets.SHARE_POINT[time] = element.SHARE_POINT;
      maxY = max(maxY, element.SHARE_POINT);

      datasets.PICTURE_COUNT[time] = element.PICTURE_COUNT;
      maxY = max(maxY, element.PICTURE_COUNT);

      datasets.PICTURE_POINT[time] = element.PICTURE_POINT;
      maxY = max(maxY, element.PICTURE_POINT);
    });
    info.maxY = maxY;
    return data;
  };

  groupList1 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList1.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.COUNT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();

      datasets[config.fields.COUNT][time] = element.COUNT;
      maxY = max(maxY, element.COUNT);
    });
    info.maxY = maxY;
    return data;
  };

  groupList2 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList2.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.NUM_USER] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();

      datasets[config.fields.NUM_USER][time] = element.NUM_USER;
      maxY = max(maxY, element.NUM_USER);
    });
    info.maxY = maxY;
    return data;
  };

  groupList3 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList3.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.POINT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      datasets[config.fields.POINT][time] = element.POINT;
      maxY = max(maxY, element.POINT);
    });
    info.maxY = maxY;
    return data;
  };

  groupList4 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList4.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.VALUE] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = config.formatValue(element.VALUE);
      datasets[config.fields.VALUE][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    return data;
  };

  groupList5 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList5.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.EARN_COUNT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = element.EARN_COUNT;
      datasets[config.fields.EARN_COUNT][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    return data;
  };

  groupList6 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList6.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.EARN_VALUE] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = config.formatValue(element.EARN_VALUE);
      datasets[config.fields.EARN_VALUE][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    return data;
  };

  groupList7 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList7.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.SHARE_COUNT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = element.SHARE_COUNT;
      datasets[config.fields.SHARE_COUNT][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    console.log('groupList7', { datasets, info });
    return data;
  };

  groupList8 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList8.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.SHARE_POINT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = element.SHARE_POINT;
      datasets[config.fields.SHARE_POINT][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    return data;
  };

  groupList9 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList9.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.PICTURE_COUNT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = element.PICTURE_COUNT;
      datasets[config.fields.PICTURE_COUNT][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    return data;
  };

  groupList10 = (list) => {
    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    const datasets = {};
    const info = { xLabel: '', yLabel: config.groupList10.yLabel, numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[config.fields.PICTURE_POINT] = {};

    list.forEach((element) => {
      const time = stringToMoment(element.DATE)
        .toDate()
        .getTime();
      const value = element.PICTURE_POINT;
      datasets[config.fields.PICTURE_POINT][time] = value;
      maxY = max(maxY, value);
    });
    info.maxY = maxY;
    return data;
  };

  exportDashboardActivity = () => {
    const { data: sourceData } = this.props.dashboardActivity.toJS().activity;
    const { start, end, timeType, list } = sourceData;
    const graph = transformData({ start, end, timeType }, this.groupList(list));

    const config = ACTIVITY_CODE_CONFIG[this.state.activityCode];
    // do header
    const HEADER = config.export.header.map((header) => config.fields[header]);
    HEADER.unshift('Date');

    // do rows
    const output = [];
    for (let i = 0; i < graph.labels.length; i++) {
      const row = [moment(graph.labels[i]).format(getDateFormat(timeType))];
      for (let j = 0; j < config.export.header.length; j++) {
        const dataset = graph.datasets.find(item => item.name === config.export.header[j]);
        row.push(dataset.list[i]);
      }
      output.push(row);
    }

    try {
      const datetime = moment().format('YYYYMMDD_hhmmss');
      const fileName = `activity_${this.state.activityCode}_report_${datetime}`;
      const sheetName = 'Sheet1';
      const ws = XLSX.utils.aoa_to_sheet([HEADER, ...output]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  render() {
    const { activityCode } = this.state;
    const { data: sourceData } = this.props.dashboardActivity.toJS().activity;
    const { start, end, timeType, list } = sourceData;
    console.log('[DashboardActivitySteps][render]', { sourceData, loading: this.state.loading, state: this.state });

    let graph = {};
    let graph1 = {};
    let graph2 = {};
    let graph3 = {};
    let graph4 = {};
    let graph5 = {};
    let graph6 = {};
    let graph7 = {};
    let graph8 = {};
    let graph9 = {};
    if (!this.state.loading) {
      if (start && end && timeType) {
        switch (activityCode) {
          case 'steps':
            graph = transformData({ start, end, timeType }, this.groupList(list));
            graph1 = transformData({ start, end, timeType }, this.groupList5(list));
            graph2 = transformData({ start, end, timeType }, this.groupList2(list));
            graph3 = transformData({ start, end, timeType }, this.groupList3(list));
            graph4 = transformData({ start, end, timeType }, this.groupList6(list));
            graph5 = transformData({ start, end, timeType }, this.groupList4(list));

            graph6 = transformData({ start, end, timeType }, this.groupList7(list));
            graph7 = transformData({ start, end, timeType }, this.groupList8(list));
            break;
          case 'running':
          case 'cycling':
          case 'swimming':
            graph = transformData({ start, end, timeType }, this.groupList(list));
            graph1 = transformData({ start, end, timeType }, this.groupList1(list));
            graph2 = transformData({ start, end, timeType }, this.groupList2(list));
            graph3 = transformData({ start, end, timeType }, this.groupList3(list));
            graph4 = transformData({ start, end, timeType }, this.groupList4(list));

            graph6 = transformData({ start, end, timeType }, this.groupList7(list));
            graph7 = transformData({ start, end, timeType }, this.groupList8(list));
            break;

          case 'traveling_check_in':
          case 'eating_check_in':
            // graph = transformData({ start, end, timeType }, this.groupList(list));
            graph1 = transformData({ start, end, timeType }, this.groupList1(list));
            graph2 = transformData({ start, end, timeType }, this.groupList2(list));
            graph3 = transformData({ start, end, timeType }, this.groupList3(list));

            graph6 = transformData({ start, end, timeType }, this.groupList7(list));
            graph7 = transformData({ start, end, timeType }, this.groupList8(list));
            graph8 = transformData({ start, end, timeType }, this.groupList9(list));
            graph9 = transformData({ start, end, timeType }, this.groupList10(list));
            break;
          default:
            break;
        }
      }
    }

    let chart;
    let chart1;
    let chart2;
    let chart3;
    let chart4;
    let chart5;
    let chart6;
    let chart7;
    let chart8;
    let chart9;
    switch (activityCode) {
      case 'steps':
        // chart = <ChartActivity title="graph" graph={graph} />;
        chart1 = <ChartActivity title="graph1" graph={graph1} />;
        chart2 = <ChartActivity title="graph2" graph={graph2} />;
        chart3 = <ChartActivity title="graph3" graph={graph3} />;
        chart4 = <ChartActivity title="graph4" graph={graph4} />;
        chart5 = <ChartActivity title="graph5" graph={graph5} />;

        chart6 = <ChartActivity title="graph6" graph={graph6} />;
        chart7 = <ChartActivity title="graph7" graph={graph7} />;
        break;
      case 'running':
      case 'cycling':
      case 'swimming':
        // chart = <ChartActivity title="graph" graph={graph} />;
        chart1 = <ChartActivity title="graph1" graph={graph1} />;
        chart2 = <ChartActivity title="graph2" graph={graph2} />;
        chart3 = <ChartActivity title="graph3" graph={graph3} />;
        chart4 = <ChartActivity title="graph4" graph={graph4} />;

        chart6 = <ChartActivity title="graph6" graph={graph6} />;
        chart7 = <ChartActivity title="graph7" graph={graph7} />;
        break;
      case 'traveling_check_in':
      case 'eating_check_in':
        // chart = <ChartActivity title="graph" graph={graph} />;
        chart1 = <ChartActivity title="graph1" graph={graph1} />;
        chart2 = <ChartActivity title="graph2" graph={graph2} />;
        chart3 = <ChartActivity title="graph3" graph={graph3} />;

        chart6 = <ChartActivity title="graph6" graph={graph6} />;
        chart7 = <ChartActivity title="graph7" graph={graph7} />;
        chart8 = <ChartActivity title="graph8" graph={graph8} />;
        chart9 = <ChartActivity title="graph9" graph={graph9} />;
        break;

      default:
        break;
    }
    return (
      <HeaderMenu title={ACTIVITY_CODE_CONFIG[activityCode].name}>
        <Page>
          <Loading loading={this.state.loading} />
          <TabMenu menus={MENU} name={ACTIVITY_CODE_CONFIG[activityCode].name} onChange={this.onTabChange} />
          <DateFilter onFilterChange={this.onFilterChange} onExport={this.exportDashboardActivity} />
          {chart}
          {chart1}
          {chart2}
          {chart3}
          {chart4}
          {chart5}
          {chart6}
          {chart7}
          {chart8}
          {chart9}
        </Page>
      </HeaderMenu>
    );
  }
}

export default inject('dashboardActivity')(observer(DashboardActivity));
