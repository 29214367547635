import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Typography, Button } from 'antd';
import styled from 'styled-components';
import format from '../../../utils/format';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { notify } from '../../../utils/notify';
import { Loading, IntCol } from '../../../components/common';
import DateFilter from '../../../components/filter/DateFilter';

const { Title } = Typography;

const NAME = 'FRIEND GET FRIEND';
const COLUMNS = [
  {
    title: 'CREATED',
    dataIndex: 'created_at',
    render: (time) => <span>{format.toFullDT(time)}</span>,
  },
  {
    title: 'FWD CODE',
    dataIndex: 'referral_code',
  },
  {
    title: 'INVITER ID',
    dataIndex: 'referrer_id',
  },
  {
    title: 'INVITER MOBILE NUMBER',
    dataIndex: 'tmp_phone',
  },
  {
    title: 'INVITER NAME',
    dataIndex: 'name',
    render: (_, v) => <span>{`${v.tmp_fname} ${v.tmp_lname}`}</span>,
  },
  {
    title: 'INVITER POINTS',
    dataIndex: 'referrer_points',
    render: (point) => <IntCol value={point} />,
  },
  {
    title: 'INVITEE ID',
    dataIndex: 'invitee_id',
  },
  {
    title: 'INVITEE POINTS',
    dataIndex: 'invitee_points',
    render: (point) => <IntCol value={point} />,
  },
];

const HEADER = [
  'Created',
  'FWD Code',
  'Inviter ID',
  'Inviter Mobile Number',
  'Inviter Name',
  'Inviter Points',
  'Invitee ID',
  'Invitee Points',
];

export class DashboardFriendGetFriend extends Component {
  constructor(props) {
    super(props);
    this.boardFilterRef = React.createRef();
  }

  fetchApi = async (date = {}, page = 1) => {
    try {
      await this.props.dashboard.getInviteFriend(date, page);
    } catch (e) {
      notify.error({ title: 'Load Friend-Get-Friend Fail', message: e.message });
    }
  };

  onFilterDate = (date) => {
    this.fetchApi(date, 1);
  };

  onChangePage = (pagination) => {
    this.fetchApi(this.boardFilterRef.current.getDate(), pagination.current);
  };

  handleExportExcel = async () => {
    try {
      await this.props.dashboard.exportInviteFriend(this.boardFilterRef.current.getDate(), HEADER);
    } catch (e) {
      notify.error({ title: 'Export Friend-Get-Friend Fail', message: e.message });
    }
  };

  render() {
    const { list, loading, pagination } = this.props.dashboard.inviteFriend;
    return (
      <HeaderMenu title={NAME}>
        <Loading loading={this.props.dashboard.loading} />

        <DateFilter onFind={this.onFilterDate} ref={this.boardFilterRef} />

        <ExportContainer>
          <Title style={css.countList} level={4}>
            Number of redemption: {pagination.total}
          </Title>
          <Button type="primary" shape="round" size="large" style={css.exportButton} onClick={this.handleExportExcel}>
            EXPORT
          </Button>
        </ExportContainer>

        <Table
          indentSize={2}
          style={css.table}
          columns={COLUMNS}
          dataSource={list}
          bordered
          loading={loading}
          onChange={this.onChangePage}
          pagination={{
            ...pagination,
            position: 'both',
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </HeaderMenu>
    );
  }
}

const css = {
  countList: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
  },
  table: {
    background: 'white',
    marginTop: 20,
  },
  exportButton: {
    backgroundColor: '#EF843D',
    borderColor: '#EF843D',
  },
};

const ExportContainer = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

export default inject('dashboard')(observer(DashboardFriendGetFriend));
