import { cloneDeep } from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { notify } from '../utils/notify';
import { helper } from '../utils/helper';

const origin = {
  activity_point_calculation_id: undefined,
  value: 0,
  point: 0,
  max_count: 0,
};

const origin_plancode = {
  plancode: 'default',
  baht: 1,
  point: 1,
  multiply: 1,
  plancode_point_calculation_type: 'ratio',
  is_default: true,
  status: 'active',
  start_at: moment(),
  end_at: moment(),
};

const origin_plancode_config = {
  loyaltyHoldPeriod: 15,
  loyaltyPastYears: 1,
  loyaltyMaxPointPerYear: 100,
};

const CSV = {
  header: ['No.', 'Plancode', 'Group', 'Multiply', 'Start Date', 'End Date'],
  key: ['no', 'plancode', 'plancode_group', 'multiply', 'start_at', 'end_at'],
};

const GLOBAL = ['welcome', 'email_verification', 'fwd_customer', 'birthday', 'poll_and_survey', 'referrer', 'invitee', 'user_limit_point_per_day']; // , 'max_points_per_user_per_month']
const ACTIVITY = ['steps', 'running', 'cycling', 'swimming'];
const ACTIVITY_SEQ = ['step_1', 'step_2', 'running', 'cycling', 'swimming'];
const PASSIVE = [
  'activity_share_cycling',
  'activity_share_running',
  'activity_share_steps',
  'activity_share_swimming',
  'share_reward',
  'traveling_check_in',
  'traveling_take_photo',
  'traveling_share_to_social',
  'read_knowledge',
  'share_knowledge',
  'highlight_sharing'
];

const BULK_SIZE = 1000;

export class Point extends BaseStore {
  constructor() {
    super();
    this.observable({
      global: {
        welcome: cloneDeep(origin),
        email_verification: cloneDeep(origin),
        fwd_customer: cloneDeep(origin),
        birthday: cloneDeep(origin),
        user_limit_point_per_day: cloneDeep(origin),
        poll_and_survey: cloneDeep(origin),
        invitee: cloneDeep(origin),
        referrer: cloneDeep(origin),
      },
      activity: {
        step_1: cloneDeep(origin),
        step_2: cloneDeep(origin),
        running: cloneDeep(origin),
        cycling: cloneDeep(origin),
        swimming: cloneDeep(origin),
      },
      passive: {
        activity_share_cycling: cloneDeep(origin),
        activity_share_running: cloneDeep(origin),
        activity_share_steps: cloneDeep(origin),
        activity_share_swimming: cloneDeep(origin),

        share_reward: cloneDeep(origin),

        traveling_check_in: cloneDeep(origin),
        traveling_take_photo: cloneDeep(origin),
        traveling_share_to_social: cloneDeep(origin),

        read_knowledge: cloneDeep(origin),
        share_knowledge: cloneDeep(origin),

        highlight_sharing: cloneDeep(origin)
      },
      plancode: {
        default: cloneDeep(origin_plancode),
        list: [],
        importList: [],
        config: cloneDeep(origin_plancode_config),
      },
      pointAdjust: {
        list: [],
        loading: false,
        pagination: {},
      },
      loading: false,
    });
  }

  async getGlobal() {
    const query = GLOBAL.join();
    const url = `${config.api.max}/v1/office/point/activity?code=${query}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    const data = await this.convertToData({ list });
    await this.checkData({ data, list: GLOBAL });
    this.global = data;
  }

  async getActivity() {
    const query = ACTIVITY.join();
    const url = `${config.api.max}/v1/office/point/activity?code=${query}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    const data = {};
    for (const item of list) {
      switch (item.activity_code) {
        case 'steps':
          if (item.sequence === 1) data.step_1 = item;
          else if (item.sequence === 2) data.step_2 = item;
          break;
        default:
          data[item.activity_code] = item;
          break;
      }
    }

    await this.checkData({ data, list: ACTIVITY_SEQ });
    this.activity = data;
  }

  async getPassiveActivity() {
    const query = PASSIVE.join();
    const url = `${config.api.max}/v1/office/point/activity?code=${query}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    const data = await this.convertToData({ list });
    await this.checkData({ data, list: PASSIVE });
    this.passive = data;
  }

  async getPlancode() {
    const url = `${config.api.max}/v1/office/plancode`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    let defaulter = cloneDeep(origin_plancode);
    const index = list.findIndex((item) => item.is_default);
    if (index >= 0) {
      defaulter = list[index];
      list.splice(index, 1);
    }

    this.plancode = {
      default: defaulter,
      list,
      importList: [],
      config: cloneDeep(origin_plancode_config),
    };
  }

  async getConfigPlancode() {
    const url = `${config.api.max}/v1/office/plancode/config`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.plancode.config = res.body;
  }

  async checkData({ data, list = [] }) {
    for (const name of list) {
      if (data[name] === undefined) {
        error.launch({ message: `require attribute ${name}` });
        return;
      }
    }
  }

  convertToData({ list }) {
    const data = {};
    for (const item of list) {
      data[item.activity_code] = item;
    }

    return data;
  }

  async saveGlobal(doc) {
    for (let [key, value] of Object.entries(doc)) {
      this.global[key].point = value.point;
    }
    const data = this.toJS().global;
    await this.saveActivityPoint({ data, names: GLOBAL });
  }

  async saveActivity() {
    const data = this.toJS().activity;
    await this.saveActivityPoint({ data, names: ACTIVITY_SEQ });
  }

  async savePassiveActivity() {
    const data = this.toJS().passive;
    await this.saveActivityPoint({ data, saveConfig: true, names: PASSIVE });
  }

  async saveActivityPoint({ data, saveConfig = false, names }) {
    const list = names.map((name) => {
      const item = data[name];
      return {
        id: item.activity_point_calculation_id,
        value: item.value,
        point: item.point,
        max_count: item.max_count,
        activity_code: item.activity_code,
      };
    });

    const url = `${config.api.max}/v1/office/point/activity`;
    const res = await http.put(url, { json: { list, saveConfig }, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async savePlancode() {
    const doc = this.toJS().plancode;

    const url = `${config.api.max}/v1/office/plancode`;
    const res = await http.put(url, { json: doc.default, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async saveConfigPlancode() {
    const doc = this.toJS().plancode;
    const url = `${config.api.max}/v1/office/plancode/config`;
    const res = await http.put(url, { json: doc.config, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async setGlobal(val) {
    this.global = val;
  }

  async setActivity(val) {
    this.activity = val;
  }

  async setPassiveActivity(val) {
    this.passive = val;
  }

  async setPlancode(val) {
    this.plancode = val;
  }

  async exportPlancode() {
    const doc = this.toJS().plancode;
    const rows = doc.list;
    const list = [];

    rows.forEach((row, index) => {
      row.no = index + 1;
      row.plancode_group = row.plancode_group !== null ? row.plancode_group : '-';
      row.multiply = row.multiply !== null ? row.multiply : '-';
      row.start_at = moment(row.start_at).format('YYYY-MM-DD HH:mm:ss');
      row.end_at = moment(row.end_at).format('YYYY-MM-DD HH:mm:ss');
      list.push(row);
    });
    const csvFile = helper.toCSV({ list, key: CSV.key, header: CSV.header });
    return csvFile;
  }

  async importPlancode({ list }) {
    const url = `${config.api.max}/v1/office/plancode/import`;
    const count = list.length;
    const loop = helper.getBulkLoop(count, BULK_SIZE);
    let i;
    let end = 0;
    for (i = 0; i < loop; i++) {
      const limit = BULK_SIZE;
      const start = i * BULK_SIZE;
      end += limit;
      const bulk = list.slice(start, end);
      const firstTime = i === 0;
      const res = await http.post(url, { json: { list: bulk, firstTime }, token: true });
      if (res.statusCode !== 200) {
        error.launch({ message: res.body.message });
        break;
      }
    }
  }

  async getWalletAdjustList(date = {}, page = 1, mobileNumber = '', status) {
    const { start, end } = date;
    const url = `${config.api.max}/v1/office/wallets/getWalletAdjustList`;
    const qs = { page, mobileNumber, limit: 10, status };
    if (start && end) {
      qs.start = start.format('YYYY-MM-DD');
      qs.end = end.format('YYYY-MM-DD');
    }
    this.pointAdjust.loading = true;
    const res = await http.get(url, { token: true, qs });
    this.pointAdjust.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { rows = [], count = 0 } = res.body;
    this.pointAdjust.list = rows;
    this.pointAdjust.pagination = {
      total: count,
      current: page,
    };
  }

  resetWalletAdjustList() {
    this.pointAdjust = {
      list: undefined,
      loading: false,
      pagination: {},
    };
  }

  async exportWalletAdjustList(date, mobileNumber, status) {
    this.loading = true;
    const { start, end } = date;
    const url = `${config.api.max}/v1/office/wallets/getWalletAdjustList`;
    const qs = { mobileNumber, limit: 1000, status };
    const items = [];
    let total = 1;
    let firstFetch = true;
    if (start && end) {
      qs.start = start.format('YYYY-MM-DD');
      qs.end = end.format('YYYY-MM-DD');
    }
    for (let page = 1; items.length < total; page++) {
      qs.page = page;
      const resp = await http.get(url, { qs, token: true });
      if (resp.statusCode !== 200) {
        this.loading = false;
        error.launch({ message: resp.body.message });
        return null;
      }
      if (firstFetch) {
        firstFetch = false;
        total = resp.body.count;
        this.pointAdjust.pagination.total = total;
      }
      items.push(...resp.body.rows);
    }
    this.loading = false;
    return items;
  }

  async importWalletAdjust(items) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/wallets/saveWalletAdjustList`;
    const res = await http.post(url, {
      json: { items },
      token: true,
    });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async onReject(walletAdjustId) {
    this.loading = true;

    const url = `${config.api.max}/v1/office/wallets/adjust/${walletAdjustId}/reject`;
    const res = await http.post(url, { token: true });

    if (res.statusCode !== 200) {
      this.loading = false;
      notify.error({
        title: 'Rejection Error',
        message: res.body.message,
      });
    }

    message.success('Point adjustment has been rejected.');
    this.loading = false;
  }

  async onApprove(walletAdjustId) {
    this.loading = true;

    const url = `${config.api.max}/v1/office/wallets/adjust/${walletAdjustId}/approve`;
    const res = await http.post(url, { token: true });

    if (res.statusCode !== 200) {
      this.loading = false;
      notify.error({
        title: 'Approve Error',
        message: res.body.message,
      });
    }

    message.success('Point adjustment has been approved.');
    this.loading = false;
  }
}

export default new Point();
