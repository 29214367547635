import React, { Component } from 'react';
import styled from 'styled-components';

import HeaderMenu from '../../../components/main/HeaderMenu';

import Basic from './Basic';
import Migration from './Migration';

export default class JobsTrigger extends Component {
  render() {
    return (
      <HeaderMenu title="JOBS-TRIGGER">
        <Page>
          <Basic />
        </Page>

        <Page>
          <Migration />
        </Page>
      </HeaderMenu>
    );
  }
}

const Page = styled.div`
  padding: 20px 0px 40px 20px;
`;
