import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

export default class TabMenu extends Component {
  state = {
    selected: 0,
  };

  componentDidMount() {
    const { menus = [], name } = this.props;
    const index = menus.findIndex((item) => {
      return item.name === name;
    });

    if (index >= 0) this.setState({ selected: index });
  }

  onChange(index) {
    this.setState({ selected: index });
    const { onChange, menus } = this.props;
    if (onChange) onChange(menus[index]);
  }

  render() {
    let { selected } = this.state;
    const { menus = [], defaultIndex } = this.props;
    if (defaultIndex) selected = defaultIndex;

    const list = menus.map((item, index) => {
      return (
        <Col style={index === selected ? css.activeCol : css.col} lg={3} md={6} sm={8} xs={8} key={index}>
          <MenuName onClick={this.onChange.bind(this, index)}>{item.name}</MenuName>
        </Col>
      );
    });
    return (
      <Row style={css.row} justify="center">
        {list}
      </Row>
    );
  }
}

const MenuName = styled.div`
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const css = {
  row: {
    backgroundColor: 'white',
    border: '0px solid #eeeeee',
    borderBottomWidth: '2px',
    paddingTop: '15px',
  },
  activeCol: {
    padding: '10px 0px',
    color: '#e87722',
    border: '0px solid #e87722',
    borderBottomWidth: '2px',
    fontWeight: 800,
  },
  col: {
    padding: '10px 0px',
    color: '#1e1e1e',
    fontWeight: 500,
  },
};
