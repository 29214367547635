import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Input, DatePicker, notification } from 'antd';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import notify from '../../../utils/notify';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, Loading, SaveSection } from '../../../components/common';
import { history } from '../../../utils/history';
import SurveyScheduleBackButton from './SurveyScheduleBackButton';
import './survey-template.css';

const { TextArea } = Input;

const datepickerFormat = 'YYYY-MM-DD HH:mm';
const dummyTemplate = {
  scheduleAt: moment(new Date()),
  titleTH: '',
  messageTH: '',
};

const SurveyTemplateEdit = (props) => {
  const { surveyTemplate, match } = props;
  const [loading, setLoading] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(dummyTemplate);
  const [scheduleError, setScheduleError] = useState({ validateStatus: '', message: '' });

  const { form } = props;
  const { getFieldDecorator } = form;

  const { params } = match;
  const { formId } = params;

  useEffect(() => {
    setLoading(true);

    surveyTemplate
      .fetchSurveyTemplateDetail(formId)
      .then(() => {
        const currTemplate = surveyTemplate.currentSurveyTemplate;
        setCurrentTemplate(() => currTemplate);
      })
      .catch((err) => {
        notify.error({
          title: 'Error occurred',
          message: err.message,
        });
      })
      .finally(() => setLoading(false));
  }, [surveyTemplate, formId]);

  const onScheduleAtChange = (value) => {
    if (value.isBefore(moment())) {
      setScheduleError({
        validateStatus: 'error',
        message: 'Survey cannot be scheduled in past date.',
      });
    } else {
      setScheduleError({
        validateStatus: 'success',
        message: '',
      });
    }
  };

  const renderSurveyForm = (template) => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    let scheduleAt = moment(new Date());
    if (template && template.scheduleAt) {
      scheduleAt = moment(template.scheduleAt).format(datepickerFormat);
    }

    const disabledDate = (current) => {
      const m = moment();
      const check = current && current.isBefore(m, 'day');

      return check;
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          setLoading(true);
          // setError('');
          notification.destroy();
          const scheduledAtAsString = values.schedule_at.toDate();
          const postData = {
            ...values,
            schedule_at: scheduledAtAsString,
          };

          surveyTemplate
            .update(formId, postData)
            .then(() => {
              setCurrentTemplate(surveyTemplate.currentSurveyTemplate);
              history.push('/office/config/survey-schedule');
              notify.success({
                title: 'Updated Successfully',
                message: 'Survey Schedule has been updated.',
              });
            })
            .catch((er) => {
              notify.error({
                title: 'Error occurred',
                message: er.message,
              });
            })
            .finally(() => setLoading(false));
        }
      });
    };

    return (
      <Form {...formItemLayout} className="page-survey">
        <Form.Item
          label="Schedule At"
          validateStatus={scheduleError.validateStatus}
          help={scheduleError.message || 'Push notification will be sent on this date.'}
        >
          {getFieldDecorator('schedule_at', {
            initialValue: moment(scheduleAt, datepickerFormat),
            rules: [
              {
                required: true,
              },
            ],
          })(
            <DatePicker
              format={datepickerFormat}
              showTime={{ format: 'HH:mm' }}
              placeholder="Select schedule date"
              disabledDate={disabledDate}
              onChange={onScheduleAtChange}
            />
          )}
        </Form.Item>
        <Form.Item label="Title (TH)">
          {getFieldDecorator('title_th', {
            initialValue: template.titleTh,
            rules: [
              {
                required: true,
              },
            ],
          })(<TextArea rows={4} />)}
        </Form.Item>
        <Form.Item label="Message (TH)">
          {getFieldDecorator('message_th', {
            rules: [
              {
                required: true,
              },
            ],
            initialValue: template.messageTh,
          })(<TextArea rows={4} />)}
        </Form.Item>
        <SaveSection onClick={handleSubmit} disabled={scheduleError.message !== ''} />
      </Form>
    );
  };

  const onDelete = (template) => {
    const modal = Modal.confirm({
      title: `Delete survey config (form id: ${template.formId})`,
      closable: true,
      okText: 'Delete',
      visible: true,
      icon: 'delete',
      content: 'Are you sure you want to delete survey config?',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        return new Promise((resolve, reject) => {
          modal.update({
            cancelButtonProps: {
              disabled: true,
            },
            okButtonProps: {
              disabled: true,
            },
            confirmLoading: true,
          });

          surveyTemplate
            .delete(template.formId)
            .then(() => {
              resolve('done');

              notify.success({
                title: 'Deleted successfully',
                message: `Survey Config with form id (${template.formId}) has been deleted successfully.`,
              });

              history.push('/office/config/survey-schedule');
            })
            .catch((e) => {
              reject(e);

              notify.error({
                title: 'Error',
                message: `Error, cannot delete. Actual error: ${e.message}`,
              });

              modal.destroy();
            });
        });
      },
      onCancel: () => {},
    });
  };

  return (
    <HeaderMenu title="SURVEY SCHEDULE">
      <Page style={css.page}>
        <Loading loading={loading} />
        <StyledPageActionBar>
          <SurveyScheduleBackButton />
          <StyledDeleteButton type="danger" onClick={() => onDelete(currentTemplate)} icon="delete">
            Delete
          </StyledDeleteButton>
          <StyledHelpParagraph>Deleting survey config will delete record. It cannot be undone.</StyledHelpParagraph>
        </StyledPageActionBar>
        {renderSurveyForm(currentTemplate)}
      </Page>
    </HeaderMenu>
  );
};

const StyledPageActionBar = styled.div`
  text-align: right;
  margin-bottom: 15px;
  font-family: tahoma, verdana, roboto;
  color: #fff;
`;

const StyledHelpParagraph = styled.p`
  color: #d44b0b;
  margin-top: 10px;
  font-size: 16px;
`;

const StyledDeleteButton = styled(Button)`
  height: 48px;
  width: 120px;
  line-height: 18px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
`;

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

const SurveyTemplateEditForm = Form.create({ name: 'survey_template_edit' })(SurveyTemplateEdit);

export default inject('surveyTemplate')(observer(SurveyTemplateEditForm));
