import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Col, Divider } from 'antd';

import { isEqual } from 'lodash';
import { Loading, SaveSection } from '../../components/common';

import { InputInt, Input } from '../../components/forms';
import BadgeTab from './BadgeTab';
import { notify } from '../../utils/notify';

const NAME = 'Badges';

export class Badge extends Component {
  state = {
    loading: false,
    validate: {},
  };

  componentDidMount() {}

  onChange = (value, key) => {
    this.doc[key] = value;
    this.props.badge.setBadge(this.doc);
  };

  validate = () => {
    try {
      const validateSubmit = {};
      const { doc, key, amountKey } = this;
      if (doc[key] === null || doc[key] === '') {
        validateSubmit[key] = true;
      }

      if (doc[amountKey] !== undefined && doc[amountKey] === null) {
        validateSubmit[amountKey] = true;
      }

      this.setState({ validateSubmit });
      if (isEqual({}, validateSubmit)) return true;
    } catch (e) {}

    return false;
  };

  onSave = async () => {
    if (this.validate() === false) return;

    try {
      this.setState({ loading: true });
      const data = {};
      data[this.key] = this.doc[this.key];
      if (this.amountKey !== undefined) {
          data[this.amountKey] = this.doc[this.amountKey];
      }
      await this.props.badge.saveBadgeLevel(data);
      notify.success({ title: `Save ${NAME} Complete` });
    } catch (e) {
      notify.error({ title: `Save ${NAME} Fail`, message: e.message });
    }
    await this.props.badge.getBadgeLevel();
    this.setState({ loading: false });
  };

  render() {
    const span = 4;
    const { loading, validate } = this.state;
    const { level, tier } = this.props.match.params;
    const tabIndex = parseInt(level, 10) - 1;
    this.doc = this.props.badge.badgeConfig;
    this.key = `badgeLv${level}`;
    this.amountKey = `badgeLv${level}Amount`;

    return (
      <BadgeTab defaultIndex={tabIndex} loading={loading}>
        <Loading loading={loading} />
        <Form colon={false}>
          <Row>
            <Col span={span}>
              <Form.Item label="Level">
                <Input name="level" value={level} allowClear={false} />
              </Form.Item>
            </Col>
            <Col span={1}></Col>

            <Col span={span}>
              <Form.Item
                label="Badge Name"
                validateStatus={validate[this.key] ? 'error' : ''}
                help={validate[this.key] ? 'Badge Name is required' : ''}
              >
                <Input name={this.key} value={this.doc[this.key]} allowClear={false} onChange={this.onChange} maxLength={50}/>
              </Form.Item>
            </Col>

            {this.doc[this.amountKey] !== undefined && (
              <>
                <Col span={1}></Col>
                <Col span={span}>
                  <Form.Item
                    label="Level criteria"
                    validateStatus={validate[this.amountKey] ? 'error' : ''}
                    help={validate[this.amountKey] ? 'Level criteria is required' : ''}
                  >
                    <InputInt
                      name={this.amountKey}
                      value={this.doc[this.amountKey]}
                      allowClear={false}
                      onChange={this.onChange}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
        <Divider />
        <div style={css.footer}>
          <p>* การเปลี่ยน Badge Name จะมีผลกับ ชื่อ Badge ที่แสดงบน Notification ที่ถูกส่งจากระบบได้แก่ Push notification และ In-app notification</p>
          {level === "2" && (<p>* ให้ระบุจำนวนกิจกรรมแบบ Active activity (เดิน วิ่ง ปั่น ว่าย). โดยเมื่อ ยูสเซอร์ ที่อยู่ Badge 1 ทำกิจกรรมถึงจำนวนที่กำหนดไว้ ยูสเซอร์จะได้รับการ Upgrade ให้เป็น Badge 2</p>)}
        </div>
        <SaveSection onClick={this.onSave} />
      </BadgeTab>
    );
  }
}

const css = {
  footer: {
    padding: '15px',
    color: 'rgba(0, 0, 0, 0.65);',
   }
};

export default inject('badge')(observer(Badge));
