import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Col, Divider, Button } from 'antd';

import { isEqual } from 'lodash';
import { Page, Loading, SaveSection } from '../../components/common';

import HeaderMenu from '../../components/main/HeaderMenu';
import { InputInt, Input } from '../../components/forms';
// import BadgeTab from './BadgeTab';
import { notify } from '../../utils/notify';

import styled from 'styled-components';

const CustomSaveSection = styled(SaveSection)`
  position: relative;
`

const CustomCol = styled(Col)`
  position: relative;
  min-width: 450px;
`

const NAME = 'Tiers';

export class Badge extends Component {
  state = {
    loading: false,
    validate: {},
  };

  componentDidMount() {
    this.props.tier.getTierConfig();
  }

  onChange = (value, key, id) => {
    this.doc[id][key] = value;
    this.props.tier.setTier(this.doc);
    console.log(value, key, id);
  };

  validate = () => {
    try {
      const validateSubmit = {};
      const { doc, key, amountKey } = this;
      if (doc[key] === null || doc[key] === '') {
        validateSubmit[key] = true;
      }

      if (doc[amountKey] !== undefined && doc[amountKey] === null) {
        validateSubmit[amountKey] = true;
      }

      this.setState({ validateSubmit });
      if (isEqual({}, validateSubmit)) return true;
    } catch (e) {}

    return false;
  };

  onSave = async () => {
    if (this.validate() === false) return;

    try {
      this.setState({ loading: true });
      await this.props.tier.saveTierConfig(this.doc);
      notify.success({ title: `Save ${NAME} Complete` });
    } catch (e) {
      notify.error({ title: `Save ${NAME} Fail`, message: e.message });
    }
    await this.props.tier.getTierConfig();
    this.setState({ loading: false });
  };

  onDelete = (index) => {
    this.doc.splice(index, 1);
  }
  onAdd = () => {
    this.doc = [...this.doc, {tier: '', level: ''}];
    this.props.tier.setTier(this.doc);
  }

  render() {
    const span = 4;
    const { loading, validate } = this.state;
    // const { level, tier } = this.props.match.params;
    const tabIndex = 0;
    this.doc = this.props.tier.tierConfig;
    console.log('this.doc ', this.doc );
    // this.key = `badgeLv${level}`;
    // this.amountKey = `badgeLv${level}Amount`;

    return (
      <HeaderMenu title={"TIER"}>
        <Page style={pageCss}>
          <Loading loading={loading} />
          <Form colon={false}>
            <Row>
              {
                this.doc.map((tier, index) => 
                  <Row>
                    <Row>
                      <CustomCol span={span}>
                        <Form.Item label="Tier">
                          <Input name="tier" value={tier.tier} allowClear={false} onChange={(value, key) => this.onChange(value, key, index)}/>
                        </Form.Item>
                      </CustomCol>
                      <Col span={1}></Col>
                      <CustomCol span={span}>
                        <Form.Item label="Level">
                          <Input name="level" value={tier.level} allowClear={false} onChange={(value, key) => this.onChange(value, key, index)}/>
                        </Form.Item>
                      </CustomCol>
                    </Row>
                    <CustomCol span={span}>
                      <Form.Item label="DescriptionTh">
                        <Input name="descriptionTh" value={tier.descriptionTh || ''} allowClear={false} onChange={(value, key) => this.onChange(value, key, index)}/>
                      </Form.Item>
                    </CustomCol>
                    <Col span={1}></Col>
                    <CustomCol span={span}>
                      <Form.Item label="DescriptionEn">
                        <Input name="descriptionEn" value={tier.descriptionEn || ''} allowClear={false} onChange={(value, key) => this.onChange(value, key, index)}/>
                      </Form.Item>
                    </CustomCol>
                    <Col span={1}></Col>
                    <Button style={{marginTop: 29, backgroundColor: "#E77822", color: "white"}} onClick={() => this.onDelete(index)} >ลบ</Button>
                  </Row>
                )
              }
              <Button style={{margin: '0 0 15px 0', backgroundColor: "#E77822", color: "white"}} onClick={() => this.onAdd()} >เพิ่ม</Button>
            </Row>
          </Form>
          <div style={css.footer}>
            <p>* Level 0 คือให้ max คิดให้ว่าเป็น level 1, 2</p>
            <p>* DescriptionEn และ DescriptionTh จะแสดงให้ลูกค้าเห็นเมื่อลูกค้า verify ไม่สำเร็จ (Tier 000, 100, 200, 300, 400) กรณี Error (3004)</p>
          </div>
          <CustomSaveSection  onClick={this.onSave} />
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  footer: {
    padding: '15px',
    color: 'rgba(0, 0, 0, 0.65);',
   }
};

const pageCss = {
  backgroundColor: 'white',
  borderRadius: '5px',
};

export default inject('tier')(observer(Badge));
