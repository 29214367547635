import React from 'react';
import moment from 'moment';
import { Form, Select, Button, Input, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/th_TH';
import { PictureWall, AppPage, AppPageParam } from '../../components/forms';
import PersonalTagCheckbox from '../../components/forms/PersonalTagCheckbox';

const { Option } = Select;
const { RangePicker } = DatePicker;

class BannerForm extends React.Component {
  personalizeTags = [];

  getPageName(pageName) {
    const [name, type = null] = pageName.split('_');
    return {
      name,
      type,
    };
  }

  onChangePersonalTag = (selected) => {
    this.personalizeTags = selected;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const now = moment();
        const endAt = values.dateRanges[1];
        const isExpired = now.diff(endAt) >= 0;
        const isStatusNotActive = values.status !== 'active';
        const isUpdateSequenceZero = isExpired || isStatusNotActive;
        if (isUpdateSequenceZero) {
          values.sequence = 0;
        }
        values.pTags = this.personalizeTags.toString();
        this.props.onSave(values);
      }
    });
  };

  handleStatusChange = (e) => {
    this.props.setStatus(e);
  };

  handlePageParam = (pageParam) => {
    const pageName = this.props.form.getFieldValue('pageName');
    const { name } = this.getPageName(pageName);
    this.props.form.setFieldsValue({
      uri: `${name}/${pageParam}`,
    });
  };

  handlePageName = (pageName) => {
    const { name } = this.getPageName(pageName);

    this.props.form.setFieldsValue({
      uri: name,
      pageParam: undefined,
    });
  };

  handleUriType = (uriType) => {
    if (uriType !== 'inapp') {
      this.props.form.setFieldsValue({
        pageName: undefined,
        pageParam: undefined,
        uri: null,
      });
    }
  };

  isInApp = () => {
    return this.props.form.getFieldValue('uriType') === 'inapp';
  };

  isInAppRewardPage = () => {
    return this.isInApp && this.props.form.getFieldValue('pageName') === 'reward';
  };

  handlePictureChanged = (value) => {
    const { paths } = value;
    this.props.form.setFieldsValue({
      imagePath: paths,
    });
    // this.setState({ previewImage: url });
  };

  renderPageParam = () => {
    const { getFieldDecorator } = this.props.form;
    const banner = this.props.banner || { pTags: null };
    const { isRequired } = this.props;
    const { pageName } = this.props.form.getFieldsValue();

    if (!pageName) return;
    const { name, type } = this.getPageName(pageName);
    const isValidPageParam = name === 'reward' || (name === 'challenges' && type === 'item');
    if (!isValidPageParam) return;
    const pageParamFormItem = (
      <Form.Item label="PageParam">
        {getFieldDecorator('pageParam', {
          initialValue: banner.pageParam || undefined,
          preserve: true,
          rules: [
            {
              required: isRequired,
            },
          ],
        })(<AppPageParam onChange={this.handlePageParam} type={name} />)}
      </Form.Item>
    );

    return pageParamFormItem;
  };

  render() {
    const banner = this.props.banner || { pTags: null };
    const { isRequired } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const pageNameFormItem = (
      <Form.Item label="PageName">
        {getFieldDecorator('pageName', {
          initialValue: banner.pageName || undefined,
          preserve: true,
          rules: [
            {
              required: isRequired && this.props.form.getFieldValue('uriType') === 'inapp',
            },
          ],
        })(<AppPage onChange={this.handlePageName} type={this.props.form.getFieldValue('uriType')} />)}
      </Form.Item>
    );

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Name" hasFeedback>
          {getFieldDecorator('name', {
            initialValue: banner.name,
            rules: [
              {
                required: true,
                message: 'Please input name',
              },
            ],
          })(<Input placeholder="Please input name" />)}
        </Form.Item>
        <Form.Item label="BannerType" hasFeedback>
          {getFieldDecorator('bannerType', {
            initialValue: banner.bannerType,
            rules: [{ required: true, message: 'Please select your banner type!' }],
          })(
            <Select placeholder="Please select a banner type">
              <Option value="home">home</Option>
              <Option value="reward">reward</Option>
              <Option value="challenge">challenge</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('status', {
            initialValue: banner.status || 'draft',
          })(
            <Select onChange={this.handleStatusChange} placeholder="Please select a status">
              <Option value="draft">draft</Option>
              <Option value="inactive">in active</Option>
              <Option value="active">active</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="UriType">
          {getFieldDecorator('uriType', {
            initialValue: banner.uriType || 'webview',
          })(
            <Select onChange={this.handleUriType} placeholder="Please select a status">
              <Option value="webview">webview</Option>
              <Option value="inapp">inapp</Option>
            </Select>
          )}
        </Form.Item>

        {this.isInApp() && pageNameFormItem}

        {this.renderPageParam()}

        <Form.Item label="Uri" hasFeedback>
          {getFieldDecorator('uri', {
            initialValue: banner.uri,
            preserve: true,
            rules: [{ required: isRequired }],
          })(<Input placeholder="Please input uri" />)}
        </Form.Item>

        <Form.Item label="startAt - endAt">
          {getFieldDecorator('dateRanges', {
            initialValue: banner.dateRanges || [],
            rules: [
              {
                required: isRequired,
                message: 'Please input start at - end at',
              },
            ],
          })(<RangePicker locale={locale} placeholder={['Start At', 'End At']} showTime />)}
        </Form.Item>

        <Form.Item label="Personalize Tags">
          <div style={{ position: 'relative', top: -5 }}>
            <PersonalTagCheckbox tags={banner.pTags} onChangePersonalTag={this.onChangePersonalTag} />
          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          {getFieldDecorator('imagePath', {
            initialValue: banner.imagePath,
            rules: [
              {
                required: isRequired,
                message: 'upload image is required',
              },
            ],
          })(<PictureWall name="banner" url={banner.imageUrl} />)}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrapBannerForm = Form.create({ name: 'banner_form' })(BannerForm);

export default WrapBannerForm;
