import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { Table } from 'antd';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Loading, DateCol, DisplayStatus } from '../../components/common';
import ChallengeUserFilterForm from './ChallengeUserFilterForm';
import ImportExportExcel from '../../components/common/ImportExportExcel';
import { format } from '../../utils/format';

const { Column } = Table;
const HEADER_IMPORT = [
  'shippingAddressId',
  'challengeItemId',
  'firstName',
  'lastName',
  'phoneNo',
  'address',
  'city',
  'zipCode',
  'name',
  'status',
  'carrier',
  'trackingNo',
];

const HEADER_EXPORT = [
  'shippingAddressId',
  'challengeItemId',
  'firstName',
  'lastName',
  'phoneNo',
  'address',
  'city',
  'zipCode',
  'name',
  'status',
  'carrier',
  'trackingNo',
  'createdAt',
  'updatedAt',
  'cancelledAt',
  'completedAt',
  'collectedAt',
];

class ChallengeUserList extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.challenge.getUserList(id);
  }

  handleExportExcel = async () => {
    const { id } = this.props.match.params;
    const items = await this.props.challenge.exportUserListToExcel(id);
    const defaultEmpty = '';
    const output = items.map((item) => [
      item.shippingAddressId,
      item.challengeItemId,
      item.firstName,
      item.lastName,
      item.phoneNo,
      item.address,
      item.city,
      item.zipCode,
      item.name,
      item.status,
      item.carrier,
      item.trackingNo,
      format.toFullDT(item.createdAt, defaultEmpty),
      format.toFullDT(item.updatedAt, defaultEmpty),
      format.toFullDT(item.cancelledAt, defaultEmpty),
      format.toFullDT(item.completedAt, defaultEmpty),
      format.toFullDT(item.collectedAt, defaultEmpty),
    ]);
    const datetime = moment().format('YYYYMMDD_hhmmss');
    const fileName = `ChallengeID_${id}_user_report_${datetime}`;
    return { output, fileName, sheetName: 'Sheet1' };
  };

  handleImportExcel = async (jsonArr) => {
    const { id } = this.props.match.params;
    const filtered = jsonArr
      .filter((d) => d.shippingAddressId)
      .map(({ shippingAddressId, carrier, trackingNo }) => ({
        shippingAddressId,
        carrier,
        trackingNo,
      }));
    await this.props.challenge.updateShippingAddress(id, filtered);
    this.props.challenge.reloadUserList(id);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { id } = this.props.match.params;
    const page = pagination.current;
    this.props.challenge.getUserList(id, { page });
  };

  handleOnSearch = (values) => {
    const { id } = this.props.match.params;
    const page = 1;
    const opts = { page, ...values };
    this.props.challenge.searchUserList(id, opts);
  };

  hanldeOnReset = () => {
    const { id } = this.props.match.params;
    this.props.challenge.reloadUserList(id);
  };

  render() {
    const { userList, loading, pagination } = this.props.challenge.toJS();

    return (
      <HeaderMenu title="CHALLENGE USER LIST">
        <Loading loading={loading} />
        <ImportExportExcel
          headerImport={HEADER_IMPORT}
          headerExport={HEADER_EXPORT}
          handleExportExcel={this.handleExportExcel}
          handleImportExcel={this.handleImportExcel}
        />
        <ChallengeUserFilterForm onSearch={this.handleOnSearch} onReset={this.hanldeOnReset} />
        <Table
          bordered
          style={css.table}
          rowKey={(record, index) => index}
          dataSource={userList}
          onChange={this.handleTableChange}
          pagination={{
            ...pagination,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{ x: 'max-content' }}
        >
          <Column title="FIRST NAME" dataIndex="firstName" key="firstName" />
          <Column title="LAST NAME" dataIndex="lastName" key="lastName" />
          <Column title="MOBILE PHONE" dataIndex="phoneNo" key="phoneNo" />
          <Column title="ADDRESS" dataIndex="address" key="address" />
          <Column title="CITY" dataIndex="city" key="city" />
          <Column title="ZIPCODE" dataIndex="zipCode" key="zipCode" />
          <Column title="REWARD NAME" dataIndex="name" key="name" />
          <Column
            title={`CHALLENGE\nPROGRESS\nSTATUS`}
            dataIndex="status"
            key="status"
            width={120}
            render={(status) => <DisplayStatus type={3} status={status} />}
          />
          <Column title="CARRIER" dataIndex="carrier" key="carrier" />
          <Column title="TRACKING NO" dataIndex="trackingNo" key="trackingNo" />
          <Column
            title="CREATED DATE"
            dataIndex="createdAt"
            key="createdAt"
            width={120}
            render={(text) => <DateCol value={text} />}
          />
          <Column
            title="LAST UPDATED DATE"
            dataIndex="updatedAt"
            key="updatedAt"
            width={120}
            render={(text) => <DateCol value={text} />}
          />
          <Column
            title="CANCELLED DATE"
            dataIndex="cancelledAt"
            key="cancelledAt"
            width={120}
            render={(text) => <DateCol value={text} />}
          />
          <Column
            title="COMPLETED DATE"
            dataIndex="completedAt"
            key="completedAt"
            width={120}
            render={(text) => <DateCol value={text} />}
          />
          <Column
            title="COLLECTED DATE"
            dataIndex="collectedAt"
            key="collectedAt"
            width={120}
            render={(text) => <DateCol value={text} />}
          />
        </Table>
      </HeaderMenu>
    );
  }
}

const css = {
  table: {
    backgroundColor: 'white',
    borderRadius: 5,
    marginTop: 10,
  },
};

export default inject('challenge')(observer(ChallengeUserList));
