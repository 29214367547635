import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

export class Content extends BaseStore {
  constructor() {
    super();
    this.observable({
      categories: [],
    });
  }

  async getContentCategory() {
    const url = `${config.api.max}/v1/office/content/categories`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
        error.launch({ message: res.body.message });
        return;
    }

    this.categories = res.body;
  }

  async saveContentCategory(data) {
    const url = `${config.api.max}/v1/office/content/categories`;
    const res = await http.put(url, { json: data, token: true });

    if (res.statusCode !== 200) {
        error.launch({ message: res.body.message });
    }
  }

  setContentCategory(val) {
    this.categories = val;
  }
}

export default new Content();
