import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

const META_TYPES = [
  {
    id: 'steps',
    keys: ['pWeightSteps', 'pTitleStepsEn', 'pTitleStepsTh'],
    display: 'Steps',
  },
  {
    id: 'running',
    keys: ['pWeightRunning', 'pTitleRunningEn', 'pTitleRunningTh'],
    display: 'Running',
  },
  {
    id: 'swimming',
    keys: ['pWeightSwimming', 'pTitleSwimmingEn', 'pTitleSwimmingTh'],
    display: 'Swimming',
  },
  {
    id: 'cycling',
    keys: ['pWeightCycling', 'pTitleCyclingEn', 'pTitleCyclingTh'],
    display: 'Cycling',
  },
  {
    id: 'dining',
    keys: ['pWeightDining', 'pTitleDiningEn', 'pTitleDiningTh'],
    display: 'Dining',
  },
  {
    id: 'traveling',
    keys: ['pWeightTraveling', 'pTitleTravelingEn', 'pTitleTravelingTh'],
    display: 'Traveling',
  },
];

const INIT_STATE = {
  general: {
    bannerSlideTimeMS: null,
    earnPointBirthdayEffectiveMonth: null,
    pollAndSurvey: null,
    // community report
    maxReportNudity: null,
    maxReportSpam: null,
    maxReportViolence: null,
    maxReportOther: null,
    // content personalize
    pNexgenSteps: null,
    pNexgenRunning: null,
    pNexgenSwimming: null,
    pNexgenCycling: null,
    pNexgenTraveling: null,
    pNexgenDining: null,
    // birthday point
    bdayPointLevel3: null,
    bdayPointLevel4: null,
    bdayPointLevel5: null,
  },
  personaliseConfigs: [],

  loading: false,
};

class AppConfigStore extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  _asPweightsJS(data) {
    const keys = [
      'pWeightSteps',
      'pWeightRunning',
      'pWeightSwimming',
      'pWeightCycling',
      'pWeightTraveling',
      'pWeightDining',
    ];
    return keys.reduce((acc, key) => {
      if (data[key]) {
        const value = data[key];
        return [...acc, { key, value }];
      }
      return acc;
    }, []);
  }

  _asPersonaliseConfigs(appConfigs) {
    return META_TYPES.reduce((acc, meta) => {
      const { id, keys, display } = meta;
      const [weightKey, titleEnKey, titleThKey] = keys;
      const weight = appConfigs[weightKey];
      const titleEn = appConfigs[titleEnKey];
      const titleTh = appConfigs[titleThKey];
      const newMeta = {
        id,
        display,
        weight,
        titleEn,
        titleTh,
      };
      return [...acc, newMeta];
    }, []);
  }

  async getList() {
    this.loading = true;
    const url = `${config.api.max}/v1/office/configs`;
    const res = await http.get(url, { token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    this.general = res.body;
  }

  async getPersonaliseConfigs() {
    this.loading = true;
    const url = `${config.api.max}/v1/office/configs`;
    const res = await http.get(url, { token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    this.personaliseConfigs = this._asPersonaliseConfigs(res.body);
  }

  async update(key, value) {
    this.loading = true;
    const json = { value };
    const url = `${config.api.max}/v1/office/configs/${key}`;
    const res = await http.put(url, { token: true, json });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async updateList(list) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/configs/updateList`;
    const res = await http.put(url, { token: true, json: { value: list } });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async updatePersonaliseConfig(values) {
    this.loading = true;
    const meta = META_TYPES.find((data) => data.id === values.id);
    const { titleEn, titleTh, weight } = values;
    const [weightKey, titleEnKey, titleThKey] = meta.keys;
    const saveConfigs = [
      {
        key: weightKey,
        value: weight,
      },
      {
        key: titleEnKey,
        value: titleEn,
      },
      {
        key: titleThKey,
        value: titleTh,
      },
    ];
    const url = `${config.api.max}/v1/office/configs/updateList`;
    const res = await http.put(url, { token: true, json: { value: saveConfigs } });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }
}

const appConfigStore = new AppConfigStore();

export default appConfigStore;
