import React from 'react';
import { Select } from 'antd';
import { Cols } from '../../components/common';
import { GroupItem } from '../../components/forms';

const { Option } = Select;

class BoarderFilterMoreItem extends React.Component {
  constructor() {
    super();
    this.state = {
      status: 'all',
      category: 'all',
    };
  }

  onFilterStatus = (status) => {
    this.setState({ status });
  };

  onFilterCategory = (category) => {
    this.setState({ category });
  };

  get = () => {
    return {
      status: this.state.status,
      category: this.state.category,
    };
  };

  render() {
    return (
      <>
        <Cols>
          <GroupItem label="Status">
            <Select
              placeholder="Select adjust status"
              style={css.status}
              onChange={this.onFilterStatus}
              value={this.state.status}
            >
              <Option value="all">All</Option>
              <Option value="pending">Waiting For Approve</Option>
              <Option value="approved">Approved Ban</Option>
              <Option value="cancelled">Cancelled Ban</Option>
            </Select>
          </GroupItem>
        </Cols>
        <Cols>
          <GroupItem label="Report Category">
            <Select
              placeholder="Select adjust status"
              style={css.category}
              onChange={this.onFilterCategory}
              value={this.state.category}
            >
              <Option value="all">All</Option>
              <Option value="nudity">Nudity</Option>
              <Option value="spam">Spam</Option>
              <Option value="violence">Violence</Option>
              <Option value="other">Other</Option>
            </Select>
          </GroupItem>
        </Cols>
      </>
    );
  }
}

const css = {
  status: {
    width: 168,
  },
  category: {
    width: 128,
  },
};

export default BoarderFilterMoreItem;
