import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

// Staff Management
import AddNewStaff from './pages/staff/add';
import EditStaff from './pages/staff/edit';
import ViewStaff from './pages/staff/view';
import Badge from './pages/badge';
import Tier from './pages/tier';
import AccessDenied from './pages/auth/AccessDenied';
import PrivateRoute from './components/routes/PrivateRoute';
import { can } from './utils/acl';
import {
  PERM_ACCESS_DASHBOARD_REGISTERED,
  PERM_ACCESS_DASHBOARD_ACTIVITY,
  PERM_ACCESS_DASHBOARD_DOWNLOAD,
  PERM_ACCESS_DASHBOARD_REWARD,
  PERM_ACCESS_DASHBOARD_CHALLENGE,
  PERM_ACCESS_DASHBOARD_FRIEND_GET_FRIEND,
  PERM_ACCESS_STAFF_LIST,
  PERM_ACCESS_STAFF_OWN_SETTINGS,
  PERM_STAFF_ADD_USER,
  PERM_STAFF_EDIT_USER,
  PERM_STAFF_VIEW_USER,
  PERM_ACCESS_DASHBOARD_USERLIST,
  PERM_ACCESS_USER_PERSONAL,
  PERM_ACCESS_USER_REWARD,
  PERM_ACCESS_USER_COUPON,
  PERM_ACCESS_USER_CHALLENGE,
  PERM_ACCESS_USER_POINT,
  PERM_ACCESS_USER_ACTIVITY,
  PERM_ACCESS_USER_POLL_AND_SURVEY,
  PERM_ACCESS_USER_AGENT,
  PERM_ACCESS_USER_BADGE_COUNTER,
  PERM_ACCESS_REWARD_APPLICATION,
  PERM_ACCESS_REWARD_CREATE,
  PERM_ACCESS_REWARD_EDIT,
  PERM_ACCESS_REWARD_USERLIST,
  PERM_ACCESS_COUPON_LIST,
  PERM_ACCESS_COUPON_CREATE,
  PERM_ACCESS_COUPON_EDIT,
  PERM_ACCESS_POINT_GLOBAL,
  PERM_ACCESS_POINT_PASSIVE_ACTIVITY,
  PERM_ACCESS_POINT_PAID_POLICY,
  PERM_ACCESS_POINT_ADJUST,
  PERM_ACCESS_CONFIG,
  PERM_ACCESS_BANNER,
  PERM_JOBS_TRIGGER,
  PERM_ACCESS_CHALLENGE,
  PERM_ACCESS_BADGE,
  PERM_ACCESS_COMMUNINY,
  PERM_ACCESS_PERSONAL_CONTENT_CONFIG,
  PERM_ACCESS_MY_DOWNLOAD,
} from './config/permissions';

import Login from './pages/login';
import Password from './pages/reset/password';
import Suggest from './pages/suggest';

import MainLayout from './components/main';

import { SurveyTemplate, SurveyTemplateEditForm, SurveyTemplateAddForm } from './pages/config/survey-template';
import { Community } from './pages/community';
import { CommunityConfig } from './pages/config/community';
import { PersonaliseConfigList } from './pages/config/personalization';
import MyDownload from './pages/download/MyDownload';

import Banner from './pages/banner/BannerList';
import BannerDetail from './pages/banner/BannerDetail';
import JobsTrigger from './pages/jobs/trigger';
import ChallengeDetail from './pages/challenge/ChallengeDetail';
import ChallengeList from './pages/challenge/ChallengeList';
import ChallengeUserList from './pages/challenge/ChallengeUserList';
import StaffList from './pages/staff/list';
import StaffSettingsPage from './pages/staff/settings';
import UserLandingPage from './pages/dashboard/UserLandingPage';
import ChallengeDashboardWithGraph from './pages/dashboard/challenge/ChallengeDashboardWithGraph';
import PollAndSurvey from './pages/poll-and-survey';
import PushNotificationTemplate from './pages/config/pushNotificationTemplate';
import PointExpireReminder from './pages/config/PointExpireReminder';
import SSOConfig from './pages/config/SSOConfig';

import PersonalContentConfig from './pages/personalContentConfig/PersonalContentConfig';
import ContentConfig from './pages/config/content';
import BirthdayEffectiveMonth from './pages/config/birthdayEffectiveMonth';

import CommingSoon from './pages/auth/CommingSoon';

import DashboardRegistered from './pages/dashboard/registered';
import DashboardPersonalise from './pages/dashboard/personalise';
import DashboardReward from './pages/dashboard/reward';
import DashboardFriendGetFriend from './pages/dashboard/friendGetFriend';
import DashboardActivity from './pages/dashboard/activity';

import UserList from './pages/user/list';
import UserPersonal from './pages/user/personal';
import UserReward from './pages/user/reward';
import UserCoupon from './pages/user/coupon';
import UserChallenge from './pages/user/challenge/UserChallenge';
import UserPoint from './pages/user/point';
import UserActivity from './pages/user/activity';
import UserAgent from './pages/user/agent/Agent';
import BadgeCounter from './pages/user/badgeCounter/BadgeCounter';

import RewardList from './pages/reward/list';
import RewardDetail from './pages/reward/detail';
import RewardApplication from './pages/reward/application';
import RewardUserList from './pages/reward/userList';
import CouponList from './pages/coupon/list';
import CouponDetail from './pages/coupon/detail';
import PointGlobal from './pages/point/global';
import PointActivities from './pages/point/activities';
import PointPaidPolicy from './pages/point/paidPolicy';
import PointAdjust from './pages/point/pointAdjust';
import ConfigConsentType from './pages/config/consent/ConsentType';
import ConfigTermType from './pages/config/consent/TermType';
import ConfigPointType from './pages/config/consent/PointType';
import ConfigRedeemType from './pages/config/consent/RedeemType';
import ConfigFaqType from './pages/config/consent/FaqType';
import ConfigUpgradeType from './pages/config/consent/UpgradeType';
import ConfigBanner from './pages/config/banner';
import NotificationBroadcast from './pages/notification/Broadcast';

const prefix = '/office';

const App = (props) => {
  const valid = props.member.isLogin();
  const userRole = props.member.getRole();

  if (valid) {
    return (
      <MainLayout>
        <Switch>
          <Route exact path={`${prefix}`} component={UserLandingPage} />
          <Route exact path={`${prefix}/access-denied`} component={AccessDenied} />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/registered`}
            component={DashboardRegistered}
            can={can(PERM_ACCESS_DASHBOARD_REGISTERED, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/activity/steps`}
            component={DashboardActivity}
            can={can(PERM_ACCESS_DASHBOARD_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/activity/running`}
            component={DashboardActivity}
            can={can(PERM_ACCESS_DASHBOARD_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/activity/cycling`}
            component={DashboardActivity}
            can={can(PERM_ACCESS_DASHBOARD_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/activity/swimming`}
            component={DashboardActivity}
            can={can(PERM_ACCESS_DASHBOARD_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/activity/dining`}
            component={DashboardActivity}
            can={can(PERM_ACCESS_DASHBOARD_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/activity/traveling`}
            component={DashboardActivity}
            can={can(PERM_ACCESS_DASHBOARD_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/personalise`}
            component={DashboardPersonalise}
            can={can(PERM_ACCESS_DASHBOARD_REGISTERED, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/dashboard/download`}
            component={CommingSoon}
            can={can(PERM_ACCESS_DASHBOARD_DOWNLOAD, userRole)}
          />
          <Route
            exact
            path={`${prefix}/dashboard/rewards`}
            component={DashboardReward}
            can={can(PERM_ACCESS_DASHBOARD_REWARD, userRole)}
          />
          <Route
            exact
            path={`${prefix}/dashboard/challenges`}
            component={ChallengeDashboardWithGraph}
            can={can(PERM_ACCESS_DASHBOARD_CHALLENGE, userRole)}
          />
          <Route
            exact
            path={`${prefix}/dashboard/friendGetFriend`}
            component={DashboardFriendGetFriend}
            can={can(PERM_ACCESS_DASHBOARD_FRIEND_GET_FRIEND, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/staff`}
            component={StaffList}
            can={can(PERM_ACCESS_STAFF_LIST, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/staff/settings`}
            component={StaffSettingsPage}
            can={can(PERM_ACCESS_STAFF_OWN_SETTINGS, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/staff/add-user`}
            component={AddNewStaff}
            can={can(PERM_STAFF_ADD_USER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/staff/edit/:id`}
            component={EditStaff}
            can={can(PERM_STAFF_EDIT_USER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/staff/view/:id`}
            component={ViewStaff}
            can={can(PERM_STAFF_VIEW_USER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user`}
            component={UserList}
            can={can(PERM_ACCESS_DASHBOARD_USERLIST, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/personal`}
            component={UserPersonal}
            can={can(PERM_ACCESS_USER_PERSONAL, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/reward`}
            component={UserReward}
            can={can(PERM_ACCESS_USER_REWARD, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/coupon`}
            component={UserCoupon}
            can={can(PERM_ACCESS_USER_COUPON, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/challenge`}
            component={UserChallenge}
            can={can(PERM_ACCESS_USER_CHALLENGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/point`}
            component={UserPoint}
            can={can(PERM_ACCESS_USER_POINT, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/activity`}
            component={UserActivity}
            can={can(PERM_ACCESS_USER_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/poll-and-survey`}
            component={PollAndSurvey}
            can={can(PERM_ACCESS_USER_POLL_AND_SURVEY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/:id/badge-counter`}
            component={BadgeCounter}
            can={can(PERM_ACCESS_USER_BADGE_COUNTER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/user/whitelist`}
            component={UserAgent}
            can={can(PERM_ACCESS_USER_AGENT, userRole)}
          />
          <PrivateRoute exact path={`${prefix}/reward`} can={can(PERM_ACCESS_DASHBOARD_REWARD, userRole)}>
            <RewardList userRole={userRole} />
          </PrivateRoute>
          <PrivateRoute exact path={`${prefix}/reward/application`} can={can(PERM_ACCESS_REWARD_APPLICATION, userRole)}>
            <RewardApplication userRole={userRole} />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={`${prefix}/reward/create`}
            component={RewardDetail}
            can={can(PERM_ACCESS_REWARD_CREATE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/reward/:id/edit`}
            component={RewardDetail}
            can={can(PERM_ACCESS_REWARD_EDIT, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/reward/:id/userList`}
            component={RewardUserList}
            can={can(PERM_ACCESS_REWARD_USERLIST, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/coupon`}
            component={CouponList}
            can={can(PERM_ACCESS_COUPON_LIST, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/coupon/create`}
            component={CouponDetail}
            can={can(PERM_ACCESS_COUPON_CREATE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/coupon/:id/edit`}
            component={CouponDetail}
            can={can(PERM_ACCESS_COUPON_EDIT, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/content`}
            component={PersonalContentConfig}
            can={can(PERM_ACCESS_PERSONAL_CONTENT_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/community`}
            component={Community}
            can={can(PERM_ACCESS_COMMUNINY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/point/global`}
            component={PointGlobal}
            can={can(PERM_ACCESS_POINT_GLOBAL, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/point/activities`}
            component={PointActivities}
            can={can(PERM_ACCESS_POINT_PASSIVE_ACTIVITY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/point/paid`}
            component={PointPaidPolicy}
            can={can(PERM_ACCESS_POINT_PAID_POLICY, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/point/pointAdjust`}
            component={PointAdjust}
            can={can(PERM_ACCESS_POINT_ADJUST, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/consent`}
            component={ConfigConsentType}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/term`}
            component={ConfigTermType}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/point`}
            component={ConfigPointType}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/redeem`}
            component={ConfigRedeemType}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/faq`}
            component={ConfigFaqType}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/upgrade`}
            component={ConfigUpgradeType}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/banner`}
            component={ConfigBanner}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/birthday-effective-month`}
            component={BirthdayEffectiveMonth}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/notification-template`}
            component={PushNotificationTemplate}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/point-expire-reminder`}
            component={PointExpireReminder}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/sso-config`}
            component={SSOConfig}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/survey-template/:formId/edit`}
            component={SurveyTemplateEditForm}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/survey-template`}
            component={SurveyTemplate}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/survey-schedule/add`}
            can={can(PERM_ACCESS_CONFIG, userRole)}
            component={SurveyTemplateAddForm}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/survey-schedule/:formId/edit`}
            component={SurveyTemplateEditForm}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/survey-schedule`}
            can={can(PERM_ACCESS_CONFIG, userRole)}
            component={SurveyTemplate}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/community`}
            can={can(PERM_ACCESS_CONFIG, userRole)}
            component={CommunityConfig}
          />
          <PrivateRoute
            exact
            path={`${prefix}/jobs/trigger`}
            component={JobsTrigger}
            can={can(PERM_JOBS_TRIGGER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/banners/all/:type`}
            component={Banner}
            can={can(PERM_ACCESS_BANNER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/banners/create`}
            component={BannerDetail}
            can={can(PERM_ACCESS_BANNER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/banners/:id/edit`}
            component={BannerDetail}
            can={can(PERM_ACCESS_BANNER, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/challenges`}
            component={ChallengeList}
            can={can(PERM_ACCESS_CHALLENGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/challenges/create`}
            component={ChallengeDetail}
            can={can(PERM_ACCESS_CHALLENGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/challenges/:id/edit`}
            component={ChallengeDetail}
            can={can(PERM_ACCESS_CHALLENGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/challenges/:id/users`}
            component={ChallengeUserList}
            can={can(PERM_ACCESS_CHALLENGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/badge/:level`}
            component={Badge}
            can={can(PERM_ACCESS_BADGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/tier/`}
            component={Tier}
            can={can(PERM_ACCESS_BADGE, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/personalise`}
            component={PersonaliseConfigList}
            can={can(PERM_ACCESS_CONFIG, userRole)}
          />
          <PrivateRoute
            exact
            path={`${prefix}/config/content`}
            can={can(PERM_ACCESS_CONFIG, userRole)}
            component={ContentConfig}
          />
          <PrivateRoute
            exact
            path={`${prefix}/download`}
            can={can(PERM_ACCESS_MY_DOWNLOAD, userRole)}
            component={MyDownload}
          />
          <PrivateRoute
            exact
            path={`${prefix}/notifications/broadcast`}
            can={can(PERM_ACCESS_CONFIG, userRole)}
            component={NotificationBroadcast}
          />
        </Switch>
      </MainLayout>
    );
  }

  return (
    <Switch>
      <Route path="/" component={Login} />
      <Route exact path="/password" component={Password} />
      <Route exact path="/suggest" component={Suggest} />
    </Switch>
  );
};

const ConnectApp = inject('member')(observer(App));
export default ConnectApp;
