import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Descriptions, Alert } from 'antd';
import styled from 'styled-components';
import { Loading } from '../../components/common';
import UserInfo from '../user/UserInfo';

const questionTitle = {
  birthDate: 'Birth Date',
  gender: 'Gender',
  martialStatus: 'Marital Status',
  education: 'Education',
  occupation: 'Occupation',
  income: 'Income',
  region: 'Region',
  province: 'Province',
};

const PollAndSurvey = (props) => {
  const user = props.user.toJS();
  const { loading } = user;
  const [surveyAnswers, setSurveyAnswers] = useState({ id: undefined });
  const [error, setError] = useState('');
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const { id } = props.match.params;
  useEffect(() => {
    props.user
      .getSurveyAnswers(id)
      .then(() => {
        const answers = props.user.surveyAnswers;
        setSurveyAnswers(answers);
      })
      .catch((e) => setError(e.message));
  }, [id, props.user]);

  useEffect(() => {
    const hasAnswers = props.user.surveyCompleted !== false;
    setSurveyCompleted(hasAnswers);
  }, [props.user.surveyCompleted]);

  return (
    <UserInfo title={`USERS-${user.personal.tmp_fname}`} name="POLL & SURVEY">
      <Loading loading={loading} />
      {error && <Alert type="error" message={error} />}
      {surveyCompleted && !loading && (
        <StyledDescriptions>
          <Descriptions title="Survey Answers">
            {loading && 'loading ...'}
            {!loading &&
              Object.keys(surveyAnswers).map((surveyQuestionKey) => (
                <Descriptions.Item key={surveyQuestionKey} label={questionTitle[surveyQuestionKey]}>
                  {surveyAnswers[surveyQuestionKey]}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </StyledDescriptions>
      )}
      {!surveyCompleted && !loading && (
        <Row>
          <StyledDescriptions>User has not completed survey yet.</StyledDescriptions>
        </Row>
      )}
    </UserInfo>
  );
};

const StyledDescriptions = styled(Row)`
  padding: 10px;
`;

export default inject('user')(observer(PollAndSurvey));
