import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';

import { history } from '../../utils/history';
import { notify } from '../../utils/notify';
import BannerForm from './BannerForm';

export class BannerDetail extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.banner.get(id);
    }
  }

  componentWillUnmount() {
    this.props.banner.reset();
  }

  onSave = async (values) => {
    const { detail } = this.props.banner.toJS();
    let promiseTask;
    if (!detail) {
      promiseTask = this.props.banner.create(values);
    } else {
      promiseTask = this.props.banner.update(detail.bannerId, values);
    }
    try {
      await promiseTask;
      notify.success({ title: 'Save banner success.' });
      history.push(`/office/banners/all/${values.bannerType}`);
    } catch (error) {
      notify.error({ title: 'Save banner failed.' });
    }
  };

  onChange = (item) => {
    this.setState({ seletedMenu: item.name });
  };

  renderContent() {
    const { detail } = this.props.banner.toJS();
    return (
      <BannerForm
        banner={detail}
        isRequired={this.props.banner.isRequired}
        onSave={this.onSave}
        setStatus={(status) => {
          this.props.banner.setStatus(status);
        }}
      />
    );
  }

  render() {
    const { detail } = this.props.banner.toJS();
    return (
      <HeaderMenu title={detail ? 'EDIT BANNER' : 'CREATE BANNER'}>
        <Loading loading={this.props.banner.loading} />
        <Page style={css.page}>{this.renderContent()}</Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('banner')(observer(BannerDetail));
