import React, { useEffect, useState } from 'react';
import { Table, message, Alert, Button, Modal, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Page, IntCol } from '../../../components/common';
import { SwitchButton } from '../../../components';
import { PointExpireReminderForm } from '../../../components/forms';
import HeaderMenu from '../../../components/main/HeaderMenu';
import PointExpireNotificationConfig from './pointExpireNotificationConfig'
import './pointExpireReminder.css';

const Header = ({ children }) => <div style={{ padding: 20 }}>
  <span style={{ fontSize: 20 }}>{children}</span>
</div>


const PushNotificationTemplateForm = (props) => {
  const { pointExpireReminder } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pointExpireSchedule, setPointExpireSchedule] = useState([]);
  const [notificationTemplates, setNotificationTemplates] = useState([]);
  const [displaySchedule, setDisplaySchedule] = useState([]);
  const [editMode, setEditMode] = useState({ id: -1, visible: false });
  const [updateError, setUpdateError] = useState('');

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (isPush) => <SwitchButton checked={Boolean(isPush)} />,
    },
    {
      title: '',
      dataIndex: 'row_action',
      key: 'row_action',
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setUpdateError('');
            setEditMode({
              id: record.id,
              visible: true,
            });
          }}
        >
          <Icon type="edit" theme="filled" /> Edit
        </Button>
      ),
    },
  ];

  const editModalBox = (mode, onSubmit, onCancel, onDelete) => {
    if (!mode.visible) {
      return null;
    }

    let record = pointExpireSchedule.find((r) => r.id === mode.id);

    if (!record && mode.id !== 'new') {
      return null;
    }

    if (mode.id === 'new') {
      record = {id: 'new', time: '9:00'}
    }

    return (
      <Modal visible title="Update Notification Template" footer={null} closable={false} destroyOnClose width={728}>
        {updateError && <Alert type="error" message={updateError} style={{ marginBottom: '15px' }} />}
        <PointExpireReminderForm
          notificationTemplate={record}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onDelete={onDelete}
          onUpdateProgress={loading}
        />
      </Modal>
    );
  };

  const displayDareSchedule = (date, type) => {
    if (type === 'constant')
      return `แจ้งเตือนทุกวันที่ ${date}`;
    if (type === 'relative')
      if (date > 0)
        return `แจ้งเตือนล่วงหน้า ${date} วัน`;
      else return `แจ้งเตือนวันสุดท้าย`;
  }

  useEffect(() => {
    setLoading(true);

    pointExpireReminder
      .fetchPointExpireReminders()
      .then(() => {
        const schedules = pointExpireReminder.list;
        console.log(schedules)

        const templates = schedules.map((n, index) => {
          return {
            id: index,
            date: displayDareSchedule(n.date, n.type),
            time: n.time,
            is_active: n.is_active,
          };
        });

        setDisplaySchedule(() => templates);
        setPointExpireSchedule(() => schedules.map((n, index) => {
          return {
            ...n,
            id: index,
          };
        }));
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [pointExpireReminder]);
  
  const onDeleteHandler = (id) => {
    setLoading(true);
    setUpdateError('');

    console.log('delete', id)

    const deleteIndex = pointExpireSchedule.findIndex(s => s.id === id)
    if (deleteIndex > -1) {
      pointExpireSchedule.splice(deleteIndex, 1);
    }

    const newSchdule = pointExpireSchedule;

    console.log('newSchdule', newSchdule, deleteIndex)

    pointExpireReminder
      .updatePointExpireReminders(newSchdule)
      .then(() => {
        if (pointExpireReminder.errors.length) {
          setUpdateError(pointExpireReminder.errors.join('<br/><br/>'));
        } else {
          message.success('Schedule updated successfully.', 2);

          setEditMode({
            visible: false,
          });

          const templates = newSchdule.map((n, index) => {
            return {
              id: index,
              date: displayDareSchedule(n.date, n.type),
              time: n.time,
              is_active: n.is_active,
            };
          });
  
          setDisplaySchedule(() => templates);
          setPointExpireSchedule(() => newSchdule.map((n, index) => {
            return {
              id: index,
              ...n,
            };
          }));
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitHandler = (data) => {
    setLoading(true);
    setUpdateError('');

    const newSchdule = pointExpireSchedule.map((s) => {
      if (s.id === data.id) return data;
      return s
    })

    if (data.id === 'new') {
      newSchdule.push({
        ...data,
        id: newSchdule.length
      })
    }

    console.log('save', data, newSchdule)

    pointExpireReminder
      .updatePointExpireReminders(newSchdule)
      .then(() => {
        if (pointExpireReminder.errors.length) {
          setUpdateError(pointExpireReminder.errors.join('<br/><br/>'));
        } else {
          message.success('Schedule updated successfully.', 2);

          setEditMode({
            visible: false,
          });

          const templates = newSchdule.map((n, index) => {
            return {
              id: index,
              date: displayDareSchedule(n.date, n.type),
              time: n.time,
              is_active: n.is_active,
            };
          });
  
          setDisplaySchedule(() => templates);
          setPointExpireSchedule(() => newSchdule.map((n, index) => {
            return {
              id: index,
              ...n,
            };
          }));
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCancelHandler = () => {
    setEditMode({ id: -1, visible: false });
  };

  return (
    <HeaderMenu title="POINT EXPIRE REMINDER">
      {error && <Alert type="error" message={error} />}
      <Header>Notification Template</Header>
      <PointExpireNotificationConfig />
      <Header>Notification Schedules</Header>
      <StyledPage>
        <Table
          loading={loading}
          bordered
          dataSource={displaySchedule}
          columns={columns}
          pagination={false}
          className="notification-templates"
        />
        <div style={{display: 'flex', width: '100%', 'justifyContent':'center', marginTop: '20px'}}>
          <Button
            type="primary"
            size="large"
            style={{margin: 'auto'}}
            onClick={() => {
              setUpdateError('');
              setEditMode({
                id: 'new',
                visible: true,
              });
            }}
          >
            <Icon type="plus"/> New
          </Button>
        </div>
      </StyledPage>
      {editModalBox(editMode, onSubmitHandler, onCancelHandler, onDeleteHandler)}
    </HeaderMenu>
  );
};

const StyledPage = styled(Page)`
  background-color: white;
  border-radius: 5px;
`;

const WrappedPushNotificationTemplate = PushNotificationTemplateForm;

export default inject('pointExpireReminder')(observer(WrappedPushNotificationTemplate));
