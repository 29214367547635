import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, Loading, Progress } from '../../../components/common';
import { TabMenu } from '../../../components/forms';
import { notify } from '../../../utils/notify';

import Information from './Information';
import Images from './Images';
import GenCode from './GenCode';

export class RewardDetail extends Component {
  state = {
    loading: false,
    processing: false,
  };

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    try {
      const { id } = this.props.match.params;
      this.setState({ loading: true });

      this.props.gen.resetConfig();
      await this.props.tag.getList();
      await this.props.whitelist.getWhitelistList();
      await this.props.tier.getTierConfig();
      if (id) {
        await this.props.reward.getDoc(id);
      } else {
        await this.props.reward.resetDoc();
      }
    } catch (e) {
      notify.error({ title: 'Load Reward Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onChange = (item) => {
    this.setState({ selected: item.name });
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      await this.props.reward.saveDoc();
      notify.success({ title: 'Save Reward Success' });
    } catch (e) {
      notify.error({ title: 'Save Reward Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onGenerate = async ({ type, data }) => {
    try {
      this.setState({ processing: true });
      const result = await this.props.reward.generateCode({ type, data });
      if (result) {
        notify.success({ title: 'Generate Reward Code Success' });
      }
      await this.props.reward.getRewardExample();
    } catch (e) {
      notify.error({ title: 'Generate Reward Code Fail', message: e.message });
    }

    this.setState({ processing: false });
  };

  onExport = async () => {
    try {
      this.setState({ processing: true });
      const blob = await this.props.reward.exportCode();
      if (blob !== undefined) {
        const reward = this.props.reward.toJS().detail;
        const middle = reward.name_en || '_';
        const name = `reward_${middle}_${moment().format('YYYYMMDD')}.csv`;
        FileSaver.saveAs(blob, name);
        notify.success({ title: 'Export Reward Code Success' });
      }
    } catch (e) {
      notify.error({ title: 'Export Reward Code Fail', message: e.message });
    }

    this.setState({ processing: false });
  };

  onCancel = async () => {
    this.props.reward.cancel();
  };

  renderContent = (selected, loading) => {
    switch (selected) {
      // case 'IMAGES':
      //   return <Images onSave={this.onSave} loading={loading} />;
      case 'CODE GENERATOR':
        return <GenCode onSave={this.onSave} onGenerate={this.onGenerate} onExport={this.onExport} loading={loading} />;
      case 'INFORMATION':
        return <Information onSave={this.onSave} loading={loading} />;
      default:
        return <Information onSave={this.onSave} loading={loading} />;
    }
  };

  render() {
    const { processing, loading, selected } = this.state;
    const reward = this.props.reward.toJS();
    const { detail: doc, progress } = reward;

    return (
      <HeaderMenu title={doc.reward_id ? 'EDIT REWARDS' : 'CREATE REWARDS'}>
        <Loading loading={loading} />
        <Progress display={processing} index={progress.index} total={progress.total} onCancel={this.onCancel} />
        <Page style={css.page}>
          <TabMenu menus={menus} onChange={this.onChange} />
          <br />
          {this.renderContent(selected, loading)}
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

const menus = [
  {
    name: 'INFORMATION',
    params: {},
  },
  // {
  //   name: 'IMAGES',
  //   params: {},
  // },
  {
    name: 'CODE GENERATOR',
    params: {},
  },
];

export default inject('gen', 'tag', 'reward', 'whitelist', 'tier')(observer(RewardDetail));
