import moment from 'moment';
import { cloneDeep } from 'lodash';

import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { helper } from '../utils/helper';
import controller from '../controller';

const LIMIT = config.limit.page;
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const origin = {
  reward_id: undefined,
  max_qty: 1,
  count_daily: 0,
  counter: 0,
  max_daily: 1,
  max_per_person: 1,
  max_per_daily_person: 1,
  desc_en: '',
  desc_th: '',
  digi_code: '',
  display_mins: 30,
  display_hours: 0,
  display_days: 0,
  image_detail: '',
  image_home: '',
  image_list: '',
  image_redeem: '',
  in_app_message: '',
  item_type: '',
  name_en: '',
  name_th: '',
  plan_code: '',
  price: 1,
  reward_cat: 'electronic',
  reward_type: 'multiple',
  sequence: 0,
  start_date: moment().startOf('day'),
  end_date: moment()
    .add(7, 'days')
    .startOf('day'),
  status: 'draft',
  term_en: '',
  term_th: '',
  user_level: 1,
  tags: [],
  p_tags: null,
  is_badge: false,
  is_agent: false,
  whitelist_value: null,
  badge_value: null,
  tier_value: null,
  is_plancode: false,
  plancode_value: null,
  code_value: null,
  text_button_th: '',
  text_button_en: '',
  max_reward_monthly: 1,
  max_reward_daily: 1,
  text_button_max_qty_th: '',
  text_button_max_qty_en: '',
  text_button_max_reward_monthly_th: '',
  text_button_max_reward_monthly_en: '',
  text_button_max_reward_daily_th: '',
  text_button_max_reward_daily_en: '',
  max_person_daily: '',
  text_button_max_per_person_th: '',
  text_button_max_per_person_en: '',
  text_button_max_per_monthly_person_th: '',
  text_button_max_per_monthly_person_en: '',
  text_button_max_per_daily_person_th: '',
  text_button_max_per_daily_person_en: '',
  show_code_config: '',
  generate_code_config: {},
};

const CSV = {
  header: ['Status', 'Max id', 'First Name', 'Last Name', 'Phone', 'Email', 'Badge', 'Redeemed Date', 'Code'],
  key: ['status', 'user_id', 'tmp_fname', 'tmp_lname', 'tmp_phone', 'tmp_email', 'level', 'updatedAt', 'digi_code'],
};

export class Reward extends BaseStore {
  constructor() {
    super();
    this.observable({
      all: {
        page: { offset: 1, total: 0, limit: LIMIT },
        list: [],
      },
      active: {
        waiting: [],
        display: [],
      },
      detail: cloneDeep(origin),
      original: cloneDeep(origin),
      uuid: '',
      progress: {
        status: '',
        total: 100,
        index: 0,
      },
      usersShippingAddress: {
        list: [],
        pagination: {},
        loading: false,
      },
      loading: false,
    });
  }

  async getList(params = {}) {
    const { offset = 1, name, start, end, status = 'all', time_type = 'all', limit = 10 } = params;
    let query = '';

    if (status !== 'all') query += `&status=${status}`;
    if (time_type !== 'all') query += `&timeType=${time_type}`;
    if (start) query += `&start=${start.format(DATE_FORMAT)}`;
    if (end) query += `&end=${end.format(DATE_FORMAT)}`;
    if (name) query += `&name=${name}`;

    const url = `${config.api.max}/v1/office/reward?offset=${offset - 1}&limit=${limit}${query}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list, total } = res.body;
    this.all = {
      page: { offset, total, limit },
      list,
    };
  }

  async getReadyList() {
    const url = `${config.api.max}/v1/office/reward?status=active,inactive&&order=sequence`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list = [] } = res.body;
    const index = list.findIndex((item) => {
      return item.sequence > 0;
    });
    let waiting = [];
    let display = [];
    if (index === -1) waiting = list;
    else if (index === 0) display = list;
    else {
      waiting = list.slice(0, index);
      display = list.slice(index, list.length);
    }

    this.active = {
      waiting,
      display,
    };
  }

  async resetDoc() {
    this.detail = cloneDeep(origin);
    this.original = cloneDeep(origin);
    this.uuid = await helper.random({ len: 8 });
  }

  setProgress(status = '', total = 100, index = 0) {
    this.progress = {
      status,
      total,
      index,
    };
  }

  async getDoc(id) {
    await this.resetDoc();

    const url = `${config.api.max}/v1/office/reward/${id}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.reward_id = id;

    const data = this.transformGetActiveTime(res.body);
    data.whitelist_value = data.whitelist_value.map((wl) => (wl.rwd_whitelist_rewards || {}).whitelist_id || '').join(',');
    this.detail = data;
    this.original = cloneDeep(data);
  }

  async createReward({ data, uploads }) {
    const url = `${config.api.max}/v1/office/reward`;
    const res = await http.post(url, { json: data, token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { id } = res.body;
    this.detail.reward_id = id;
    data.reward_id = id;
    await this.uploadRewardImg({ data, uploads });
  }

  transformSaveActiveTime(data) {
    return { 
      ...data,
      start_active_time: data.start_active_time ? data.start_active_time.format('HH:mm:ss') : undefined,
      end_active_time: data.end_active_time ? data.end_active_time.format('HH:mm:ss') : undefined,
    };
  }

  transformGetActiveTime(data) {
    return { 
      ...data,
      start_active_time: data.start_active_time ? moment(data.start_active_time, "HH:mm:ss") : undefined,
      end_active_time: data.end_active_time ? moment(data.end_active_time, "HH:mm:ss") : undefined,
    };
  }

  async saveDoc() {
    const { detail: doc } = this;
    const names = ['image_home', 'image_list', 'image_detail', 'image_redeem'];
    const uploads = [];
    names.forEach((name) => {
      const image = doc[name];
      const prefix = image.substring(0, 4);
      if (prefix === 'data') {
        uploads.push({ name, image });
        doc[name] = '';
      }
    });

    if (doc.reward_id) {
      await this.uploadRewardImg({ data: doc, uploads });
    } else {
      await this.createReward({ data: doc, uploads });
    }

    this.original = cloneDeep(this.toJS().detail);
  }

  async uploadRewardImg({ data, uploads = [] }) {
    const list = [];
    uploads.forEach((item) => {
      list.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          const res = await this.uploadImg({ data: item.image });
          resolve({ name: item.name, image: item.image, res });
        })
      );
    });

    let err = '';
    const resp = await Promise.all(list);
    resp.forEach((item) => {
      const { name, image, res } = item;
      if (res.err) {
        err += `${name} ${res.err} `;
        data[name] = image;
      } else {
        data[name] = helper.getUrl(res.url);
      }
    });

    this.detail = data;

    const url = `${config.api.max}/v1/office/reward/${data.reward_id}`;
    const res = await http.put(url, { json: this.transformSaveActiveTime(data), token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }

    if (err !== '') error.launch({ message: err });
  }

  async changeStatus({ id, status }) {
    const url = `${config.api.max}/v1/office/reward/${id}/${status}`;
    const res = await http.put(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  setDoc(val) {
    this.detail = val;
  }

  async uploadImg({ uuid, data }) {
    let url = `${config.api.max}/v1/office/utils/image/public/reward`;
    if (uuid) url += `?uuid=${uuid}`;

    const json = { file: data };
    const res = await http.post(url, { json, token: true });
    let paths = [];
    if (res.statusCode === 200) paths = res.body.paths;

    return paths.length > 0 ? { url: paths } : { err: 'upload image fail' };
  }

  moveItem({ source = {}, destination = {} }) {
    const doc = this.toJS().active;
    const { waiting } = doc;
    const { display } = doc;

    let item;
    switch (source.name) {
      case 'waiting':
        item = waiting[source.index];
        waiting.splice(source.index, 1);
        break;
      case 'display':
        item = display[source.index];
        display.splice(source.index, 1);
        break;
      default:
        return;
    }

    switch (destination.name) {
      case 'waiting':
        waiting.splice(destination.index, 0, item);
        break;
      case 'display':
        display.splice(destination.index, 0, item);
        break;
      default:
        return;
    }

    this.active = {
      waiting,
      display,
    };
  }

  async saveActive() {
    const doc = this.toJS().active;
    const waiting = doc.waiting.map((item) => {
      return item.reward_id;
    });
    const display = doc.display.map((item) => {
      return item.reward_id;
    });

    const url = `${config.api.max}/v1/office/reward/sequence`;
    const res = await http.post(url, { json: { waiting, display }, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async generateCode({ type, data }) {
    const { reward_id, max_qty } = this.detail;
    const url = `${config.api.max}/v1/office/reward/generate/${reward_id}/${type}`;
    const params = {
      url,
      total: max_qty,
      data,
    };

    let result;
    if (type === 'import') {
      result = await controller.code.import(params, { notify: this });
    } else {
      result = await controller.code.generate(params, { notify: this });
    }

    return result;
  }

  async exportCode() {
    const doc = this.toJS();
    const id = doc.detail.reward_id;
    const params = { type: 'reward', id };
    const result = await controller.code.export(params, { notify: this, CSV });
    return result;
  }

  cancel() {
    controller.code.cancel();
  }

  async rewardUserList(reward_id, page) {
    this.usersShippingAddress.loading = true;
    const url = `${config.api.max}/v1/office/reward/${reward_id}/shippingAddress`;
    const qs = { page, limit: 10 };
    const res = await http.get(url, { qs, token: true });
    this.usersShippingAddress.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    const { data = [], count = 0 } = res.body;
    this.usersShippingAddress.list = data;
    this.usersShippingAddress.pagination = {
      total: count,
      current: page,
    };
  }

  resetRewardUserList() {
    this.usersShippingAddress = {
      list: undefined,
      loading: false,
      pagination: {},
    };
  }

  async exportRewardUserList(reward_id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/reward/${reward_id}/shippingAddress`;
    const qs = { limit: 1000 };
    const items = [];
    const { total } = this.usersShippingAddress.pagination;
    for (let page = 1; items.length < total; page++) {
      qs.page = page;
      const resp = await http.get(url, { qs, token: true });
      if (resp.statusCode !== 200) {
        this.loading = false;
        error.launch({ message: resp.body.message });
        return null;
      }
      items.push(...resp.body.data);
    }

    this.loading = false;
    return items;
  }

  async importRewardUserList(items) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/reward/updateShippingDeliveryList`;
    const res = await http.put(url, {
      json: { items },
      token: true,
    });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async removeReward(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/reward/${id}`;
    const res = await http.delete(url, {
      token: true,
    });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async getRewardExample() {
    this.loading = true;
    const url = `${config.api.max}/v1/office/reward/${this.reward_id}/examplecode`;
    const res = await http.get(url, {
      token: true,
    });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
    this.exampleCode = (res.body.data || {}).digi_code;
  }
}

export default new Reward();
