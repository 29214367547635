import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

const UrlLink = ({ value }) => {
  const normalLink = <StyledLink>{value}</StyledLink>;
  const wrappedWithTooltip = (
    <Tooltip placement="top" title={value}>
      {normalLink}
    </Tooltip>
  );

  return wrappedWithTooltip;
};

const StyledLink = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default UrlLink;
