import React, { Component } from 'react';
import { Input as InputAntd } from 'antd';

export default class Input extends Component {
  onChange = (evt) => {
    const { name, onChange } = this.props;
    if (onChange) {
      onChange(evt.target.value, name);
    }
  };

  render() {
    const { placeHolder, readOnly = false, allowClear = true, disabled = false, value = '', maxLength = null } = this.props;
    const css = { width: '100%' };
    if (readOnly) {
      css.backgroundColor = '#DADAD9';
    }
    return (
      <InputAntd
        placeholder={placeHolder}
        readOnly={readOnly}
        disabled={disabled}
        style={css}
        value={value}
        onChange={this.onChange}
        allowClear={allowClear}
        maxLength={maxLength}
      />
    );
  }
}
