import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Row } from 'antd';

import { Loading, Cols } from '../../../components/common';
import { Input } from '../../../components/forms';
import { Btn } from '../../../components/button';
import { notify } from '../../../utils/notify';

export class Migration extends Component {
  state = {
    loading: false,
    data: {
      max1UserId: '',
      max2UserId: '',
    },
  };

  onRunMigration = async () => {
    const name = 'Migration';
    try {
      const { data } = this.state;
      this.setState({ loading: true });
      await this.props.trigger.runMigration(data);
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onChange = (val, name) => {
    const { data } = this.state;
    data[name] = val;
    this.setState({ data });
  };

  render() {
    const { loading, data } = this.state;

    const span = 4;
    const spanBtn = 3;
    return (
      <JobSection>
        <Loading loading={loading} />
        <Row>
          <Cols span={span}>
            <Form.Item label="Max 1">
              <Input name="max1UserId" value={data.max1UserId} onChange={this.onChange} />
            </Form.Item>
          </Cols>
          <Cols span={span}>
            <Form.Item label="Max 2">
              <Input name="max2UserId" value={data.max2UserId} onChange={this.onChange} />
            </Form.Item>
          </Cols>
          <Cols span={spanBtn}>
            <Form.Item label="Migration">
              <Btn text="Run" onClick={this.onRunMigration} />
            </Form.Item>
          </Cols>
        </Row>
      </JobSection>
    );
  }
}

const JobSection = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: white;
`;

export default inject('trigger')(observer(Migration));
