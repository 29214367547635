import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { notify } from '../../../utils/notify';
import AdminDashboard from '../admin';

// Time unit used in Search filter
import { TIME_UNIT_DAY, TIME_UNIT_HOUR, TIME_UNIT_MONTH } from '../constants';

const { getTimeType, stringToMoment, transformData } = require('../activity/chartUtils');

const SUMMARTY_TYPE_TOTAL = 'total';
const SUMMARTY_TYPE_IS_FWD_CUSTOMER = 'is_fwd';
const SUMMARTY_TYPE_IS_NON_FWD_CUSTOMER = 'is_nonfwd';
const SUMMARTY_TYPE_IS_BAN = 'is_ban';

const CUSTOMER = 'FWD Customers';
const NON_CUSTOMER = 'LEAD';
const BANNER_USERS = 'BANNED USERS';

const summaryTypeLookup = Object.freeze({
  [CUSTOMER]: SUMMARTY_TYPE_IS_FWD_CUSTOMER,
  [NON_CUSTOMER]: SUMMARTY_TYPE_IS_NON_FWD_CUSTOMER,
  [BANNER_USERS]: SUMMARTY_TYPE_IS_BAN,
});

const NUMBER_OF_REGISTRATIONS = 'NUMBER OF REGISTRATIONS';
const FWD_CUSTOMERS = 'FWD CUSTOMERS';
const LEAD = 'LEAD';
const BANNED_USERS = 'BANNED USERS';

function getTimeUnit(args) {
  const { start, end } = args;
  const diff = end.unix() - start.unix();
  if (diff < 0) {
    notify.error({ title: 'Search error', message: 'End date must be more than Start date' });
    return undefined;
  }

  const days = diff / 86400;

  if (days < 3) {
    return TIME_UNIT_HOUR;
  }

  if (days < 60) {
    return TIME_UNIT_DAY;
  }

  return TIME_UNIT_MONTH;
}

export class DashboardRegistered extends Component {
  state = {
    loading: false,
    type: 'Today',
    unit: TIME_UNIT_DAY,
    fetchingSummary: false,
    summaryData: [],
  };

  onSummaryCountClick = async (params = {}) => {
    const { name, start, end } = params;
    this.setState({
      fetchingSummary: true,
      summaryData: [],
    });

    const summaryType = summaryTypeLookup[name] || SUMMARTY_TYPE_TOTAL;

    this.props.dashboard
      .getSummaryModalData({
        type: TIME_UNIT_DAY,
        start,
        end,
        summarytype: summaryType,
      })
      .then(() => {
        const summaryData = this.props.dashboard.getSummaryData();

        this.setState({ summaryData });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.setState({ fetchingSummary: false });
      });
  };

  onFind = async (params = {}) => {
    try {
      const { type = 'Today' } = params;
      const timeUnit = getTimeType({ start: params.start, end: params.end });
      this.setState({ unit: timeUnit });
      this.setState({ loading: true, type });
      await this.props.dashboard.getRegistered(params);
    } catch (e) {
      notify.error({ title: 'Load Register Fail', message: e.message });
    }
    this.setState({ loading: false });
  };

  onType = ({ type }) => {
    this.setState({ type });
  };

  groupListRegistration = (list) => {
    const datasets = {};
    const info = { xLabel: 'Registration Date', yLabel: 'User Counts', numPointsY: 10 };
    const data = { datasets, info };
    let maxY = 0;

    datasets[NUMBER_OF_REGISTRATIONS] = {};
    datasets[FWD_CUSTOMERS] = {};
    datasets[LEAD] = {};
    datasets[BANNED_USERS] = {};

    list.forEach((element) => {
      const value = element.count;

      const time = stringToMoment(element.registered_at)
        .toDate()
        .getTime();
      let total;
      switch (element.event_type) {
        case 'register':
          maxY = Math.max(maxY, value);
          // eslint-disable-next-line no-case-declarations
          total = datasets[NUMBER_OF_REGISTRATIONS][time] || 0;
          total += value;
          datasets[NUMBER_OF_REGISTRATIONS][time] = total;
          maxY = Math.max(maxY, total);

          if (element.is_fwd === 1) {
            total = datasets[FWD_CUSTOMERS][time] || 0;
            total += value;
            datasets[FWD_CUSTOMERS][time] = total;
            maxY = Math.max(maxY, total);
          } else {
            total = datasets[LEAD][time] || 0;
            total += value;
            datasets[LEAD][time] = total;
            maxY = Math.max(maxY, total);
          }
          break;

        case 'inactive':
          total = datasets[BANNED_USERS][time] || 0;
          total += value;
          datasets[BANNED_USERS][time] = total;
          maxY = Math.max(maxY, total);
          break;

        default:
          break;
      }
    });
    info.maxY = maxY;
    return data;
  };

  render() {
    const { loading, type, unit } = this.state;
    const { registered } = this.props.dashboard.toJS();

    const { registration } = registered;
    const { data: sourceData } = registration;
    const { start, end, timeType, list } = sourceData;
    let graphRegister = {};
    if (!this.state.loading) {
      if (start && end && timeType) {
        graphRegister = transformData({ start, end, timeType }, this.groupListRegistration(list));
      }
    }

    return (
      <AdminDashboard
        type={type}
        timeUnit={unit}
        loading={loading}
        registered={registered}
        fetching={this.state.fetchingSummary}
        summaryData={this.state.summaryData}
        onFind={this.onFind}
        onSummaryCountClick={this.onSummaryCountClick}
        graphRegister={graphRegister}
      />
    );
  }
}

export default inject('dashboard', 'member')(observer(DashboardRegistered));
