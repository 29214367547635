import React, { Component } from 'react';
import { Row, Col } from 'antd';

import ButtonSection from './ButtonSection';

export class WrappedPhysicalCode extends Component {
  onGenerate = (evt) => {
    evt.preventDefault();
    const { onGenerate } = this.props;
    if (onGenerate) onGenerate({ type: 'single', data: {} });
  };

  onExport = () => {
    const { onExport } = this.props;
    if (onExport) onExport();
  };

  render() {
    const { exportor, generator } = this.props;

    return (
      <Row type="flex" justify="end">
        <Col span={12} offset={1}>
          <ButtonSection
            exportor={exportor}
            generator={generator}
            onExport={this.onExport}
            onGenerate={this.onGenerate}
          />
        </Col>
      </Row>
    );
  }
}

export default WrappedPhysicalCode;
