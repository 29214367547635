export const SUPER_ADMIN = 'super_admin';
export const ADMIN = 'admin';
export const PURCHASE = 'purchase';
export const CALL_CENTER = 'call_center';

const availableUserRoles = [SUPER_ADMIN, ADMIN, PURCHASE, CALL_CENTER];

export const userRoleMap = {
  [SUPER_ADMIN]: 'Super Admin',
  [ADMIN]: 'Admin',
  [PURCHASE]: 'Purchase',
  [CALL_CENTER]: 'Call Center',
};

export default availableUserRoles;
