import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Row, Col, Divider } from 'antd';

import { Loading, SaveSection } from '../../../components/common';

import { notify } from '../../../utils/notify';
import { PictureWall } from '../../../components/forms';
import HeaderMenu from '../../../components/main/HeaderMenu';

const NAME = 'Article management';

export class ContentConfig extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.onFind();
  }

  onFind = async () => {
    try {
      this.setState({ loading: true });
      await this.props.content.getContentCategory();
    } catch (e) {
      notify.error({ title: `Load ${NAME} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onChange = (data) => {
    this.props.content.setContentCategory(data);
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      await this.props.content.saveContentCategory(this.docs);
      notify.success({ title: `Save ${NAME} Complete` });
    } catch (e) {
      notify.error({ title: `Save ${NAME} Fail`, message: e.message });
    }
    await this.props.content.getContentCategory();
    this.setState({ loading: false });
  };

  handleImageChange = async (paths, url, oldUrl) => {
    for (let i = 0; i < this.docs.length; i++) {
      if (this.docs[i].imageUrl === oldUrl) {
        this.docs[i].imageUrl = url;
      }
    }
    this.onChange(this.docs);
  };

  render() {
    const span = 10;
    const { loading } = this.state;
    this.docs = this.props.content.toJS().categories;
    const categories = this.docs.map((item) => {
      return (
        <Col span={span} key={item.key}>
          <Form.Item label={item.name_en}>
            <PictureWall name="content" url={item.imageUrl} onChange={this.handleImageChange} height="92px" />
          </Form.Item>
        </Col>
      );
    });

    return (
      <HeaderMenu title={NAME}>
        <Loading loading={loading} />
        <Page>
          <Divider>Upload article categories image (507 x 192 pixels)</Divider>
          <br />
          <Form {...formLayout}>
            <Row>{categories}</Row>
          </Form>
        </Page>
        <br />
        <SaveSection onClick={this.onSave} />
      </HeaderMenu>
    );
  }
}

const Page = styled.div`
  padding: 20px 0px 20px 20px;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 30px;
  background-color: white;
`;

const formLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

export default inject('content')(observer(ContentConfig));
