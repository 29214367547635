import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { helper } from '../utils/helper';

export class Code {
  async generate({ url, total, data } = {}, { notify } = {}) {
    this.status = '';
    const limit = config.limit.generate;

    let loopAmt = 0;
    let remainder = 0;
    if (total > 0) {
      loopAmt = Math.ceil(total / limit);
      remainder = total % limit;
      if (remainder === 0) remainder = limit;
    }

    const progress = {
      status: 'progress',
      total: loopAmt,
      index: 0,
    };
    this.setProgress(notify, progress);
    data.amount = limit;

    for (let index = 0; index < loopAmt; index++) {
      data.clear = index === 0;
      if (index + 1 === loopAmt) {
        data.amount = remainder;
      }
      data.offset = index * limit;

      const res = await http.post(url, { json: data, token: true });

      if (this.status === 'cancel') {
        return false;
      }

      progress.index = index;
      this.setProgress(notify, progress);
      if (res.statusCode !== 200) {
        error.lunch({ message: res.body.message });
        return false;
      }
    }

    this.setProgress(notify, { status: 'success', total: 100, index: 100 });
    return true;
  }

  async import({ url, data = {} } = {}, { notify } = {}) {
    this.status = '';
    const { list = [] } = data;
    const limit = config.limit.generate;
    let loopAmt = 0;
    let remainder = 0;
    const total = data.list.length;
    if (total > 0) {
      loopAmt = Math.ceil(total / limit);
      remainder = total % limit;
      if (remainder === 0) remainder = limit;
    }

    const progress = {
      status: 'progress',
      total: loopAmt,
      index: 0,
    };
    this.setProgress(notify, progress);

    const json = data;
    delete json.list;
    for (let index = 0; index < loopAmt; index++) {
      const start = index * limit;
      let end = 0;
      json.clear = index === 0;
      if (index + 1 === loopAmt) {
        end = total;
      } else {
        end = start + limit;
      }

      json.list = list.slice(start, end);
      const res = await http.post(url, { json, token: true });

      if (this.status === 'cancel') {
        return false;
      }

      progress.index = index;
      this.setProgress(notify, progress);
      if (res.statusCode !== 200) {
        error.lunch({ message: res.body.message });
        return false;
      }
    }

    this.setProgress(notify, { status: 'success', total: 100, index: 100 });
    return true;
  }

  cancel() {
    this.status = 'cancel';
  }

  setProgress(notify, { status = '', total = 100, index = 0 } = {}) {
    if (notify && notify.setProgress) {
      notify.setProgress(status, total, index);
    }
  }

  async countItemCode({ type, id }) {
    const url = `${config.api.max}/v1/office/${type}/${id}/counter`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.lunch({ message: res.body.message });
      return {};
    }

    const doc = res.body;

    let assigned = 0;
    let available = 0;
    if (doc.assigned) assigned += doc.assigned;
    if (doc.available) available += doc.available;
    if (doc.inactive) available += doc.inactive;
    return {
      assigned,
      available,
      total: doc.total,
    };
  }

  async getItem(params) {
    const { id = '', type, index = 0, limit = 1000, userIdNull } = params;

    let query = `offset=${index}&&limit=${limit}`;
    if (userIdNull) query += `&&userIdNull=${userIdNull}`;
    const url = `${config.api.max}/v1/office/${type}/${id}/item?${query}`;

    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.lunch({ message: res.body.message });
    }

    return res.body || [];
  }

  async loopExportCode(params = {}, { CSV, notify, progress } = {}) {
    const { type, offset = 0, amount = 0, id, limit, userIdNull } = params;
    let itemList = [];
    let csvList = [];
    let rows = [];
    for (let index = 0; index < amount; index++) {
      const result = await Promise.all([
        this.getItem({ id, type, index, limit, userIdNull }),
        helper.toRowCSV({ list: itemList, key: CSV.key, subname: 'user' }),
      ]);

      itemList = result[0];

      // cast tmp_phone to string csv string effect reward item page and coupon item page
      if (itemList.length > 0) {
        itemList = itemList.map((item) => {
          if (item.user && item.user.tmp_phone) {
            item.user.tmp_phone = `"=""${item.user.tmp_phone}"""`;
          }
          return item;
        });
      }

      csvList = result[1];
      rows = rows.concat(csvList);

      progress.index = offset + index;
      this.setProgress(notify, progress);
      if (this.status === 'cancel') {
        return undefined;
      }
    }

    csvList = helper.toRowCSV({ list: itemList, key: CSV.key, subname: 'user' });
    rows = rows.concat(csvList);

    return rows;
  }

  async export({ id, type }, { notify, CSV }) {
    this.setProgress(notify, { status: 'processing' });
    this.status = '';
    const counter = await this.countItemCode({ id, type });
    const limit = config.limit.export;
    let assigned = 0;
    let available = 0;
    if (counter.assigned > 0) assigned = Math.ceil(counter.assigned / limit);
    if (counter.available > 0) available = Math.ceil(counter.available / limit);

    const progress = { type, status: 'processing', total: assigned + available, index: 0 };
    this.setProgress(notify, progress);

    let rows = [];
    const params = { type, amount: assigned, id, limit, userIdNull: 'no' };
    rows = await this.loopExportCode(params, { CSV, notify, progress });
    if (rows === undefined) return undefined;

    params.offset = assigned;
    params.amount = available;
    params.userIdNull = 'yes';
    const temp = await this.loopExportCode(params, { CSV, notify, progress });
    if (temp === undefined) return undefined;

    rows = rows.concat(temp);

    const csvFile = helper.toCSVByRow({ rows, header: CSV.header });
    this.setProgress(notify, { status: 'success', total: 100, index: 100 });
    return csvFile;
  }
}

export default new Code();
