import React from 'react';
import { Row, Icon, Col } from 'antd';
import styled from 'styled-components';
import BarGraph from '../BarGraph';
import { Loading } from '../../../components/common';
import HeaderMenu from '../../../components/main/HeaderMenu';
import Summary from './summary';

const PersonaliseGraph = ({ loading, doc, maxUserCount, summary }) => {
  return (
    <HeaderMenu title="PERSONALIZE">
      <StyledPage>
        <Loading loading={loading} />
        <br />
        <Row>
          {loading && (
            <Col span={22} style={{ textAlign: 'center' }}>
              Fetching data
              <Icon type="loading" />
            </Col>
          )}
          {!loading && <BarGraph title="NUMBER OF PERSONALIZE BY EACH TYPE" doc={doc} maxUserCount={maxUserCount} />}
        </Row>
        <br />
        <Row>
          <Col span={18}>
            <Summary title="SUMMARY OF PERSONALIZE" list={summary} />
          </Col>
        </Row>
      </StyledPage>
    </HeaderMenu>
  );
};

const StyledPage = styled.div`
  background-color: #fff;
  padding: 20px;
`;

export default PersonaliseGraph;
