export class Storages {
  remove(cname) {
    localStorage.removeItem(cname);
  }

  save(cname, cvalue) {
    const message = JSON.stringify(cvalue);
    localStorage.setItem(cname, message);
  }

  load(cname) {
    const temp = localStorage.getItem(cname);
    if (temp !== undefined && temp !== '') {
      return JSON.parse(temp);
    }
    return undefined;
  }
}

export const storage = new Storages();
export default storage;
