import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Col, InputNumber } from 'antd';
import styled from 'styled-components';

import { SaveSection } from '../../../components/common';
import { InputPoint } from '../../../components/forms';

export class HighlightForm  extends Component {
  onChangePoint = (val, name) => {
    this.doc[name].point = val;
    this.props.point.setPassiveActivity(this.doc);
  };

  render() {
    const doc = this.props.point.toJS().passive;
    this.doc = doc;
    return (
      <>
      <Row>
        <Col span={4}>
          <Title>SHARING POINTS</Title>
          <Span>Once a week</Span>
        </Col>

        <Col span={4}>
            <Form.Item>
              <InputPoint
                name="highlight_sharing"
                value={doc.highlight_sharing.point}
                onChange={this.onChangePoint}
              />
            </Form.Item>
          </Col>
      </Row>
        <SaveSection onClick={this.props.onSave} />
      </>
    );
  }
}

const css = {
  input: { width: '100%' },
};

const Title = styled.h3`
  margin-top: 0.4em;
  margin-bottom: 0.1em;
`;

const Span = styled.p`
  font-size: 12px;
`;

const WrappedHighlight = HighlightForm;
export default inject('point')(observer(WrappedHighlight));
