import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { notify } from '../../../utils/notify';
import PersonaliseGraph from './graph';

export class DashboardPersonalise extends Component {
  state = {
    loading: false,
  };

  onFind = async () => {
    try {
      this.setState({ loading: true });
      await this.props.dashboard.getPersonalise();
    } catch (e) {
      notify.error({ title: 'Load Personalize Fail', message: e.message });
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.onFind();
  }

  render() {
    const { loading } = this.state;
    const { personalise } = this.props.dashboard.toJS();
    const { maxUserCount = 0, summary } = personalise;
    return (
      <PersonaliseGraph
        maxUserCount={maxUserCount}
        loading={loading}
        doc={personalise.graph}
        summary={summary}
      />
    );
  }
}

export default inject('dashboard', 'member')(observer(DashboardPersonalise));
