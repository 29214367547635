import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Row, Col, Form } from 'antd';

import { UploadImg } from '../../../components/forms';
import { SaveSection } from '../../../components/common';

export class WrappedImages extends Component {
  componentWillReceiveProps() {
    this.forceUpdate();
  }

  // onSave = async (evt) => {
  //   const { form, onSave } = this.props;
  //   try {
  //     evt.preventDefault();
  //     await form.validateFields();
  //   } catch (e) {
  //     return;
  //   }

  //   if (onSave) onSave();
  // };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const required = true;

    const reward = this.props.reward.toJS();
    const doc = reward.detail;

    return (
      <>
        <Row>
          <Col span={11}>
            <h2>Reward Home Page</h2>
            <Form.Item>
              {getFieldDecorator('image_home', {
                initialValue: doc.image_home,
                rules: [{ required, message: 'Please upload reward home!' }],
              })(<UploadImg name="home" />)}
            </Form.Item>
            <p>Image size must be 630x495px MIN</p>
          </Col>

          <Col span={11} offset={1}>
            <h2>Reward list</h2>
            <Form.Item>
              {getFieldDecorator('image_list', {
                initialValue: doc.image_list,
                rules: [{ required, message: 'Please upload reward list!' }],
              })(<UploadImg name="list" />)}
            </Form.Item>
            <p>Image size must be 1035x390px MIN</p>
          </Col>
        </Row>

        <br />

        <Row>
          <Col span={11}>
            <h2>Reward Code</h2>
            <Form.Item>
              {getFieldDecorator('image_redeem', {
                initialValue: doc.image_redeem,
                rules: [{ required: true, message: 'Please upload image code!' }],
              })(<UploadImg name="redeem" />)}
            </Form.Item>
            <p>Image size must be 945x357px MIN</p>
          </Col>

          <Col span={11} offset={1}>
            <h2>Reward Detail/Share/Redeemed</h2>
            <Form.Item>
              {getFieldDecorator('image_detail', {
                initialValue: doc.image_detail,
                rules: [{ required, message: 'Please upload reward detail/share/redeemed!' }],
              })(<UploadImg name="detail" />)}
            </Form.Item>
            <p>Image size must be 1125x1125px MIN</p>
          </Col>
        </Row>
        <br />
        {/* <SaveSection onClick={this.onSave} /> */}
      </>
    );
  }
}

const onFieldsChange = (props, changedFields, allFields) => {
  const doc = props.reward.toJS().detail;
  const keys = Object.keys(changedFields);
  for (const name of keys) {
    const item = changedFields[name];
    doc[name] = item.value;
  }
  props.reward.setDoc(doc);
};

const Images = Form.create({ onFieldsChange })(WrappedImages);
export default inject('reward')(observer(Images));
