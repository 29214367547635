import React, { useEffect, useState } from 'react';
import { Form, Table, Button, Alert } from 'antd';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, DateCol, Loading } from '../../../components/common';

const transform = (templates) => {
  if (!templates.length) {
    return [];
  }

  const mappedData = templates.map((template) => {
    return {
      key: template.surveyConfigId,
      id: template.surveyConfigId,
      form_id: template.formId,
      title_en: template.titleEn,
      message_en: template.messageEn,
      title_th: template.titleTh,
      message_th: template.messageTh,
      points: template.point,
      schedule_date: template.scheduleAt,
    };
  });

  return mappedData;
};

const SurveyTemplate = (props) => {
  const { surveyTemplate } = props;
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // TODO: This will be implemented by FWDM2-1022
  const editLink = (formId) => `/office/config/survey-schedule/${formId}/edit`;

  // TODO: This will be implemented by FWDM2-1024 ticket
  // Will be done on separate PR

  useEffect(() => {
    setLoading(true);

    surveyTemplate
      .fetchAll()
      .then(() => {
        const data = surveyTemplate.list;
        const transformedData = transform(data);
        setDataSource(transformedData);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }, [surveyTemplate]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'FORM_ID',
      dataIndex: 'form_id',
      key: 'form_id',
    },
    {
      title: 'TITLE (EN)',
      dataIndex: 'title_en',
      key: 'title_en',
    },
    {
      title: 'MESSAGE (EN)',
      dataIndex: 'message_en',
      key: 'message_en',
    },
    {
      title: 'TITLE (TH)',
      dataIndex: 'title_th',
      key: 'title_th',
    },
    {
      title: 'MESSAGE (TH)',
      dataIndex: 'message_th',
      key: 'message_th',
    },
    {
      title: 'SCHEDULE DATE',
      dataIndex: 'schedule_date',
      key: 'schedule_date',
      width: 200,
      render: (text, record) => <DateCol value={record.schedule_date} />,
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        const formId = record.form_id;

        return (
          <div>
            <Link to={editLink(formId)}>
              <Button type="primary">Edit</Button>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <HeaderMenu title="SURVEY SCHEDULE">
      <Page style={css.page}>
        {error && <Alert type="error" message={error} />}
        <Loading loading={loading} />
        <Table
          style={{ fontFamily: 'sans-serif', fontSize: '16px' }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </Page>
    </HeaderMenu>
  );
};

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

const SurveyTemplateForm = Form.create({ name: 'survey_template' })(SurveyTemplate);

export default inject('surveyTemplate')(observer(SurveyTemplateForm));
