import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, DatePicker, Input } from 'antd';

import { Filter, GroupItem, DropdownList } from '../../../components/forms';
import { FindBtn } from '../../../components/button';

import { RewardStatusFilter } from '../../../definition/filter';

export default class RewardFilter extends Component {
  state = {
    time_type: 'create',
    start: undefined,
    end: undefined,
    status: 'all',
  };

  componentDidMount() {
    this.onFind();
  }

  onStartDate = (val) => {
    this.setState({ start: val });
  };

  onEndDate = (val) => {
    this.setState({ end: val });
  };

  onChange = (val, name) => {
    const { state } = this;
    state[name] = val;
    this.setState(state);
  };

  onFind = (evt) => {
    if (evt) evt.preventDefault();

    const { onFind } = this.props;
    if (onFind) onFind(this.state);
  };

  onNameChanged = (event) => {
    this.setState({ name: event.target.value });
  };

  render() {
    const { time_type, start, end, status } = this.state;
    return (
      <Filter style={filterCss}>
        <FilterPage>
          <Row gutter={12} type="flex">
            <Col>
              <GroupItem label="Time Type">
                <DropdownList
                  name="time_type"
                  menus={timeMenu}
                  value={time_type}
                  allowClear={false}
                  onChange={this.onChange}
                  style={{ width: 100 }}
                />
              </GroupItem>
            </Col>
            <Col>
              <GroupItem label="Start Date/Time">
                <DatePicker showTime style={itemCss} value={start} onChange={this.onStartDate} />
              </GroupItem>
            </Col>
            <Col>
              <GroupItem label="End Date/Time">
                <DatePicker showTime style={itemCss} value={end} onChange={this.onEndDate} />
              </GroupItem>
            </Col>

            <Col>
              <GroupItem label="Status">
                <DropdownList
                  name="status"
                  menus={RewardStatusFilter}
                  value={status}
                  allowClear={false}
                  onChange={this.onChange}
                  style={{ width: 150 }}
                />
              </GroupItem>
            </Col>
            <Col>
              <GroupItem label="Name">
                <Input placeholder="enter name" onChange={this.onNameChanged} />
              </GroupItem>
            </Col>
            <Col>
              <GroupItem>
                <FindBtn onClick={this.onFind} />
              </GroupItem>
            </Col>
          </Row>
        </FilterPage>
      </Filter>
    );
  }
}

const itemCss = {
  width: '98%',
};

const filterCss = {
  border: '1px solid #E7E6E6',
  borderRadius: '5px',
};

const FilterPage = styled.div`
  padding: 10px;
`;

const timeMenu = [
  { name: 'Created', value: 'create' },
  { name: 'Start', value: 'start' },
  { name: 'Expire', value: 'end' },
];
