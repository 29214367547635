import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Row, Col } from 'antd';

import { InputInt, InputPoint } from '../../../components/forms';

export class Defaulter extends Component {
  onChange = (val, name) => {
    this.doc.default[name] = val;
    this.props.point.setPlancode(this.doc);
  };

  render() {
    const span = 4;
    const { validate = {} } = this.props;
    const doc = this.props.point.toJS().plancode;
    this.doc = doc;
    return (
      <Row>
        <Title>Default Paid Policy</Title>
        <Col span={span}>
          <Form.Item
            label="Baht"
            validateStatus={validate.baht ? 'error' : ''}
            help={validate.baht ? 'Please change, cannot be zero' : ''}
          >
            <InputInt name="baht" value={doc.default.baht} onChange={this.onChange} text="baht" />
          </Form.Item>
        </Col>

        <Col span={1}>
          <Form.Item label=" ">
            <Center>=</Center>
          </Form.Item>
        </Col>

        <Col span={span}>
          <Form.Item
            label="Points"
            validateStatus={validate.point ? 'error' : ''}
            help={validate.point ? 'Point is required' : ''}
          >
            <InputPoint name="point" value={doc.default.point} onChange={this.onChange} />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

const Title = styled.h2`
  padding: 10px 0px;
`;

const Center = styled.div`
  text-align: center;
  font-size: 20px;
`;

export default inject('point')(observer(Defaulter));
