import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const AddButtonCircle = ({ to }) => {
  return (
    <Link to={to}>
      <StyledAddButton type="primary" shape="circle">
        <i className="fas fa-plus" />
      </StyledAddButton>
    </Link>
  );
};

const StyledAddButton = styled(Button)`
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 32px;
  line-height: 32px;
  z-index: 100;
  background-color: #ee761e;
  border-color: #ee761e;
  &:hover {
    background-color: #f28d00;
    border-color: #f28d00;
  }
`;

export default AddButtonCircle;
