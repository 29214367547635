import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Select, Row, Col, InputNumber, DatePicker, Divider, Button, Switch } from 'antd';
import locale from 'antd/es/date-picker/locale/th_TH';
import { SaveSection } from '../../components/common';
import { PictureWall } from '../../components/forms';
import PersonalTagCheckbox from '../../components/forms/PersonalTagCheckbox';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const activityTypes = [
  { code: 'steps', type: 'active', display: 'Steps' },
  { code: 'running', type: 'active', display: 'Running' },
  { code: 'swimming', type: 'active', display: 'Swimming' },
  { code: 'cycling', type: 'active', display: 'Cycling' },
  { code: 'eating_check_in', type: 'passive', display: 'Dining' },
  { code: 'traveling_check_in', type: 'passive', display: 'Traveling' },
];

const formFieldList = [
  'status',
  'activityCode',
  'rewardType',
  'isLuckyDraw',
  'sequence',
  'rewardPoint',
  'maxCollected',
  'maxJoin',
  'targetDistance',
  'targetElev',
  'targetTime',
  'targetStep',
  'targetLocationId',
  'targetLocationName',
  'targetLocationLat',
  'targetLocationLong',
  'titleEn',
  'titleTh',
  'subtitleEn',
  'subtitleTh',
  'rewardTitleEn',
  'rewardTitleTh',
  'rewardDescEn',
  'rewardDescTh',
  'rewardImage',
  'termEn',
  'termTh',
  'imageList',
  'imageHome',
  'imageDetail',
  'redeemExpiredAt',
  'dateRanges',
];

const rewardFieldList = [
  'rewardChallengeId',
  'maxQty',
  'titleEn',
  'titleTh',
  'descTh',
  'descEn',
  'imageDetailEn',
  'imageDetailTh',
  'imageList',
];

const ChallengeForm = (props) => {
  const { getFieldDecorator, getFieldsValue, getFieldValue, setFieldsValue } = props.form;
  const { challenge, venue } = props;
  const [imageChallengeUrls, setImageChallengeUrls] = useState({});
  const [imageRewardUrls, setImageRewardUrls] = useState({});
  const [imagePointUrl, setImagePointUrls] = useState(null);
  const [isRewardTypeEditable, setRewardTypeEditable] = useState(true);
  const [isPointRewardType, setPoinRewardType] = useState(true);
  const [isDisabledMaxQty, setDisabledMaxQty] = useState(false);
  const [isDisabledRewardCondition, setDisabledRewardCondition] = useState(false);
  const [personalizeTags, setPersonalizeTags] = useState([]);

  const [{ isDisabledStep, isDisabledDistance, isDisabledElev, isDisabledTime }, setDisableRewardCondition] = useState({
    isDisabledStep: false,
    isDisabledDistance: true,
    isDisabledElev: true,
    isDisabledTime: true,
  });

  const cleanup = () => props.onReset();

  const checkDisabledRewardCondition = (code) => {
    switch (code) {
      case 'swimming':
        return setDisableRewardCondition({
          isDisabledStep: true,
          isDisabledDistance: false,
          isDisabledElev: true,
          isDisabledTime: false,
        });
      case 'running':
      case 'cycling':
        return setDisableRewardCondition({
          isDisabledStep: true,
          isDisabledDistance: false,
          isDisabledElev: false,
          isDisabledTime: false,
        });
      case 'steps':
        return setDisableRewardCondition({
          isDisabledStep: false,
          isDisabledDistance: true,
          isDisabledElev: true,
          isDisabledTime: true,
        });
      default:
        return setDisableRewardCondition({
          isDisabledStep: false,
          isDisabledDistance: true,
          isDisabledElev: true,
          isDisabledTime: true,
        });
    }
  };

  const onChangePersonalTag = (selected) => {
    setPersonalizeTags(selected);
  };

  const getActiveCategoryByCode = (code) => {
    const selectedType = activityTypes.find((type) => type.code === code);
    return selectedType ? selectedType.type : 'passive';
  };

  const getActivityCodeByType = (type) => {
    const selectedType = activityTypes.find((current) => current.type === type);
    return selectedType.code;
  };

  const handleRewardType = useCallback(
    (rewardType) => {
      if (rewardType === 'point') {
        setPoinRewardType(true);
        setFieldsValue({
          isLuckyDraw: false,
        });
      } else {
        setPoinRewardType(false);
      }
    },
    [setFieldsValue]
  );

  const handleCategory = useCallback(
    (category) => {
      const baseField = { activityCode: getActivityCodeByType(category) };
      const isPassive = category === 'passive';
      if (isPassive) {
        checkDisabledRewardCondition(getActivityCodeByType(category));
      } else {
        Object.assign(baseField, {
          targetLocationId: null,
          targetLocationName: null,
          targetLocationLat: null,
          targetLocationLong: null,
        });
      }
      setDisabledRewardCondition(isPassive);
      setFieldsValue(baseField);
    },
    [setFieldsValue]
  );

  const handleActivityCode = useCallback(
    (code) => {
      setFieldsValue({
        targetStep: 0,
        targetDistance: 0,
        targetElev: 0,
        targetTime: 0,
        activityCode: code,
      });
      checkDisabledRewardCondition(code);
    },
    [setFieldsValue]
  );

  const handleMaxJoin = useCallback(
    (maxJoin) => {
      setFieldsValue({
        maxJoin,
        maxCollected: maxJoin,
      });
    },
    [setFieldsValue]
  );

  const checkDisabledMaxQty = (isLuckyDraw) => {
    setDisabledMaxQty(isLuckyDraw);
    if (isLuckyDraw) {
      setFieldsValue({
        'rewardChallenge.maxQty': null,
      });
    }
  };

  useEffect(() => {
    if (challenge) {
      const data = {};
      formFieldList.forEach((key) => {
        data[key] = challenge[key];
      });

      if (challenge.rewardChallenge) {
        data.rewardChallenge = {};
        rewardFieldList.forEach((key) => {
          data.rewardChallenge[key] = challenge.rewardChallenge[key];
        });
      }

      setFieldsValue({
        ...data,
        activityCategory: getActiveCategoryByCode(challenge.activityCode),
      });
      const { imageDetailUrl: detail, imageHomeUrl: home, imageUrl: list, rewardImageUrl } = challenge;
      setImageChallengeUrls({
        detail,
        home,
        list,
      });
      setImagePointUrls(rewardImageUrl);
      setRewardTypeEditable(false);
      checkDisabledRewardCondition(challenge.activityCode);
      setPoinRewardType(challenge.rewardType === 'point');
      checkDisabledMaxQty(challenge.isLuckyDraw);
      setDisabledRewardCondition(getActiveCategoryByCode(challenge.activityCode) === 'passive');

      if (challenge.rewardChallenge) {
        const { imageDetailEnUrl, imageDetailThUrl, imageListUrl } = challenge.rewardChallenge;
        setImageRewardUrls({ detailEn: imageDetailEnUrl, detailTh: imageDetailThUrl, list: imageListUrl });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge]);

  useEffect(() => {
    return () => cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (venue) {
      setFieldsValue({
        targetLocationId: venue.id,
        targetLocationName: venue.name,
        targetLocationLat: venue.location.lat,
        targetLocationLong: venue.location.lng,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue]);

  const renderPreviewData = () => {
    return (
      <div>
        <pre>{JSON.stringify(getFieldsValue(), null, 2)}</pre>
      </div>
    );
  };

  const handleOnSave = async (e) => {
    e.preventDefault();
    try {
      const values = await props.form.validateFields();
      values.pTags = personalizeTags.toString();
      props.onSubmit(values);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const renderSelectActivityType = () => {
    const selectedCat = getFieldValue('activityCategory');
    const activityByCategories = activityTypes.filter((a) => a.type === selectedCat);
    return (
      <Select onChange={handleActivityCode}>
        {activityByCategories.map(({ code, display }) => {
          return (
            <Option key={code} value={code}>
              {display}
            </Option>
          );
        })}
      </Select>
    );
  };

  const renderRewardCondition = () => {
    const content = (
      <>
        <div style={{ display: isDisabledRewardCondition ? 'none' : '' }}>
          <Divider>Reward Condition</Divider>
          <Row gutter={12}>
            <Col span={6}>
              <Form.Item label="Distance (เมตร)">
                {getFieldDecorator('targetDistance')(
                  <InputNumber disabled={isDisabledDistance} min={0} style={{ width: '100%' }} />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Elev (เมตร)">
                {getFieldDecorator('targetElev')(
                  <InputNumber disabled={isDisabledElev} min={0} style={{ width: '100%' }} />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Time (จำนวนครั้ง)">
                {getFieldDecorator('targetTime')(
                  <InputNumber disabled={isDisabledTime} min={0} style={{ width: '100%' }} />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Step (จำนวนก้าว)">
                {getFieldDecorator('targetStep')(
                  <InputNumber disabled={isDisabledStep} min={0} style={{ width: '100%' }} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    );

    return content;
  };

  const renderLocation = () => {
    const content = (
      <>
        <div style={{ display: getFieldValue('activityCategory') === 'active' ? 'none' : '' }}>
          <Divider>Location Information</Divider>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Target location id (Foursquare)">
                <Row gutter={12}>
                  <Col span={18}>{getFieldDecorator('targetLocationId')(<Input />)}</Col>
                  <Col span={6}>
                    <Button
                      type="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        const targetLocationId = getFieldValue('targetLocationId');
                        props.onGetVenueById(targetLocationId);
                      }}
                    >
                      search
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Target location name">
                {getFieldDecorator('targetLocationName')(<Input maxLength={150} />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Target location lat">
                {getFieldDecorator('targetLocationLat')(<InputNumber style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Target location long">
                {getFieldDecorator('targetLocationLong')(<InputNumber style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    );
    return content;
  };

  const renderPhysicalReward = () => {
    const content = (
      <div style={{ display: getFieldValue('rewardType') !== 'physical' ? 'none' : '' }}>
        <Divider>Reward Information (Physical Only)</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="RewardChallengeId">
              {getFieldDecorator('rewardChallenge.rewardChallengeId')(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Max Qty">
              {getFieldDecorator('rewardChallenge.maxQty')(
                <InputNumber disabled={isDisabledMaxQty} min={0} style={{ width: '100%' }} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Title by TH">
              {getFieldDecorator('rewardChallenge.titleTh')(<Input maxLength={45} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Title by EN">
              {getFieldDecorator('rewardChallenge.titleEn')(<Input maxLength={45} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Desc by TH">{getFieldDecorator('rewardChallenge.descTh')(<TextArea />)}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Desc by EN">{getFieldDecorator('rewardChallenge.descEn')(<TextArea />)}</Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Image Detail by TH (1035 x 390 pixels)">
              {getFieldDecorator('rewardChallenge.imageDetailTh')(
                <PictureWall name="challenge" url={imageRewardUrls.detailTh} />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Image Detail by EN (1035 x 390 pixels)">
              {getFieldDecorator('rewardChallenge.imageDetailEn')(
                <PictureWall name="challenge" url={imageRewardUrls.detailEn} />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Image Success Page (675 x 675 pixels)">
              {getFieldDecorator('rewardChallenge.imageList')(
                <PictureWall name="challenge" url={imageRewardUrls.list} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
    return content;
  };

  const renderPoinReward = () => {
    return (
      <>
        <Row gutter={16} style={{ display: !isPointRewardType ? 'none' : '' }}>
          <Col span={12}>
            <Form.Item label="Reward Name by TH">
              {getFieldDecorator('rewardTitleTh')(<Input maxLength={100} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Reward Name by EN">
              {getFieldDecorator('rewardTitleEn')(<Input maxLength={100} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Reward Description by TH">{getFieldDecorator('rewardDescTh')(<TextArea />)}</Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Reward Description by EN">{getFieldDecorator('rewardDescEn')(<TextArea />)}</Form.Item>
          </Col>
          <Col span={12} offset={8}>
            <Form.Item label="Image Reward (1035 x 390 pixels)">
              {getFieldDecorator('rewardImage')(<PictureWall name="challenge" url={imagePointUrl} />)}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form>
      {/* {renderPreviewData()} */}
      <Divider>Challenge Information</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Name by TH">{getFieldDecorator('titleTh')(<Input maxLength={100} />)}</Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Name by ENG">{getFieldDecorator('titleEn')(<Input maxLength={100} />)}</Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Challenges status">
            {getFieldDecorator('status', {
              initialValue: 'draft',
            })(
              <Select>
                <Option value="draft">Draft</Option>
                <Option value="inactive">Inactive</Option>
                <Option value="active">Active</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Image Home (300 x 300 pixels)">
            {getFieldDecorator('imageHome')(<PictureWall name="challenge" url={imageChallengeUrls.home} />)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Image List (300 x 300 pixels)">
            {getFieldDecorator('imageList')(<PictureWall name="challenge" url={imageChallengeUrls.list} />)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Image Detail (1125 x 1125 pixels)">
            {getFieldDecorator('imageDetail')(<PictureWall name="challenge" url={imageChallengeUrls.detail} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Form.Item label="Description by TH">{getFieldDecorator('subtitleTh')(<TextArea maxLength={100} />)}</Form.Item>
      </Row>
      <Row gutter={24}>
        <Form.Item label="Description by EN">{getFieldDecorator('subtitleEn')(<TextArea maxLength={100} />)}</Form.Item>
      </Row>
      <Row gutter={24}>
        <Form.Item label="Personalize Tags">
          <PersonalTagCheckbox tags={challenge && challenge.pTags} onChangePersonalTag={onChangePersonalTag} />
        </Form.Item>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Activity category">
            {getFieldDecorator('activityCategory', {
              initialValue: 'active',
            })(
              <Select onChange={handleCategory} placeholder="Please select a activity category">
                <Option value="active">Active</Option>
                <Option value="passive">Passive</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Activity type">
            {getFieldDecorator('activityCode', {
              initialValue: 'steps',
            })(renderSelectActivityType())}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Sequence (Note: This sequence ordering is applied to challenges which display at Home only. Max sequence ordering is 4.)">
            {getFieldDecorator('sequence')(<InputNumber min={0} max={4} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        <Col span={12} offset={12}>
          <Form.Item label="MaxCollected" style={{ display: 'none' }}>
            {getFieldDecorator('maxCollected')(<InputNumber min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="MaxJoin">
            {getFieldDecorator('maxJoin')(<InputNumber onChange={handleMaxJoin} min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
      </Row>
      {/* activity codition  */}
      {renderRewardCondition()}
      {/* location information when dining or travelling */}
      {renderLocation()}
      {/* term and condition */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Term by TH">{getFieldDecorator('termTh')(<TextArea rows={14} />)}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Term by EN">{getFieldDecorator('termEn')(<TextArea rows={14} />)}</Form.Item>
        </Col>
      </Row>
      {/* reward detail */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Reward type">
            {getFieldDecorator('rewardType', { initialValue: 'point' })(
              <Select
                disabled={!isRewardTypeEditable}
                placeholder="Please select a reward type"
                onChange={handleRewardType}
              >
                <Option value="point">Point</Option>
                <Option value="physical">Physical</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* dynamic show when reward type = point */}
          <Form.Item label="Point">
            {getFieldDecorator('rewardPoint')(<InputNumber min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Unlimited Reward">
            {getFieldDecorator('isLuckyDraw', { valuePropName: 'checked' })(
              <Switch onChange={(e) => checkDisabledMaxQty(e)} disabled={isPointRewardType} />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Redeem Expired At">
            {getFieldDecorator('redeemExpiredAt')(<DatePicker showTime />)}
          </Form.Item>
        </Col>
      </Row>

      {/* date period */}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="StartAt - EndAt">
            {getFieldDecorator('dateRanges', { initialValue: [] })(
              <RangePicker style={{ width: '100%' }} locale={locale} placeholder={['Start At', 'End At']} showTime />
            )}
          </Form.Item>
        </Col>
      </Row>

      {/* point info show when reward type = point only */}
      {renderPoinReward()}

      {/* rewardChallegne */}
      {renderPhysicalReward()}

      <Row gutter={24}>
        <Form.Item>
          <SaveSection onClick={handleOnSave} />
        </Form.Item>
      </Row>
    </Form>
  );
};

const WrappedChallengeForm = Form.create({ name: 'challenge_form' })(ChallengeForm);

export default WrappedChallengeForm;
