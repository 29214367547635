import { BadgeMap, ActivityCodeMap, ActivityCateMap, StatusMap, RewardStatusMap, ActivityGroupMap } from '../mapping';

const objectToMenu = (obj = {}) => {
  const list = [{ name: 'All', value: 'all' }];
  const keys = Object.keys(obj);
  for (const key of keys) {
    const name = obj[key];
    list.push({ name, value: key });
  }

  return list;
};

export const BadgeFilter = objectToMenu(BadgeMap);

export const ActiviCateFilter = objectToMenu(ActivityCateMap);

export const ActiviCodeFilter = objectToMenu(ActivityCodeMap);

export const ActivityGroupFilter = objectToMenu(ActivityGroupMap);

export const StatusFilter = objectToMenu(StatusMap);

export const RewardStatusFilter = objectToMenu(RewardStatusMap);
