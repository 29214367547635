import React, { Component } from 'react';
import styled from 'styled-components';

import HeaderMenu from '../../components/main/HeaderMenu';
import { Page } from '../../components/common';
import { TabMenu } from '../../components/forms';
import { history } from '../../utils/history';

export default class UserInfo extends Component {
  onChange = (item) => {
    const { name } = this.props;
    if (item.link && name !== item.name) history.push(item.link);
  };

  render() {
    const { title = '', name } = this.props;
    return (
      <HeaderMenu title={title}>
        <Page>
          <TabMenu name={name} menus={MENU} onChange={this.onChange} />
          <Section>{this.props.children}</Section>
        </Page>
      </HeaderMenu>
    );
  }
}

const Section = styled.div`
  background-color: white;
`;

const MENU = [
  {
    name: 'PERSONAL',
    link: './personal',
  },
  {
    name: 'REWARDS',
    link: './reward',
  },
  {
    name: 'COUPONS',
    link: './coupon',
  },
  {
    name: 'ACTIVITY',
    link: './activity',
  },
  {
    name: 'CHALLENGES',
    link: './challenge',
  },
  {
    name: 'POINTS',
    link: './point',
  },
  {
    name: 'POLL & SURVEY',
    link: './poll-and-survey',
  },
  {
    name: 'BADGE COUNTER',
    link: './badge-counter',
  },
];
