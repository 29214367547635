import React from 'react';

const userActivities = {
  steps: 'Steps',
  running: 'Running',
  cycling: 'Cycling',
  swimming: 'Swimming',
  eating_check_in: 'Eating Check In',
  traveling_check_in: 'Traveling Check In',
};

const UserActivity = ({ value, ...props }) => {
  return <span {...props}>{userActivities[value]}</span>;
};

export default UserActivity;
