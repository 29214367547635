import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'antd';

import { DropdownList } from '../forms';
import GenRandomCode from './GenRandomCode';
import GenSingleCode from './GenSingleCode';
import GenPhysicalCode from './GenPhysicalCode';
import GenIncrementCode from './GenIncrementCode';
import ImportCode from './ImportCode';

const TypeMenu = [
  { name: 'Single', value: 'single' },
  { name: 'Multiple', value: 'multiple' },
  { name: 'Import Code', value: 'import' },
  { name: 'Auto Increment', value: 'auto-increment' },
];
const TypeMenuPhysical = [{ name: 'Physical', value: 'physical' }];

function Generator(props) {
  const [currentType, setCurrentType] = useState('multiple');
  // const [isInitial, setIsInitial] = useState(false);
  const onChangeType = (val) => setCurrentType(val);

  const { isPhysicalReward } = props;
  useEffect(() => {
    if (isPhysicalReward) {
      setCurrentType('physical');
    } else {
      setCurrentType('multiple');
    }
  }, [isPhysicalReward]);

  useEffect(() => {
    console.log('props.initialValues', props.initialValues)
    if (props.initialValues && props.initialValues.type) setCurrentType(props.initialValues.type);
    // props.initialConfig(props.initialValues.type, {...props.initialValues});

  }, [props.initialValues]);

  function RenderContent(type) {
    switch (type) {
      case 'single':
        return <GenSingleCode {...props} />;
      case 'import':
        return <ImportCode {...props} />;
      case 'physical':
        return <GenPhysicalCode {...props} />;
      case 'auto-increment':
        return <GenIncrementCode {...props} />;
      default:
        return <GenRandomCode {...props} />;
    }
  }

  return (
    <div>
      <Form hideRequiredMark>
        <Row>
          <Col span={6}>
            <Form.Item label="Code Type">
              {isPhysicalReward ? (
                <DropdownList
                  menus={TypeMenuPhysical}
                  value="Physical"
                  allowClear={false}
                  disabled={!props.generator}
                />
              ) : (
                <DropdownList
                  menus={TypeMenu}
                  value={currentType}
                  allowClear={false}
                  onChange={onChangeType}
                  disabled={!props.generator}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {RenderContent(currentType)}
      <br />
    </div>
  );
}

export default Generator;
