import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Button } from 'antd';

export default class KeyBtn extends Component {
  render() {
    const { onClick, style = {}, text = 'Click', disabled = false } = this.props;
    const css = cloneDeep(style);
    const { textAlign, width, color, backgroundColor } = style;

    css.textAlign = textAlign || 'center';
    css.width = width || '100%';
    css.color = color || 'white';
    css.backgroundColor = backgroundColor || '#e87722';

    if (disabled) css.opacity = 0.5;

    return (
      <Button style={css} onClick={onClick} disabled={disabled}>
        {text}
      </Button>
    );
  }
}
