import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Input, notification, Select, Button, Icon, Modal } from 'antd';
import styled from 'styled-components';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { notify } from '../../../utils/notify';
import ImportExportExcel from '../../../components/common/ImportExportExcel';
import { Loading, Cols, DateCol, DisplayStatus } from '../../../components/common';
import { GroupItem } from '../../../components/forms';
import DateFilter from '../../../components/filter/DateFilter';
import Colors from '../../../assets/Colors';
import { PERM_APPROVE_OR_REJECT_POINT_ADJUST_REQUEST } from '../../../config/permissions';
import { can } from '../../../utils/acl';

const { Option } = Select;

const NAME = 'USERS POINT ADJUST';
const APPROVED = 'approved';
const CANCELLED = 'cancelled';
const INPROGRESS = 'inprogress';

const WALLET_ADJUST_STATUS = {
  APPROVED,
  CANCELLED,
  INPROGRESS,
};

const HEADER = ['Mobile Number', 'Title English', 'Description English', 'Title Thai', 'Description Thai', 'Point'];

export class PointAdjust extends Component {
  constructor(props) {
    super(props);
    this.boardFilterRef = React.createRef();
    this.state = {
      mobileNumber: '',
      adjustStatus: '',
      columns: [
        {
          title: 'MOBILE NUMBER',
          dataIndex: 'tmp_phone',
        },
        {
          title: 'TITLE EN',
          dataIndex: 'title_en',
        },
        {
          title: 'DESCRIPTION EN',
          dataIndex: 'desc_en',
        },
        {
          title: 'TITLE TH',
          dataIndex: 'title_th',
        },
        {
          title: 'DESCRIPTION TH',
          dataIndex: 'desc_th',
        },
        {
          title: 'POINT',
          dataIndex: 'amount',
          render: (_, record) => {
            return <StyledAmount color={record.increment ? Colors.green1 : Colors.red1}>{record.amount}</StyledAmount>;
          },
        },
        {
          title: 'CREATED',
          dataIndex: 'createdAt',
          width: 120,
          render: (time) => <DateCol value={time} />,
        },
        {
          title: 'REQUEST BY',
          dataIndex: 'requestBy',
        },
        {
          title: 'APPROVE BY',
          dataIndex: 'approveBy',
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          render: (status) => <DisplayStatus type={4} status={status} />,
        },
        {
          title: 'ACTION',
          dataIndex: 'action',
          render: (_, record) => {
            if (!(record.status === WALLET_ADJUST_STATUS.INPROGRESS)) return null;

            return (
              <>
                <Button type="link" shape="round" size="large" onClick={() => this.onReject(record.id)}>
                  <StyledIcon type="close-circle" />
                </Button>
                <Button type="link" shape="round" size="large" onClick={() => this.onApprove(record.id)}>
                  <StyledIcon type="check-circle" />
                </Button>
              </>
            );
          },
        },
      ],
    };
  }

  componentWillUnmount() {
    this.props.point.resetWalletAdjustList();
  }

  onApprove = (id) => {
    const confirmation = Modal.confirm({
      title: 'Approve Point Adjustment',
      icon: 'check-circle',
      content: 'Please confirm approval.',
      okType: 'primary',
      onOk: async () => {
        confirmation.update({
          okButtonProps: { disabled: true },
          cancelButtonProps: { disabled: true },
          loading: true,
        });
        try {
          await this.props.point.onApprove(id);
          await this.fetchApi(this.boardFilterRef.current.getDate(), this.props.point.pointAdjust.pagination.current);
        } catch (e) {
          confirmation.destroy();
        }
      },
    });
  };

  onReject = (id) => {
    const confirmation = Modal.confirm({
      title: 'Reject Point Adjustment',
      icon: 'close-circle',
      okType: 'danger',
      content: 'Please confirm rejection.',
      onOk: async () => {
        confirmation.update({
          okButtonProps: { disabled: true },
          cancelButtonProps: { disabled: true },
          loading: true,
        });

        try {
          await this.props.point.onReject(id);
          await this.fetchApi(this.boardFilterRef.current.getDate(), this.props.point.pointAdjust.pagination.current);
        } catch (e) {
          confirmation.destroy();
        }
      },
    });
  };

  fetchApi = async (date = {}, page = 1) => {
    try {
      const { mobileNumber, adjustStatus } = this.state;
      await this.props.point.getWalletAdjustList(date, page, mobileNumber, adjustStatus);
    } catch (e) {
      notify.error({ title: 'Load Point-Adjust Fail', message: e.message });
    }
  };

  onFilterDate = (date) => {
    if (this.state.mobileNumber !== '' && this.state.mobileNumber.length < 10) {
      notify.error({ title: 'Invalid Input', message: 'either no mobile phone or mobile phone must be 10 digit' });
      return;
    }
    this.fetchApi(date, 1);
  };

  onChangePage = (pagination) => {
    this.fetchApi(this.boardFilterRef.current.getDate(), pagination.current);
  };

  onChangeMobileNumber = (e) => {
    this.state.mobileNumber = e.target.value;
  };

  onAdjustStatusChange = (status) => {
    this.state.adjustStatus = status;
  };

  handleExportExcel = async () => {
    const date = this.boardFilterRef.current.getDate();
    const items = await this.props.point.exportWalletAdjustList(date, this.state.mobileNumber, this.state.adjustStatus);
    if (!items) return {};
    const output = items.map((v) => {
      return [v.tmp_phone, v.title_en, v.desc_en, v.title_th, v.desc_th, Number(v.amount)];
    });
    const startDate = date.start.format('YYYYMMDD');
    const endDate = date.end.format('YYYYMMDD');
    const fileName = `wallet_adjust_${startDate}-${endDate}_report`;
    const sheetName = 'wallet_adjust';
    return { output, fileName, sheetName };
  };

  handleImportExcel = async (jsonArr) => {
    const items = jsonArr.map((v) => {
      return {
        tmp_phone: v['Mobile Number'].toString(),
        title_en: v['Title English'],
        title_th: v['Title Thai'],
        desc_en: v['Description English'],
        desc_th: v['Description Thai'],
        trans_type: v.Point > 0 ? 'dr' : 'cr',
        amount: Math.abs(v.Point),
        adjust_status: 'inprogress',
        request_user_id: this.props.member.user.profile.backoffice_user_id,
      };
    });
    notification.open({
      message: 'Warning',
      description: 'This process could take long time',
    });
    await this.props.point.importWalletAdjust(items);
    this.fetchApi(this.boardFilterRef.current.getDate(), 1);
  };

  handleDownloadExample = () => {
    const output = [
      ['1111111111', 'title1', 'des1', 'หัวข้อ1', 'ข้อความ1', 100],
      ['1111111112', 'title2', 'des2', 'หัวข้อ2', 'ข้อความ2', -100],
    ];
    const fileName = `Example_template_wallet-adjust_report`;
    const sheetName = 'wallet adjust';
    return { output, fileName, sheetName };
  };

  renderBoarderFilterMoreItem() {
    return (
      <>
        <Cols>
          <GroupItem label="Status">
            <Select placeholder="Select adjust status" style={{ width: 180 }} onChange={this.onAdjustStatusChange}>
              <Option value="all">Show all</Option>
              <Option value="inprogress">Waiting for approve</Option>
              <Option value="approved">Approved</Option>
              <Option value="cancelled">Rejected</Option>
            </Select>
          </GroupItem>
        </Cols>
        <Cols>
          <GroupItem label="Mobile Number">
            <Input onChange={this.onChangeMobileNumber} />
          </GroupItem>
        </Cols>
      </>
    );
  }

  render() {
    const hasPermission = can(PERM_APPROVE_OR_REJECT_POINT_ADJUST_REQUEST, this.props.member.user.profile.role_name);

    const { list, loading, pagination } = this.props.point.pointAdjust;

    return (
      <HeaderMenu title={NAME}>
        <Loading loading={this.props.point.loading} />
        <ImportExportExcel
          headerImport={HEADER}
          headerExport={HEADER}
          handleExportExcel={this.handleExportExcel}
          handleImportExcel={this.handleImportExcel}
          handleDownloadExample={this.handleDownloadExample}
        />
        <DateFilter
          onFind={this.onFilterDate}
          ref={this.boardFilterRef}
          moreItem={this.renderBoarderFilterMoreItem()}
        />

        <Table
          indentSize={2}
          style={css.table}
          columns={
            this.state.columns.length === 11 && !hasPermission ? this.state.columns.splice(-1, 1) : this.state.columns
          }
          dataSource={list}
          bordered
          loading={loading}
          onChange={this.onChangePage}
          pagination={{
            ...pagination,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </HeaderMenu>
    );
  }
}

const StyledAmount = styled('div')`
  color: ${(props) => props.color};
  text-align: right;
`;

const StyledIcon = styled(Icon)`
  font-size: 25px;
`;

const css = {
  countList: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
  },
  table: {
    background: 'white',
    marginTop: 20,
    borderRadius: 5,
  },
};

export default inject('point', 'member')(observer(PointAdjust));
