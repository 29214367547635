import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const UserExportButton = (props) => {
  const { onClick, title = 'EXPORT', ...rest } = props;

  return (
    <StyledButton onClick={onClick} {...rest} icon="export">
      {title}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  background-color: transparent;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  transition: all 0.2s ease;
  width: 100%;
  :active {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
  :focus,
  :hover {
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.primaryHover};
    background-color: ${({ theme }) => theme.colors.primaryHover};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default UserExportButton;
