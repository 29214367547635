import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'antd';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import moment from 'moment';

import { DropdownList, DatePicker, InputPoint, InputInt, Input, TextArea, TimePicker } from '../../../components/forms';
import { timer } from '../../../utils/timer';
import { SaveSection } from '../../../components/common';
import { RewardStatusMenu } from '../../../definition/dropdown';
import { UploadImg } from '../../../components/forms';
import PersonalTagCheckbox from '../../../components/forms/PersonalTagCheckbox';
import Images from './Images';

export class WappedInformation extends Component {
  personalizeTags = [];

  onSave = async (evt) => {
    const { form, onSave } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
    } catch (e) {
      return;
    }

    this.props.reward.detail.p_tags = this.personalizeTags.toString();
    if (onSave) onSave();
  };

  onChange = (val, name) => {
    const doc = this.props.reward.toJS().detail;
    doc[name] = val;

    this.props.reward.setDoc(doc);
  };

  onChangePersonalTag = (selected) => {
    this.personalizeTags = selected;
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const required = true;
    const reward = this.props.reward.toJS();
    const doc = reward.detail;
    const docTag = this.props.tag.toJS();

    const tags = doc.tags.map((item) => {
      return item.tag_code;
    });

    return (
      <Form>
        <Row>
          <Col span={8}>
            <Form.Item label="Name by TH">
              {getFieldDecorator('name_th', {
                initialValue: doc.name_th,
                rules: [{ required, message: 'Please fill name TH!' }],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={8} offset={1}>
            <Form.Item label="Name by EN">
              {getFieldDecorator('name_en', {
                initialValue: doc.name_en,
                rules: [{ required, message: 'Please fill name EN!' }],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="Status">
              {getFieldDecorator('status', {
                initialValue: doc.status,
              })(<DropdownList disabled={doc.reward_id === undefined} allowClear={false} menus={RewardStatusMenu} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={17}>
            <Form.Item label="Description by TH">
              {getFieldDecorator('desc_th', {
                initialValue: doc.desc_th,
                rules: [{ required, message: 'Please fill description TH!' }],
              })(<TextArea rows="2" />)}
            </Form.Item>
          </Col>

          <Col span={17}>
            <Form.Item label="Description by EN">
              {getFieldDecorator('desc_en', {
                initialValue: doc.desc_en,
                rules: [{ required, message: 'Please fill description EN!' }],
              })(<TextArea rows="2" />)}
            </Form.Item>
          </Col>

          <Col span={17}>
            <Form.Item label="Personalize Tags">
              <PersonalTagCheckbox tags={doc.p_tags} onChangePersonalTag={this.onChangePersonalTag} />
            </Form.Item>
          </Col>
        </Row>

        <Line />

        <Row>
          <Col span={4}>
            <Form.Item label="Redeem point">
              {getFieldDecorator('price', {
                initialValue: doc.price,
                rules: [{ required, message: 'Please fill redeem point!' }],
              })(<InputPoint />)}
            </Form.Item>
          </Col>

          <Col span={10} offset={1}>
            <Form.Item label="Tag">
              {getFieldDecorator('tags', {
                // initialValue: tags,
                initialValue: 'new',
              })(<DropdownList menus={docTag.dropdown} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            <Form.Item label="Start date">
              {getFieldDecorator('start_date', {
                initialValue: timer.toDatetime(doc.start_date),
                rules: [{ required, message: 'Please fill create date!' }],
              })(<DatePicker showTime />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="Expired date">
              {getFieldDecorator('end_date', {
                initialValue: timer.toDatetime(doc.end_date),
                rules: [{ required, message: 'Please fill expire date!' }],
              })(<DatePicker showTime />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            <Form.Item label="Display minute">
              {getFieldDecorator('display_mins', {
                initialValue: doc.display_mins || 30,
                rules: [
                  { required, message: 'Please fill display minute!' },
                  { type: 'number', min: 0, max: 59 },
                ],
              })(<InputInt text="minutes" min={0} max={59} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="Display hour">
              {getFieldDecorator('display_hours', {
                initialValue: doc.display_hours,
                rules: [
                  { required, message: 'Please fill display hour!' },
                  { type: 'number', min: 0, max: 23 },
                ],
              })(<InputInt text="hours" min={0} max={23} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={2}>
            <Form.Item label="Display day">
              {getFieldDecorator('display_days', {
                initialValue: doc.display_days,
                rules: [
                  { required, message: 'Please fill display day!' },
                  { type: 'number', min: 0, max: 30 },
                ],
              })(<InputInt text="days" min={0} max={30} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Text Button by TH">
              {getFieldDecorator('text_button_th', {
                initialValue: doc.text_button_th,
                rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
              })(<Input text="text button" maxLength={40} />)}
            </Form.Item>
          </Col>

          <Col span={10} offset={1}>
            <Form.Item label="Text Button by EN">
              {getFieldDecorator('text_button_en', {
                initialValue: doc.text_button_en,
                rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
              })(<Input text="text button" maxLength={40} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            {console.log('timer.toTime(doc.start_active_time)', timer.toTime(doc.start_active_time))}
            <Form.Item label="Start active time">
              {getFieldDecorator('start_active_time', {
                initialValue: doc.start_active_time ? moment(doc.start_active_time, "HH:mm:ss") : undefined,
                // rules: [{ required, message: 'Please fill create date!' }],
              })(<TimePicker format="HH:mm:ss" minuteStep={1} secondStep={1} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="End active time">
              {getFieldDecorator('end_active_time', {
                initialValue: doc.end_active_time ? moment(doc.end_active_time, "HH:mm:ss") : undefined,
                rules: [{ required: doc.start_active_time, message: 'Please fill end of active time!' }],
              })(<TimePicker format="HH:mm:ss" minuteStep={1} secondStep={1} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Out of active time range button TH">
              {getFieldDecorator('text_out_of_time_error_button_th', {
                initialValue: doc.text_out_of_time_error_button_th,
                rules: [{ required: doc.start_active_time && doc.end_active_time, message: 'Please fill text button Out of active time rang TH!' }, { max: 40 }],
              })(<Input text="text button" maxLength={40} />)}
            </Form.Item>
          </Col>

          <Col span={10} offset={1}>
            <Form.Item label="Out of active time range button EN">
              {getFieldDecorator('text_out_of_time_error_button_en', {
                initialValue: doc.text_out_of_time_error_button_en,
                rules: [{ required: doc.start_active_time && doc.end_active_time, message: 'Please fill text button Out of active time rang EN!' }, { max: 40 }],
              })(<Input text="text button" maxLength={40} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Terms and conditions by TH">
              {getFieldDecorator('term_th', {
                initialValue: doc.term_th,
                rules: [{ required, message: 'Please fill terms and conditions TH!' }],
              })(<TextArea rows={15} />)}
              {/* {getFieldDecorator('term_th', {
                initialValue: doc.term_th,
                rules: [{ required, message: 'Please fill terms and conditions TH!' }],
              })(
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                />
              )} */}
            </Form.Item>
          </Col>

          <Col span={10} offset={1}>
            <Form.Item label="Terms and conditions by EN">
              {getFieldDecorator('term_en', {
                initialValue: doc.term_en,
                rules: [{ required, message: 'Please fill terms and conditions EN!' }],
              })(<TextArea rows={15} />)}
            </Form.Item>
          </Col>
        </Row>

        <Line />
        
        <Images onSave={this.onSave} />
        {/* --------------------------------------------- */}
        {/* ------------------- IMAGE ------------------- */}
        {/* --------------------------------------------- */}

        {/* <Line />
        <Row>
          <Col span={11}>
            <h2>
              <span style={{ color: 'red' }}>*</span> Reward Home Page
            </h2>
            <Form.Item>
              {getFieldDecorator('image_home', {
                initialValue: doc.image_home,
                rules: [{ required, message: 'Please upload reward home!' }],
              })(<UploadImg name="home" />)}
            </Form.Item>
            <p>Image size must be 630x495px MIN</p>
          </Col>

          <Col span={11} offset={1}>
            <h2>
              <span style={{ color: 'red' }}>*</span> Reward list
            </h2>
            <Form.Item>
              {getFieldDecorator('image_list', {
                initialValue: doc.image_list,
                rules: [{ required, message: 'Please upload reward list!' }],
              })(<UploadImg name="list" />)}
            </Form.Item>
            <p>Image size must be 1035x390px MIN</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={11}>
            <h2>
              <span style={{ color: 'red' }}>*</span> Reward Code
            </h2>
            <Form.Item>
              {getFieldDecorator('image_redeem', {
                initialValue: doc.image_redeem,
                rules: [{ required: true, message: 'Please upload image code!' }],
              })(<UploadImg name="redeem" />)}
            </Form.Item>
            <p>Image size must be 945x357px MIN</p>
          </Col>

          <Col span={11} offset={1}>
            <h2>
              <span style={{ color: 'red' }}>*</span> Reward Detail/Share/Redeemed
            </h2>
            <Form.Item>
              {getFieldDecorator('image_detail', {
                initialValue: doc.image_detail,
                rules: [{ required, message: 'Please upload reward detail/share/redeemed!' }],
              })(<UploadImg name="detail" />)}
            </Form.Item>
            <p>Image size must be 1125x1125px MIN</p>
          </Col>
        </Row> */}
        <br />
        <br />

        <SaveSection onClick={this.onSave} />
      </Form>
    );
  }
}

const onFieldsChange = (props, changedFields, allFields) => {
  const doc = props.reward.toJS().detail;
  const keys = Object.keys(changedFields);
  for (const name of keys) {
    const item = changedFields[name];
    if (name === 'tags') {
      let list = [];

      if (item.value) list = [{ tag_code: item.value }];
      doc[name] = list;
    } else {
      doc[name] = item.value;
    }
  }

  props.reward.setDoc(doc);
};

const Line = styled.hr`
  color: #888989;
  margin: 25px 5px;
`;

const Information = Form.create({ onFieldsChange })(WappedInformation);
export default inject('tag', 'reward')(observer(Information));
