import React, { Component } from 'react';
import { DatePicker as Picker } from 'antd';

export default class DatePicker extends Component {
  onChange = (val) => {
    if (val === null) val = undefined;

    const { name, onChange } = this.props;
    if (onChange) {
      onChange(val, name);
    }
  };

  render() {
    const { disabled = false, value, allowClear = false, showTime = false, readOnly = false } = this.props;

    let timeOption;
    if (showTime) {
      timeOption = {
        minuteStep: 5,
        secondStep: 5,
      };
    }

    let css = '';
    if (readOnly) {
      css = 'picker-readonly';
    }
    return (
      <Picker
        disabled={disabled || readOnly}
        allowClear={allowClear}
        showTime={timeOption}
        minuteStep={5}
        className={css}
        style={{ width: '100%' }}
        value={value}
        onChange={this.onChange}
      />
    );
  }
}
