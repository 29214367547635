import React, { Component } from 'react';
import { Select } from 'antd';

export default class CheckList extends Component {
  onSelected = async (selected) => {
    const { onChange, name } = this.props;
    if (onChange) onChange(selected, name);
  };

  render() {
    const { menus = [], value = [], placeHolder = 'Please Select', readOnly = false, disabled = false } = this.props;

    const choice = [];
    for (const item of menus) {
      choice.push(<Select.Option key={item.value}>{item.name}</Select.Option>);
    }

    let css = '';
    if (readOnly) {
      css = 'select-readonly';
    }

    return (
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        disabled={disabled || readOnly}
        className={css}
        placeholder={placeHolder}
        value={value}
        allowClear
        showArrow
        onChange={this.onSelected}
      >
        {choice}
      </Select>
    );
  }
}
