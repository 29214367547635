import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'antd';

import styled from 'styled-components';
import { DisplayStatus, RowItem, IntCol, DateCol } from '../../../components/common';

import { BadgeMap } from '../../../definition/mapping';
import { helper } from '../../../utils/helper';
import Colors from '../../../assets/Colors';

export default class RewardRow extends Component {
  render() {
    const { index, item } = this.props;
    const link = `/office/reward/${item.reward_id}/edit`;
    return (
      <RowItem style={css.row} index={index}>
        <ImgStyle span={3}>
          <img src={helper.getUrl(item.image_home)} alt="" />
        </ImgStyle>
        <ColStyle span={4}>{item.name_en}</ColStyle>
        <ColStyle span={3}>{item.reward_cat}</ColStyle>
        <ColStyle span={2}>
          <IntCol value={item.price} />
        </ColStyle>
        <ColStyle span={2}>
          <IntCol value={BadgeMap[item.user_level]} />
        </ColStyle>
        <ColStyle span={2}>
          <DisplayStatus status={item.status} />
        </ColStyle>
        <ColStyle span={3}>
          <DateCol value={item.start_date} />
        </ColStyle>
        <ColStyle span={3}>
          <DateCol value={item.end_date} />
        </ColStyle>
        <IconStyle span={2} style={css.link}>
          <Link to={link}>
            <i className="far fa-edit" />
          </Link>
        </IconStyle>
      </RowItem>
    );
  }
}

const ColStyle = styled(Col)`
  height: 70px;
  border-right: 1px solid ${Colors.borderTable};
  padding: 16px 14px 16px 16px;
  font-size: 12px;
  overflow: hidden;
`;

const ImgStyle = styled(ColStyle)`
  padding: 0px;
  img {
    padding: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const IconStyle = styled(ColStyle)`
  text-align: center;
  i {
    color: #686666;
  }
`;

const css = {
  row: {
    borderBottom: `1px solid ${Colors.borderTable}`,
    fontSize: '14px',
    overflow: 'hidden',
  },
};
