import React, { Component } from 'react';

import ConfigBase from './ImageBase';

export default class ConfigRedeemType extends Component {
  render() {
    return (
      <ConfigBase
        title="HOW TO REDEEM CONFIGURATION"
        type="redeem"
        menus={menus}
        dividerText="Upload How to redeem image (750 x (Anything) pixels) with .JPG or .PNG "
      />
    );
  }
}

const menus = [{ name: 'Redeem TH', value: 'th' }, { name: 'Redeem ENG', value: 'eng' }];
