import { config } from '../config';

export class Helper {
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isNull(val) {
    return val === null || val === undefined;
  }

  notNull(val) {
    return val !== null && val !== undefined;
  }

  isValue(val) {
    return val !== null && val !== undefined && val !== '';
  }

  notValue(val) {
    return val === null || val === undefined || val === '';
  }

  toPercent(a, b) {
    if (a === 0 || b === 0) return 0;

    return a / b;
  }

  random({
    len,
    prefix = '',
    postfix = '',
    character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  }) {
    const need = len - (prefix.length + postfix.length);
    const max = character.length;
    if (need < 0) return;
    if (max === 0) return;
    let resp = '';

    for (let i = 0; i < need; i++) {
      resp += character.charAt(Math.floor(Math.random() * max));
    }
    return `${prefix}${resp}${postfix}`;
  }

  getUrl(val) {
    if (this.isValue(val)) {
      const prefix = val.substring(0, 4);
      if (prefix === 'http' || prefix === 'data') return val;

      return `${config.upload.url}/${val}`;
    }
    return undefined;
  }

  toCSV({ list = [], key = [], header = [], subname }) {
    const rows = this.toRowCSV({ list, key, subname });
    const blob = this.toCSVByRow({ rows, header });
    return blob;
  }

  toCSVByRow({ rows = [], header = [] }) {
    const BOM = '\uFEFF';
    const result = rows.reduce((init, name) => `${init}\n${name.slice(0, -1)}`, header);
    const csvData = `${BOM}${result}`;
    const blob = new Blob([csvData]);
    return blob;
  }

  toRowCSV({ list = [], key = [], subname }) {
    const result = list.map((item) => {
      return key.reduce((init, name) => {
        let tmp = item[name];
        if (tmp === undefined && item[subname]) {
          tmp = item[subname][name];
        }
        const txt = tmp ? `${tmp}`.replace(/,/g, '') : '';
        return `${init + txt},`;
      }, '');
    });

    return result;
  }

  getBulkLoop = (count, size) => {
    const loop = Math.ceil(count / size);
    return loop;
  };

  hexToRgbA = (hex, opacity) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }
    throw new Error('Bad Hex');
  };

  getLabelForFwdCustomerOrLead = (isFwd) => (Number(isFwd) === 1 ? 'FWD Customer' : 'Lead');
}

export const helper = new Helper();
export default helper;
