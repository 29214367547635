import React from 'react';
import styled from 'styled-components';

const CUSTOMER_TYPE_LEAD = 'Lead';

const CustomerType = ({ title }) => {
  const color = (type) => {
    if (type === CUSTOMER_TYPE_LEAD) {
      return '#444';
    }

    return '#eb7c1c';
  };

  return <StyledTitle color={color(title)}>{title}</StyledTitle>;
};

const StyledTitle = styled.span`
  color: ${(props) => props.color};
  padding: 5px;
`;

export default CustomerType;
