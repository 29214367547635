import { format } from './format';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const getQueryStringAsObject = ({
  start,
  end,
  badge,
  status,
  type,
  name,
  surname,
  email,
  phone,
  citizenId,
  uuid,
  maxId,
  personalize,
  userProvince,
  userBirthday,
  searchSurveyFields,
  surveyFields,
}) => {
  const data = {
    start: start ? start.format(DATE_FORMAT) : '',
    end: end ? end.format(DATE_FORMAT) : '',
    name,
    surname,
    email,
    phone,
    citizenId,
    uuid,
    maxId,
    badge: badge.length !== 0 ? badge.join() : '',
    type: type !== 'all' ? type : '',
    status: status !== 'all' ? status : '',
    personalize: personalize !== 'all' ? personalize : '',
    userProvince: userProvince !== 'all' ? userProvince : '',
    userBirthday: userBirthday ? userBirthday.format(DATE_FORMAT) : '',
    searchSurveyFields: searchSurveyFields || '',
  };

  if (searchSurveyFields.toLowerCase() === 'yes') {
    Object.entries(surveyFields).forEach((field) => {
      const [key, value] = field;
      if (value && value !== 'all') {
        if (key === 'birthday' && typeof value === 'object') {
          data.surveyBirthday = value.format(DATE_FORMAT);
        } else {
          data[`survey${format.capitalizeFirstLetter(key)}`] = value;
        }
      }
    });
  }

  return data;
};

const UserFilterUtilities = {
  getQueryStringAsObject,
};

export default UserFilterUtilities;
