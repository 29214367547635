import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import Colors from '../../assets/Colors';

const DraggableTableCol = ({ children, ...props }) => {
  return <ColStyle {...props}>{children}</ColStyle>;
};

const ColStyle = styled(Col)`
  height: auto;
  border-right: 1px solid ${Colors.borderTable};
  padding: 16px 16px 16px 16px;
  font-size: 14px;
`;

export default DraggableTableCol;
