/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Checkbox } from 'antd';
import './css/TagSelector.css';

const options = [
  { label: 'Tier 000', value: '000' },
  { label: 'Tier 100', value: '100' },
  { label: 'Tier 200', value: '200' },
  { label: 'Tier 300', value: '300' },
  { label: 'Tier 400', value: '400' },
  { label: 'Tier 500', value: '500' },
  { label: 'Tier 600', value: '600' },
  { label: 'Tier 700', value: '700' },
];

function SpecificTierCheckBox(props) {
  return (
    <div className="tag-selector">
      <Checkbox.Group options={props.options || options} {...props} />
    </div>
  );
}

export default SpecificTierCheckBox;
