import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Descriptions } from 'antd';
import styled from 'styled-components';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';

const ViewStaff = (props) => {
  const { id } = props.match.params;
  const [currentStaff, setCurrentStaff] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCurrentStaffRecord = (backofficeUserId) => {
      props.staff
        .fetchStaffUserProfile(backofficeUserId)
        .then(() => {
          const user = props.staff.currentStaffRecord;
          setCurrentStaff(user);
        })
        .catch((e) => setError(e.message));
    };

    fetchCurrentStaffRecord(id);
  }, [id, props.staff]);

  return (
    <HeaderMenu title="Staff List">
      <Page style={css.page}>
        {error && <Alert type="error" message={error} />}
        <Loading loading={props.staff.loading} />
        {!props.staff.loading && (
          <StyledDescriptions title={`Information - ${currentStaff.firstname} ${currentStaff.lastname}`.toUpperCase()}>
            <Descriptions.Item label="Firstname">{currentStaff.firstname}</Descriptions.Item>
            <Descriptions.Item label="Lastname">{currentStaff.lastname}</Descriptions.Item>
            <Descriptions.Item label="Username">{currentStaff.username}</Descriptions.Item>
            <Descriptions.Item label="Password">-</Descriptions.Item>
            <Descriptions.Item label="Telephone">{currentStaff.telephone}</Descriptions.Item>
            <Descriptions.Item label="Email">{currentStaff.email}</Descriptions.Item>
            <Descriptions.Item label="User role">{currentStaff.role_name}</Descriptions.Item>
          </StyledDescriptions>
        )}
      </Page>
    </HeaderMenu>
  );
};

const StyledDescriptions = styled(Descriptions)``;

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('staff')(observer(ViewStaff));
