import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';

const INIT_STATE = {
  list: [],
  errors: [],
  loading: true,
  recentUpdate: undefined,
};

class PushNotificationTemplate extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  async fetchNotificationTemplates() {
    const url = `${config.api.max}/v1/office/configs/push-notification-template`;

    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }

    const { data } = res.body;
    this.list = data;
  }

  async updateNotificationTemplate(data, id) {
    this.loading = true;

    const url = `${config.api.max}/v1/office/configs/push-notification-template/${id}`;

    const headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
    };

    const response = await http.post(url, { json: data, token: true, undefined, headers });

    if (Number(response.body.statusCode) !== 200) {
      if (response.body.errors && response.body.errors.length) {
        const errorMessages = response.body.errors.map((e) => `${e.value}: ${e.message}`);

        this.errors = errorMessages;
      } else {
        this.errors = [];
      }
    }

    this.recentUpdate = response.body.data;
    this.loading = false;
  }
}

const pushNotificationTemplate = new PushNotificationTemplate();

export default pushNotificationTemplate;
