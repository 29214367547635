import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';

const { Option } = Select;
class AppPageParam extends React.Component {
  componentDidMount() {
    if (this.props.type === 'reward') {
      this.props.appPageParam.getRewardList();
    } else {
      this.props.appPageParam.getChallengeList();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      if (this.props.type === 'reward') {
        this.props.appPageParam.getRewardList();
      } else {
        this.props.appPageParam.getChallengeList();
      }
    }
  }

  render() {
    const { list } = this.props.appPageParam.toJS();
    const contens = list.map((item) => {
      return (
        <Option key={item.id} value={`${item.id}`}>
          {item.name}
        </Option>
      );
    });
    return (
      <Select {...this.props} placeholder="Please select a page param">
        {contens}
      </Select>
    );
  }
}

export default inject('appPageParam')(observer(AppPageParam));
