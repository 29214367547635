const personalizeOptions = [
  { name: 'All', value: 'all' },
  { name: 'Default', value: 'default' },
  { name: 'Steps', value: 'steps' },
  { name: 'Running', value: 'running' },
  { name: 'Cycling', value: 'cycling' },
  { name: 'Swimming', value: 'swimming' },
  { name: 'Traveling Check-in', value: 'traveling_check_in' },
  { name: 'Eating Check-in', value: 'eating_check_in' },
];

export default personalizeOptions;
