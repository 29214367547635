import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Modal, Table } from 'antd';

import styled from 'styled-components';
import { DisplayStatus, IntCol, DateCol } from '../../../components/common';
import { BadgeMap } from '../../../definition/mapping';
import { history } from '../../../utils/history';
import { notify } from '../../../utils/notify';
import { format } from '../../../utils/format';
import { helper } from '../../../utils/helper';
import { can } from '../../../utils/acl';

import RewardFilter from './RewardFilter';
import RewardTab from '../RewardTab';

const { confirm } = Modal;

export class RewardList extends Component {
  state = {
    loading: false,
  };

  params = {};

  columns = [
    {
      title: '',
      dataIndex: 'image_home',
      key: 'image_home',
      render: (image_home, record) => (
        <Link to={`reward/${record.reward_id}/edit`}>
          <img style={css.img} shape="square" icon="picture" src={helper.getUrl(image_home)} alt="" />
        </Link>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name_en',
      key: 'name_en',
      width: 180,
      render: (name_en, record) => (
        <Link style={{ color: '#686666', flexWrap: 'break' }} to={`reward/${record.reward_id}/edit`}>
          {name_en}
        </Link>
      ),
    },
    {
      title: 'CATEGORY',
      dataIndex: 'reward_cat',
      key: 'reward_cat',
      render: (reward_cat) => <span>{reward_cat}</span>,
    },
    {
      title: 'BADGE',
      dataIndex: 'user_level',
      key: 'user_level',
      render: (user_level) => <IntCol value={BadgeMap[user_level]} />,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <DisplayStatus status={status} />,
    },
    {
      title: 'CREATED',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      render: (createdAt) => <DateCol value={createdAt} />,
    },
    {
      title: 'START',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '120px',
      render: (start_date) => <DateCol value={start_date} />,
    },
    {
      title: 'EXPIRE',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '120px',
      render: (end_date) => <DateCol value={end_date} />,
    },
    {
      title: 'REDEEM',
      dataIndex: 'redeem.assigned',
      key: 'redeem.assigned',
      render: (redeemAssigned) => <IntCol value={redeemAssigned} />,
    },
    {
      title: 'REMAINING',
      dataIndex: 'redeem.available',
      key: 'redeem.available',
      render: (redeemAvailable) => <IntCol value={redeemAvailable} />,
    },
    {
      title: 'TOTAL',
      dataIndex: 'redeem',
      key: 'redeem',
      render: (redeem) => <IntCol value={this.getTotal(redeem)} />,
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      render: (action, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Link
            to={`reward/${record.reward_id}/userList`}
            disabled={record.reward_cat !== 'physical'}
            style={{ color: record.reward_cat !== 'physical' ? '#eee' : '#E87722', padding: 5 }}
          >
            <i className="fas fa-users" />
          </Link>
          <Button style={css.btnDelete} onClick={() => this.onShowDeleteConfirm(record.name_en, record.reward_id)}>
            <i className="far fa-trash-alt" />
          </Button>
        </div>
      ),
    },
  ];

  getTotal = (redeem) => {
    if (!redeem) {
      return 0;
    }

    const { assigned = 0, available = 0, inactive = 0 } = redeem;
    return assigned + available + inactive;
  };

  onCreate = () => {
    history.push('/office/reward/create');
  };

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      params.limit = this.params.limit;
      await this.props.reward.getList(params);
    } catch (e) {
      notify.error({ title: 'Load Reward Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onFilter = async (params = {}) => {
    params.offset = 1;
    this.params.limit = 10;
    this.params = params;
    this.onLoad(this.params);
  };

  onShowSizeChange = (current, size) => {
    this.params.offset = current;
    this.params.limit = size;

    this.onLoad(this.params);
  };

  onPagination = (offset, limit) => {
    this.params.offset = offset;
    this.params.limit = limit;
    this.onLoad(this.params);
  };

  onShowDeleteConfirm = (name, id) => {
    const modalConfirm = confirm({
      title: 'CONFIRM DELETE',
      content: `Are you sure delete ${name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        modalConfirm.destroy();
        try {
          this.setState({ loading: true });
          await this.props.reward.removeReward(id);
          await this.onLoad(this.params);
        } catch (e) {
          notify.error({ title: 'Delete Reward Fail', message: e.message });
        }

        this.setState({ loading: false });
      },
    });
  };

  render() {
    const { loading } = this.state;
    const { userRole, reward } = this.props;
    const { page, list } = reward.all;
    const pagination = {
      current: this.params.offset || 1,
      position: 'both',
      total: page.total,
      pageSizeOptions: ['10', '100', '1000'],
      onChange: this.onPagination,
      showSizeChanger: true,
      onShowSizeChange: this.onShowSizeChange,
      showQuickJumper: true,
      showTotal: (total) => `Total ${format.toDigi(total)}`,
      hideOnSinglePage: false,
    };

    return (
      <RewardTab defaultIndex={0}>
        <RewardFilter onFind={this.onFilter} />
        <TableStyle
          bordered
          loading={loading}
          dataSource={list}
          columns={this.columns}
          rowKey={(record) => record.reward_id}
          pagination={pagination}
          scroll={{ x: 'max-content' }}
        />
        {can('add_reward', userRole) && (
          <Button style={css.btn} type="primary" shape="circle" onClick={this.onCreate}>
            <i className="fas fa-plus" />
          </Button>
        )}
      </RewardTab>
    );
  }
}

const css = {
  center: { textAlign: 'center' },
  img: {
    width: '80px',
    height: 'auto',
    padding: '0px 5px',
    borderRadius: '8px',
  },
  btn: {
    width: '64px',
    height: '64px',
    bottom: '20px',
    right: '20px',
    fontSize: '32px',
    zIndex: '100',
    position: 'fixed',
  },
  btnDelete: {
    border: 0,
    backgroundColor: 'transparent',
  },
};

const TableStyle = styled(Table)`
  .ant-table {
    font-size: 12px;
  }
`;

export default inject('reward')(observer(RewardList));
