function isAdjustPointValueValid({ userId, amount, titleEn, titleTh, descEn, descTh }) {
  if (userId === '') {
    return 'user not found';
  }
  if (titleEn === '' || titleTh === '' || descEn === '' || descTh === '' || amount === null) {
    return 'Please fill out the form';
  }
  return '';
}

export default isAdjustPointValueValid;
