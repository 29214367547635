import React from 'react';
import { Alert } from 'antd';
import { inject, observer } from 'mobx-react';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';
import { StaffSettingsForm } from '../../components/forms';

const StaffSettingsPage = (props) => {
  const error = '';

  return (
    <HeaderMenu title="Staff Settings Page">
      <Loading loading={props.member.loading} />
      <Page style={css.page}>
        {error && <Alert type="error" message={error} />}
        <StaffSettingsForm />
      </Page>
    </HeaderMenu>
  );
};

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('member')(observer(StaffSettingsPage));
