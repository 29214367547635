import React, { Component } from 'react';
import styled from 'styled-components';

export default class Page extends Component {
  render() {
    const { style, children } = this.props;
    return <Section style={style}>{children}</Section>;
  }
}

const Section = styled.div`
  padding: 20px 50px;
  background-color: #fff;
`;
