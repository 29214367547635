import React from 'react';
import { Tabs } from 'antd';

import styled from 'styled-components';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page } from '../../components/common';
import Colors from '../../assets/Colors';

import CommunityReport from './CommunityReport';
import CommunityTopPost from './CommunityTopPost';

const { TabPane } = Tabs;

const name = 'COMMUNITY';
const Community = () => {
  return (
    <HeaderMenu title={name}>
      <Page style={pageCss}>
        <TabsStyle defaultActiveKey="1" animated={false}>
          <TabPane tab="REPORT POST" key="1">
            <CommunityReport />
          </TabPane>
          <TabPane tab="TOP POST" key="2">
            <CommunityTopPost />
          </TabPane>
        </TabsStyle>
      </Page>
    </HeaderMenu>
  );
};

const TabsStyle = styled(Tabs)`
  padding-top: 15px;

  .ant-tabs-tab,
  .ant-tabs-tab:hover {
    font-size: 14px;
    color: black;
    padding: 13px 25px 13px 25px;
    transition: none;
  }

  .ant-tabs-tab-active,
  .ant-tabs-tab-active:hover {
    font-weight: 800;
    color: ${Colors.orange4};
  }

  .ant-tabs-ink-bar {
    background-color: ${Colors.orange4};
  }
`;

const pageCss = {
  backgroundColor: 'white',
  borderRadius: '5px',
};

export default Community;
