import moment from 'moment';
import { cloneDeep } from 'lodash';

import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { helper } from '../utils/helper';
import controller from '../controller';

const LIMIT = config.limit.page;
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const origin = {
  coupon_id: undefined,
  max_qty: 1,
  count_daily: 0,
  counter: 0,
  max_daily: 1,
  max_per_person: 1,
  max_per_daily_person: 1,
  in_app_message: '',
  name_en: '',
  name_th: '',
  code: '',
  price: 1,
  start_date: moment().startOf('day'),
  end_date: moment()
    .add(7, 'days')
    .startOf('day'),
  status: 'draft',
  user_level: 1,
};

const CSV = {
  header: ['Status', 'First Name', 'Last Name', 'Phone', 'Email', 'Badge', 'Code', 'Point'],
  key: ['status', 'tmp_fname', 'tmp_lname', 'tmp_phone', 'tmp_email', 'level', 'code', 'price'],
};

export class Coupon extends BaseStore {
  constructor() {
    super();
    this.observable({
      all: {
        page: { offset: 1, total: 0, limit: LIMIT },
        list: [],
      },
      detail: cloneDeep(origin),
      original: cloneDeep(origin),
      uuid: '',
      progress: {
        status: '',
        total: 100,
        index: 0,
      },
    });
  }

  async getList(params = {}) {
    const { offset = 1, limit = LIMIT, name, start, end, status = 'all', time_type = 'all' } = params;
    let query = '';

    if (status !== 'all') {
      query = `${query}&status=${status}`;
    }
    if (time_type !== 'all') {
      query = `${query}&timeType=${time_type}`;
    }
    if (start) {
      query = `${query}&start=${start.format(DATE_FORMAT)}`;
    }
    if (end) {
      query = `${query}&end=${end.format(DATE_FORMAT)}`;
    }
    if (name) {
      query = `${query}&name=${name}`;
    }

    const url = `${config.api.max}/v1/office/coupon?offset=${offset - 1}&limit=${limit}${query}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { list, total } = res.body;
    this.all = {
      page: { offset, total, limit },
      list,
    };
  }

  async resetDoc() {
    this.detail = cloneDeep(origin);
    this.original = cloneDeep(origin);
    this.uuid = await helper.random({ len: 8 });
  }

  setProgress(status = '', total = 100, index = 0) {
    this.progress = {
      status,
      total,
      index,
    };
  }

  async getDoc(id) {
    await this.resetDoc();

    const url = `${config.api.max}/v1/office/coupon/${id}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const data = res.body;
    this.detail = data;
    this.original = cloneDeep(data);
  }

  /* eslint-disable */

  async saveDoc() {
    let doc = this.toJS();
    let data = doc.detail;
    let original = doc.original;
    let url;
    let res;

    if (data.coupon_id) {
      if (original.status === 'draft') {
        url = `${config.api.max}/v1/office/coupon/${data.coupon_id}`;
        res = await http.put(url, { json: data, token: true });
      } else {
        url = `${config.api.max}/v1/office/coupon/status/${data.coupon_id}/${data.status}`;
        res = await http.put(url, { token: true });
      }
    } else {
      url = `${config.api.max}/v1/office/coupon`;
      res = await http.post(url, { json: data, token: true });
    }

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }

    let { id } = res.body;
    if (id) {
      this.detail.coupon_id = id;
      data.coupon_id = id;
    }

    this.original = cloneDeep(this.toJS().detail);
  }

  /* eslint-enable */

  setDoc(val) {
    this.detail = val;
  }

  async generateCode({ type, data }) {
    const doc = this.toJS();
    const id = doc.detail.coupon_id;
    const total = doc.original.max_qty;
    data.price = doc.original.price;

    const url = `${config.api.max}/v1/office/coupon/generate/${id}/${type}`;
    const params = {
      url,
      total,
      data,
    };

    let result;
    if (type === 'import') {
      result = await controller.code.import(params, { notify: this });
    } else {
      result = await controller.code.generate(params, { notify: this });
    }

    return result;
  }

  async exportCode() {
    const doc = this.toJS();
    const id = doc.detail.coupon_id;
    const params = { type: 'coupon', id };
    const result = await controller.code.export(params, { notify: this, CSV });
    return result;
  }

  cancel() {
    controller.code.cancel();
  }
}

export default new Coupon();
