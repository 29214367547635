import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, DatePicker } from 'antd';

import moment from 'moment';
import { Filter, GroupItem, DropdownList } from '../forms';
import { Cols } from '../common';
import { FindBtn } from '../button';
import selectTime from '../../utils/SelectTime';

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getUrlNameFromUrlParam({ type: 'This Month' });
  }

  componentDidMount() {
    if (!this.props.dontLoadAtStart) {
      this.props.onFind(this.state);
    }
  }

  onStartDate = (val) => {
    this.setState({ start: val, type: 'Custom Date' });
    this.setUrlName(val.format('YYYY-MM-DD'), this.state.end.format('YYYY-MM-DD'));
  };

  onEndDate = (val) => {
    this.setState({ end: val, type: 'Custom Date' });
    this.setUrlName(this.state.start.format('YYYY-MM-DD'), val.format('YYYY-MM-DD'));
  };

  onChange = (val, name) => {
    if (name === 'type') {
      this.setFilter({ type: val });
    } else {
      const { state } = this;
      state[name] = val;
      this.setState(state);
    }
  };

  getDate = () => {
    return this.state;
  };

  getUrlNameFromUrlParam = ({ type = 'This Month' }) => {
    // TODO: use Sam Suggestion make thing easier, https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    const { href } = window.location;
    const startIndex = href.indexOf('?start=');
    if (startIndex !== -1) {
      const date = href.substr(href.indexOf('?start=') + 7, href.length);
      const endIndex = date.indexOf('&end=');
      if (endIndex !== -1) {
        const start = date.substr(0, endIndex);
        const end = date.substr(endIndex + 5, date.length);
        const newState = { start: moment(start, 'YYYY-MM-DD'), end: moment(end, 'YYYY-MM-DD'), type: 'Custom Date' };
        return newState;
      }
    }
    const { start, end } = selectTime.getValue({ type });
    return { start, end, type };
  };

  setUrlName = (start, end) => {
    window.history.replaceState('', '', `${window.location.pathname}?start=${start}&end=${end}`);
  };

  setFilter = ({ type }) => {
    const { start, end } = selectTime.getValue({ type });
    this.setState({ start, end, type });
    this.setUrlName(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
  };

  onFind = (evt) => {
    if (evt) evt.preventDefault();

    const { onFind } = this.props;
    if (onFind && typeof onFind === 'function') onFind(this.state);
  };

  render() {
    const { start, end, type } = this.state;
    return (
      <Filter style={filterCss}>
        <FilterPage>
          <Row type="flex">
            <Cols>
              <GroupItem label="Start Date">
                <DatePicker allowClear={false} style={itemCss} value={start} onChange={this.onStartDate} />
              </GroupItem>
            </Cols>
            <Cols>
              <GroupItem label="End Date">
                <DatePicker allowClear={false} style={itemCss} value={end} onChange={this.onEndDate} />
              </GroupItem>
            </Cols>
            <Cols>
              <GroupItem label="Range Type">
                <DropdownList
                  style={{ width: 130 }}
                  name="type"
                  menus={dateMenu}
                  value={type}
                  allowClear={false}
                  onChange={this.onChange}
                />
              </GroupItem>
            </Cols>

            {this.props.moreItem}

            <Cols>
              <GroupItem>
                <FindBtn onClick={this.onFind} style={{}} />
              </GroupItem>
            </Cols>
          </Row>
        </FilterPage>
      </Filter>
    );
  }
}

const itemCss = {
  width: '98%',
};

const filterCss = {
  border: '1px solid #e7e6e6',
  borderRadius: '5px',
};

const FilterPage = styled.div`
  padding: 10px;
`;

const dateMenu = selectTime.getMenu();
