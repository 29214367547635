import React from 'react';
import { inject, observer } from 'mobx-react';

import { Form, Input, Typography, Modal } from 'antd';
import notify from '../../../utils/notify';
import { Btn } from '../../../components/button';

class VerifyOtpForm extends React.Component {
  handleOnCancel = () => {
    this.props.form.resetFields();
    this.props.user.setShowVerifyOtpForm(false);
  };

  render() {
    const { getFieldDecorator, getFieldsError, validateFields } = this.props.form;
    const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);
    const refCode = this.props.user.otp ? this.props.user.otp.refCode : '';
    const formInput = (
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            const { otpCode } = await validateFields();
            await this.props.user.verifyOtpChnagePhoneNo(otpCode);
            this.props.user.resetOtpProcess();
            notify.success({
              title: 'Verify otp success',
            });
          } catch (error) {
            notify.error({
              title: 'Verify otp failed',
              message: error.message,
            });
          }
        }}
      >
        <Typography.Paragraph style={{ marginBottom: '0px' }}>OTP REF : {refCode}</Typography.Paragraph>
        <Form.Item style={{ marginBottom: '0px' }}>
          {getFieldDecorator('otpCode', {
            rules: [{ required: true, message: 'otp code is required' }],
          })(<Input placeholder="Please enter otp code" />)}
        </Form.Item>

        <Form.Item>
          <Btn text="SUBMIT" htmlType="submit" disabled={hasErrors(getFieldsError())} />
        </Form.Item>
      </Form>
    );
    return (
      <Modal
        title="CHANGE PHONE NO VERIFY:"
        visible={this.props.user.showVerifyOtpForm}
        onCancel={this.handleOnCancel}
        destroyOnClose
        footer={null}
      >
        {formInput}
      </Modal>
    );
  }
}

const WrappedVerifyOtpForm = Form.create({ name: 'verify_otp_form' })(observer(VerifyOtpForm));

export default inject('user')(WrappedVerifyOtpForm);
