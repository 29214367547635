import numeral from 'numeral';
import moment from 'moment';

export class Format {
  isNull(val) {
    if (val === undefined || val === null || val === '') return true;
    return false;
  }

  toDigi(val) {
    if (this.isNull(val) || isNaN(+val)) return '0';
    return numeral(val).format('0,0');
  }

  toPercent(val) {
    if (this.isNull(val) || isNaN(+val)) return '0%';
    return numeral(val).format('0.00%');
  }

  toMoney(val) {
    if (this.isNull(val) || isNaN(+val)) return '0';
    return numeral(val).format('0,0.00');
  }

  toText(val) {
    if (this.isNull(val)) return '-';
    return val;
  }

  toFullDT(val, emptyVal = '-') {
    if (this.isNull(val)) return emptyVal;
    return moment(val).format('DD-MM-YYYY HH:mm:ss');
  }

  toShortDay(val) {
    if (this.isNull(val)) return '-';
    if (typeof val === 'string') return val.slice(0, 3);
    return moment(val).format('DD');
  }

  toShortMonth(val) {
    if (this.isNull(val)) return '-';
    if (typeof val === 'string') return val.slice(0, 3);
    return moment(val).format('MM');
  }

  toDate(val) {
    if (this.isNull(val)) return '-';
    return moment(val).format('DD-MM-YYYY');
  }

  toTime(val) {
    if (this.isNull(val)) return '-';
    return moment(val).format('HH:mm:ss');
  }

  capitalizeFirstLetter = (str) => `${str[0].toUpperCase()}${str.substr(1, str.length)}`;
}

export const format = new Format();
export default format;
