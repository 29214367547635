import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, Loading, Progress } from '../../../components/common';
import { TabMenu } from '../../../components/forms';
import { notify } from '../../../utils/notify';

import Information from './Information';
import GenCode from './GenCode';

export class CouponDetail extends Component {
  state = {
    loading: false,
    processing: false,
  };

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    try {
      const { id } = this.props.match.params;
      this.setState({ loading: true });

      this.props.gen.resetConfig();
      if (id) {
        await this.props.coupon.getDoc(id);
      } else {
        await this.props.coupon.resetDoc();
      }
    } catch (e) {
      notify.error({ title: 'Load Coupon Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onChange = (item) => {
    this.setState({ selected: item.name });
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      await this.props.coupon.saveDoc();
      notify.success({ title: 'Save Coupon Success' });
    } catch (e) {
      notify.error({ title: 'Save Coupon Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onGenerate = async ({ type, data }) => {
    try {
      this.setState({ processing: true });
      const result = await this.props.coupon.generateCode({ type, data });
      if (result) {
        notify.success({ title: 'Generate Coupon Code Success' });
      }
    } catch (e) {
      notify.error({ title: 'Generate Coupon Code Fail', message: e.message });
    }

    this.setState({ processing: false });
  };

  onExport = async () => {
    try {
      this.setState({ processing: true });
      const blob = await this.props.coupon.exportCode();
      if (blob !== undefined) {
        const coupon = this.props.coupon.toJS().detail;
        const name = `coupon_${coupon.name_en || '_'}_${moment().format('YYYYMMDD')}.csv`;
        FileSaver.saveAs(blob, name);
        notify.success({ title: 'Export Coupon Code Success' });
      }
    } catch (e) {
      notify.error({ title: 'Export Coupon Code Fail', message: e.message });
    }

    this.setState({ processing: false });
  };

  onCancel = async () => {
    this.props.coupon.cancel();
  };

  render() {
    const { loading, processing, selected } = this.state;
    const coupon = this.props.coupon.toJS();
    const { detail: doc, progress } = coupon;
    let content;
    switch (selected) {
      case 'CODE GENERATOR':
        content = (
          <GenCode loading={loading} onSave={this.onSave} onGenerate={this.onGenerate} onExport={this.onExport} />
        );
        break;
      default:
        content = <Information onSave={this.onSave} loading={loading} />;
    }
    return (
      <HeaderMenu title={doc.coupon_id ? 'EDIT COUPON' : 'CREATE COUPON'}>
        <Loading loading={loading} />
        <Progress display={processing} index={progress.index} total={progress.total} onCancel={this.onCancel} />
        <Page style={css.page}>
          <TabMenu menus={menus} onChange={this.onChange} />
          <br />
          {content}
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

const menus = [
  {
    name: 'INFORMATION',
    params: {},
  },
  {
    name: 'CODE GENERATOR',
    params: {},
  },
];

export default inject('gen', 'coupon')(observer(CouponDetail));
