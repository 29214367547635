import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';

const INIT_STATE = {
  list: [],
  errors: [],
  loading: true,
  recentUpdate: undefined,
};

class SSOConfig extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  async fetchSSOConfigs() {
    const url = `${config.api.max}/v1/configs/ssoconfig`;

    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      console.error(res.body.message);
      return;
    }
    console.log(res)
    // const { data } = res.body;
    this.list = res.body;
  }

  async updateSSOConfigs(data) {
    this.loading = true;

    const url = `${config.api.max}/v1/configs/ssoconfig`;

    const headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
    };

    const response = await http.post(url, { json: data, token: true, undefined, headers });

    if (Number(response.body.statusCode) !== 200) {
      if (response.body.errors && response.body.errors.length) {
        const errorMessages = response.body.errors.map((e) => `${e.value}: ${e.message}`);

        this.errors = errorMessages;
      } else {
        this.errors = [];
      }
    }

    this.recentUpdate = response.body.data;
    this.loading = false;
  }
}

const SSOConfigInstance = new SSOConfig();

export default SSOConfigInstance;
