import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js';

import { Title } from '../../components/common';

function Graph({ doc, timeUnit, title }) {
  const ctx = useRef(null);
  const chart = useRef(null);

  useEffect(() => {
    ctx.current = document.getElementById('myChart').getContext('2d');
  }, []);

  useEffect(() => {
    if (ctx.current == null) {
      return;
    }

    const borderWidth = 2;
    const fill = false;
    const datasets = doc.data.map((item, index) => {
      const color = colors[index];
      return {
        type: 'line',
        label: item.name,
        fill,
        data: item.list,
        backgroundColor: color.background,
        borderColor: color.border,
        borderWidth,
        lineTension: 0.05,
      };
    });

    // Destroy previous chart instance before creating a new one, otherwise
    // If we don't do this then our previous record will overwrite the new data again
    // @see https://www.chartjs.org/docs/latest/developers/api.html#destroy
    if (chart.current != null) {
      chart.current.destroy();
    }

    Chart.defaults.global.responsiveAnimationDuration = 0;
    Chart.defaults.global.animation.duration = 0;
    chart.current = new Chart(ctx.current, {
      type: 'line',
      data: {
        labels: doc.labels,
        datasets,
      },
      options: {
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0, // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        legend: {
          display: true,
          position: 'top',
          onClick: null,
          onHover: null,
          labels: {
            boxWidth: 20,
            padding: 50,
          },
          fullWidth: true,
        },
        scales: {
          yAxes: [
            {
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                fontSize: 20,
                labelString: 'User Counts',
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                scaleLabel: { display: true, fontColor: '#ff0000' },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                minRotation: 45,
                maxRotation: 45,
              },
              scaleLabel: {
                display: true,
                fontSize: 20,
                labelString: 'Registration Date',
              },
              position: 'bottom',
              type: 'time',
              distribution: 'series',
              time: {
                displayFormats: {
                  day: 'DD MMM YY',
                  month: 'MMM YY',
                  hour: 'DD MMM YY HH:00',
                },
                stepSize: 1,
                // @see https://www.chartjs.org/docs/latest/axes/cartesian/time.html#time-units
                unit: timeUnit,
                minUnit: timeUnit,
              },
            },
          ],
        },
      },
    });
  }, [doc, timeUnit]);

  return (
    <div>
      <Title>{title || ''}</Title>
      <Canvas id="myChart" height="100%">
        <p>Canvas not supported</p>
      </Canvas>
    </div>
  );
}

export default Graph;

const colors = [
  { background: 'rgba(231, 62, 80, 0.5)', border: 'rgba(231, 62, 80, 0.5)' },
  { background: 'rgba(77, 104, 151, 0.5)', border: 'rgba(77, 104, 151, 0.5)' },
  { background: 'rgba(131, 113, 169, 0.5)', border: 'rgba(131, 113, 169, 0.5)' },
  { background: 'rgba(160, 81, 149, 0.5)', border: 'rgba(160, 81, 149, 0.5)' },
  { background: 'rgba(184, 114, 174, 0.5)', border: 'rgba(184, 114, 174, 0.5)' },
  { background: 'rgba(247, 152, 111, 0.5)', border: 'rgba(247, 152, 111, 0.5)' },
  { background: 'rgba(249, 190, 81, 0.5)', border: 'rgba(249, 190, 81, 0.5)' },
];

const Canvas = styled.canvas`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
  background-color: white;
`;
