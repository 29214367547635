/* eslint-disable no-nested-ternary */
import React from 'react';
import memoize from 'memoize-one';
import { Typography, Modal, Col, Spin, Row, Input, Table } from 'antd';

import format from '../../utils/format';
import getCommuImage from '../../utils/getCommuImage';

const { Text } = Typography;
const { Search } = Input;

class CommunityTopPostDetailModal extends React.Component {
  filter = memoize((list, filterText) =>
    list.filter((item) => `${item.user.firstName} ${item.user.lastName}`.toLowerCase().includes(filterText))
  );

  constructor() {
    super();
    this.state = {
      modal: false,
      filterText: '',
      columns: [
        {
          title: () => {
            return <Search placeholder="input search text" onSearch={this.search} style={{ width: 400 }} />;
          },
          key: 'userId',
          dataIndex: 'name',
          render: (_, v) => {
            return <span>{`${v.user.firstName} ${v.user.lastName}`}</span>;
          },
        },
      ],
    };
  }

  search = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };

  modalOpen = () => {
    this.setState({ modal: true });
  };

  modalClose = () => {
    this.props.resetPostDetail();
    this.setState({ modal: false });
  };

  render() {
    const {
      title: postTitle,
      postId,
      previewImages = [],
      displayName,
      likeCounter,
      likes = [],
      type,
      data,
    } = this.props.data;
    const filteredList = this.filter(likes, this.state.filterText);
    return (
      <Modal
        title={postId ? `POST ID = ${postId}` : ''}
        visible={this.state.modal}
        onCancel={this.modalClose}
        footer={null}
        width="90%"
      >
        {this.props.loading ? (
          <div style={css.loading}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div style={css.detailContainer}>
              <Row type="flex" justify="center">
                {type === 'challenge' ? (
                  <Col style={{ marginRight: 10 }}>
                    <img alt="" style={css.img} src={data.imageUrl}></img>
                  </Col>
                ) : previewImages.length ? (
                  previewImages.map((img) => (
                    <Col style={{ marginRight: 10 }}>
                      <img alt="" style={css.img} src={img}></img>
                    </Col>
                  ))
                ) : (
                  <Col style={{ marginRight: 10 }}>
                    <img alt="" style={css.img} src={getCommuImage(type)}></img>
                  </Col>
                )}
              </Row>

              <Row justify="center" type="flex" style={css.title}>
                <Text>{`"${postTitle}"`}</Text>
              </Row>
              <Row gutter={50} justify="center" type="flex">
                <Col className="gutter-row">
                  <Row>
                    <Text type="secondary">POST BY</Text>
                  </Row>
                  <Row>
                    <Text>{displayName}</Text>
                  </Row>
                </Col>
                <Col className="gutter-row">
                  <Row>
                    <Text type="secondary">NUMBER OF LIKE</Text>
                  </Row>
                  <Row>
                    <Text>{`${format.toDigi(likeCounter)} Likes`}</Text>
                  </Row>
                </Col>
              </Row>
            </div>

            <div style={css.likesContainer}>
              <Table
                rowKey={(record) => record.user.userId}
                indentSize={2}
                bordered
                style={css.table}
                columns={this.state.columns}
                dataSource={filteredList}
                onChange={this.onChangePage}
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        )}
      </Modal>
    );
  }
}

const css = {
  img: {
    height: 200,
  },
  loading: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailContainer: {
    borderBottom: '1px solid #e8e8e8',
    paddingBottom: 20,
    marginBottom: 20,
    paddingLeft: 10,
  },
  likesContainer: {
    paddingLeft: 10,
  },
  likeItem: {
    margin: '10px 0 10px 0',
  },

  table: {
    background: 'white',
    marginTop: 20,
  },
  content: {
    margin: '3px 0 20px 0',
  },
  title: {
    margin: '20px 0 20px 0',
  },
};

export default CommunityTopPostDetailModal;
