import React from 'react';
import { Button, Upload, Icon } from 'antd';
import styled from 'styled-components';
import XLSX from 'xlsx';
import notify from '../../utils/notify';

class ImportExportExcel extends React.Component {
  state = {
    fileList: [],
  };

  onRemove = () => {
    this.setState({ fileList: [] });
  };

  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;
  };

  handleImportExcel = () => {
    const file = this.state.fileList[0];
    const fileReader = new FileReader();
    const rABS = !!fileReader.readAsBinaryString;
    const HEADER = this.props.headerImport;
    fileReader.onload = async (fileReaderEvent) => {
      const { result: bstr } = fileReaderEvent.target;
      const workbook = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonArr = XLSX.utils.sheet_to_json(firstWorksheet, { header: HEADER, range: 1 });
      try {
        await this.props.handleImportExcel(jsonArr);
        notify.success({ title: 'Import Success' });
      } catch (error) {
        notify.error({ title: 'Import Error', message: error.message });
      }
    };
    if (rABS) fileReader.readAsBinaryString(file);
    else fileReader.readAsArrayBuffer(file);
  };

  handleExportExcel = async () => {
    const HEADER = this.props.headerExport;
    try {
      const { output, fileName, sheetName } = await this.props.handleExportExcel();
      const ws = XLSX.utils.aoa_to_sheet([HEADER, ...output]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  handleDownloadExample = async () => {
    const HEADER = this.props.headerImport;
    try {
      const { output, fileName, sheetName } = await this.props.handleDownloadExample();
      const ws = XLSX.utils.aoa_to_sheet([HEADER, ...output]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  render() {
    const importDisable = this.state.fileList.length === 0;
    const { style } = this.props;
    return (
      <Section style={style}>
        <div>
          <Button type="primary" shape="round" size="large" style={css.excelButton} onClick={this.handleExportExcel}>
            EXPORT
          </Button>

          <Button
            type="primary"
            shape="round"
            size="large"
            style={importDisable ? css.disableButton : css.excelButton}
            onClick={this.handleImportExcel}
            disabled={importDisable}
          >
            IMPORT
          </Button>
          <Upload
            accept=".xlsx"
            onRemove={this.onRemove}
            beforeUpload={this.beforeUpload}
            onChange={this.onChange}
            fileList={this.state.fileList}
          >
            <Button type="primary" shape="round" size="large" style={css.importButton}>
              <Icon type="upload" /> SELECT FILE
            </Button>
          </Upload>
        </div>
        {this.props.handleDownloadExample && (
          <div>
            <Button type="primary" size="large" onClick={this.handleDownloadExample}>
              EXAMPLE IMPORT FILE
            </Button>
          </div>
        )}
      </Section>
    );
  }
}

const css = {
  excelButton: {
    backgroundColor: '#EF843D',
    borderColor: '#EF843D',
    marginRight: 20,
  },
  disableButton: {
    marginRight: 20,
  },
};

const Section = styled.div`
  background-color: #fff;
  margin: 20px 0 20px 0;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

export default ImportExportExcel;
