import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { SaveBtn } from '../button';

export default class SaveSection extends Component {
  render() {
    const { span = 4, offset = 20, display = true, onClick } = this.props;
    if (display) {
      return (
        <Section className={this.props.className}>
          <Row>
            <Col span={span} offset={offset}>
              <SaveBtn onClick={onClick} style={{ height: '48px' }} {...this.props} />
            </Col>
          </Row>
        </Section>
      );
    }
    return <div />;
  }
}

const Section = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #dddbdb;
`;
