import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const PrimaryButton = ({ loading, disabled, title, children, ...props }) => {
  if (children) {
    return (
      <StyledButton type="primary" loading={loading} disabled={disabled} htmlType="submit" {...props}>
        {children}
      </StyledButton>
    );
  }

  return (
    <StyledButton type="primary" loading={loading} disabled={disabled} htmlType="submit" {...props}>
      {title}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
  float: ${({ align }) => align || ''};
  min-width: 90px;
  height: 40px;
  :active {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default PrimaryButton;
