import member from './Member';
import reward from './Reward';
import tag from './Tag';
import user from './User';
import menu from './Menu';
import dashboard from './Dashboard';
import dashboardActivity from './DashboardActivity';
import config from './Configuration';
import point from './Point';
import coupon from './Coupon';
import trigger from './Trigger';
import gen from './Generator';
import banner from './Banner';
import util from './Util';
import appConfig from './AppConfig';
import appPage from './AppPage';
import appPageParam from './AppPageParam';
import challenge from './Challenge';
import surveyTemplate from './SurveyTemplate';
import pushNotificationTemplate from './PushNotificationTemplate';
import pointExpireReminder from './PointExpireReminder';
import SSOConfig from './SSOConfig';
import badge from './Badge';
import tier from './Tier';
import staff from './Staff';
import community from './Community';
import content from './Content';
import report from './Report';
import whitelist from './Whitelist'
import notification from './Notification'

export default {
  member,
  user,
  menu,
  reward,
  tag,
  dashboard,
  dashboardActivity,
  config,
  point,
  coupon,
  trigger,
  gen,
  banner,
  util,
  appConfig,
  appPage,
  appPageParam,
  challenge,
  surveyTemplate,
  pushNotificationTemplate,
  pointExpireReminder,
  SSOConfig,
  badge,
  tier,
  staff,
  community,
  content,
  report,
  whitelist,
  notification,
};
