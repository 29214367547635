import React, { Component } from 'react';
import { TimePicker as Picker } from 'antd';

export default class TimePicker extends Component {
  onChange = (val) => {
    if (val === null) val = undefined;

    const { onChange, name } = this.props;
    if (onChange) onChange(val, name);
  };

  render() {
    const { value, minuteStep = 5, secondStep = 5 } = this.props;
    return (
      <Picker
        style={{ width: '100%' }}
        minuteStep={minuteStep}
        secondStep={secondStep}
        value={value}
        onChange={this.onChange}
        {...this.props}
      />
    );
  }
}
