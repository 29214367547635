/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Table, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import HeaderMenu from '../../components/main/HeaderMenu';
import format from '../../utils/format';

function MyDownload(props) {
  const COLUMNS = [
    {
      title: 'File Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Downloaded Date-Time',
      key: 'date',
      dataIndex: 'date',
      render: (time) => <span>{format.toFullDT(time)}</span>,
    },
    {
      title: 'Export',
      key: 'action',
      dataIndex: 'name',
      render: (name) => {
        return <Button onClick={() => downloadFile(name)}>Export</Button>;
      },
    },
  ];

  async function downloadFile(fileName) {
    await props.report.downloadFile({ path: 'userReports', fileName });
  }

  const fetchApi = async () => {
    await props.report.getUploadUserReportsFiles();
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const { list, loading } = props.report.userReport;
  return (
    <HeaderMenu title="My Download">
      <StyledTable
        indentSize={2}
        columns={COLUMNS}
        dataSource={list}
        bordered
        loading={loading}
        rowKey={(record) => record.name}
      />
    </HeaderMenu>
  );
}

const StyledTable = styled(Table)`
  background: white;
  margin-top: 20px;
  border-radius: 5px;
`;

export default inject('report')(observer(MyDownload));
