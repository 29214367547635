import React, { useEffect, useState } from 'react';
import { Table, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, IntCol } from '../../../components/common';
import { PrimaryButton } from '../../../components/button';
import PersonalizationDetailModal from './PersonaliseDetailModal';

const PersonalizationConfigList = (props) => {
  const { appConfig } = props;
  const { loading, personaliseConfigs } = appConfig;
  const [personaliseList, setPersonaliseConfigs] = useState([]);
  const [personalizationDetailProps, setEditDetail] = useState({ visible: false, data: null });
  const handleOnClose = () => setEditDetail({ visible: false, data: null });
  const handleOnSave = async (values) => {
    await appConfig.updatePersonaliseConfig(values);
    await appConfig.getPersonaliseConfigs();
    setEditDetail({ visible: false, data: null });
  };
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'display',
      key: 'display',
    },
    { title: 'TITLE (EN)', dataIndex: 'titleEn', key: 'titleEn' },
    { title: 'TITLE (TH)', dataIndex: 'titleTh', key: 'titleTh' },
    {
      title: 'WEIGHT',
      dataIndex: 'weight',
      key: 'weight',
      width: '100px',
      render: (weight) => <IntCol value={weight} />,
    },
    {
      title: 'ACTION',
      render: (text, row) => {
        return (
          <span>
            <PrimaryButton
              type="primary"
              size="large"
              onClick={() => {
                setEditDetail({ visible: true, data: row });
              }}
            >
              <Icon type="edit" theme="filled" /> Edit
            </PrimaryButton>
          </span>
        );
      },
    },
  ];
  useEffect(() => {
    appConfig.getPersonaliseConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPersonaliseConfigs(personaliseConfigs);
  }, [personaliseConfigs]);

  return (
    <HeaderMenu title="PERSONALIZE CONFIG">
      <Page style={css.page}>
        {personalizationDetailProps.visible && (
          <PersonalizationDetailModal {...personalizationDetailProps} onClose={handleOnClose} onSave={handleOnSave} />
        )}
        <Table
          bordered
          pagination={false}
          dataSource={personaliseList}
          columns={columns}
          loading={loading}
          rowKey={(r) => r.id}
          footer={() => (
            <div style={css.footer}>
              * การเปลี่ยนชื่อ Personalize จะมีผลในหน้าแอปพลิเคชั่น ได้แก่ user profile และ friend profile
            </div>
          )}
        />
      </Page>
    </HeaderMenu>
  );
};

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  footer: {
    padding: '15px',
    color: 'rgba(0, 0, 0, 0.65);',
  },
};

export default inject('appConfig')(observer(PersonalizationConfigList));
