import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import { storage } from '../utils/storage';

const INIT_STATE = {
  list: [
    {
      key: '1',
      id: 0,
      form_id: 'unique_form_id',
      title: '-',
      message: '-',
      points: 0,
      schedule_date: '',
    },
  ],
  loading: false,
  currentSurveyTemplate: {},
};

class SurveyTemplate extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  async update(formId, data) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/survey-template/${formId}`;
    const res = await http.post(url, { json: data, token: true });

    if (res.statusCode !== 200) {
      throw new Error(res.body.message);
    }

    this.currentSurveyTemplate = res.body.data;

    this.loading = false;
  }

  async fetchSurveyTemplateDetail(formId) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/survey-template/${formId}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      throw new Error(res.body.message);
    }

    const { data } = res.body;

    this.currentSurveyTemplate = data;

    this.loading = false;
  }

  async fetchAll() {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/survey-template`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      console.error(res.body.message);
    }

    const { data } = res.body;

    this.list = data;
    this.loading = false;
  }

  async create(data) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/survey-template`;
    const res = await http.post(url, { json: data, token: true });

    this.loading = false;
    if (res.statusCode !== 200) {
      throw new Error(res.body.message);
    }
  }

  async delete(formId) {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/survey-template/${formId}`;
    const res = await http.delete(url, { token: true });

    this.loading = false;
    if (res.statusCode !== 200) {
      throw new Error(res.body.message);
    }
  }
}

const surveyTemplate = new SurveyTemplate();

export default surveyTemplate;
