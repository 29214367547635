import React from 'react';
import { Avatar, Col } from 'antd';

import styled from 'styled-components';
import { DateCol } from '../../components/common';
import Colors from '../../assets/Colors';
import { DragAndDropTableZone, UrlLink } from '../../components';
import { bannerStatus } from './BannerList';

const BannerRow = React.forwardRef(({ index, item, isDragging = undefined }, refs) => {
  const { DraggableTableRow } = DragAndDropTableZone;

  return (
    <DraggableTableRow index={index} isDragging={isDragging} ref={refs}>
      <ColStyle span={2}>
        <Avatar style={{ verticalAlign: 'middle' }} shape="square" size="large" src={item.imageUrl} />
      </ColStyle>
      <ColStyle span={3}>{item.name}</ColStyle>
      <ColStyle span={5}>
        <UrlLink value={item.uri} />
      </ColStyle>
      <ColStyle span={2}>{bannerStatus({ date: item.endAt, status: item.status })}</ColStyle>
      <ColStyle span={3}>
        <DateCol value={item.startAt} />
      </ColStyle>
      <ColStyle span={3}>
        <DateCol value={item.endAt} />
      </ColStyle>
    </DraggableTableRow>
  );
});

const ColStyle = styled(Col)`
  display: inline-block;
  height: 70px;
  background-color: transparent;
  border-right: 1px solid ${Colors.borderTable};
  padding: 16px 14px 16px 16px;
  font-size: 12px;
`;

export default BannerRow;
