import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

export class Tier extends BaseStore {
  constructor() {
    super();
    this.observable({
      tierConfig: [],
    });
  }

  async getTierConfig() {
    const url = `${config.api.max}/v1/office/tiers`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    this.tierConfig = res.body.badgeTierMapping || [];
  }

  async saveTierConfig(data) {
    const url = `${config.api.max}/v1/office/tiers`;
    const res = await http.put(url, { json: data, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async setTier(val) {
    this.tierConfig = [...val];
  }
}

export default new Tier();
