import React, { Component } from 'react';

import HeaderMenu from '../../components/main/HeaderMenu';
import { Page } from '../../components/common';

import { TabMenu } from '../../components/forms';
import { history } from '../../utils/history';

const name = 'REWARDS';
export default class RewardTab extends Component {
  onChange = (item = {}) => {
    const { url } = item.params;
    if (url) history.push(url);
  };

  render() {
    const { defaultIndex = 0, children } = this.props;

    return (
      <HeaderMenu title={name}>
        <Page style={pageCss}>
          <TabMenu defaultIndex={defaultIndex} menus={menus} onChange={this.onChange} />
          <br />
          {children}
        </Page>
      </HeaderMenu>
    );
  }
}

const pageCss = {
  backgroundColor: 'white',
  borderRadius: '5px',
};

const menus = [
  {
    name: 'ALL REWARDS',
    params: { url: '/office/reward' },
  },
  {
    name: 'REWARDS IN APP',
    params: { url: '/office/reward/application' },
  },
];
