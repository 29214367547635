import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Col } from 'antd';

import { SaveSection } from '../../../components/common';
import { InputInt, InputPoint } from '../../../components/forms';

export class Traveling extends Component {
  onMax = (val) => {
    this.doc.traveling_check_in.max_count = val;
    this.doc.traveling_take_photo.max_count = val;
    this.doc.traveling_share_to_social.max_count = val;
    this.props.point.setPassiveActivity(this.doc);
  };

  onChangePoint = (val, name) => {
    this.doc[name].point = val;
    this.props.point.setPassiveActivity(this.doc);
  };

  render() {
    const span = 4;
    const doc = this.props.point.toJS().passive;
    this.doc = doc;
    return (
      <div>
        <Row>
          <Col span={span}>
            <Form.Item label="Max Per Day">
              <InputInt value={doc.traveling_check_in.max_count} onChange={this.onMax} text="time" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={span}>
            <Form.Item label="Check in">
              <InputPoint
                name="traveling_check_in"
                value={doc.traveling_check_in.point}
                onChange={this.onChangePoint}
              />
            </Form.Item>
          </Col>

          <Col span={span} offset={1}>
            <Form.Item label="+ Take Photo">
              <InputPoint
                name="traveling_take_photo"
                value={doc.traveling_take_photo.point}
                onChange={this.onChangePoint}
              />
            </Form.Item>
          </Col>

          <Col span={span} offset={1}>
            <Form.Item label="+ Share to Social">
              <InputPoint
                name="traveling_share_to_social"
                value={doc.traveling_share_to_social.point}
                onChange={this.onChangePoint}
              />
            </Form.Item>
          </Col>
        </Row>

        <SaveSection onClick={this.props.onSave} />
      </div>
    );
  }
}

export default inject('point')(observer(Traveling));
