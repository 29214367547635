import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './assets';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';

import { ThemeProvider } from 'styled-components';
import ScrollToTop from './components/ScrollToTop';

import App from './app';
import Maintenance from './pages/auth/Maintenance';
import NotFound from './pages/auth/404';
import Forbidden from './pages/auth/403';

import history from './utils/history';
import store from './stores';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => null;
}

// Theme will be application wide theme
const theme = {
  colors: {
    white: '#fff',
    primary: '#e8831a',
    primaryHover: '#f28d00',
  },
};

ReactDOM.render(
  <Provider {...store}>
    <Router history={history}>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/403" component={Forbidden} />
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/" component={App} />
            <Route component={NotFound} />
          </Switch>
        </ThemeProvider>
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById('root')
);
