import React, { Component } from 'react';

import ConfigBase from './ImageBase';

export default class ConfigPointType extends Component {
  render() {
    return (
      <ConfigBase
        title="HOW TO EARN POINT CONFIGURATION"
        type="point"
        menus={menus}
        dividerText="Upload How to earn point image (750 x (Anything) pixels) with .JPG or .PNG"
      />
    );
  }
}

const menus = [{ name: 'Earn Point TH', value: 'th' }, { name: 'Earn Point ENG', value: 'eng' }];
