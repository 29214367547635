import React, { Component } from 'react';
import { Button, Col, Modal } from 'antd';
import { inject, observer } from 'mobx-react';

import { KeyBtn, Btn } from '../button';
import SampleCode from './SampleCode';
import { CodeTabMenu } from '../forms';

class ButtonSection extends Component {
  state = {
    isModalVisible: false,
  };
  componentDidMount() {
    this.props.reward.getRewardExample(this.props.id);
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  onChange = (item) => {
    this.setState({ selected: item.name });
  };

  getFontSize(codeLenght) {
    if (codeLenght < 10) {
      return 26;
    }
    if (codeLenght >= 10 && codeLenght < 15) {
      return 22;
    }
    if (codeLenght >= 15 && codeLenght < 25) {
      return 14;
    }
    return 12;
  }

  render() {
    let { isModalVisible, selected } = this.state;
    const { code, txtGenerate = 'GENERATE', exportor = false, generator = false, onExport, onGenerate, reward } = this.props;
    console.log('reward', reward)
    let sample;
    if (code !== undefined) {
      sample = <SampleCode code={code} />;
    }

    console.log('this.props.reward.detail.show_code_config', this.props.reward.detail.show_code_config);
    const codeConfig = (this.props.reward.detail.show_code_config || '').split(',').filter(c => c != "");
    console.log('codeConfig', codeConfig)
    const filteredMenus = menus.filter(menu => (!codeConfig.length || codeConfig.includes(menu.id)))

    if (!filteredMenus.map(menu => menu.name).includes(selected)) {
      selected = filteredMenus[0].name;
    }
    console.log('selected', selected)

    let contents;
    switch (selected) {
      case 'BARCODE':
        contents = (
          <div style={css.codeBox}>
            <img src={require('../../assets/img/xs20624.png')} />
          </div>
        );
        break;
      case 'QR CODE':
        contents = (
          <div style={css.codeBox}>
            <img src={require('../../assets/img/521263.png')} />
          </div>
        );
        break;
      default:
        contents = (
          <>
            <div style={css.codeBackground}>
              <span style={{ fontSize: this.getFontSize((reward.exampleCode || '').length) }}>{reward.exampleCode || ''}</span>
            </div>
            <Button style={css.copyCodeButton}>Copy</Button>
          </>
        );
    }

    return (
      <>
        <div>
          {sample}
          <Col span={7} offset={1}>
            <Btn style={css.btn} text="EXAMPLE" onClick={this.showModal} />
          </Col>
          <Col span={7} offset={1}>
            <Btn style={css.btn} text="EXPORT" onClick={onExport} disabled={!exportor} />
          </Col>
          <Col span={7} offset={1}>
            <KeyBtn style={css.btn} text={txtGenerate} onClick={onGenerate} disabled={!generator} />
          </Col>
        </div>

        <Modal
          visible={isModalVisible}
          onCancel={this.handleCancel}
          width={376}
          footer={[
            <Button key="close" onClick={this.handleCancel}>
              Close
            </Button>,
          ]}
        >
          <CodeTabMenu defaultIndex={filteredMenus.findIndex(menu => menu.name === selected)} menus={filteredMenus} onChange={this.onChange} style={{ width: '100%' }} />
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignContent: 'center',
              padding: 24,
            }}
          >
            {contents}
          </div>
        </Modal>
      </>
    );
  }
}

const css = {
  btn: {
    borderRadius: '18px',
    height: '36px',
  },
  codeBox: {
    width: 280,
    height: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  codeBackground: {
    width: 280,
    height: 88,
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EDEFF0',
    borderRadius: 4,
  },
  copyCodeButton: {
    width: 280,
    height: 40,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E87722',
    color: 'white',
  },
};

const menus = [
  {
    name: 'CODE',
    id: 'digital',
    params: {},
  },
  {
    name: 'BARCODE',
    id: 'barcode',
    params: {},
  },
  {
    name: 'QR CODE',
    id: 'qr',
    params: {},
  },
];

export default inject('reward', 'whitelist')(observer(ButtonSection)) 
