import { error } from '../../../utils/error';

const moment = require('moment');

export const max = (num1 = 0, num2 = 0) => {
  return Math.max(num1, num2);
};

export const stringToMoment = (date) => moment(date);

export const getTimeType = ({ start, end }) => {
  const diff = end.unix() - start.unix();
  if (diff < 0) {
    error.launch({ message: 'End date must more than Start date' });
    return;
  }
  const days = diff / 86400;
  let type;
  if (days < 3) type = 'hour';
  else if (days < 60) type = 'day';
  else type = 'month';

  // eslint-disable-next-line consistent-return
  return type;
};

export const getDateFormat = (timeType) => {
  console.log('[getDateFormat]', timeType);
  let result = '';
  switch (timeType) {
    case 'hour':
      result = 'DD MMM YYYY HH.mm';
      break;
    case 'day':
      result = 'DD MMM YYYY';
      break;
    case 'month':
      result = 'MMM YYYY';
      break;
    default:
      result = 'DD MM YYYY';
      break;
  }
  console.log('[getDateFormat] result', result);
  return result;
};

export const getTimeLables = (param) => {
  const { start, end, timeType } = param;
  console.log('[getTimeLables]', { start, end, timeType });
  const currentEnd = end.clone().endOf('day');

  const now = moment();
  if (currentEnd.isSame(now, 'day')) {
    currentEnd.hour(now.hour());
  }
  const resultList = [];
  const currentDate = start.startOf('day').clone();
  const diff = currentEnd.diff(currentDate, timeType);

  resultList.push(currentDate.toDate().getTime());
  console.log('[getTimeLables]', { diff });
  for (let index = 0; index < diff; index++) {
    currentDate.add(1, timeType);
    // console.log('[getTimeLables]', { current: start.toDate(), index });
    resultList.push(currentDate.toDate().getTime());
  }
  console.log('[getTimeLables]', { resultList });
  return resultList;
};

export const transformList = (lables, list) => {
  console.log('[transformList]', { lables, list });
  const resultList = [];
  for (let index = 0; index < lables.length; index++) {
    const key = lables[index];
    let value = list[key];
    if (!value) {
      value = 0;
    }
    resultList.push(value);
    // console.log('[transformList]', { key, value });
  }
  console.log('[transformList]', { resultList });
  return resultList;
};

export const transformDatasets = (labels, datasets) => {
  console.log('[transformDatasets]', { labels, datasets });
  const resultDatasets = [];
  Object.entries(datasets).forEach(([key, list]) => {
    resultDatasets.push({ name: key, list: transformList(labels, list) });
  });
  console.log('[transformDatasets]', { resultDatasets });
  return resultDatasets;
};

export const transformData = (param, data) => {
  console.log('[transformData]', { data });
  const labels = getTimeLables(param);
  console.log('[transformData]', { labels });
  const datasets = transformDatasets(labels, data.datasets);
  return { labels, datasets, info: data.info, param };
};
