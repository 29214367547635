import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form, Input } from 'antd';

import ButtonSection from './ButtonSection';

export class WrappedIncrementCode extends Component {
  onGenerate = async (evt) => {
    const { form } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
    } catch (e) {
      return;
    }

    const { onGenerate } = this.props;
    if (onGenerate) onGenerate({ type: 'auto-increment', data: this.config.increment });
  };

  onExport = (evt) => {
    const { onExport } = this.props;
    if (onExport) onExport();
  };

  onChange = (evt) => {
    this.config.increment.prefix = evt.target.value;
    this.props.gen.setConfig(this.config);
  };

  render() {
    const { config } = this.props.gen.toJS();
    this.config = config;
    const { prefix } = config.increment;
    const { form, exportor, generator } = this.props;
    const { getFieldDecorator } = form;
    const required = true;

    return (
      <div>
        <Form hideRequiredMark>
          <Row type="flex" justify="space-between">
            <Col span={6}>
              <Form.Item label="Prefix">
                {getFieldDecorator('prefix', {
                  initialValue: this.props.initialValues && this.props.initialValues.prefix ? this.props.initialValues.prefix : prefix,
                  rules: [{ required, message: 'Please fill code!' }],
                })(<Input onChange={this.onChange} />)}
              </Form.Item>
            </Col>

            <Col span={12} offset={1}>
              <ButtonSection
                exportor={exportor}
                generator={generator}
                onExport={this.onExport}
                onGenerate={this.onGenerate}
                code={prefix}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const GenIncrementCode = Form.create({})(WrappedIncrementCode);
export default inject('gen')(observer(GenIncrementCode));
