import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const SecondaryButton = ({ title, onClick, disabled = false, ...props }) => {
  return (
    <StyledButton type="secondary" disabled={disabled} onClick={onClick} {...props}>
      {title}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  float: ${({ align }) => align || ''};
  min-width: 90px;
  height: 40px;
  :active {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
  :focus,
  &:hover {
    border-width: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default SecondaryButton;
