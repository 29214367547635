import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

export class Tag extends BaseStore {
  constructor() {
    super();
    this.observable({
      list: [],
      dropdown: [],
    });
  }

  async getList() {
    const len = this.toJS().list.length;
    if (len > 0) return;

    const url = `${config.api.max}/v1/office/utils/tags?status=active`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body;
    this.dropdown = list.map((item) => {
      return { name: item.tag_code, value: item.tag_code };
    });
    this.list = list;
  }
}

export default new Tag();
