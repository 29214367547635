import React from 'react';
import { Table, Tag, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import DateFilter from '../../components/filter/DateFilter';
import notify from '../../utils/notify';
import BoarderFilterMoreItem from './BoarderFilterMoreItem';
import CommunityReportDetailModal from './CommunityReportDetailModal';
import format from '../../utils/format';
import { DisplayStatus, DateCol } from '../../components/common';

export class CommunityReport extends React.Component {
  constructor() {
    super();
    this.boardFilterRef = React.createRef();
    this.boardFilterMoreRef = React.createRef();
    this.detailRef = React.createRef();
    this.state = {
      columns: [
        {
          title: 'REPORT ID',
          dataIndex: 'reportId',
        },
        {
          title: 'REPORT DATE',
          dataIndex: 'createdAt',
          render: (time) => <DateCol value={time} />,
        },
        {
          title: 'REPORT CATEGORY',
          dataIndex: 'type',
        },
        {
          title: 'POST',
          dataIndex: 'post.title',
        },
        {
          title: 'POST OWNER',
          dataIndex: 'postowner',
          render: (_, value) => {
            return <span>{value.post ? value.post.displayName : ''}</span>;
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          render: (status) => <DisplayStatus type={2} status={status} />,
        },
        {
          title: 'ACTION',
          dataIndex: 'action',
          render: (_, value) => {
            return (
              <Button style={css.actionButton} type="link" onClick={() => this.openModal(value)}>
                {value.status === 'pending' ? 'Edit' : 'Detail'}
              </Button>
            );
          },
        },
      ],
    };
  }

  fetchApi = async (date = {}, page = 1) => {
    try {
      const { status, category } = this.boardFilterMoreRef.current.get();
      await this.props.community.getReportList(date, page, status, category);
    } catch (e) {
      notify.error({ title: 'Load Community Report Fail', message: e.message });
    }
  };

  onFilterDate = (date) => {
    this.fetchApi(date, 1);
  };

  onChangePage = (page) => {
    this.fetchApi(this.boardFilterRef.current.getDate(), page.current);
  };

  approveBan = async (id) => {
    try {
      await this.props.community.approveBan(id);
      this.fetchApi(this.boardFilterRef.current.getDate(), this.props.community.reportRequest.pagination.current);
    } catch (e) {
      notify.error({ title: 'Approve Ban Fail', message: e.message });
    }
    this.detailRef.current.modalClose();
  };

  rejectBan = async (id) => {
    try {
      await this.props.community.rejectBan(id);
      this.fetchApi(this.boardFilterRef.current.getDate(), this.props.community.reportRequest.pagination.current);
    } catch (e) {
      notify.error({ title: 'Cancel Ban Fail', message: e.message });
    }
    this.detailRef.current.modalClose();
  };

  fetchApiDetail = async (id) => {
    try {
      await this.props.community.getReportDetail(id);
    } catch (e) {
      notify.error({ title: `Load Community Report ID: ${id} Fail`, message: e.message });
    }
  };

  openModal = (value) => {
    this.fetchApiDetail(value.reportId);
    this.detailRef.current.modalOpen();
  };

  render() {
    const { reportRequest, reportRequestDetail } = this.props.community;
    const { list, loading, pagination } = reportRequest;
    const { data: postData, loading: detailLoading } = reportRequestDetail;

    return (
      <>
        <DateFilter
          onFind={this.onFilterDate}
          ref={this.boardFilterRef}
          moreItem={<BoarderFilterMoreItem ref={this.boardFilterMoreRef} />}
        />

        <Table
          indentSize={2}
          bordered
          style={css.table}
          columns={this.state.columns}
          dataSource={list}
          loading={loading}
          onChange={this.onChangePage}
          pagination={{
            ...pagination,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />

        <CommunityReportDetailModal
          ref={this.detailRef}
          loading={detailLoading}
          data={postData}
          approveBan={this.approveBan}
          rejectBan={this.rejectBan}
        />
      </>
    );
  }
}

const css = {
  table: {
    background: 'white',
    marginTop: 20,
  },
  actionButton: {
    paddingLeft: 0,
  },
};

export default inject('community', 'member')(observer(CommunityReport));
