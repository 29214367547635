import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Row, Col, DatePicker, Form, Button, Select, Input } from 'antd';

import { Filter, GroupItem } from '../../components/forms';
import { FindBtn } from '../../components/button';

const { Option } = Select;

const mapDefaultActivityTypeByCategory = {
  all: 'all',
  active: 'steps',
  passive: 'eating_check_in',
  default: 'all',
};

const ChallengeFilter = (props) => {
  const { getFieldDecorator, validateFields, getFieldsValue, setFieldsValue } = props.form;

  const [isAllTimeType, setAllTimeType] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        values.startDate = values.startDate ? values.startDate.toISOString() : null;
        values.endDate = values.endDate ? values.endDate.toISOString() : null;
        props.challenge.getList(values);
      }
    });
  };
  const renderActivityCode = () => {
    const { category } = getFieldsValue();
    const width = 190;

    // eslint-disable-next-line no-nested-ternary
    return category === 'all' ? (
      <Select style={{ width }}>
        <Option value="all">All</Option>
        <Option value="steps">Steps</Option>
        <Option value="running">Running</Option>
        <Option value="swimming">Swimming</Option>
        <Option value="cycling">Cycling</Option>
        <Option value="eating_check_in">Eating Check In</Option>
        <Option value="traveling_check_in">Traveling Check In</Option>
      </Select>
    ) : category === 'active' ? (
      <Select style={{ width }}>
        <Option value="all">All</Option>
        <Option value="steps">Steps</Option>
        <Option value="running">Running</Option>
        <Option value="swimming">Swimming</Option>
        <Option value="cycling">Cycling</Option>
      </Select>
    ) : (
      <Select style={{ width }}>
        <Option value="all">All</Option>
        <Option value="eating_check_in">Eating Check In</Option>
        <Option value="traveling_check_in">Traveling Check In</Option>
      </Select>
    );
  };

  return (
    <Filter style={css.filter}>
      <FilterPage>
        <Form layout="horizontal" colon={false}>
          <Row type="flex" gutter={12}>
            <Col>
              <FormItem label="Time Type">
                {getFieldDecorator('timeType', {
                  initialValue: 'all',
                })(
                  <Select
                    style={{ width: 100 }}
                    onChange={(value) => {
                      setFieldsValue({
                        startDate: null,
                        endDate: null,
                      });
                      setAllTimeType(value === 'all');
                    }}
                  >
                    <Option value="all">All</Option>
                    <Option value="createdAt">Created</Option>
                    <Option value="startAt">Start</Option>
                    <Option value="endAt">Expire</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Start Date/Time">
                {getFieldDecorator('startDate', {
                  rules: [
                    {
                      required: !isAllTimeType,
                      message: 'Start Date/Time is required',
                    },
                  ],
                })(<DatePicker disabled={isAllTimeType} style={css.datePicker} showTime></DatePicker>)}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="End Date/Time">
                {getFieldDecorator('endDate', {
                  rules: [
                    {
                      required: !isAllTimeType,
                      message: 'Start Date/Time is required',
                    },
                  ],
                })(<DatePicker disabled={isAllTimeType} style={css.datePicker} showTime></DatePicker>)}
              </FormItem>
            </Col>

            <Col>
              <FormItem label="Status">
                {getFieldDecorator('status', {
                  initialValue: 'all',
                })(
                  <Select style={{ width: 100 }}>
                    <Option value="all">All</Option>
                    <Option value="active">Active</Option>
                    <Option value="inactive">In Active</Option>
                    <Option value="draft">Draft</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Category">
                {getFieldDecorator('category', {
                  initialValue: 'all',
                })(
                  <Select
                    style={{ width: 100 }}
                    onChange={(value) => {
                      const activityCode =
                        mapDefaultActivityTypeByCategory[value] || mapDefaultActivityTypeByCategory.default;
                      setFieldsValue({
                        activityCode,
                      });
                    }}
                  >
                    <Option value="all">All</Option>
                    <Option value="active">Active</Option>
                    <Option value="passive">Passive</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Activity Type">
                {getFieldDecorator('activityCode', {
                  initialValue: 'all',
                })(renderActivityCode())}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Name">{getFieldDecorator('title')(<Input placeholder="Please enter name" />)}</FormItem>
            </Col>
            <Col>
              <GroupItem>
                <FindBtn onClick={handleSubmit} />
              </GroupItem>
            </Col>
          </Row>
        </Form>
      </FilterPage>
    </Filter>
  );
};

const css = {
  filter: {
    border: '1px solid #E7E6E6',
    borderRadius: '5px',
  },
  datePicker: {
    width: '98%',
  },
};

const FilterPage = styled.div`
  padding: 10px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
  label {
    font-weight: bold;
  }
`;

const WrappedChallengeFilter = Form.create({ name: 'challenge_filter_search_form' })(ChallengeFilter);

export default inject('challenge')(observer(WrappedChallengeFilter));
