import React from 'react';
import { Form, InputNumber } from 'antd';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Page, Loading, SaveSection } from '../../../components/common';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { notify } from '../../../utils/notify';

class ConfigBanner extends React.Component {
  async componentDidMount() {
    try {
      await this.props.appConfig.getList();
    } catch (error) {
      notify.error({ title: 'Get Banner Config Failed' });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { form } = this.props;
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const bannerSlideTimeMS = values.bannerSlideTimeInSeconds * 1000; // convert unit from seconds to miliseconds
      await this.props.appConfig.updateList([
        {
          key: 'bannerSlideTimeMS',
          value: bannerSlideTimeMS,
          status: 'active',
        },
      ]);
      notify.success({ title: 'Update Banner Config Success' });
    } catch (error) {
      notify.error({ title: 'Update Banner Config Failed' });
    }
  };

  render() {
    const { general, loading } = this.props.appConfig.toJS();
    const { getFieldDecorator } = this.props.form;
    const bannerSlideTimeInSeconds = general.bannerSlideTimeMS / 1000; // convert miliseconds to seconds;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
    };
    const formContent = (
      <Form {...formItemLayout}>
        <FormItem label="Banner slide times">
          {getFieldDecorator('bannerSlideTimeInSeconds', {
            initialValue: bannerSlideTimeInSeconds || 1,
            rules: [
              {
                required: true,
                message: 'Please input your banner slide times!',
              },
            ],
          })(<InputNumber min={1} style={{ marginRight: 5 }} />)}
          <span>Seconds</span>
        </FormItem>
        <SaveSection onClick={this.handleSubmit} />
      </Form>
    );
    return (
      <HeaderMenu title="BANNER CONFIG">
        <Page style={css.page}>
          <Loading loading={loading} />
          {formContent}
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    line-height: 39px;
  }
`;

const WrappedGlobalConfigForm = Form.create({ name: 'banner_config_form' })(
  inject('appConfig')(observer(ConfigBanner))
);

export default WrappedGlobalConfigForm;
