import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';

const { Option } = Select;
class AppPage extends React.Component {
  componentDidMount() {
    this.props.appPage.getList();
  }

  render() {
    const items = this.props.appPage.list.toJS();
    const contens = items.map((item) => {
      return (
        <Option key={item.value} value={item.value}>
          {item.display}
        </Option>
      );
    });
    return (
      <Select {...this.props} disabled={this.props.type !== 'inapp'} placeholder="Please select a page type">
        {contens}
      </Select>
    );
  }
}

export default inject('appPage')(observer(AppPage));
