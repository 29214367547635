import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

const INIT_STATE = {
  reportRequest: {
    list: [],
    pagination: {},
    loading: false,
  },
  reportRequestDetail: {
    data: {},
    loading: false,
  },
  post: {
    list: [],
    pagination: {},
    loading: false,
  },
  postDetail: {
    data: {},
    loading: false,
  },
  loading: false,
};

class CommunityStore extends BaseStore {
  constructor() {
    super();
    this.observable(INIT_STATE);
  }

  async getReportList(date, page = 1, status, category) {
    this.reportRequest.loading = true;

    const url = `${config.api.max}/v1/office/postReportRequests`;
    const qs = { page, limit: 10, status, category };
    const { start, end } = date;
    if (start) qs.start = start.format('YYYY-MM-DD');
    if (end) qs.end = end.format('YYYY-MM-DD');
    const res = await http.get(url, { qs, token: true });
    this.reportRequest.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    const { data = [], count = 0 } = res.body;
    this.reportRequest.list = data;
    this.reportRequest.pagination = {
      total: count,
      current: page,
    };
  }

  async getReportDetail(id) {
    this.reportRequestDetail.loading = true;
    const url = `${config.api.max}/v1/office/postReportRequests/${id}`;
    const res = await http.get(url, { token: true });
    this.reportRequestDetail.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    this.reportRequestDetail.data = res.body;
  }

  async approveBan(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/postReportRequests/${id}/delete`;
    const res = await http.patch(url, { token: true });
    this.loading = false;
    if (res.statusCode < 200 || res.statusCode > 299) {
      error.launch({ message: res.body.message });
    }
  }

  async rejectBan(id) {
    this.loading = true;
    const url = `${config.api.max}/v1/office/postReportRequests/${id}/undo`;
    const res = await http.patch(url, { token: true });
    this.loading = false;
    if (res.statusCode < 200 || res.statusCode > 299) {
      error.launch({ message: res.body.message });
    }
  }

  async getLikeList(date, page = 1) {
    this.post.loading = true;

    const url = `${config.api.max}/v1/office/posts`;
    const qs = { page, limit: 10 };
    const { start, end } = date;
    if (start) qs.start = start.format('YYYY-MM-DD');
    if (end) qs.end = end.format('YYYY-MM-DD');
    const res = await http.get(url, { qs, token: true });
    this.post.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    const { data = [], count = 0 } = res.body;
    this.post.list = data;
    this.post.pagination = {
      total: count,
      current: page,
    };
  }

  async getPostDetail(id) {
    this.postDetail.loading = true;
    const url = `${config.api.max}/v1/office/posts/${id}`;
    const res = await http.get(url, { token: true });
    this.postDetail.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    this.postDetail.data = res.body;
  }

  resetPostDetail() {
    this.postDetail.data = {};
  }
}

export default new CommunityStore();
