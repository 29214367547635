import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Col } from 'antd';

import styled from 'styled-components';
import { InputInt } from '../../../components/forms';

export class Config extends Component {
  onChange = (val, name) => {
    this.doc.config[name] = val;
    this.props.point.setPlancode(this.doc);
  };

  render() {
    const span = 8;
    const { validate = {} } = this.props;
    const doc = this.props.point.toJS().plancode;
    this.doc = doc;
    return (
      <ConfigSection>
        <Form {...formLayout}>
          <Row>
            <Col span={span}>
              <Form.Item
                label="Hold Period"
                validateStatus={validate.loyaltyHoldPeriod ? 'error' : ''}
                help={validate.loyaltyHoldPeriod ? 'Hold Period is required' : ''}
              >
                <InputInt name="loyaltyHoldPeriod" value={doc.config.loyaltyHoldPeriod} onChange={this.onChange} />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Help>Days</Help>
            </Col>
          </Row>
          <Row>
            <Col span={span}>
              <Form.Item
                label="Backward duration"
                validateStatus={validate.loyaltyPastYears ? 'error' : ''}
                help={validate.loyaltyPastYears ? 'Backward duration is required' : ''}
              >
                <InputInt name="loyaltyPastYears" value={doc.config.loyaltyPastYears} onChange={this.onChange} />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Help>Years</Help>
            </Col>
          </Row>
          <Row>
            <Col span={span}>
              <Form.Item
                label="Max point per year"
                validateStatus={validate.loyaltyMaxPointPerYear ? 'error' : ''}
                help={validate.loyaltyMaxPointPerYear ? 'Max point per year is required' : ''}
              >
                <InputInt
                  name="loyaltyMaxPointPerYear"
                  value={doc.config.loyaltyMaxPointPerYear}
                  onChange={this.onChange}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Help>Points</Help>
            </Col>
          </Row>
        </Form>
      </ConfigSection>
    );
  }
}

const ConfigSection = styled.div`
  background-color: white;
`;

const Help = styled.div`
  padding: 10px;
`;

const formLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

export default inject('point')(observer(Config));
