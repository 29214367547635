import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'antd';

import { DropdownList, InputPoint, DatePicker, Input } from '../../../components/forms';
import { timer } from '../../../utils/timer';
import { SaveSection } from '../../../components/common';
import { BadgeMenu, RewardStatusMenu } from '../../../definition/dropdown';

export class WapperdInformation extends Component {
  componentWillReceiveProps() {
    this.forceUpdate();
  }

  onSave = async (evt) => {
    const { form, onSave } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
    } catch (e) {
      return;
    }

    if (onSave) onSave();
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const required = true;
    const coupon = this.props.coupon.toJS();
    const doc = coupon.detail;
    const { original } = coupon;
    const readOnly = original.status !== 'draft';

    return (
      <Form hideRequiredMark>
        <Row>
          <Col span={8}>
            <Form.Item hideRequiredMark label="Name by TH">
              {getFieldDecorator('name_th', {
                initialValue: doc.name_th,
                rules: [{ required, message: 'Please fill name thai!' }],
              })(<Input readOnly={readOnly} />)}
            </Form.Item>
          </Col>

          <Col span={8} offset={1}>
            <Form.Item label="Name by EN">
              {getFieldDecorator('name_en', {
                initialValue: doc.name_en,
                rules: [{ required, message: 'Please fill name english!' }],
              })(<Input readOnly={readOnly} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item hideRequiredMark label="Status">
              {getFieldDecorator('status', {
                initialValue: doc.status,
              })(<DropdownList disabled={doc.coupon_id === undefined} allowClear={false} menus={RewardStatusMenu} />)}
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <br />
        <Row>
          <Col span={4}>
            <Form.Item label="Avaliable for">
              {getFieldDecorator('user_level', {
                initialValue: `${doc.user_level}`,
                rules: [{ required, message: 'Please choose badge!' }],
              })(<DropdownList readOnly={readOnly} menus={BadgeMenu} allowClear={false} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="Points">
              {getFieldDecorator('price', {
                initialValue: doc.price,
                rules: [{ required, message: 'Please fill decrease point!' }],
              })(<InputPoint readOnly={readOnly} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="Start date">
              {getFieldDecorator('start_date', {
                initialValue: timer.toDatetime(doc.start_date),
                rules: [{ required, message: 'Please fill start date!' }],
              })(<DatePicker showTime readOnly={readOnly} />)}
            </Form.Item>
          </Col>

          <Col span={4} offset={1}>
            <Form.Item label="Expired date">
              {getFieldDecorator('end_date', {
                initialValue: timer.toDatetime(doc.end_date),
                rules: [{ required, message: 'Please fill expired date!' }],
              })(<DatePicker showTime readOnly={readOnly} />)}
            </Form.Item>
          </Col>
        </Row>

        <br />
        <SaveSection onClick={this.onSave} />
      </Form>
    );
  }
}

const onFieldsChange = (props, changedFields, allFields) => {
  const doc = props.coupon.toJS().detail;
  const keys = Object.keys(changedFields);
  for (const name of keys) {
    const item = changedFields[name];
    doc[name] = item.value;
  }
  props.coupon.setDoc(doc);
};
const Information = Form.create({ onFieldsChange })(WapperdInformation);
export default inject('coupon')(observer(Information));
