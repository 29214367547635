import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default class DraftEditor extends Component {
  state = {
    editor: EditorState.createEmpty(),
  };

  onChange = (editor) => {
    const content = editor.getCurrentContent();
    const raw = convertToRaw(content);
    this.setState({ editor });

    const { name, onHtml, onMarkdown } = this.props;
    if (onHtml) {
      const html = draftToHtml(raw);
      onHtml(html, name);
    }

    if (onMarkdown) {
      const markup = draftToMarkdown(raw);

      onMarkdown(markup, name);
    }
  };

  setHtml = (value) => {
    const raw = htmlToDraft(value);
    const { contentBlocks, entityMap } = raw;
    const content = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editor = EditorState.createWithContent(content);
    this.setState({ editor });
  };

  setMarkdown = (value) => {
    const config = {
      remarkablePreset: 'commonmark',
      remarkableOptions: { html: true },
    };

    const raw = markdownToDraft(value, config);
    const content = convertFromRaw(raw);
    const editor = EditorState.createWithContent(content);
    this.setState({ editor });
  };

  render() {
    const { editor } = this.state;
    const { readOnly = false } = this.props;
    let css = '';

    if (readOnly) {
      css = 'markdown-readonly';
    }
    return (
      <Editor
        editorClassName={`my-editor ${css}`}
        readOnly={readOnly}
        editorState={editor}
        onEditorStateChange={this.onChange}
        toolbar={toolbar}
      />
    );
  }
}

const defConfig = { className: undefined };
const toolbar = {
  options: ['inline', 'blockType', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'strikethrough'],
    bold: defConfig,
    italic: defConfig,
    strikethrough: defConfig,
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
};
