import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

const logo =
  'https://media.licdn.com/dms/image/C4E0BAQHyIK5STdJeDg/company-logo_200_200/0?e=2159024400&v=beta&t=yvayB-sh3kPxhFsPO2uQYLhLxBqKkgMT5A64g5qA724';
const link = 'https://fwdmax.fwd.co.th/registration/v/acbsdec';
export default class Suggest extends Component {
  render() {
    return (
      <Page>
        <Logo src={logo} />
        <Row>
          <Col span={16} offset={4}>
            <Main>
              <Title>สวัสดี คุณนวภัท จะบัง</Title>
              <Info>
                ขอต้อนรับเข้าสู่ <span>FWD MAX</span> แอปพลิเคชันสะสมแต้ม เพื่อแลกรับสิทธิพิเศษจากกิจกรรมประจำวัน
                ทั้งการเดิน การวิ่ง การเดินทาง
              </Info>

              <Body>
                <InfoTitle>เรายินดีมอบแต็มพิเศษเพิ่มอีก 100 แต็ม สำหรับการยืนยันอีเมล</InfoTitle>
                <Info>
                  เพื่อให้การยืนยันลงทะเบียนอีเมลของคุณสมบูรณ์และรับแต้มสะสมพิเศษเพิ่มเติมกรุณาคลิกลิงค์ด้านล่าง
                </Info>
                <Info>
                  <Link to={link}>{link}</Link>
                </Info>
                <Info>หากคลิกลิงค์ไม่สำเร็จ กรุณาคัดลองลิงค์แล้วเปิดในเบราเซอร์หลักของคุณ</Info>
              </Body>

              <Note>
                หมายเหตุซ อีเมล์ฉบับนี้ส่งโดยระบบอัตโนมัติ หากต้องการสอบถามเพิ่มเติม กรุณาติดต่อ FWDMAX@FWD.CO.TH
              </Note>
              <Note>ขอบคุณอีกครั้งที่คุณเข้าร่วมเป็นส่วนหนึ่งของ FWD MAX Reward your PASSION ทีมงาน FWD MAX</Note>
            </Main>
          </Col>
        </Row>

        <br />
        <Row style={{ textAlign: 'left' }}>
          <Col span={16} offset={4}>
            <SubMain>
              <Row>
                <Col span={4} style={{ textAlign: 'center' }}>
                  <LogoInfo src={logo} />
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                  <LogoInfo src={logo} />
                </Col>
                <Col span={12} offset={1}>
                  <Note>
                    บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน) 130-132 อาคารสินธร ทาวเวอร์ 3 ชั้น 14,16,27,28 และ 29
                    ถนนวิทยุ แขวงลุมพินี เขตปทุมวัน กรุงเทพ 10330 ศูนย์บริการลูกค้าแอฟดับบลิวดี 1351
                  </Note>
                  <br />
                  <Note>เปิดบริการ 08:00 - 20:00 น. ทุกวันไม่เว้นวันหยุด</Note>
                </Col>
              </Row>
            </SubMain>
          </Col>
        </Row>
      </Page>
    );
  }
}

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-height: 140px;
  padding: 10px;
  color: white;
`;

const LogoInfo = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-height: 80px;
  padding: 10px;
  color: white;
`;

const Page = styled.div`
  text-align: center;
  background-color: #e87722;
  padding: 40px;
  height: 100vh;
`;

const Main = styled.div`
  background-color: white;
  padding: 40px 20px;
  border-radius: 10px;
  min-height: 125px;
`;

const SubMain = styled.div`
  background-color: white;
  padding: 20px 20px;
  border-radius: 10px;
  min-height: 125px;
`;

const Title = styled.h1`
  color: #404040;
`;

const Info = styled.p`
  color: #404040;
  span {
    color: #e87722;
    font-weight: bold;
  }
`;

const InfoTitle = styled.p`
  color: #404040;
  font-weight: bold;
`;

const Body = styled.div`
  color: #404040;
  padding: 20px;
  margin 0px 60px;
  border-radius: 10px;
  border: solid 1px #C6C5C5;
  margin-bottom: 15px;
`;

const Note = styled.div`
  color: #6c6b6b;
  font-size: 10px;
  margin-bottom: 0.1em;
`;
