/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Checkbox } from 'antd';
import './css/TagSelector.css';

const options = [
  { label: 'Level 1', value: '1' },
  { label: 'Level 2', value: '2' },
  { label: 'Level 3', value: '3' },
  { label: 'Level 4', value: '4' },
  { label: 'Level 5', value: '5' },
];

function SpecificBadgeCheckBox(props) {
  return (
    <div className="tag-selector">
      <Checkbox.Group options={options} {...props} />
    </div>
  );
}

export default SpecificBadgeCheckBox;
