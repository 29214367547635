export const whitelistOptions = [
    { label: 'Whitelist 1', value: 'Whitelist 1' },
    { label: 'Whitelist 2', value: 'Whitelist 2' },
    { label: 'Whitelist 3', value: 'Whitelist 3' },
    { label: 'Whitelist 4', value: 'Whitelist 4' },
    { label: 'Whitelist 5', value: 'Whitelist 5' },
    { label: 'Whitelist 6', value: 'Whitelist 6' },
    { label: 'Whitelist 7', value: 'Whitelist 7' },
  ];

  export const specificBadgeOptions = [
    { label: 'Level 1', value: '1' },
    { label: 'Level 2', value: '2' },
    { label: 'Level 3', value: '3' },
    { label: 'Level 4', value: '4' },
    { label: 'Level 5', value: '5' },
  ];

  export const specificTierOptions = [
    { label: 'Tier 000', value: '000' },
    { label: 'Tier 100', value: '100' },
    { label: 'Tier 200', value: '200' },
    { label: 'Tier 300', value: '300' },
    { label: 'Tier 400', value: '400' },
    { label: 'Tier 500', value: '500' },
    { label: 'Tier 600', value: '600' },
    { label: 'Tier 700', value: '700' },
  ];

  export const codeShowOptions = [
    { label: 'Code', value: 'code' },
    { label: 'Barcode', value: 'barcode' },
    { label: 'QR Code', value: 'qrcode' },
  ];