import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js';
import { Row } from 'antd';
import { Title } from '../../components/common';
import { format } from '../../utils/format';

const BarGraph = (props) => {
  const { title, maxUserCount, doc = [] } = props;
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [borderColor, setBorderColor] = useState([]);

  const canvasRef = useRef(null);
  Chart.scaleService.updateScaleDefaults('linear', {
    ticks: {
      min: 0,
      stepSize: 1,
    },
  });
  Chart.defaults.global.defaultFontColor = '#666';
  Chart.defaults.global.defaultFontSize = 16;

  useEffect(() => {
    const firstDoc = doc.data[0];
    if (firstDoc) {
      setLabels(doc.labels);
      setData(firstDoc.data);
      setBackgroundColor(doc.backgroundColor);
      setBorderColor(doc.borderColor);
    }
  }, [doc]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Personalize activity',
            data,
            backgroundColor,
            borderColor,
            borderWidth: 1,
          },
        ],
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return format.toDigi(tooltipItem.yLabel);
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                autoSkipPadding: 100,
                callback: (value) => {
                  return format.toDigi(value);
                },
              },
            },
          ],
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: '#666',
            fontSize: 20,
            generateLabels: (chart) => {
              const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
              labels[0].fillStyle = '#fff';
              labels[0].strokeStyle = '#fff';
              return labels;
            }
          },
          onClick: null,
          onHover: null,
        },
      },
    });
  });

  return (
    <Row>
      <StyledRow type="flex" align="middle" justify="space-between">
        <Title>{title || ''}</Title>
        <StyledCount>{format.toDigi(maxUserCount)} USERS</StyledCount>
      </StyledRow>
      <Row type="flex">
        <Canvas id="myChart" ref={canvasRef}>
          <p>Your browser do not support canvas.</p>
        </Canvas>
      </Row>
    </Row>
  );
};

const StyledRow = styled(Row)`
  background: #fff;
  padding: 10px 10px 0 10px;
  max-width: 1280px;
`;

const Canvas = styled.canvas`
  width: 100%;
  max-width: 1280px;
  padding: 10px;
  background-color: white;
  height: auto;
`;

const StyledCount = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export default BarGraph;
