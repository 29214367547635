/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Typography, Modal, Col, Spin, Row } from 'antd';

import format from '../../utils/format';
import getCommuImage from '../../utils/getCommuImage';

const { Text } = Typography;

class CommunityDetailModal extends React.Component {
  state = {
    modal: false,
  };

  modalOpen = () => {
    this.setState({ modal: true });
  };

  modalClose = () => {
    this.setState({ modal: false });
  };

  approveBan = () => {
    this.props.approveBan(this.props.data.reportId);
  };

  rejectBan = () => {
    this.props.rejectBan(this.props.data.reportId);
  };

  render() {
    const { reportId, createdAt, post = { previewImages: [] }, type, status } = this.props.data;
    return (
      <Modal
        title={reportId ? `REPORT ID = ${reportId} - POST DETAIL :` : ''}
        visible={this.state.modal}
        onCancel={this.modalClose}
        footer={null}
        width="90%"
      >
        {this.props.loading ? (
          <div style={css.loading}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Row justify="center" type="flex">
              {post.type === 'challenge' ? (
                <Col style={{ marginRight: 10 }}>
                  <img alt="" style={css.img} src={post.data.imageUrl}></img>
                </Col>
              ) : post.previewImages.length ? (
                post.previewImages.map((img) => (
                  <Col style={{ marginRight: 10 }}>
                    <img alt="" style={css.img} src={img}></img>
                  </Col>
                ))
              ) : (
                <Col style={{ marginRight: 10 }}>
                  <img alt="" style={css.img} src={getCommuImage(post.type)}></img>
                </Col>
              )}
            </Row>
            <Row justify="center" type="flex" style={css.title}>
              <Text>{`"${post.title}"`}</Text>
            </Row>
            <Row gutter={50} justify="center" type="flex" style={css.content}>
              <Col className="gutter-row">
                <Row>
                  <Text type="secondary">POST DATE</Text>
                </Row>
                <Row>
                  <Text>{format.toDate(createdAt)}</Text>
                </Row>
              </Col>
              <Col className="gutter-row">
                <Row>
                  <Text type="secondary">POST OWNER</Text>
                </Row>
                <Row>
                  <Text>{post.displayName}</Text>
                </Row>
              </Col>
              <Col className="gutter-row">
                <Row>
                  <Text type="secondary">REPORT CATEGORY</Text>
                </Row>
                <Row>
                  <Text>{type}</Text>
                </Row>
              </Col>
              <Col className="gutter-row">
                <Row>
                  <div className="gutter-box">
                    <Text type="secondary">STATUS</Text>
                  </div>
                </Row>
                <Row>
                  <Text>{status}</Text>
                </Row>
              </Col>
            </Row>
            {status === 'pending' && (
              <div style={css.footer}>
                <Button style={css.buttonStyle} type="primary" shape="round" onClick={this.approveBan}>
                  Approve Ban
                </Button>
                <Button style={css.buttonStyle} type="primary" shape="round" onClick={this.rejectBan}>
                  Reject Ban
                </Button>
              </div>
            )}
          </>
        )}
      </Modal>
    );
  }
}

const css = {
  buttonStyle: {
    backgroundColor: '#E87722',
    borderColor: '#E87722',
    margin: '0 10px 0 10px',
  },
  title: {
    margin: '20px 0 20px 0',
  },
  img: {
    height: 200,
  },
  content: {
    margin: '3px 0 20px 0',
  },
  loading: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default CommunityDetailModal;
