import { cloneDeep } from 'lodash';
import XLSX from 'xlsx';
import moment from 'moment';
import format from '../utils/format';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { helper } from '../utils/helper';

const FORMAT = 'YYYY-MM-DD';

export class Dashboard extends BaseStore {
  constructor() {
    super();
    this.observable({
      challenges: {
        dynamicChallenges: [],
        graph: {
          labels: [],
          data: [],
        },
      },
      personalise: {
        maxUserCount: 0,
        graph: {
          labels: [],
          data: [{ label: 'Personalize activity', data: [] }],
          backgroundColor: [],
          borderColor: [],
        },
        summary: [],
        list: [],
      },
      registered: {
        graph: { labels: [], data: [] },
        summary: [],
        fetchingSummaryData: false,
        summaryDataSource: [],
        registration: {
          graph: { labels: [], data: [] },
          data: {},
        },
      },

      reward: {
        user: [],
        fetchRedeemDetail: false,
        redeemDetail: [],
        colBadgeSum: {},
      },
      inviteFriend: {
        list: [],
        loading: false,
        pagination: {},
      },
      loading: false,
    });
  }

  fetchRedeemDetails = async (rewardId, options = {}) => {
    this.reward.fetchRedeemDetail = true;
    const { start, end, badgeId = undefined, isAgent = undefined } = options;
    let url = `${config.api.max}/v1/office/reward/redeem/detail/${rewardId}?start=${start.format(
      'YYYY-MM-DD'
    )}&end=${end.format('YYYY-MM-DD')}`;

    if (badgeId) {
      url += `&badgeId=${badgeId}`;
    }

    if (isAgent) {
      url += `&isAgent=1`;
    }

    const res = await http.get(url, { token: true });
    this.reward.fetchRedeemDetail = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.reward.redeemDetail = res.body.map((record) => {
      return { ...record, date: format.toFullDT(record.date) };
    });
  };

  /**
   * Generates date range to be used as series for X-Axis
   *
   * @param { string } start start
   * @param { string } end date
   * @param { 'days'|'month'|'hour' } type of range
   */
  getDateRange = (start, end, type) => {
    const dates = [];
    const mStart = moment(start);
    const mEnd = moment(end);

    for (let s = mStart; s <= mEnd; s.add(1, 'day')) {
      switch (type) {
        case 'day':
          dates.push(s.format('YYYY-MM-DD'));
          break;
        case 'month':
          dates.push(s.format('YYYY-MM'));
          break;
        default:
          dates.push(s.format('YYYY-MM-DD'));
          break;
        // TODO: IMPLEMENT FOR HOUR AS WELL
      }
    }

    return dates;
  };

  getTimeRange = (start) => {
    const prefix = start.format('YYYY-MM-DD');
    const stopTime = Number(moment(new Date()).get('H'));
    const range = [];
    for (let s = 0; s <= stopTime; s += 1) {
      if (s < 10) {
        range.push(`${prefix} 0${s}:00:00`);
      } else {
        range.push(`${prefix} ${s}:00:00`);
      }
    }

    return range;
  };

  prepareData(xAxisRange) {
    const regLabels = ['NUMBER OF REGISTRATIONS', 'FWD CUSTOMERS', 'NON FWD CUSTOMERS', 'BANNED USERS'];
    const values = xAxisRange.reduce((dd, skipIt) => {
      dd.push(0);
      return dd;
    }, []);

    const mappedData = regLabels.map((name) => {
      return {
        name,
        list: values,
      };
    });

    const summary = [
      {
        key: undefined,
        name: 'Total Registration',
        value: 0,
      },
      {
        key: 'is_fwd',
        name: 'FWD Customers',
        percent: 0,
        value: 0,
      },
      {
        key: 'is_nonfwd',
        name: 'Lead',
        percent: 0,
        value: 0,
      },
      {
        key: 'is_ban',
        name: 'Banned Users',
        value: 0,
      },
    ];

    return {
      graph: { labels: xAxisRange, data: mappedData },
      summary,
    };
  }

  createSummary({ start, end, type = 'day', list = [] }) {
    // We have request to show data for same day, generate zero point values
    if (list.length === 0 && type === 'hour' && start.isSame(end, 'day')) {
      const timeRange = this.getTimeRange(start);
      return this.prepareData(timeRange);
    }

    // Generate zero data points
    if (list.length === 0 && type !== 'hour') {
      const dateRange = this.getDateRange(start, end, type);
      return this.prepareData(dateRange);
    }

    const points = {
      total: [],
      customer: [],
      nonCustomer: [],
      banned: [],
      active: [],
    };

    const origin = {
      total: 0,
      customer: 0,
      nonCustomer: 0,
      banned: 0,
      active: 0,
    };

    const labels = [];
    let counter = cloneDeep(origin);
    const sum = cloneDeep(origin);

    let tag = list[0].registered_at;
    list.forEach((item) => {
      if (tag !== item.registered_at) {
        labels.push(tag);
        tag = item.registered_at;
        points.total.push(counter.total);
        points.customer.push(counter.customer);
        points.nonCustomer.push(counter.nonCustomer);
        points.banned.push(counter.banned);

        counter = cloneDeep(origin);
      }

      switch (item.event_type) {
        case 'register':
          if (item.is_fwd === 1) {
            counter.customer += item.count;
            sum.customer += item.count;
          } else {
            counter.nonCustomer += item.count;
            sum.nonCustomer += item.count;
          }

          counter.total += item.count;
          sum.total += item.count;
          break;
        case 'inactive':
          counter.banned += item.count;
          sum.banned += item.count;
          break;
        case 'active':
          // not use now
          counter.active += item.count;
          sum.active += item.count;
          break;
        default:
          break;
      }
    });

    labels.push(tag);
    points.total.push(counter.total);
    points.customer.push(counter.customer);
    points.nonCustomer.push(counter.nonCustomer);
    points.banned.push(counter.banned);

    const data = [
      {
        name: 'NUMBER OF REGISTRATIONS',
        list: points.total,
      },
      {
        name: 'FWD CUSTOMERS',
        list: points.customer,
      },
      {
        name: 'LEAD',
        list: points.nonCustomer,
      },
      {
        name: 'BANNED USERS',
        list: points.banned,
      },
    ];

    const summary = [
      {
        name: 'Total Registration',
        value: sum.total,
      },
      {
        name: 'FWD Customers',
        percent: helper.toPercent(sum.customer, sum.total),
        value: sum.customer,
      },
      {
        name: 'LEAD',
        percent: helper.toPercent(sum.nonCustomer, sum.total),
        value: sum.nonCustomer,
      },
      {
        name: 'BANNED USERS',
        value: sum.banned,
      },
    ];
    const registration = {
      data: {
        start,
        end,
        timeType: type,
        list,
      },
    };
    return {
      graph: { labels, data },
      summary,
      registration,
    };
  }

  getDays({ start, end }) {
    const diff = end.unix() - start.unix();
    if (diff < 0) {
      error.launch({ message: 'End date must more than Start date' });
      return;
    }
    const days = diff / 86400;

    return days;
  }

  async getFilterRangeType({ start, end }) {
    const days = this.getDays({ start, end });
    let type;
    if (days < 3) type = 'hour';
    else if (days < 60) type = 'day';
    else type = 'month';

    return type;
  }

  async getChallengeChoices(type) {
    this.challenges.loading = true;
    const url = `${config.api.max}/v1/office/user/dashboard/challenges/choices?type=${type}`;

    const res = await http.get(url, { token: true });
    this.challenges.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.challenges.dynamicChallenges = res.body.data;
  }

  async getChallenge(params = {}) {
    this.loading = true;
    const { start, end, type = 'all', status = 'all', singleChallenge = 'all', activeInactive = 'all' } = params;
    const challengeId = singleChallenge === 'all' ? '' : singleChallenge;

    let query = '';
    let url = `${config.api.max}/v1/office/user/dashboard/challenges?type=${type}&status=${status}&challengeId=${challengeId}&activeInactive=${activeInactive}`;
    if (start) query += `&start=${start.format(FORMAT)}`;
    if (end) query += `&end=${end.format(FORMAT)}`;
    if (query !== '') url += query;

    const res = await http.get(url, { token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.challenges.graph.labels = res.body.data.labels;
    this.challenges.graph.data = res.body.data.datasets;
    this.challenges.dynamicChallenges = res.body.data.dynamicChallenges;
  }

  getSummaryData() {
    return this.registered.summaryDataSource;
  }

  async getSummaryModalData(params = {}) {
    this.registered.fetchingSummaryData = true;
    // http://127.0.0.1:3000/api/v1/office/user/registered/summary?type=day&start=2019-01-01&end=2020-01-29&summarytype=is_ban
    const { type, start, end, summarytype } = params;
    const query = `&start=${start}&end=${end}&summarytype=${summarytype}`;
    let url = `${config.api.max}/v1/office/user/registered/summary?type=${type}`;
    url = `${url}${query}`;

    this.registered.fetchingSummaryData = false;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }

    const sourceData = res.body || [];

    // Transform data
    const mappedDataSource = sourceData.reduce((acc, item) => {
      const p = {
        key: item.user_id,
        userId: item.user_id,
        name: item.fullname,
        customerType: helper.getLabelForFwdCustomerOrLead(item.is_fwd),
        telephone: item.telephone,
        date: item.registered_at,
      };

      acc.push(p);

      return acc;
    }, []);

    this.registered.summaryDataSource = mappedDataSource;
  }

  async getRegistered(params = {}) {
    const { start, end } = params;
    // type --> hour|day|month
    const type = await this.getFilterRangeType({ start, end });
    let url = `${config.api.max}/v1/office/user/registered?type=${type}`;
    let query = '';
    if (start) query += `&start=${start.format(FORMAT)}`;
    if (end) query += `&end=${end.format(FORMAT)}`;
    if (query !== '') url += query;

    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    this.registered = await this.createSummary({ start, end, type, list });
  }

  async getMaxUserCount() {
    const url = `${config.api.max}/v1/office/user/dashboard/personalise/maxusercount`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    const { data } = res.body;
    const { count = 0 } = data;
    this.personalise.maxUserCount = count;
  }

  processSummaryPercent(summary, total) {
    // sum percent from summary data
    const sum = summary.reduce((sum, personalize) => {
      if (typeof personalize.value === 'number' && personalize.percent !== 1) {
        sum += parseFloat(format.toPercent(personalize.value / total));
      }
      return parseFloat(sum.toFixed(2));
    }, 0);

    // cal diff 100 percent ex. 100 - 99.99 = 0.01 or 100 - 100.01 = -0.01
    const diffPercent = parseFloat((100 - sum).toFixed(2));

    // if not diff return old summary
    if (diffPercent === 0) {
      return summary;
    }

    // if diff add or subtract percent of Not Applicable
    const indexOfNotApplicable = summary.length - 2;
    const value = parseFloat(format.toPercent(summary[indexOfNotApplicable].value / total));
    summary[indexOfNotApplicable].percent = (value + diffPercent) / 100;
    return summary;
  }

  async getPersonalise() {
    this.personalise.summary = [];
    await this.getMaxUserCount();
    const url = `${config.api.max}/v1/office/user/dashboard/personalise`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { data } = res.body;

    const labels = data.map((d) => d.name);
    const list = data.map((d) => d.count);
    const personaliseTotal = list.reduce((total, num) => total + num);
    const backgroundColor = data.map((d) => helper.hexToRgbA(d.color, 0.7));
    const borderColor = data.map((d) => helper.hexToRgbA(d.color, 1));
    const total = this.personalise.maxUserCount;
    const notApplicable = total - personaliseTotal;
    const summary = data.map((d) => {
      return {
        name: d.name,
        value: d.count,
        percent: d.count / total,
        p_value: d.p_value,
      };
    });
    if (summary.length > 0) {
      summary.unshift({
        name: 'Personalize Tag',
        value: 'Value',
        percent: 'Percent',
        isBold: true,
        isClickAble: false,
      });
      summary.push({
        name: 'Migrate user who have not open application yet',
        value: notApplicable,
        percent: notApplicable / total,
        isClickAble: false,
      });
      summary.push({
        name: 'Total',
        value: total,
        percent: 1,
        isBold: true,
        isClickAble: false,
      });
    }

    this.personalise.graph = {
      labels,
      data: [{ label: 'Personalize activity', data: list }],
      backgroundColor,
      borderColor,
    };
    this.personalise.summary = this.processSummaryPercent(summary, total);
  }

  async getPersonaliseByTag(p_value, params) {
    this.personalise.list = [];
    const { limit, pageNo = 1 } = params;
    const url = `${config.api.max}/v1/office/user/dashboard/personalise/${p_value}?offset=${pageNo - 1}&limit=${limit}`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const { data } = res.body;
    this.personalise.list = data;
  }

  async getSummaryReward(params = {}) {
    const { start, end } = params;
    let query = '';

    let url = `${config.api.max}/v1/office/reward/summary/user?`;
    if (start) query += `&start=${start.format(FORMAT)}`;
    if (end) query += `&end=${end.format(FORMAT)}`;
    if (query !== '') url += query;

    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    const result = [];
    const origin = {
      id: undefined,
      image: undefined,
      name: '',
      l0: 0,
      l1: 0,
      l2: 0,
      l3: 0,
      l4: 0,
      l5: 0,
      agent: 0,
      total: 0,
    };

    let reward = cloneDeep(origin);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of list) {
      if (reward.id !== item.reward_id) {
        result.push(reward);
        reward = cloneDeep(origin);
        reward.id = item.reward_id;
        reward.image = helper.getUrl(item.image_redeem);
        reward.name = item.name_en;
      }

      if (item.is_agent === 1) reward.agent += item.counter;
      else reward[`l${item.level}`] += item.counter;

      reward.total += item.counter;
    }

    result.push(reward);
    result.shift();
    this.reward.user = result;

    this.reward.colBadgeSum = result.reduce(
      (acc, item) => {
        acc.l0 += item.l0;
        acc.l1 += item.l1;
        acc.l2 += item.l2;
        acc.l3 += item.l3;
        acc.l4 += item.l4;
        acc.l5 += item.l5;
        acc.agent += item.agent;
        acc.total += item.total;

        return acc;
      },
      {
        l0: 0,
        l1: 0,
        l2: 0,
        l3: 0,
        l4: 0,
        l5: 0,
        agent: 0,
        total: 0,
      }
    );
  }

  async getInviteFriend(date = {}, page = 1) {
    const { start, end } = date;
    const url = `${config.api.max}/v1/office/inviteFriend`;
    const qs = { page, limit: 10 };
    if (start) qs.start = start.format(FORMAT);
    if (end) qs.end = end.format(FORMAT);

    this.inviteFriend.loading = true;
    this.inviteFriend.list = [];

    const res = await http.get(url, { token: true, qs });
    this.inviteFriend.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    const { data = [], count = 0 } = res.body;
    this.inviteFriend.list = data;
    this.inviteFriend.pagination = {
      total: count,
      current: page,
    };
  }

  async exportInviteFriend(date, FIRST_ROW) {
    this.loading = true;
    const { start, end } = date;
    const url = `${config.api.max}/v1/office/inviteFriend`;
    const qs = { limit: 1000 };
    const items = [];
    let { total } = this.inviteFriend.pagination;
    let noTotal = false;
    if (start) qs.start = start.format(FORMAT);
    if (end) qs.end = end.format(FORMAT);
    if (!total) {
      noTotal = true;
      total = 1;
    }
    for (let page = 1; items.length < total; page++) {
      qs.page = page;
      const resp = await http.get(url, { qs, token: true });
      if (resp.statusCode !== 200) {
        error.launch({ message: resp.body.message });
        return;
      }
      if (noTotal) {
        total = resp.body.count;
        this.inviteFriend.pagination.total = total;
      }
      items.push(...resp.body.data);
    }

    const output = items.map((v) => {
      return [
        format.toFullDT(v.created_at),
        v.referral_code,
        v.referrer_id,
        v.tmp_phone,
        `${v.tmp_fname} ${v.tmp_lname}`,
        v.referrer_points,
        v.invitee_id,
        v.invitee_points,
      ];
    });
    const startDate = date.start.format('YYYYMMDD');
    const endDate = date.end.format('YYYYMMDD');
    const ws = XLSX.utils.aoa_to_sheet([FIRST_ROW, ...output]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'friend-get-friend');
    XLSX.writeFile(wb, `friend_get_friend_${startDate}-${endDate}_report.xlsx`);
    this.loading = false;
  }
}

export default new Dashboard();
