import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Alert, Table, Tag, Button, Modal, Divider } from 'antd';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading, DateCol } from '../../components/common';
import { shouldEnableButton } from '../../utils/acl';

const transform = (staffs = []) => {
  const records = staffs.map((u) => {
    const user = toJS(u);

    return {
      key: user.backoffice_user_id,
      name: `${user.firstname} ${user.lastname}`,
      type: user.role_name,
      registered: user.createdAt,
      mobile: user.telephone,
      username: user.username,
    };
  });

  return records;
};

const EditButton = (props) => {
  const { backofficeUserRole, staffRole, staffId } = props;

  if (shouldEnableButton(backofficeUserRole, staffRole)) {
    return (
      <Link to={`/office/staff/edit/${staffId}`}>
        <Button type="primary">Edit</Button>
      </Link>
    );
  }

  return (
    <Button type="primary" disabled>
      Edit
    </Button>
  );
};

const DeleteButton = (props) => {
  const { backofficeUserRole, staffRole, onDelete } = props;
  const enable = shouldEnableButton(backofficeUserRole, staffRole);

  if (!enable) {
    return (
      <Button type="danger" disabled icon="delete">
        Delete
      </Button>
    );
  }

  return (
    <Button type="danger" onClick={onDelete} icon="delete">
      Delete
    </Button>
  );
};

const StaffList = (props) => {
  const { staff } = props;
  const { pagination } = staff;
  const [dataSource, setDataSource] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState({
    offset: 0,
    limit: 20,
  });
  const [error, setError] = useState('');
  const { list } = staff;

  useEffect(() => {
    staff
      .fetchAll({ offset: paginationConfig.offset, limit: paginationConfig.limit })
      .then(() => {
        const data = staff.list.toJS();
        setDataSource(transform(data));
        setTotalRows(staff.pagination.totalRows);
      })
      .catch((er) => setError(er.message));
  }, [paginationConfig.limit, paginationConfig.offset, staff]);

  useEffect(() => {
    setDataSource(transform(list));
    setTotalRows(staff.pagination.totalRows);
  }, [list, staff.pagination.totalRows]);

  const onDelete = async (id, staffName) => {
    // Perform edit operation
    const deleteModal = Modal.confirm({
      title: `Delete Confirmation - ${staffName}`,
      content: 'Are you sure you want to delete this user?',
      destryOnClose: true,
      icon: 'delete',
      centered: true,
      visible: false,
      onOk: () => {
        deleteModal.update({
          visible: true,
          loading: true,
          okButtonProps: { disabled: true },
          cancelButtonProps: { disabled: true },
        });

        return new Promise((resolve, reject) => {
          return staff
            .delete(id)
            .then(() => {
              resolve('deleted');
            })
            .catch((e) => reject(e));
        });
      },
      onCancel: () => {},
    });
  };

  const backofficeUserRoles = {
    super_admin: 'Super Admin',
    admin: 'Admin',
    purchase: 'Purchase',
    call_center: 'Call Center',
    unknown: 'unknown',
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <span>
          <Link to={`/office/staff/view/${record.key}`}>{name}</Link>
          <br />({record.username})
        </span>
      ),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      render: (roleName) => <Tag color="blue">{backofficeUserRoles[roleName] || 'unknown'}</Tag>,
    },
    {
      title: 'REGISTERED',
      dataIndex: 'registered',
      key: 'registered',
      width: '140px', // 120px is too small for this column, it breaks word
      render: (createdAt) => <DateCol value={createdAt} />,
    },
    {
      title: 'MOBILE',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        const staffId = record.key;
        const staffName = record.name;
        const staffRole = record.type;
        const backofficeUserRole = props.member.user.profile.role_name;

        return (
          <div>
            <EditButton backofficeUserRole={backofficeUserRole} staffRole={staffRole} staffId={staffId} />
            <Divider type="vertical" />
            <DeleteButton
              backofficeUserRole={backofficeUserRole}
              staffRole={staffRole}
              onDelete={() => onDelete(staffId, staffName)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <HeaderMenu title="STAFF LIST">
      <StyledPage>
        {error && <Alert type="error" message={error} />}
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={staff.loading}
          pagination={{
            position: 'both',
            total: pagination.totalRows,
            pageSize: pagination.limit,
            showTotal: (total) => <span>Total Records: {total}</span>,
            onChange: (page, pageSize) => {
              setPaginationConfig({
                limit: pageSize,
                totalRows,
                offset: page - 1,
              });
            },
          }}
        />
      </StyledPage>
    </HeaderMenu>
  );
};

const StyledPage = styled(Page)`
  background-color: white;
  border-radius: 5px;
`;

export default inject('staff', 'member')(observer(StaffList));
