import React from 'react';
import styled from 'styled-components';
import HeaderMenu from '../../components/main/HeaderMenu';

const AccessDenied = () => {
  return (
    <HeaderMenu title="ACCESS DENIED">
      <StyledPage>
        <StyledTitle>Access Denied</StyledTitle>
        <StyledContent>Sorry! You do not have enough privilege to access this page.</StyledContent>
      </StyledPage>
    </HeaderMenu>
  );
};

const StyledPage = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  padding: 60px;
  width: 100%;
  text-align: center;
`;

const StyledContent = styled.p`
  font-size: 16px;
  padding: 20px 50px;
`;

const StyledTitle = styled.h2`
  text-align: center;
`;

export default AccessDenied;
