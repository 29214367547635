import React, { useRef } from 'react';
import { Row, Col } from 'antd';

import { Loading, Page } from '../../../components/common';
import Graph from '../Graph';
import Summary from '../Summary';
import DateFilter from '../../../components/filter/DateFilter';
import HeaderMenu from '../../../components/main/HeaderMenu';
import ChartActivity from '../activity/ChartActivity';

// Time unit used in Search filter
import { TIME_UNIT_DAY } from '../constants';

const AdminDashboard = (props) => {
  const {
    label = 'REGISTERED',
    type,
    timeUnit = TIME_UNIT_DAY,
    loading,
    registered,
    summaryData,
    fetching,
    onFind,
    onSummaryCountClick,
    graphRegister,
  } = props;

  const filterMoreItemRef = useRef(null);

  const bannedUsers = registered.summary.pop() || [];
  const bannedUsersList = [bannedUsers];

  let chart;
  if (graphRegister) {
    chart = <ChartActivity title="graphRegister" graph={graphRegister} />;
  }

  return (
    <HeaderMenu title={`${label} - ${type}`}>
      <Page style={{ background: '#fff', fontFamily: 'verdana, tahoma, sans-serif' }}>
        <Loading loading={loading} />
        <DateFilter onFind={onFind} ref={filterMoreItemRef} />
        <br />
        <Row>
          <Col span={22}>
            {/* <Graph title="NUMBER OF REGISTRATIONS" doc={registered.graph} timeUnit={timeUnit} /> */}
            {chart}
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={18}>
            <Summary
              title="SUMMARY OF REGISTERED"
              list={registered.summary}
              source={summaryData}
              loading={fetching}
              filterMoreRef={filterMoreItemRef}
              onCountClick={onSummaryCountClick}
            />
            <Summary
              title="BANNED USERS"
              list={bannedUsersList}
              source={summaryData}
              loading={fetching}
              filterMoreRef={filterMoreItemRef}
              onCountClick={onSummaryCountClick}
            />
          </Col>
        </Row>
      </Page>
    </HeaderMenu>
  );
};

export default AdminDashboard;
