import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import styled from 'styled-components';

import { Page, DisplayStatus, IntCol, DateCol } from '../../../components/common';
import HeaderMenu from '../../../components/main/HeaderMenu';

import { history } from '../../../utils/history';
import { notify } from '../../../utils/notify';
import { BadgeMap } from '../../../definition/mapping';

import CouponFilter from './CouponFilter';

const columns = [
  {
    title: 'NAME',
    dataIndex: 'name_en',
    key: 'name_en',
    render: (nameEn, record) => {
      const link = `/office/coupon/${record.coupon_id}/edit`;
      return (
        <Link style={{ color: '#686666' }} to={link}>
          {nameEn}
        </Link>
      );
    },
  },
  {
    title: 'POINTS',
    dataIndex: 'price',
    key: 'price',
    render: (points, record) => {
      const link = `/office/coupon/${record.coupon_id}/edit`;
      return (
        <Link style={{ color: '#686666' }} to={link}>
          <IntCol value={points} />
        </Link>
      );
    },
  },
  {
    title: 'BADGE',
    dataIndex: 'user_level',
    key: 'user_level',
    render: (userLevel) => {
      const badge = BadgeMap[userLevel] || userLevel;
      return <IntCol value={badge} />;
    },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: (status) => <DisplayStatus status={status} />,
  },
  {
    title: 'CREATED',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '120px',
    render: (createdAt) => <DateCol value={createdAt} />,
  },
  {
    title: 'START',
    dataIndex: 'start_date',
    key: 'start_date',
    width: '120px',
    render: (startDate) => <DateCol value={startDate} />,
  },
  {
    title: 'EXPIRE',
    dataIndex: 'end_date',
    key: 'end_date',
    width: '120px',
    render: (endDate) => <DateCol value={endDate} />,
  },
  {
    title: 'REDEEM',
    dataIndex: 'assigned',
    key: 'assigned',
    render: (assigned) => <IntCol value={assigned} />,
  },
  {
    title: 'REMAINING',
    dataIndex: 'available',
    key: 'available',
    render: (value) => <IntCol value={value} />,
  },
  {
    title: 'TOTAL',
    dataIndex: 'sum',
    key: 'sum',
    render: (value) => <IntCol value={value} />,
  },
];

export class CouponList extends Component {
  state = {
    loading: false,
  };

  params = {};

  onCreate = () => {
    history.push('/office/coupon/create');
  };

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      await this.props.coupon.getList(params);
    } catch (e) {
      notify.error({ title: 'Load Coupon Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onFilter = async (params = {}) => {
    params.offset = 1;
    this.params = params;
    this.onLoad(this.params);
  };

  onShowSizeChange = (current, size) => {
    this.params.offset = current;
    this.onLoad({
      ...this.params,
      offset: current,
      limit: size,
    });
  };

  onPagin = (offset) => {
    this.params.offset = offset;
    this.onLoad(this.params);
  };

  render() {
    const { loading } = this.state;
    const { offset: currentPage } = this.params;
    const doc = this.props.coupon.toJS().all;
    const { page, list } = doc;
    const dataSources = list.map((item, index) => {
      const { redeem = {} } = item;
      const { available = 0, assigned = 0, inactive = 0 } = redeem;
      const sum = assigned + available + inactive;

      return {
        ...item,
        key: index,
        assigned,
        available,
        sum,
      };
    });

    return (
      <HeaderMenu title="COUPON">
        <Page>
          <CouponFilter onFind={this.onFilter} />
          <Table
            loading={loading}
            bordered
            columns={columns}
            dataSource={dataSources}
            pagination={{
              current: currentPage || 1,
              position: 'both',
              total: Number(page.total),
              pageSizeOptions: ['10', '100', '1000'],
              onChange: this.onPagin,
              showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange,
              showQuickJumper: true,
              showTotal: (total) => `Total ${total}`,
              hideOnSinglePage: false,
            }}
          />
          <AddButtonCircle type="primary" shape="circle" onClick={this.onCreate}>
            <i className="fas fa-plus" />
          </AddButtonCircle>
        </Page>
      </HeaderMenu>
    );
  }
}

const AddButtonCircle = styled(Button)`
  width: 64px;
  height: 64px;
  bottom: 20px;
  right: 20px;
  font-size: 32px;
  z-index: 100;
  position: fixed;
`;

export default inject('coupon')(observer(CouponList));
