import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Input } from 'antd';
import styled from 'styled-components';
import mammoth from 'mammoth';

import HeaderMenu from '../../../components/main/HeaderMenu';
import { Loading, Page, Cols, SaveSection, OnRight } from '../../../components/common';
import { DropdownList, DraftEditor } from '../../../components/forms';
import { SelectFile } from '../../../components/button';
import { notify } from '../../../utils/notify';

export class ConfigBase extends Component {
  refEditor = React.createRef();

  state = {
    loading: false,
    menu: 'th',
  };

  componentDidMount() {
    this.name = this.props.type.toUpperCase();
    this.onFind();
  }

  onFind = async () => {
    try {
      this.setState({ loading: true });
      await this.props.config.getConsent({ type: this.props.type });
      this.setMarkdown();
    } catch (e) {
      notify.error({ title: `Load ${this.name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      await this.props.config.saveConsent({ type: this.props.type });
      notify.success({ title: `Save ${this.name} Completed` });
    } catch (e) {
      notify.error({ title: `Save ${this.name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onFile = async (file, name) => {
    const me = this;
    const input = {
      arrayBuffer: file,
    };
    mammoth
      .convertToMarkdown(input)
      .then(({ value, messages }) => {
        me.onMarkdown(value);
        me.refEditor.current.setMarkdown(value);
      })
      .done();
  };

  onMenu = async (val) => {
    await this.setState({ menu: val });
    this.setMarkdown();
  };

  onMarkdown = (val) => {
    const { menu } = this.state;
    const doc = this.props.config.toJS().consent;
    if (menu === 'th') doc.desc_th = val;
    else doc.desc_en = val;

    this.props.config.setConsent(doc);
  };

  setMarkdown = () => {
    const { menu } = this.state;
    const doc = this.props.config.toJS().consent;
    const value = menu === 'th' ? doc.desc_th : doc.desc_en;

    this.refEditor.current.setMarkdown(value);
  };

  onChange = async (evt) => {
    const data = this.props.config.toJS().consent;
    data.version = evt.target.value;
    this.props.config.setConsent(data);
  };

  render() {
    const { title, menus } = this.props;
    const { loading, menu } = this.state;
    const data = this.props.config.toJS().consent;

    return (
      <HeaderMenu title={title}>
        <Page>
          <Loading loading={loading} />
          <Form hideRequiredMark>
            <Section>
              <Row>
                <Cols span={4}>
                  <Form.Item label="Language">
                    <DropdownList allowClear={false} menus={menus} value={menu} onChange={this.onMenu} />
                  </Form.Item>
                </Cols>

                <Cols span={4}>
                  <Form.Item label="Version">
                    <Input value={data.version} onChange={this.onChange} />
                  </Form.Item>
                </Cols>
              </Row>
            </Section>
            <br />

            <Section>
              <DraftEditor ref={this.refEditor} onMarkdown={this.onMarkdown} />

              <OnRight>
                <SelectFile style={css.select} onChange={this.onFile} />
              </OnRight>
            </Section>

            <br />
            <br />
            <SaveSection onClick={this.onSave} />
          </Form>
        </Page>
      </HeaderMenu>
    );
  }
}

const Section = styled.div`
  padding: 10px;
  border-radius: 2px;
  background-color: white;
`;

const css = {
  input: { width: '100%' },
  select: { marginTop: '4px' },
};

export default inject('config')(observer(ConfigBase));
