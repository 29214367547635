/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Typography, Row, InputNumber } from 'antd';
import { inject, observer } from 'mobx-react';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { Loading, Page, SaveSection } from '../../../components/common';
import notify from '../../../utils/notify';

const { Text } = Typography;

function RenderItem({ title, value, set }) {
  function setValue(v) {
    if (v && v > 0) {
      set(v);
    }
  }

  return (
    <div style={{ marginBottom: 40 }}>
      <Text strong>{title}</Text>
      <Row>
        <Text style={{ marginRight: 10 }}>Number of report to hide post:</Text>
        <InputNumber style={{ textAlign: 'center' }} onChange={(v) => setValue(v)} value={value} />
        <Text style={{ marginLeft: 10 }}>times</Text>
      </Row>
    </div>
  );
}
function CommunityConfig(props) {
  const [nudity, setNudity] = useState(null);
  const [spam, setSpam] = useState(null);
  const [violence, setViolence] = useState(null);
  const [other, setOther] = useState(null);

  async function fetchApi() {
    try {
      await props.appConfig.getList();
    } catch (e) {
      notify.error({ title: 'Get Config Failed', message: e.message });
    }
  }
  useEffect(() => {
    fetchApi();
  }, []);

  useEffect(() => {
    setNudity(props.appConfig.general.maxReportNudity);
    setSpam(props.appConfig.general.maxReportSpam);
    setViolence(props.appConfig.general.maxReportViolence);
    setOther(props.appConfig.general.maxReportOther);
  }, [props.appConfig.general]);

  async function save() {
    try {
      await props.appConfig.updateList([
        {
          key: 'maxReportNudity',
          value: nudity,
          status: 'active',
        },
        {
          key: 'maxReportSpam',
          value: spam,
          status: 'active',
        },
        {
          key: 'maxReportViolence',
          value: violence,
          status: 'active',
        },
        {
          key: 'maxReportOther',
          value: other,
          status: 'active',
        },
      ]);
    } catch (e) {
      notify.error({ title: 'Update Config Failed', message: e.message });
    }
  }
  return (
    <HeaderMenu title="COMMUNITY">
      <Loading loading={props.appConfig.loading} />
      <Page style={css.page}>
        <RenderItem title="Nudity" value={nudity} set={setNudity} />
        <RenderItem title="Spam" value={spam} set={setSpam} />
        <RenderItem title="Violence" value={violence} set={setViolence} />
        <RenderItem title="Something Else" value={other} set={setOther} />
      </Page>
      <SaveSection onClick={save} />
    </HeaderMenu>
  );
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('appConfig')(observer(CommunityConfig));
