import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, DatePicker, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Filter, GroupItem, DropdownList, CheckList, Input } from '../../../components/forms';

import { Cols as Col } from '../../../components/common';
import { FindBtn } from '../../../components/button';
import { BadgeMenu } from '../../../definition/dropdown';
import { StatusFilter } from '../../../definition/filter';
import { ProvinceSelect } from '../../../components';
import surveyFieldOptions from './surveyFieldOptions';
import personalizeOptions from './personalizeOptions';

// #region --- Page Constants
const SELECT_VALUE_ALL = 'all';
const SELECT_VALUE_YES = 'yes';
const SELECT_VALUE_NO = 'no';
// #endregion

export default class UserFilter extends Component {
  state = {
    start: undefined,
    end: undefined,
    status: SELECT_VALUE_ALL,
    type: SELECT_VALUE_ALL,
    badge: [],
    name: '',
    surname: '',
    phone: '',
    email: '',
    uuid: '',
    citizenId: '',
    maxId: '',
    personalize: SELECT_VALUE_ALL,
    userProvince: SELECT_VALUE_ALL,
    userBirthday: undefined,
    searchSurveyFields: SELECT_VALUE_NO,
    surveyFields: {
      birthday: undefined,
      gender: SELECT_VALUE_ALL,
      income: SELECT_VALUE_ALL,
      education: SELECT_VALUE_ALL,
      occupation: SELECT_VALUE_ALL,
      maritalStatus: SELECT_VALUE_ALL,
      province: SELECT_VALUE_ALL,
    },
  };

  componentDidMount() {
    this.onFind();
  }

  onStartDate = (val) => {
    this.setState({ start: val });
  };

  onUserBirthdayChange = (val) => {
    this.setState({ userBirthday: val });
  };

  onEndDate = (val) => {
    this.setState({ end: val });
  };

  onChange = (val, name) => {
    const { state } = this;
    state[name] = val;
    this.setState(state);
  };

  onSurveyFieldsSearchChange = (val, name) => {
    this.setState({
      [name]: val,
    });
  };

  onSurveyFieldsChange = (val, name) => {
    const newSurveyFields = { ...this.state.surveyFields };
    newSurveyFields[name] = val;

    this.setState({
      surveyFields: newSurveyFields,
    });
  };

  onSurveyBirthdayChange = (date) => {
    this.onSurveyFieldsChange(date, 'birthday');
  };

  onUserProvinceChange = (province) => {
    this.onChange(province, 'userProvince');
  };

  onSurveyProvinceChange = (province) => {
    this.onSurveyFieldsChange(province, 'province');
  };

  onFind = (evt) => {
    if (evt) evt.preventDefault();

    if (this.props.onFind && typeof this.props.onFind === 'function') {
      this.props.onFind(this.state);
    }
  };

  onExportUser = () => {
    this.props.onExportUser(this.state);
  };

  disableBirthdayDates = (currentDate) => currentDate > moment(new Date());

  getFilter = () => {
    return this.state;
  };

  render() {
    const {
      start,
      end,
      status,
      type,
      badge,
      uuid,
      email,
      phone,
      name,
      surname,
      citizenId,
      maxId,
      personalize,
      userBirthday,
      searchSurveyFields,
      surveyFields,
    } = this.state;
    return (
      <Filter>
        <FilterPage>
          <Row>
            <Col span={5}>
              <GroupItem label="Start Date/Time">
                <DatePicker style={css.date} showTime value={start} onChange={this.onStartDate} />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem label="End Date/Time">
                <DatePicker style={css.date} showTime value={end} onChange={this.onEndDate} />
              </GroupItem>
            </Col>
            <Col span={4}>
              <GroupItem label="Status">
                <DropdownList
                  name="status"
                  menus={StatusFilter}
                  value={status}
                  allowClear={false}
                  onChange={this.onChange}
                />
              </GroupItem>
            </Col>
            <Col span={4}>
              <GroupItem label="Customer Type">
                <DropdownList name="type" menus={typeMenu} value={type} allowClear={false} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem label="Badge">
                <CheckList
                  name="badge"
                  menus={BadgeMenu}
                  placeHolder="Choose badge"
                  value={badge}
                  onChange={this.onChange}
                />
              </GroupItem>
            </Col>
          </Row>

          <Row>
            <Col span={5}>
              <GroupItem label="UUID">
                <Input name="uuid" placeHolder="Fill UUID" value={uuid} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem label="Citizen ID">
                <Input name="citizenId" placeHolder="Fill Citizen ID" value={citizenId} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={4}>
              <GroupItem label="Max ID">
                <Input name="maxId" placeHolder="Fill Max ID" value={maxId} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={4}>
              <GroupItem label="Name">
                <Input name="name" placeHolder="Fill Name" value={name} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem label="Last Name">
                <Input name="surname" placeHolder="Fill Last Name" value={surname} onChange={this.onChange} />
              </GroupItem>
            </Col>
          </Row>

          <Row>
            <Col span={5}>
              <GroupItem label="Email">
                <Input name="email" placeHolder="Fill Email" value={email} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem label="Phone">
                <Input name="phone" placeHolder="Fill Phone" value={phone} onChange={this.onChange} />
              </GroupItem>
            </Col>
            <Col span={4}>
              <GroupItem label="Personalize">
                <DropdownList
                  name="personalize"
                  menus={personalizeOptions}
                  value={personalize}
                  allowClear={false}
                  onChange={this.onChange}
                />
              </GroupItem>
            </Col>
            <Col span={4}>
              <GroupItem label="User Birthday">
                <DatePicker
                  disabledDate={this.disableBirthdayDates}
                  name="userBirthday"
                  style={css.date}
                  showTime={false}
                  value={userBirthday}
                  onChange={this.onUserBirthdayChange}
                />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem label="User Province">
                <Row gutter={8}>
                  <Col span={22}>
                    <ProvinceSelect style={{ width: '100%' }} onChange={this.onUserProvinceChange} />
                  </Col>
                  <Col span={2}>
                    <Tooltip title="This will search in both Thai and English province name.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              </GroupItem>
            </Col>
          </Row>

          <Row>
            <Col span={5}>
              <GroupItem label="Survey">
                <DropdownList
                  name="survey_fields"
                  menus={searchSurveyFieldsMenu}
                  value={searchSurveyFields}
                  allowClear={false}
                  onChange={(val) => this.setState({ searchSurveyFields: val })}
                />
              </GroupItem>
            </Col>
            <Col span={5}>
              <GroupItem>
                <FindBtn onClick={this.onFind} />
              </GroupItem>
            </Col>
          </Row>

          {this.state.searchSurveyFields === SELECT_VALUE_YES && (
            <Row>
              <Row>
                <Line />
                <h3 style={{ padding: '10px' }}>
                  Survey Fields{' '}
                  <Tooltip title="These fields must exactly match with user data.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </h3>
                <Col span={5}>
                  <GroupItem label="Birthday">
                    <DatePicker
                      disabledDate={this.disableBirthdayDates}
                      name="birthday"
                      style={css.date}
                      showTime={false}
                      value={this.state.surveyFields.birthday}
                      onChange={this.onSurveyBirthdayChange}
                    />
                  </GroupItem>
                </Col>
                <Col span={5}>
                  <GroupItem label="Gender">
                    <DropdownList
                      name="gender"
                      menus={surveyFieldOptions.gender}
                      value={surveyFields.gender}
                      allowClear={false}
                      onChange={this.onSurveyFieldsChange}
                    />
                  </GroupItem>
                </Col>
                <Col span={4}>
                  <GroupItem label="Income">
                    <DropdownList
                      name="income"
                      menus={surveyFieldOptions.income}
                      value={surveyFields.income}
                      allowClear={false}
                      onChange={this.onSurveyFieldsChange}
                    />
                  </GroupItem>
                </Col>
                <Col span={4}>
                  <GroupItem label="Education">
                    <DropdownList
                      name="education"
                      menus={surveyFieldOptions.education}
                      value={surveyFields.education}
                      allowClear={false}
                      onChange={this.onSurveyFieldsChange}
                    />
                  </GroupItem>
                </Col>
                <Col span={5}>
                  <GroupItem label="Marital Status">
                    <DropdownList
                      name="maritalStatus"
                      menus={surveyFieldOptions.maritalStatus}
                      value={surveyFields.maritalStatus}
                      allowClear={false}
                      onChange={this.onSurveyFieldsChange}
                    />
                  </GroupItem>
                </Col>
              </Row>

              <Row>
                <Col span={5}>
                  <GroupItem label="Survey Province">
                    <ProvinceSelect showSearch style={{ width: '100%' }} onChange={this.onSurveyProvinceChange} />
                  </GroupItem>
                </Col>
                <Col span={5}>
                  <GroupItem label="Occupation">
                    <DropdownList
                      name="occupation"
                      menus={surveyFieldOptions.occupation}
                      value={surveyFields.occupation}
                      allowClear={false}
                      onChange={this.onSurveyFieldsChange}
                    />
                  </GroupItem>
                </Col>
              </Row>
            </Row>
          )}
        </FilterPage>
      </Filter>
    );
  }
}

const css = {
  date: {
    width: '100%',
  },
  exportButton: {
    textAlign: 'center',
    width: '100%',
  },
};

const FilterPage = styled.div`
  padding: 10px;
`;

const Line = styled.hr`
  margin: 10px 0px;
  border: none;
  border-top: 1px solid #ccc;
`;

const typeMenu = [
  { name: 'All', value: SELECT_VALUE_ALL },
  { name: 'FWD Customer', value: SELECT_VALUE_YES },
  { name: 'Lead', value: SELECT_VALUE_NO },
];

const searchSurveyFieldsMenu = [{ name: 'No', value: SELECT_VALUE_NO }, { name: 'Yes', value: SELECT_VALUE_YES }];
