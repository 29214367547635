import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SaveSection } from '../../../components/common';
import RowInline from './RowInline';

export class Reward extends Component {
  onChange = (name, tag, val) => {
    this.doc[name][tag] = val;
    this.props.point.setPassiveActivity(this.doc);
  };

  render() {
    const doc = this.props.point.toJS().passive;
    this.doc = doc;
    return (
      <div>
        <RowInline title="Share Reward" name="share_reward" item={doc.share_reward} onChange={this.onChange} />

        <SaveSection onClick={this.props.onSave} />
      </div>
    );
  }
}

export default inject('point')(observer(Reward));
