import React from 'react';
import Chart from 'chart.js';
import styled from 'styled-components';
import {format} from "../../../utils/format";

const { getDateFormat } = require('./chartUtils');

const colors = [
  { background: 'rgba(231, 62, 80, 0.5)', border: 'rgba(231, 62, 80, 0.5)' },
  { background: 'rgba(77, 104, 151, 0.5)', border: 'rgba(77, 104, 151, 0.5)' },
  { background: 'rgba(131, 113, 169, 0.5)', border: 'rgba(131, 113, 169, 0.5)' },
  { background: 'rgba(160, 81, 149, 0.5)', border: 'rgba(160, 81, 149, 0.5)' },
  { background: 'rgba(184, 114, 174, 0.5)', border: 'rgba(184, 114, 174, 0.5)' },
  { background: 'rgba(247, 152, 111, 0.5)', border: 'rgba(247, 152, 111, 0.5)' },
  { background: 'rgba(249, 190, 81, 0.5)', border: 'rgba(249, 190, 81, 0.5)' },
];

const Canvas = styled.canvas``;
const Div = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

export default class ChartActivity extends React.Component {
  myChart;

  constructor(props) {
    super(props);
    console.log('[ChartActivity][constructor]', this.props);
  }

  componentDidMount() {
    console.log('[ChartActivity][componentDidMount]', this.props.title);
    this.ctx = document.getElementById(this.props.title).getContext('2d');
    console.log('[ChartActivity][componentDidMount]ctx', this.ctx);
  }

  componentDidUpdate() {
    console.log('[ChartActivity][componentDidUpdate]');
    this.setGraph({ graph: this.props.graph });
  }

  getStepSize(max, numPoints) {
    const stepSize = Math.max(1, Math.ceil(max / numPoints));
    console.log('[ChartActivity][stepSize]', { max, numPoints, stepSize });
    return stepSize;
  }

  getMax(max, numPoints) {
    let result = max;
    const stepSize = this.getStepSize(max, numPoints);
    if (max % stepSize) {
      result = stepSize * (numPoints + 1);
      if (max > result) {
        result = stepSize * (numPoints + 2);
      }
    }
    if (max === 0) {
      result = numPoints;
    }
    console.log('[ChartActivity][getMax]', { max, numPoints, stepSize, result });
    return result;
  }

  setGraph({ graph }) {
    const { ctx } = this;
    if (!graph || !graph.datasets) {
      return;
    }

    const datasets = graph.datasets.map((item, index) => {
      const color = colors[index % colors.length];
      return {
        type: 'line',
        label: item.name,
        fill: false,
        data: item.list,
        backgroundColor: color.background,
        borderColor: color.border,
        borderWidth: 2,
        lineTension: 0.05,
      };
    });
    const { info, param } = graph;
    console.log('[ChartActivity][setGraph]', { info, datasets, param });
    const { myChart } = this;
    if (myChart) {
      myChart.destroy();
    }

    console.log('[ChartActivity][setGraph]', {
      max: Math.max(info.maxY, info.numPointsY),
      stepSize: this.getStepSize(info.maxY, info.numPointsY),
    });

    const chartData = {
      type: 'line',
      data: {
        labels: graph.labels,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        // onResize: (chart, newSize) => console.log('[onResize]', chart, newSize),
        hover: {
          intersect: false,
          animationDuration: 0,
        },
        tooltips: {
          mode: 'nearest',
          callbacks: {
            label: (tooltipItem) => {
              return `${datasets[tooltipItem.datasetIndex].label}: ${format.toDigi(tooltipItem.yLabel)}`;
            },
          },
        },
        animation: {
          duration: 0,
        },
        legend: {
          display: true,
          position: 'top',
          onClick: null,
          onHover: null,
          labels: {
            boxWidth: 20,
            padding: 50,
          },
          fullWidth: true,
        },
        scales: {
          yAxes: [
            {
              position: 'left',
              scaleLabel: {
                display: true,
                fontSize: 20,
                labelString: info.yLabel,
              },
              type: 'linear',
              ticks: {
                min: 0,
                max: this.getMax(info.maxY, info.numPointsY),
                stepSize: this.getStepSize(info.maxY, info.numPointsY),
                callback: (value) => {
                  return format.toDigi(value);
                },
              },
            },
          ],
          xAxes: [
            {
              position: 'bottom',
              scaleLabel: {
                display: true,
                fontSize: 20,
                labelString: info.xLabel,
              },
              type: 'time',
              distribution: 'series',
              time: {
                displayFormats: {
                  hour: getDateFormat(param.timeType),
                  day: getDateFormat(param.timeType),
                  month: getDateFormat(param.timeType),
                },
                tooltipFormat: getDateFormat(param.timeType),
                unit: param.timeType,
              },
              ticks: {
                minRotation: 50,
                maxRotation: 50,
                // callback(value, index, values) {
                //   if (index % 5 === 0) return `$${index}`;
                //   return '';
                // },
              },
            },
          ],
        },
      },
    };

    this.myChart = new Chart(ctx, chartData);
  }

  render() {
    console.log('[ChartActivity][render]', this.props);

    return (
      <Div>
        <Canvas id={this.props.title}>
          <p>Canvas not supported</p>
        </Canvas>
      </Div>
    );
  }
}
