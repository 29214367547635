import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Avatar, Table, Row, Modal, Col } from 'antd';
import XLSX from 'xlsx';
import styled from 'styled-components';

import HeaderMenu from '../../../components/main/HeaderMenu';
import { Page, IntCol, Cols, NoData, Loading } from '../../../components/common';

import { notify } from '../../../utils/notify';

import DateFilter from '../../../components/filter/DateFilter';
import { ExportButton } from '../../../components/button';

const name = 'REWARDS';

const BadgeColVal = ({ id, value, onClick }) => {
  if (Number(value) === 0) {
    return <IntCol style={{ textAlign: 'center', padding: '0' }} value={value} />;
  }

  return (
    <a href={`/rewards/redeem/detail/${id}`} onClick={onClick}>
      <IntCol style={{ textAlign: 'center', padding: '0' }} value={value} />
    </a>
  );
};

const DashboardReward = (props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [redeemDetailDatasource, setRedeemDetailDatasource] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFetchingRedeemDetail, setIsFetchingRedeemDetail] = useState(true);
  const [redeemRewardName, setRedeemRewardName] = useState('');
  const [headerAdded, setHeaderAdded] = useState(false);

  const dashboardStore = props.dashboard.toJS();
  const { reward } = dashboardStore;
  const list = reward.user;
  const footerSum = reward.colBadgeSum;

  const extractRedeemDetailList = () => {
    const { redeemDetail = [], fetchRedeemDetail } = props.dashboard.toJS().reward;

    setRedeemDetailDatasource(redeemDetail);
    setIsFetchingRedeemDetail(fetchRedeemDetail);
  };

  const onFind = async (params = {}) => {
    try {
      setLoading(true);
      const { start, end } = params;
      setOptions({ start, end });
      await props.dashboard.getSummaryReward(params);
    } catch (e) {
      notify.error({ title: 'Load Reward Fail', message: e.message });
    }
    setLoading(false);
  };

  const onBadgeCountClickHandler = async (e, rewardId, badgeId, isAgent, selectedRowIndex) => {
    e.preventDefault();

    // Set Reward Name
    const rewardName = list[selectedRowIndex].name;
    setRedeemRewardName(rewardName);

    setRedeemDetailDatasource([]);
    setIsFetchingRedeemDetail(true);
    setModalVisible(true);

    // Fetch  details
    options.badgeId = badgeId;
    options.isAgent = isAgent === true ? 1 : 0;
    await props.dashboard.fetchRedeemDetails(rewardId, options);
    extractRedeemDetailList();
  };

  const doExport = async (fileName, dataSource) => {
    try {
      const output = dataSource.map((d) => {
        delete d.key;

        return d;
      });

      output.unshift({
        userId: 'Max UserID',
        name: 'User Fullname',
        badge: 'Badge',
        telephone: 'Telelphone',
        date: 'Date',
        redeemCode: 'Redeem Code',
      });

      const sheetName = '';
      const ws = XLSX.utils.json_to_sheet([...output], { skipHeader: true });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  const rows = list.map((item, index) => {
    return (
      <Row style={rowItem} key={index}>
        <Cols span={2}>
          <Avatar style={css.img} src={item.image} shape="square" icon="file-image" />
        </Cols>
        <Cols span={9}>{item.name}</Cols>

        <Cols span={11}>
          <Col span={4}>
            <BadgeColVal
              id={item.id}
              value={item.l1}
              onClick={(e) => onBadgeCountClickHandler(e, item.id, 1, false, index)}
            />
          </Col>

          <Col span={4}>
            <BadgeColVal
              id={item.id}
              value={item.l2}
              onClick={(e) => onBadgeCountClickHandler(e, item.id, 2, false, index)}
            />
          </Col>
          <Col span={4}>
            <BadgeColVal
              id={item.id}
              value={item.l3}
              onClick={(e) => onBadgeCountClickHandler(e, item.id, 3, false, index)}
            />
          </Col>

          <Col span={4}>
            <BadgeColVal
              id={item.id}
              value={item.l4}
              onClick={(e) => onBadgeCountClickHandler(e, item.id, 4, false, index)}
            />
          </Col>

          <Col span={4}>
            <BadgeColVal
              id={item.id}
              value={item.l5}
              onClick={(e) => onBadgeCountClickHandler(e, item.id, 5, false, index)}
            />
          </Col>

          <Col span={4}>
            <BadgeColVal
              id={item.id}
              value={item.agent}
              onClick={(e) => onBadgeCountClickHandler(e, item.id, undefined, true, index)}
            />
            {/* <IntCol style={{ textAlign: 'center', padding: '0' }} value={item.agent} /> */}
          </Col>
        </Cols>

        <Cols span={2}>
          <BadgeColVal
            id={item.id}
            value={item.total}
            onClick={(e) => onBadgeCountClickHandler(e, item.id, undefined, false, index)}
          />
        </Cols>
      </Row>
    );
  });

  return (
    // const { loading } = this.state;
    <HeaderMenu title={name}>
      <Page style={pageCss}>
        <Loading loading={loading} />
        <DateFilter onFind={onFind} />
        <br />
        <Row style={rowName}>
          <Cols span={4} />
          <Cols span={7}>
            <StyledRewardHeader style={{ textAlign: 'left' }}>REWARD NAME</StyledRewardHeader>
          </Cols>
          <Cols span={11}>
            <StyledRewardHeader>BADGE</StyledRewardHeader>
            <br />
            <Col span={4} style={css.center}>
              1
            </Col>
            <Col span={4} style={css.center}>
              2
            </Col>
            <Col span={4} style={css.center}>
              3
            </Col>
            <Col span={4} style={css.center}>
              4
            </Col>
            <Col span={4} style={css.center}>
              5
            </Col>
            <Col span={4} style={css.center}>
              Agent
            </Col>
          </Cols>
          <Cols span={2} style={css.center}>
            <StyledRewardHeader>TOTAL</StyledRewardHeader>
          </Cols>
        </Row>

        <NoData list={rows} />
        <Row style={footerRowName}>
          <Cols span={4} />
          <Cols span={7}>
            <StyledRewardFooter>TOTAL</StyledRewardFooter>
          </Cols>
          <Cols span={11}>
            <Col span={4} style={css.center}>
              {footerSum.l1}
            </Col>
            <Col span={4} style={css.center}>
              {footerSum.l2}
            </Col>
            <Col span={4} style={css.center}>
              {footerSum.l3}
            </Col>
            <Col span={4} style={css.center}>
              {footerSum.l4}
            </Col>
            <Col span={4} style={css.center}>
              {footerSum.l5}
            </Col>
            <Col span={4} style={css.center}>
              {footerSum.agent}
            </Col>
          </Cols>
          <Cols span={2} style={css.center}>
            <StyledRewardFooter>{footerSum.total}</StyledRewardFooter>
          </Cols>
        </Row>
        <Modal
          closeable
          width={1024}
          height={520}
          centered
          footer={null}
          title={
            <ModalTitle
              title={redeemRewardName}
              handleClick={() => doExport(redeemRewardName, redeemDetailDatasource)}
              disableExport={isFetchingRedeemDetail}
            />
          }
          visible={modalVisible}
          destroyOnClose
          onCancel={() => setModalVisible(false)}
        >
          <Table
            dataSource={redeemDetailDatasource}
            columns={redeemColumns}
            loading={isFetchingRedeemDetail}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Modal>
      </Page>
    </HeaderMenu>
  );
};

const ModalTitle = ({ title, handleClick, disableExport }) => {
  const StyledHeader = styled.h1`
    font-size: 16px;
    font-weight: bold;
    float: left;
  `;

  return (
    <div style={{ overflow: 'auto' }}>
      <StyledHeader>{title}</StyledHeader>
      <ExportButton type="primary" onClick={handleClick} disabled={disableExport}>
        Export
      </ExportButton>
    </div>
  );
};

const redeemColumns = [
  {
    title: 'MaxID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Badge',
    dataIndex: 'badge',
    key: 'badge',
  },
  {
    title: 'Telephone',
    dataIndex: 'telephone',
    key: 'telephone',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Redeem Code',
    dataIndex: 'redeemCode',
    key: 'redeemCode',
  },
];

const pageCss = {
  fontFamily: 'tahoma, verdana, sans-serif',
  backgroundColor: 'white',
  borderRadius: '5px',
};

const rowName = {
  backgroundColor: 'white',
  border: 'solid 0px #C6C5C5',
  borderBottomWidth: '1px',
  padding: '25px 15px 10px 20px',
  fontWeight: 'bold',
};

const footerRowName = {
  borderBottomWidth: '1px',
  padding: '25px 15px 10px 20px',
  fontWeight: 'bold',
};

const rowItem = {
  backgroundColor: 'white',
  border: 'solid 0px #C6C5C5',
  borderBottomWidth: '1px',
  padding: '25px 15px 10px 20px',
};

const css = {
  center: { textAlign: 'center' },
  img: { width: '100%', height: '60px' },
};

const StyledRewardHeader = styled.div`
  text-align: center;
  padding: 10px;
  display: block;
  font-size: 16px;
  line-height: 16px;
`;

const StyledRewardFooter = styled.div`
  text-align: center;
  display: block;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 20px;
`;

export default inject('dashboard')(observer(DashboardReward));
