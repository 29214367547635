import React, { useEffect, useState, useRef } from 'react';
import { Row } from 'antd';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import LineGraph from './LineGraph';
import { Loading } from '../../../components/common';
import DateFilter from '../../../components/filter/DateFilter';
import HeaderMenu from '../../../components/main/HeaderMenu';
import FilterMoreItem from './FilterMoreItem';

const ChallengeDashboardWithGraph = (props) => {
  const filterMoreItemRef = useRef(null);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    props.dashboard.getChallenge(filters).catch((e) => console.error(e));
  }, [filters, props.dashboard]);

  const onFind = (value) => {
    const { start, end } = value;
    const { type, status, singleChallenge, activeInactive } = filterMoreItemRef.current.get();
    setFilters({ start, end, type, status, singleChallenge, activeInactive });
  };

  const fetchChoices = (type) => {
    props.dashboard.getChallengeChoices(type);
  };

  return (
    <HeaderMenu title="CHALLENGE">
      <StyledPage>
        <Loading loading={props.dashboard.loading} />
        <DateFilter
          onFind={onFind}
          moreItem={
            <FilterMoreItem
              ref={filterMoreItemRef}
              fetchChoices={fetchChoices}
              challenges={props.dashboard.challenges.dynamicChallenges}
              loading={props.dashboard.challenges.loading}
            />
          }
        />
        <br />
        <Row>
          {!props.dashboard.loading && (
            <LineGraph
              title="CHALLENGE TYPES"
              labels={props.dashboard.challenges.graph.labels}
              data={props.dashboard.challenges.graph.data}
            />
          )}
        </Row>
      </StyledPage>
    </HeaderMenu>
  );
};

const StyledPage = styled.div`
  padding: 20px;
  background-color: #fff;
`;

export default inject('dashboard')(observer(ChallengeDashboardWithGraph));
