import { SUPER_ADMIN, ADMIN, PURCHASE, CALL_CENTER } from '../config/user-roles';
import permissions from '../config/permissions';
/**
 * Checks for currently logged in user in backoffice and return true if user can
 * is privileged to perform action, otherwise it returns false.
 *
 * @param {string} backofficeUserRole role of the currently logged in backoffice user
 * @param {string} staffRole role of the current staff row
 * @returns {boolean} Returns true if action can be performed otherwise returns false
 */
export const shouldEnableButton = (backofficeUserRole, staffRole) => {
  const backofficeSuperAdmin = backofficeUserRole === SUPER_ADMIN;
  const bothSuperAdmin = backofficeUserRole === SUPER_ADMIN && staffRole === SUPER_ADMIN;
  const staffNotSuperAdmin = backofficeUserRole === ADMIN && (staffRole === PURCHASE || staffRole === CALL_CENTER);

  return backofficeSuperAdmin || bothSuperAdmin || staffNotSuperAdmin;
};

const createRoleCheck = (name) => (backofficeRoleName) => name === backofficeRoleName;

export const superAdminOnly = createRoleCheck(SUPER_ADMIN);
export const generalAdminOnly = createRoleCheck(ADMIN);

export const generalOrSuperAdmin = (roleName) => superAdminOnly(roleName) || generalAdminOnly(roleName);

/**
 * Checks if user can use this permission or not
 * @param {string} permission User Permission to check
 * @param {string} userRole user Role to check for permission
 * @return {boolean} returns true if user can access otherwise false
 */
export const can = (permission, userRole) => {
  // console.log({ rolePermissions: permissions[userRole] });
  if (permissions[userRole]) {
    const hasPermission = permissions[userRole][permission];
    if (!hasPermission) {
      return false;
    }

    return true;
  }

  return undefined;
};

export default {
  createRoleCheck,
  shouldEnableButton,
  can,
  generalOrSuperAdmin,
};
