const config = {
  web: {
    maintenance: false,
  },
  upload: {
    url: 'https://s3-ap-southeast-1.amazonaws.com/www.fwd-dev',
  },
  api: {
    // max: 'http://fwd-dev.appmojo.com/api',
    max: 'http://127.0.0.1:3000/api',
  },
  limit: {
    page: 10,
    export: 100,
    generate: 100,
  },
};

module.exports = config;
