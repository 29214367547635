import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { storage } from '../utils/storage';
import { SUPER_ADMIN } from '../config/user-roles';
import notify from '../utils/notify';

const origin_user = {
  id: '',
  profile: {
    backoffice_user_id: -1,
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    role_name: '',
    telephone: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  },
  acl: {
    role: '',
    permission: {},
  },
};

export class Member extends BaseStore {
  constructor() {
    super();
    this.observable({
      user: cloneDeep(origin_user),
      loading: false,
    });

    this.verifyToken();
    this.loadApiConfig();
  }

  reset() {
    this.user = cloneDeep(origin_user);
  }

  async loadApiConfig() {
    const doc = storage.load('api');
    if (doc) {
      config.api.max = doc.url;
    }
  }

  async verifyToken() {
    const auth = storage.load('authentication');
    if (auth && auth.token) {
      // CHANGE TOKEN
      this.user.id = '1';
      await http.setToken(auth.token);
    }
  }

  saveAcl({ acl }) {
    storage.save('acl', acl);
  }

  removeAcl() {
    storage.remove('acl');
  }

  getUserProfileFromStorage() {
    return storage.load('profile');
  }

  saveUserProfileToStorage(userProfile) {
    storage.save('profile', userProfile);
  }

  saveToStorage({ token }) {
    const auth = {
      token,
    };
    storage.save('authentication', auth);
  }

  removeStorage() {
    storage.save('authentication', {});
  }

  async login(params = {}) {
    try {
      const { username, password } = params;
      const json = {
        username,
        password,
      };

      const url = `${config.api.max}/v1/public/office/login`;
      const res = await http.post(url, { json });
      if (res.statusCode !== 200) {
        error.launch({ message: res.body.message });
        return;
      }

      const { token, acl, userId } = res.body;
      this.saveToStorage({ token });
      await http.setToken(token);
      this.user.id = userId;
      this.user.acl = acl;
      await this.getUserProfile();

      this.saveAcl({ acl: acl.permission, role: acl.role });
      this.saveUserProfileToStorage(this.user.profile);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  isLogin() {
    return this.user.id !== '';
  }

  getRoleName() {
    return this.user.profile.role_name;
  }

  getUserFullName() {
    const userProfile = this.user.profile;

    return `${userProfile.firstname} ${userProfile.lastname}`;
  }

  async getUserProfile() {
    this.loading = true;

    const auth = storage.load('authentication');
    http.setToken(auth.token);

    const url = `${config.api.max}/v1/office/staffs/me`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      this.loading = false;

      notify.error({ title: 'Authentication Error', message: res.body.message });
      return;
    }

    this.user.profile = res.body.data.profile;
    this.loading = false;
  }

  removeProfile() {
    storage.save('profile', {});
  }

  logout() {
    this.reset();
    this.removeStorage();
    this.removeAcl();
    this.removeProfile();
  }

  async saveUserProfile(backofficeUserId, data) {
    try {
      this.loading = true;

      const auth = storage.load('authentication');
      http.setToken(auth.token);

      const url = `${config.api.max}/v1/office/staffs/${backofficeUserId}`;
      const res = await http.patch(url, { json: data, token: true });
      this.loading = false;
      if (res.statusCode !== 200) {
        Promise.reject(res.body);
        return;
      }
      Promise.resolve();
    } catch (e) {
      Promise.reject(new Error(e.message));
    }
  }

  getProfile() {
    return this.user.profile;
  }

  getRole() {
    const profile = this.getProfile();

    return profile.role_name;
  }

  isSuperAdmin() {
    return this.getRole() === SUPER_ADMIN;
  }
}

export default new Member();
