import React, { Component } from 'react';
import { Row } from 'antd';
import _ from 'lodash';

export default class RowItem extends Component {
  render() {
    const { style = {}, children, index = 0 } = this.props;
    const css = _.cloneDeep(style);
    if (index % 2 === 0) css.backgroundColor = '#FEFCFC';
    else css.backgroundColor = '#F7F6F6';

    return <Row style={css}>{children}</Row>;
  }
}
