import React, { useEffect, useState } from 'react';
import { message, Col, Modal, Input, Form, InputNumber } from 'antd';
import { PrimaryButton, SecondaryButton } from '../../../components/button';
import notify from '../../../utils/notify';

const PersonalizationDetailModal = ({ data, visible, onClose, form, onSave }) => {
  const [formState, setFormState] = useState('initial_state');
  const { titleEn, titleTh, weight } = data;
  const { getFieldDecorator, setFieldsValue, getFieldsError, validateFields } = form;
  const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);
  const handleOnSubmit = async (e) => {
    setFormState('on_progress');
    e.preventDefault();
    try {
      const values = await validateFields();
      await onSave({ ...values, id: data.id });
      message.success('Update personalize config success.', 2);
    } catch (error) {
      notify.error({
        title: 'Update personalize config failed.',
        message: error.message,
      });
    }
  };
  useEffect(() => {
    setFieldsValue({
      titleEn,
      titleTh,
      weight,
    });
  }, [titleEn, titleTh, weight, setFieldsValue]);
  return (
    <Modal title="EDIT PERSONALIZE NAME" visible={visible} onCancel={onClose} destroyOnClose footer={null}>
      <Form onSubmit={handleOnSubmit} colon={false}>
        <Form.Item label="TitleEn">
          {getFieldDecorator('titleEn', {
            rules: [{ required: true, message: 'Title (EN) is required' }],
          })(<Input placeholder="Enter title en" />)}
        </Form.Item>

        <Form.Item label="TitleTh">
          {getFieldDecorator('titleTh', {
            rules: [{ required: true, message: 'Title (TH) required' }],
          })(<Input placeholder="Enter title th" />)}
        </Form.Item>

        <Form.Item label="Weight">
          {getFieldDecorator('weight', {
            rules: [{ required: true, message: 'Weight is required' }],
          })(<InputNumber style={{ width: '100%' }} placeholder="Enter weight" />)}
        </Form.Item>

        <Form.Item>
          <Col span={24}>
            <SecondaryButton
              align="left"
              loading={formState === 'on_progress'}
              size="large"
              disabled={hasErrors(getFieldsError())}
              title="Cancel"
              onClick={onClose}
            />
            <PrimaryButton
              align="right"
              loading={formState === 'on_progress'}
              size="large"
              disabled={hasErrors(getFieldsError())}
              title="Update"
            />
          </Col>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const WrappedPersonalizationDetailModalForm = Form.create({ name: 'personalization_config_detail_form' })(
  PersonalizationDetailModal
);

export default WrappedPersonalizationDetailModalForm;
