import React from 'react';
import { Table, Button } from 'antd';
import { observer, inject } from 'mobx-react';
import DateFilter from '../../components/filter/DateFilter';
import notify from '../../utils/notify';
import format from '../../utils/format';
import CommunityTopPostDetailModal from './CommunityTopPostDetailModal';
import { DateCol, IntCol } from '../../components/common';

export class CommunityPost extends React.Component {
  constructor() {
    super();
    this.boardFilterRef = React.createRef();
    this.postDetailRef = React.createRef();
    this.state = {
      columns: [
        {
          title: 'POST DATE',
          dataIndex: 'createdAt',
          render: (time) => <DateCol value={time} />,
        },
        {
          title: 'POST TYPE',
          dataIndex: 'type',
        },
        {
          title: 'POST',
          dataIndex: 'title',
        },
        {
          title: 'POST OWNER',
          dataIndex: 'displayName',
        },
        {
          title: 'TOTAL LIKES',
          dataIndex: 'likeCounter',
          render: (value) => <IntCol value={value} />,
        },
        {
          title: 'ACTION',
          dataIndex: 'Action',
          render: (_, value) => {
            return (
              <Button style={css.actionButton} type="link" onClick={() => this.openModal(value)}>
                Detail
              </Button>
            );
          },
        },
      ],
    };
  }

  fetchApi = async (date = {}, page = 1) => {
    try {
      await this.props.community.getLikeList(date, page);
    } catch (e) {
      notify.error({ title: 'Load Community Post Fail', message: e.message });
    }
  };

  onFilterDate = (date) => {
    this.fetchApi(date, 1);
  };

  onChangePage = (page) => {
    this.fetchApi(this.boardFilterRef.current.getDate(), page.current);
  };

  fetchApiDetail = async (id) => {
    try {
      await this.props.community.getPostDetail(id);
    } catch (e) {
      notify.error({ title: `Load Community Post ID: ${id} Fail`, message: e.message });
    }
  };

  openModal = (value) => {
    this.fetchApiDetail(value.postId);
    this.postDetailRef.current.modalOpen();
  };

  resetPostDetail = () => {
    this.props.community.resetPostDetail();
  };

  render() {
    const { post, postDetail } = this.props.community;
    const { list, loading, pagination } = post;
    const { data: postData, loading: detailLoading } = postDetail;
    return (
      <>
        <DateFilter onFind={this.onFilterDate} ref={this.boardFilterRef} />
        <Table
          rowKey={(record) => record.postId}
          indentSize={2}
          bordered
          style={css.table}
          columns={this.state.columns}
          dataSource={list}
          loading={loading}
          onChange={this.onChangePage}
          pagination={{
            ...pagination,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
        <CommunityTopPostDetailModal
          ref={this.postDetailRef}
          loading={detailLoading}
          data={postData}
          closeModal={this.closeModal}
          resetPostDetail={this.resetPostDetail}
        />
      </>
    );
  }
}

const css = {
  table: {
    background: 'white',
    marginTop: 20,
  },
  actionButton: {
    paddingLeft: 0,
  },
};

export default inject('community', 'member')(observer(CommunityPost));
