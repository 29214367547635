import React, { Component } from 'react';

import ConfigBase from './ImageBase';

export default class ConfigUpgradeType extends Component {
  render() {
    return (
      <ConfigBase
        title="HOW TO INVITE FRIEND"
        type="upgrade"
        menus={menus}
        dividerText="Upload How to earn point image (750 x (Anything) pixels) with .JPG or .PNG"
      />
    );
  }
}

const menus = [{ name: 'Upgrade TH', value: 'th' }, { name: 'Upgrade ENG', value: 'eng' }];
