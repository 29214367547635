import React from 'react';
import { inject, observer } from 'mobx-react';
import * as DashboardCommon from './common';
import { CALL_CENTER, PURCHASE, ADMIN, SUPER_ADMIN } from '../../config/user-roles';

const UserLandingPage = (props) => {
  const prefix = '/office';

  const LOGIN_REDIRECT_PATH = {
    SUPER_ADMIN: `${prefix}/dashboard/registered`,
    ADMIN_OR_CALL_CENTER: `${prefix}/user`,
    PURCHASE: `${prefix}/reward`,
  };

  const { history } = props;
  const role = props.member.getRole();

  if (role === SUPER_ADMIN) {
    history.replace(LOGIN_REDIRECT_PATH.SUPER_ADMIN, {
      state: {
        fromLandingPage: true,
      },
    });
  }

  if (role === CALL_CENTER || role === ADMIN) {
    history.replace(LOGIN_REDIRECT_PATH.ADMIN_OR_CALL_CENTER, {
      state: {
        fromLandingPage: true,
      },
    });
  }

  if (role === PURCHASE) {
    history.replace(LOGIN_REDIRECT_PATH.PURCHASE, {
      state: {
        fromLandingPage: true,
      },
    });
  }

  return <DashboardCommon.PageRedirect />;
};

export default inject('dashboard', 'member')(observer(UserLandingPage));
