import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Upload, Icon, Modal, Table, Tooltip, Row, Col, Input, Popover } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SwitchButton } from '../../components';
import XLSX from 'xlsx';
import notify from '../../utils/notify';

import WhitelistTableModal from './WhitelistTableModal'


const ConfirmChangeStatusModal = ({
  title,
  visible,
  onCancel,
  onConfirm,
  description,
}) => (
    <Modal
    title={title}
    visible={visible}
    onCancel={onCancel}
    width={600}
    footer={[
      <Button key="back" onClick={onCancel}>
        Close
      </Button>,
      <Button type="primary" onClick={onConfirm}>
        Confirm
      </Button>,
    ]}
  >
    {description}
  </Modal>
)

class ImportExportExcel extends React.Component {
  state = {
    fileList: [],
    loading: false,
    tableModalVisible: false,
    uploadModalVisible: false,
    editModalVisible: false,
    whitelists: [],
    whitelistName: '',
    deleteWhitelist: '',
    editIndex: -1,
    deleteIndex: -1,
    isFirstTime: true,
    tableModalWhitelistIndex: -1,
  };

  // onChangePage(pagination) {
  //   // fetchApi(pagination.current);
  //   this.props.whitelist.getWhitelistUsers((this.props.whitelist.whitelists[this.state.tableModalWhitelistIndex] || {}).whitelist_id, pagination.current)
  // }

  onRemove = () => {
    this.setState({ fileList: [] });
  };

  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;
  };

  saveWhitelist = (data) => {
    this.props.whitelist.saveWhitelist(data);
  }

  createWhitelist = (data) => {
    this.props.whitelist.createWhitelist(data);
  }

  handleImportExcel = () => {
    if (this.state.fileList.length) {
      const file = this.state.fileList[0];
      const fileReader = new FileReader();
      const rABS = !!fileReader.readAsBinaryString;
      const HEADER = this.props.headerImport;
      fileReader.onload = async (fileReaderEvent) => {
        const { result: bstr } = fileReaderEvent.target;
        const workbook = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonArr = XLSX.utils.sheet_to_json(firstWorksheet, { header: HEADER, range: 1 });
        try {
          // await this.props.handleImportExcel(jsonArr);
          notify.success({ title: 'Import Success' });
          if (this.state.editIndex == -1) {
            const newWhitelist = [...this.state.whitelists, { name: this.state.whitelistName, users: jsonArr, status: 'active'  }]
            this.createWhitelist({ name: this.state.whitelistName, users: jsonArr, status: 'active' });
            this.setState({
              whitelistName: '',
              // whitelists: newWhitelist,
            });
          } else {
            const newWhitelist = this.state.whitelists;
            newWhitelist[this.state.editIndex] = { ...newWhitelist[this.state.editIndex], name: this.state.whitelistName, users: jsonArr };
            this.setState({ whitelistName: '', whitelists: [...newWhitelist] });
            this.saveWhitelist(newWhitelist[this.state.editIndex])
          }
          this.setShowUploadModal(false);
        } catch (error) {
          notify.error({ title: 'Import Error', message: error.message });
        }
      };
      if (rABS) fileReader.readAsBinaryString(file);
      else fileReader.readAsArrayBuffer(file);
    } else {
      const newWhitelist = this.state.whitelists;
      newWhitelist[this.state.editIndex] = { ...newWhitelist[this.state.editIndex], name: this.state.whitelistName };
      this.saveWhitelist(newWhitelist[this.state.editIndex]);
      this.setState({ whitelistName: '', whitelists: [...newWhitelist] });
      this.setShowUploadModal(false);
    }
  };

  handleExportExcel = async (whitelistId) => {
    const HEADER = this.props.headerExport;
    try {
      const { output, fileName, sheetName } = await this.props.handleExportExcel(whitelistId);
      const ws = XLSX.utils.aoa_to_sheet([HEADER, ...output]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  handleDownloadExample = async () => {
    const HEADER = this.props.headerImport;
    try {
      const { output, fileName, sheetName } = await this.props.handleDownloadExample();
      const ws = XLSX.utils.aoa_to_sheet([HEADER, ...output]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      notify.error({ title: 'Export Failed', message: error.message });
    }
  };

  showTableModal = (whitelistId, index) => {
    console.log('whitelistId, index', whitelistId, index)
    this.setState({
      tableModalVisible: true,
      tableModalWhitelistIndex: index,
    });
    this.props.whitelist.getWhitelistUsers(whitelistId)
  };

  handleTableModalClose = () => {
    this.setState({ tableModalVisible: false, tableModalWhitelistIndex: -1 });
  };

  setShowUploadModal = (isShow) => {
    this.setState({
      uploadModalVisible: isShow,
      whitelistName: '',
    });
    this.onRemove();
  };

  setShowEditModal = (isShow) => {
    this.setState({
      uploadModalVisible: isShow,
    });
    this.onRemove();
  };

  handleUploadModalClose = () => {
    this.onRemove();
    this.setState({ uploadModalVisible: false });
  };

  setShowDeleteModal = (isShow, index) => {
    if (index > 0) {
        this.setState({
            deleteWhitelist: this.state.whitelists[index].name,
          });
    }
    this.setState({
        deleteModalVisible: isShow,
        deleteIndex: index,
      });
  };

  handleDeleteWhitelist = () => {
    if (this.state.deleteIndex > -1) {
      this.props.whitelist.deleteWhitelist(this.state.whitelists[this.state.deleteIndex]);
      this.state.whitelists.splice(this.state.deleteIndex, 1);
      this.setState({ whitelists: this.state.whitelists, deleteModalVisible: false, deleteIndex: -1 });
    }
  };

  setShowEditStatusModal = (isShow, index, newStatus) => {
    console.log('newStatus', newStatus, index)
    if (index >= 0) {
      this.setState({
        editStatusWhitelist: this.state.whitelists[index].name,
        newStatus,
      });
    }
    this.setState({
      editStatusModalVisible: isShow,
      editStatusIndex: index,
    });
  };

  handleEditStatusWhitelist = () => {
    if (this.state.editStatusIndex > -1) {
      this.handleChangeStatus(this.state.editStatusIndex, this.state.newStatus);
      this.setState({ newStatus: '', editStatusModalVisible: false, editStatusIndex: -1 });
    }
  };

  handleWhitelistName = (e) => {
    this.setState({ whitelistName: e.target.value });
  };

  handleChangeStatus = (id, status) => {
    this.state.whitelists[id].status = status ? 'active' : 'inactive';
    this.saveWhitelist(this.state.whitelists[id]);
  }

  render() {
    console.log(this.state.isFirstTime, this.props.whitelist.whitelists)
    if (this.props.whitelist.whitelists.length != this.state.whitelists.length) {
      this.setState({
        isFirstTime: false,
        whitelists: this.props.whitelist.whitelists,
      })
    }
    const isEdit = this.state.editIndex >= 0;
    const importDisable = this.state.fileList.length === 0 && !isEdit;
    const { style } = this.props;
    const { visible, loading } = this.state;
    const COLUMNS = [
      {
        title: 'MOBILE NUMBER',
        dataIndex: 'tmp_phone',
        width: 160,
      },
      {
        title: 'FIRST NAME',
        dataIndex: 'tmp_fname',
      },
      {
        title: 'LAST NAME',
        dataIndex: 'tmp_lname',
      },
      {
        title: 'LEVEL',
        dataIndex: 'level',
        width: 100,
        // render: (level) => <span>{mapLevel[level]}</span>,
      },
      {
        title: 'IS AGENT',
        dataIndex: 'is_agent',
        width: 120,
        render: () => <span>yes</span>,
      },
      {
        title: 'JOIN',
        dataIndex: 'created_at',
        // render: (time) => <span>{format.toFullDT(time)}</span>,
      },
    ];

    return (
      <>
        <Button type="primary" size="large" onClick={this.handleDownloadExample}>
          EXAMPLE UPLOAD FILE
        </Button>
        <Section style={style}>
          {this.state.whitelists.map((wl, index) => (
            <Row style={css.row} key={wl.whitelist_id}>
              <Col span={2}>
                <SwitchButton
                  checkedChildren="active"
                  unCheckedChildren="inactive"
                  checked={wl.status === "active"}
                  onChange={() => {
                    this.setShowEditStatusModal(true, index, wl.status !== "active");
                  }}
                />
              </Col>
              <Col span={4}>
                <span style={{ fontWeight: 'bold', marginLeft: 20 }}>{wl.name}</span>
              </Col>

              <Col span={2}>
                <Tooltip title="View">
                  <Button shape="circle" onClick={() => this.showTableModal(wl.whitelist_id, index)}>
                    <SearchOutlined />
                  </Button>
                </Tooltip>
              </Col>

              <Col span={6} offset={10}>
                <Button shape="round" style={css.excelButton} onClick={() => this.handleExportExcel(wl.whitelist_id)}>
                  EXPORT
                </Button>
                <Button
                  shape="round"
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    this.setState({ editIndex: index, whitelistName: wl.name });
                    this.setShowEditModal(true);
                  }}
                >
                  EDIT
                </Button>
                <Tooltip title="Delete">
                  <Button shape="circle" onClick={() => this.setShowDeleteModal(true, index)}>
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          ))}
          <Row style={css.row}>
            <Col>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  this.setState({ editIndex: -1 });
                  this.setShowUploadModal(true);
                }}
              >
                ADD
              </Button>
            </Col>
          </Row>
        </Section>

        <Modal
          title="Upload Whitelist"
          visible={this.state.uploadModalVisible}
          onCancel={this.handleUploadModalClose}
          width={600}
          footer={[
            <Button key="back" onClick={this.handleUploadModalClose}>
              Close
            </Button>,
            <Button
              type="primary"
              style={importDisable ? css.disableButton : css.excelButton}
              onClick={this.handleImportExcel}
              disabled={importDisable}
            >
              {!isEdit ? `Upload` : `Update`}
            </Button>,
          ]}
        >
          <Row>
            <Col span={10}>
              <Input
                placeholder="Fill Whitelist Name"
                value={this.state.whitelistName}
                onChange={this.handleWhitelistName}
                style={{ marginRight: 20 }}
              />
            </Col>
            <Col span={12} offset={1}>
              <Upload
                accept=".xlsx"
                onRemove={this.onRemove}
                beforeUpload={this.beforeUpload}
                onChange={this.onChange}
                fileList={this.state.fileList}
              >
                <Button type="primary" shape="round" style={css.importButton}>
                  <Icon type="upload" /> SELECT FILE
                </Button>
              </Upload>
            </Col>
          </Row>
        </Modal>
        <WhitelistTableModal
          whitelistName={(this.props.whitelist.whitelists[this.state.tableModalWhitelistIndex] || {}).name}
          whitelistId={(this.props.whitelist.whitelists[this.state.tableModalWhitelistIndex] || {}).whitelist_id}
          // whitelistUsers={}
          tableModalVisible={this.state.tableModalVisible}
          handleTableModalClose={this.handleTableModalClose}
          
        />
        <Modal
          title="Delete Whitelist"
          visible={this.state.deleteModalVisible}
          onCancel={() => this.setShowDeleteModal(false, -1)}
          width={600}
          footer={[
            <Button key="back" onClick={() => this.setShowDeleteModal(false, -1)}>
              Close
            </Button>,
            <Button type="primary" onClick={() => this.handleDeleteWhitelist()}>
              Confirm
            </Button>,
          ]}
        >
          Are you sure to delete "Whitelist {this.state.deleteWhitelist}" ?
        </Modal>
        <ConfirmChangeStatusModal
          title="Change Whitelist status"
          visible={this.state.editStatusModalVisible}
          onCancel={() => this.setShowEditStatusModal(false, -1, '')}
          onConfirm={() => this.handleEditStatusWhitelist()}
          description={`Are you sure to ${this.state.newStatus ? 'active' : 'inactive'} "Whitelist ${this.state.editStatusWhitelist}" ?`}
        />
      </>
    );
  }
}

const css = {
  excelButton: {
    // backgroundColor: '#EF843D',
    // borderColor: '#EF843D',
    marginRight: 20,
  },
  disableButton: {
    marginRight: 20,
  },
  row: {
    marginBottom: 20,
  },
};

const Section = styled.div`
  background-color: #fff;
  margin: 20px 0 20px 0;
  border-radius: 5px;
  padding: 20px;
  // display: flex;
  // align-content: center;
  // justify-content: space-between;
`;

export default inject('user', 'whitelist')(observer(ImportExportExcel));
