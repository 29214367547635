import React, { Component } from 'react';
import styled from 'styled-components';

export default class SampleCode extends Component {
  render() {
    let { code = 'xxxx-xxxxx-xxxx' } = this.props;
    if (code === '') code = 'xxxx-xxxxx-xxxx';
    return (
      <Preview>
        <PreviewCode>{code}</PreviewCode>
        <PreviewText>Example code</PreviewText>
      </Preview>
    );
  }
}

const Preview = styled.div`
  background-color: #d8d8d8;
  border-radius: 12px;
  padding: 10px 10px;
  text-align: center;
  margin-bottom: 10px;
`;

const PreviewCode = styled.div`
  color: rgba(92, 92, 92, 0.85);
  font-size: 22px;
  font-weight: 900;
`;

const PreviewText = styled.div`
  color: rgba(0, 0, 0, 0.38);
`;
