import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

export class Badge extends BaseStore {
  constructor() {
    super();
    this.observable({
      badgeConfig: {},
    });
  }

  async getBadgeLevel() {
    const url = `${config.api.max}/v1/office/badges`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    this.badgeConfig = res.body;
  }

  async saveBadgeLevel(data) {
    const url = `${config.api.max}/v1/office/badges`;
    const res = await http.put(url, { json: data, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async setBadge(val) {
    this.badgeConfig = val;
  }
}

export default new Badge();
