import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Generator from '../../../components/generator';
import GenInfo from './GenInfo';

function GenCode({ reward, onSave, onGenerate, onExport }) {
  const rewardJs = reward.toJS();
  let generator = false;
  let exportor = false;
  const [rewardCat, setRewardCat] = useState('electronic');
  const [initailConfig, setInitailConfig] = useState({});

  useEffect(() => {
    setRewardCat(reward.detail.reward_cat);
  }, [reward.detail.reward_cat]);


  useEffect(() => {
    console.log('props.initialValues', reward.detail.generate_code_config)
    // setCurrentType(reward.detail.generate_code_config.type);
    setInitailConfig(rewardJs.detail.generate_code_config);

  }, [reward.detail.generate_code_config]);

  function onChangeRewardCat(type) {
    setRewardCat(type);
  }

  if (reward.detail.reward_id) {
    exportor = true;
    if (reward.original.status === 'draft') generator = true;
  }
  return (
    <div>
      <GenInfo onSave={onSave} onChangeRewardCat={onChangeRewardCat} />
      <Line />
      <Generator
        initialValues={initailConfig}
        exportor={exportor}
        generator={generator}
        onSave={onSave}
        onGenerate={onGenerate}
        onExport={onExport}
        isPhysicalReward={rewardCat === 'physical'}
      />
      <br />
    </div>
  );
}

const Line = styled.div`
  height: 1px;
  background-color: rgb(238, 238, 238);
  margin: 20px 0 20px 0;
`;

export default inject('reward', 'gen')(observer(GenCode));
