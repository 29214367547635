const config = {
  web: {
    maintenance: false,
  },
  upload: {
    url: 'https://fwdth-max2-1.s3-ap-southeast-1.amazonaws.com',
  },
  api: {
    max: 'http://nlb-fwd-max2-sit-3e063298f5153ed3.elb.ap-southeast-1.amazonaws.com/api',
  },
  limit: {
    page: 10,
    export: 1000,
    generate: 1000,
  },
};

module.exports = config;
