import { cloneDeep } from 'lodash';
import { http } from '../utils/http';
import { error } from '../utils/error';
import { config } from '../config';

import BaseStore from './BaseStore';

const FORMAT = 'YYYY-MM-DD';

const origin_config = {
  activity: {
    graph: { labels: [], data: [] },
    data: {},
  },
  registeration: {
    graph: { labels: [], data: [] },
    data: {},
  },
};

export class DashboardActivity extends BaseStore {
  constructor() {
    super();
    this.observable(cloneDeep(origin_config));
  }

  async getActivity(params = {}) {
    const { start, end, timeType, activityCode } = params;
    console.log('[DashboardActivity][getActivity]', params);
    let url = `${config.api.max}/v1/office/user/dashboard/activity?timeType=${timeType}`;
    let query = '';
    if (start) query += `&start=${start.format(FORMAT)}`;
    if (end) query += `&end=${end.format(FORMAT)}`;
    if (activityCode) query += `&activityCode=${activityCode}`;
    if (query !== '') url += query;
    console.log('[DashboardActivity][getActivity]', url);
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    this.activity = {
      data: {
        start,
        end,
        timeType,
        list,
      },
    };
    console.log('[DashboardActivity][getActivity]', { steps: this.activity });
  }

  async getRegistration(params = {}) {
    const { start, end, timeType } = params;
    // type --> hour|day|month
    console.log('[DashboardActivity][getRegistration]', params);
    let url = `${config.api.max}/v1/office/user/registered?type=${timeType}`;
    let query = '';
    if (start) query += `&start=${start.format(FORMAT)}`;
    if (end) query += `&end=${end.format(FORMAT)}`;
    if (query !== '') url += query;

    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const list = res.body || [];
    this.registeration = {
      data: {
        start,
        end,
        timeType,
        list,
      },
    };
    console.log('[DashboardActivity][getRegistration]', { registeration: this.registeration });
  }
}

export default new DashboardActivity();
