import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as DashboardCommon from '../../pages/dashboard/common';

const PrivateRoute = ({ component: Component, can, ...props }) => {
  if (can === undefined) {
    return <DashboardCommon.PageRedirect />;
  }

  return (
    <Route
      {...props}
      render={({ location }) => {
        if (can) {
          return <Component location={location} match={props.computedMatch} />;
        }

        return <Redirect to={{ pathname: '/office/access-denied', state: { from: location, can } }} />;
      }}
    />
  );
};

export default PrivateRoute;
