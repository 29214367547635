import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'antd';

import { InputInt } from '../../../components/forms';
import { SaveSection } from '../../../components/common';

export class WapperdGenInfo extends Component {
  onSave = async (evt) => {
    const { form, onSave } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
    } catch (e) {
      return;
    }

    if (onSave) onSave();
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const required = true;
    const coupon = this.props.coupon.toJS();
    const doc = coupon.detail;
    const { original } = coupon;
    const readOnly = original.status !== 'draft';

    return (
      <Form hideRequiredMark>
        <Row>
          <Col span={6}>
            <Form.Item label="Total Coupon">
              {getFieldDecorator('max_qty', {
                initialValue: doc.max_qty,
                rules: [{ required, message: 'Please fill max coupon!' }],
              })(<InputInt readOnly={readOnly} text="coupons" />)}
            </Form.Item>

            <Form.Item label="Max Users Per Coupon">
              {getFieldDecorator('max_per_person', {
                initialValue: doc.max_per_person,
                rules: [{ required, message: 'Please fill max user!' }],
              })(<InputInt readOnly={readOnly} text="users" />)}
            </Form.Item>
          </Col>

          <Col span={6} offset={1}>
            <Form.Item label="Max Coupon Per Day">
              {getFieldDecorator('max_daily', {
                initialValue: doc.max_daily,
                rules: [{ required, message: 'Please fill max coupon daily!' }],
              })(<InputInt readOnly={readOnly} text="coupons" />)}
            </Form.Item>
            <Form.Item label="Max User Per Day">
              {getFieldDecorator('max_per_daily_person', {
                initialValue: doc.max_per_daily_person,
                rules: [{ required, message: 'Please fill max user daily!' }],
              })(<InputInt readOnly={readOnly} text="users" />)}
            </Form.Item>
          </Col>
        </Row>

        <SaveSection onClick={this.onSave} />
      </Form>
    );
  }
}

const onFieldsChange = (props, changedFields, allFields) => {
  const doc = props.coupon.toJS().detail;
  const keys = Object.keys(changedFields);
  for (const name of keys) {
    const item = changedFields[name];
    doc[name] = item.value;
  }
  props.coupon.setDoc(doc);
};
const GenInfo = Form.create({ onFieldsChange })(WapperdGenInfo);
export default inject('coupon')(observer(GenInfo));
