import React, { Component } from 'react';
import { Button } from 'antd';

export default class FindBtn extends Component {
  render() {
    const { onClick, style, htmlType = 'button' } = this.props;
    return (
      <Button style={style || css} htmlType={htmlType} type="primary" icon="search" onClick={onClick}>
        SEARCH
      </Button>
    );
  }
}

const css = {
  textAlign: 'center',
  width: '100%',
};
