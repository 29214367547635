import React from 'react';
import { Form, InputNumber } from 'antd';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Page, Loading, SaveSection } from '../../../components/common';
import HeaderMenu from '../../../components/main/HeaderMenu';
import { notify } from '../../../utils/notify';

class BirthdayEffectiveMonth extends React.Component {
  async componentDidMount() {
    try {
      await this.props.appConfig.getList();
    } catch (error) {
      notify.error({ title: 'Get Birthday Effective Month Config Failed' });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { form } = this.props;
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      await this.props.appConfig.updateList([
        {
          key: 'earnPointBirthdayEffectiveMonth',
          value: values.earnPointBirthdayEffectiveMonth,
          status: 'active',
        },
      ]);
      notify.success({ title: 'Update Birthday Effective Month Config Success' });
    } catch (error) {
      notify.error({ title: 'Update Birthday Effective Month Config Failed' });
    }
  };

  render() {
    const { general, loading } = this.props.appConfig.toJS();
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
    };
    const formContent = (
      <Form {...formItemLayout}>
        <FormItem label="Birthday Effective Month">
          {getFieldDecorator('earnPointBirthdayEffectiveMonth', {
            initialValue: general.earnPointBirthdayEffectiveMonth,
            rules: [
              {
                required: true,
                message: 'Please input birthday effective month!',
              },
            ],
          })(<InputNumber min={0} max={12} style={{ marginRight: 5 }} />)}
          <span>Months</span>
        </FormItem>

        <SaveSection onClick={this.handleSubmit} />
      </Form>
    );
    return (
      <HeaderMenu title="BIRTHDAY CONFIG">
        <Page style={css.page}>
          <Loading loading={loading} />
          {formContent}
          <div style={css.footer}>
            * การกรอกจำนวน Effective Month เพื่อให้ birthday point มีผลกับจำนวนเดือนย้อนหลัง 0 ถึง 12 โดย 0
            หมายถึงวันที่ปัจจุบันและสูงสุดได้ไม่เกินต้นปีปัจจุบัน
          </div>
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
    fontFamily: 'tahoma, verdana, sans-serif',
  },
  footer: {
    padding: '15px',
    paddingLeft: '0px',
  },
};

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    line-height: 39px;
  }
`;

const WrappedBirthdayEffectiveMonthConfigForm = Form.create({ name: 'birthdayEffectiveMonth_config_form' })(
  inject('appConfig')(observer(BirthdayEffectiveMonth))
);

export default WrappedBirthdayEffectiveMonthConfigForm;
