import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Row, Input, Divider } from 'antd';
import styled from 'styled-components';

import HeaderMenu from '../../../components/main/HeaderMenu';
import { Loading, Page, Cols, SaveSection } from '../../../components/common';
import { DropdownList, UploadImg } from '../../../components/forms';
import { notify } from '../../../utils/notify';

export class ConfigBase extends Component {
  state = {
    loading: false,
    menu: 'th',
  };

  componentDidMount() {
    this.name = this.props.type.toUpperCase();
    this.onFind();
  }

  onFind = async () => {
    try {
      this.setState({ loading: true });
      await this.props.config.getConsent({ type: this.props.type });
    } catch (e) {
      notify.error({ title: `Load ${this.name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onSave = async () => {
    try {
      this.setState({ loading: true });
      await this.props.config.saveImageConsent({ type: this.props.type });
      notify.success({ title: `Save ${this.name} Completed` });
    } catch (e) {
      notify.error({ title: `Save ${this.name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onImg = async (val, name) => {
    const { menu } = this.state;
    const data = this.props.config.toJS().consent;
    if (menu === 'th') data.desc_th = val;
    else data.desc_en = val;
    this.props.config.setConsent(data);
  };

  onMenu = async (val) => {
    await this.setState({ menu: val });
  };

  onChange = async (evt) => {
    const data = this.props.config.toJS().consent;
    data.version = evt.target.value;
    this.props.config.setConsent(data);
  };

  render() {
    const { title, menus, dividerText } = this.props;
    const { loading, menu } = this.state;
    const data = this.props.config.toJS().consent;

    return (
      <HeaderMenu title={title}>
        <Page>
          <Loading loading={loading} />
          <Form hideRequiredMark>
            <Section>
              <Row>
                <Cols span={4}>
                  <Form.Item label="Language">
                    <DropdownList allowClear={false} menus={menus} value={menu} onChange={this.onMenu} />
                  </Form.Item>
                </Cols>

                <Cols span={4}>
                  <Form.Item label="Version">
                    <Input value={data.version} onChange={this.onChange} />
                  </Form.Item>
                </Cols>
              </Row>
            </Section>
            <Divider>{dividerText}</Divider>

            <Section>
              <Row>
                <Cols span={24}>
                  <UploadImg value={menu === 'th' ? data.desc_th : data.desc_en} onChange={this.onImg} />
                </Cols>
              </Row>
            </Section>

            <br />
            <br />
            <SaveSection onClick={this.onSave} />
          </Form>
        </Page>
      </HeaderMenu>
    );
  }
}

const Section = styled.div`
  padding: 10px;
  border-radius: 2px;
  background-color: white;
`;

export default inject('config')(observer(ConfigBase));
