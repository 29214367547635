import React, { Component } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';

export default class TextInput extends Component {
  render() {
    const { label, value, onChange } = this.props;
    const txtLabel = <Label>{label}</Label>;
    if (label)
      return (
        <Section>
          <Form.Item style={itemCss}>
            {txtLabel}
            <Input onChange={onChange} value={value} readOnly />
          </Form.Item>
        </Section>
      );
  }
}

const itemCss = {
  marginBottom: '0px',
};

const Section = styled.div`
  padding: 4px 0px;
  margin: 0px;
`;

const Label = styled.p`
  color: #979796;
  margin: 0px;
  padding: 0px 0px;
  line-height: 25px;
`;
