const prefix = 'office';

const adminOnly = {
  super_admin: true,
  admin: true,
};

const registered = {
  icon: 'fas fa-tachometer-alt',
  name: 'Dashboard',
  /** Set roles to undefined so that all roles can access it */
  roles: {
    ...adminOnly,
    purchase: true,
  },
  submenu: [
    {
      name: 'Registered',
      link: `/${prefix}/dashboard/registered`,
      // Roles that can have access to this menu
      roles: {
        ...adminOnly,
      },
    },
    {
      name: 'Activity',
      link: `/${prefix}/dashboard/activity/steps`,
      roles: {
        ...adminOnly,
      },
    },
    {
      name: 'Personalize',
      link: `/${prefix}/dashboard/personalise`,
      roles: {
        ...adminOnly,
      },
    },
    /** Hide Challenge Menu until feature is ready to release on uat
    {
      name: 'Challenges',
      link: `/${prefix}/dashboard/challenges`,
      roles: {
        ...adminOnly,
      },
    }, */
    {
      name: 'Rewards',
      link: `/${prefix}/dashboard/rewards`,
      roles: {
        ...adminOnly,
        purchase: true,
      },
    },
    {
      name: 'Friend get friend',
      link: `/${prefix}/dashboard/friendGetFriend`,
      roles: {
        ...adminOnly,
      },
    },
  ],
};

const point = {
  icon: 'fas fa-coins',
  name: 'Point Management',
  roles: {
    ...adminOnly,
  },
  submenu: [
    {
      name: 'Global',
      link: `/${prefix}/point/global`,
    },
    {
      name: 'Activities',
      link: `/${prefix}/point/activities`,
    },
    {
      name: 'Paid Policy',
      link: `/${prefix}/point/paid`,
    },
    {
      name: 'Point Adjust',
      link: `/${prefix}/point/pointAdjust`,
    },
  ],
};

const config = {
  icon: 'fas fa-cogs',
  name: 'Global Config',
  roles: {
    ...adminOnly,
  },
  submenu: [
    {
      name: 'SSO Config',
      link: `/${prefix}/config/sso-config`,
    },
    {
      name: 'Consent',
      link: `/${prefix}/config/consent`,
    },
    {
      name: 'Term & Condition',
      link: `/${prefix}/config/term`,
    },
    {
      name: 'How to earn point',
      link: `/${prefix}/config/point`,
    },
    {
      name: 'How to redeem',
      link: `/${prefix}/config/redeem`,
    },
    {
      name: 'How to invite friend',
      link: `/${prefix}/config/upgrade`,
    },
    {
      name: 'FAQ',
      link: `/${prefix}/config/faq`,
    },
    {
      name: 'Banner',
      link: `/${prefix}/config/banner`,
    },
    {
      name: 'Birthday',
      link: `/${prefix}/config/birthday-effective-month`,
    },
    {
      name: 'Survey Schedule',
      link: `/${prefix}/config/survey-schedule`,
    },
    {
      name: 'Push Notification',
      link: `/${prefix}/config/notification-template`,
    },
    {
      name: 'Point Expire Reminder',
      link: `/${prefix}/config/point-expire-reminder`,
    },
    {
      name: 'Community',
      link: `/${prefix}/config/community`,
    },
    {
      name: 'Personalize',
      link: `/${prefix}/config/personalise`,
    },
    {
      name: 'Article',
      link: `/${prefix}/config/content`,
    },
  ],
};

const jobs = {
  icon: 'fas fa-building',
  name: 'Cron Jobs',
  roles: {
    ...adminOnly,
  },
  submenu: [
    {
      name: 'Trigger',
      link: `/${prefix}/jobs/trigger`,
    },
  ],
};

const staff = {
  icon: 'fas fa-user-shield',
  name: 'Staff Management',
  roles: {
    super_admin: true,
    admin: true,
    purchase: false,
    call_center: false,
  },
  submenu: [
    {
      name: 'List Backoffice User',
      link: `/${prefix}/staff`,
      roles: {
        super_admin: true,
        admin: true,
        purchase: false,
        call_center: false,
      },
    },
    {
      name: 'Add Backoffice User',
      link: `/${prefix}/staff/add-user`,
      roles: {
        super_admin: true,
        admin: true,
        purchase: false,
        call_center: false,
      },
    },
    {
      name: 'Whitelist',
      link: `/${prefix}/user/whitelist`,
      roles: {
        super_admin: true,
        admin: true,
        purchase: false,
        call_center: false,
      },
    },
  ],
};

const side_menu = [
  registered,
  {
    icon: 'fas fa-user',
    link: `/${prefix}/user`,
    name: 'User',
    roles: {
      ...adminOnly,
      call_center: true,
    },
  },
  {
    icon: 'fas fa-certificate',
    link: `/${prefix}/badge/1`,
    name: 'Badges',
    roles: {
      ...adminOnly,
    },
  },
  {
    icon: 'fas fa-crown',
    link: `/${prefix}/tier`,
    name: 'Tiers',
    roles: {
      ...adminOnly,
    },
  },
  {
    icon: 'fas fa-trophy',
    link: `/${prefix}/reward`,
    name: 'Reward',
    roles: {
      ...adminOnly,
      purchase: true,
    },
  },
  {
    icon: 'fas fa-award',
    link: `/${prefix}/challenges`,
    name: 'Challenge',
    roles: {
      ...adminOnly,
    },
  },
  {
    icon: 'fas fa-tags',
    link: `/${prefix}/banners/all/home`,
    name: 'Banner',
    roles: {
      ...adminOnly,
    },
  },
  {
    icon: 'fas fa-bell',
    link: `/${prefix}/notifications/broadcast`,
    name: 'Notification',
    roles: {
      ...adminOnly,
      call_center: true,
    },
  },
  point,
  {
    icon: 'fas fa-gift',
    link: `/${prefix}/coupon`,
    name: 'Coupon',
    roles: {
      ...adminOnly,
    },
  },
  {
    icon: 'fas fa-newspaper',
    link: `/${prefix}/content`,
    name: 'Content',
    roles: {
      ...adminOnly,
    },
  },
  {
    icon: 'fas fa-comments',
    link: `/${prefix}/community`,
    name: 'Community',
    roles: {
      ...adminOnly,
    },
  },
  staff,
  config,
  jobs,
  {
    icon: 'fas fa-file-download',
    link: `/${prefix}/download`,
    name: 'My download',
    roles: {
      ...adminOnly,
    },
  },
];

export default { side_menu };
