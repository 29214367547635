import React, { Component } from 'react';
import styled from 'styled-components';

export default class GroupItem extends Component {
  render() {
    const { label, children } = this.props;
    let text;
    if (label) text = <Label>{label}</Label>;
    else text = <LabelSpace>-</LabelSpace>;
    return (
      <Page>
        {text}
        {children}
      </Page>
    );
  }
}

const Page = styled.div`
  padding: 4px 0px;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: left;
`;

const LabelSpace = styled.p`
  color: transparent;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: left;
`;
