if (typeof window === 'undefined') global.window = { location: { host: 'testhost' } };

const host = window.location.host
let env = 'localhost'
if (host.includes('fwd-dev.appmojo')) {
  env = 'develop'
} else if (host.includes('nlb-fwd-max2-sit')) {
  env = 'sit'
} else if (host.includes('nlb-fwd-max2-uat')) {
  env = 'uat'
} else if (host.includes('nlb-fwd-max2-prod')) {
  env = 'master'
} else if (host.includes('fwd.backoffice')) {
  env = 'tqld'
}

export const config = require(`./config.${env}`);
