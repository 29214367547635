import React, { Component } from 'react';
import { InputNumber } from 'antd';

export default class InputInt extends Component {
  onChange = (val) => {
    const { name, onChange } = this.props;
    if (onChange) {
      onChange(val, name);
    }
  };

  getValue(value) {
    const { text = '' } = this.props;
    value = `${value}`.replace(text, '');
    value = value.replace(' ', '');
    value = `${value}`.trim();
    const len = value.length;
    for (let i = 0; i < len; i++) {
      const tmp = +value[i];
      if (isNaN(tmp)) {
        return value.substr(0, i);
      }
    }

    return value;
  }

  onParser = (value) => {
    value = this.getValue(value);
    return value;
  };

  onFormat = (value) => {
    value = this.getValue(value);
    if (this.props.text) {
      return `${value} ${this.props.text}`;
    }
    return value;
  };

  render() {
    const { readOnly = false, disabled = false, min = 0, value = 0, max } = this.props;

    const css = { width: '100%' };
    if (readOnly) {
      css.backgroundColor = '#DADAD9';
    }

    return (
      <InputNumber
        readOnly={readOnly}
        disabled={disabled}
        min={min}
        max={max}
        style={css}
        value={value}
        onChange={this.onChange}
        formatter={this.onFormat}
        parser={this.onParser}
      />
    );
  }
}
