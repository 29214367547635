import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { Loading } from '../../../components/common';
import { Btn } from '../../../components/button';
import { notify } from '../../../utils/notify';

export class Basic extends Component {
  state = {
    loading: false,
  };

  onRunEaiting = async () => {
    const name = 'Passive Activity-Dining Point';
    try {
      this.setState({ loading: true });
      await this.props.trigger.runEatingPointJob();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunPlancode = async () => {
    const name = 'Process Plancode';
    try {
      this.setState({ loading: true });
      await this.props.trigger.runPlancodeJob();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunActivityCounter = async () => {
    const name = 'Reset All Daily Activity Counter';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunActivityCounter();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunDiningDailyActivityCounter = async () => {
    const name = 'Reset Dining Daily Activity Counter';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunDailyDiningActivityCounter();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunTravelingDailyActivityCounter = async () => {
    const name = 'Reset Traveling Daily Activity Counter';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunDailyTravelingActivityCounter();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunRewardAndCouponCounter = async () => {
    const name = 'Reset Reward and Coupon Counter';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunRewardAndCouponCounter();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunRewardMonthlyCounter = async () => {
    const name = 'Reset Reward Monthly Counter';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunRewardMonthlyCounter();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunExpireChallenge = async () => {
    const name = 'Expire Challenge';
    try {
      this.setState({ loading: true });
      await this.props.trigger.runExpireChallenge();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunInviteFriendLeader = async () => {
    const name = 'Invite Friend Leader';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunInviteFriendLeader();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onProcessEarnPointBirthday = async () => {
    const name = 'Process Earn Point Birthday ';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onProcessEarnPointBirthday();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRefreshArticleCache = async () => {
    const name = 'Refresh Article Cache ';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRefreshArticleCache();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  };

  onRunProcessHighlight = async () => {
    const name = 'Process Customer Highlight';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunProcessHighlight();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  }
  
  onRunProcessTier = async () => {
    const name = 'Process Customer Tier';
    try {
      this.setState({ loading: true });
      await this.props.trigger.onRunProcessTier();
      notify.success({ title: `Run ${name} Complete` });
    } catch (e) {
      notify.error({ title: `Run ${name} Fail`, message: e.message });
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    const span = 6;
    const spanBtn = 3;
    const spanDes = 10;
    return (
      <JobSection>
        <Loading loading={loading} />
        <Row>
          <Col span={span}>
            <Name>Reset All Daily Activity Counter</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunActivityCounter} />
          </Col>
          <Col span={spanDes}>
            <Detail>รีเซ็ตตัวนับ Daily Activity ทั้งหมด</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Reset Dining Daily Activity Counter</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunDiningDailyActivityCounter} />
          </Col>
          <Col span={spanDes}>
            <Detail>รีเซ็ตตัวนับ Daily Activity สำหรับเช็คอินกินข้าว</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Reset Traveling Daily Activity Counter</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunTravelingDailyActivityCounter} />
          </Col>
          <Col span={spanDes}>
            <Detail>รีเซ็ตตัวนับ Daily Activity สำหรับเช็คอินท่องเที่ยว</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Passive Activity-Dining Point</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunEaiting} />
          </Col>
          <Col span={spanDes}>
            <Detail>อัพเดทการตั้งค่าช่วงเวลาในการเช็คอินกินข้าว</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Process Plancode</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunPlancode} />
          </Col>
          <Col span={spanDes}>
            <Detail>ให้แต้มลูกค้าที่จ่ายเบี้ยมาใหม่</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Expire Challenge</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunExpireChallenge} />
          </Col>
          <Col span={spanDes}>
            <Detail>ทำให้ challenges ที่ active อยู่หมดอายุ</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Reset Reward and Coupon Counter</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunRewardAndCouponCounter} />
          </Col>
          <Col span={spanDes}>
            <Detail>รีเซ็ตตัวนับจำนวนการแลกของรางวัลประจำวัน</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Reset Reward Monthly Counter</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunRewardMonthlyCounter} />
          </Col>
          <Col span={spanDes}>
            <Detail>รีเซ็ตตัวนับจำนวนการแลกของรางวัลประจำเดือน</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Invite Friend Leader</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunInviteFriendLeader} />
          </Col>
          <Col span={spanDes}>
            <Detail>ประมวลผล Leader board สำหรับการชวนเพื่อน</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Process Earn Point Birthday</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onProcessEarnPointBirthday} />
          </Col>
          <Col span={spanDes}>
            <Detail>ให้แต้มลูกค้าที่เกิดในเดือนนี้</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Refresh Article Cache</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRefreshArticleCache} />
          </Col>
          <Col span={spanDes}>
            <Detail>อัพเดทบทความใหม่</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Process Customer Tier</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunProcessTier} />
          </Col>
          <Col span={spanDes}>
            <Detail>ประมวลผล tier ลูกค้าทั้งหมด</Detail>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={span}>
            <Name>Process Highlight Of TheWeek</Name>
          </Col>
          <Col span={spanBtn}>
            <Btn text="Run" onClick={this.onRunProcessHighlight} />
          </Col>
          <Col span={spanDes}>
            <Detail>ประมวลผลไฮไลท์ประจำสัปดาห์</Detail>
          </Col>
        </Row>
      </JobSection>
    );
  }
}

const JobSection = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: white;
`;

const Name = styled.h4`
  text-align: right;
  padding-right: 10px;
  margin: 0.4em 0;
`;

const Detail = styled.h4`
  padding-left: 10px;
  margin: 0.4em 0;
`;

export default inject('trigger')(observer(Basic));
