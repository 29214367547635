import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SaveSection } from '../../../components/common';
import RowItem from './RowItem';

export class ReadContent extends Component {
  onChange = (name, tag, val) => {
    this.doc[name][tag] = val;
    this.props.point.setPassiveActivity(this.doc);
  };

  render() {
    const doc = this.props.point.toJS().passive;
    this.doc = doc;
    return (
      <div>
        <RowItem
          title="Read Content"
          name="read_knowledge"
          item={doc.read_knowledge}
          onChange={this.onChange}
        />

        <RowItem
          title="+ Share to Social"
          name="share_knowledge"
          item={doc.share_knowledge}
          onChange={this.onChange}
        />

        <SaveSection onClick={this.props.onSave} />
      </div>
    );
  }
}

export default inject('point')(observer(ReadContent));
