/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Checkbox } from 'antd';
import './css/TagSelector.css';

const options = [
  { label: 'Digital Code', value: 'digital' },
  { label: 'Barcode', value: 'barcode' },
  { label: 'QR Code', value: 'qr' },
];

function ShowCodeCheckBox(props) {
  return (
    <div className="tag-selector">
      <Checkbox.Group options={props.options || options} {...props} />
    </div>
  );
}

export default ShowCodeCheckBox;
