import React from 'react';
import { Select } from 'antd';
import provinceOptions from './provinceOptions';

const { Option, OptGroup } = Select;

const ProvinceSelect = (props) => {
  const { onChange, value, showSearch = true, placeholder = 'เลือกจังหวัด', ...rest } = props;

  const options = provinceOptions.map((option) => {
    const children = option.children.map((c) => (
      <Option key={c.value} value={c.value}>
        {c.label}
      </Option>
    ));

    return (
      <OptGroup key={option.value} label={option.label}>
        {children}
      </OptGroup>
    );
  });

  options.unshift(
    <Option key="all" value="all">
      ทั้งหมด
    </Option>
  );

  return (
    <Select showSearch={showSearch} defaultValue={value} {...rest} onChange={onChange} placeholder={placeholder}>
      {options}
    </Select>
  );
};

export default ProvinceSelect;
