import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table } from 'antd';
import { IntCol, DateCol } from '../../../components/common';
import { format } from '../../../utils/format';
import { notify } from '../../../utils/notify';
import { BadgeMap } from '../../../definition/mapping';
import CouponFilter from './CouponFilter';
import UserInfo from '../UserInfo';

export class UserCoupon extends Component {
  state = {
    loading: false,
  };

  params = {};

  columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CODE',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'POINTS',
      dataIndex: 'points',
      width: '120px',
      key: 'points',
      render: (value) => <IntCol value={value} />,
    },
    {
      title: 'BADGE',
      dataIndex: 'badge',
      width: '120px',
      key: 'badge',
      render: (value) => <IntCol value={value} />,
    },
    {
      title: `REDEEMED\nDATE TIME`,
      dataIndex: 'redeemDateTime',
      key: 'redeemDateTime',
      width: '120px',
      render: (value) => <DateCol value={value} />,
    },
  ];

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async (params = {}) => {
    try {
      this.setState({ loading: true });
      const { id } = this.props.match.params;
      const promise = [this.props.user.getPersonal(id), this.props.user.getCouponUser({ id, ...params })];
      await Promise.all(promise);
    } catch (e) {
      notify.error({ title: 'Load User Coupon Fail', message: e.message });
    }

    this.setState({ loading: false });
  };

  onFilter = async (params = {}) => {
    params.offset = 1;
    this.params = params;
    this.onLoad(this.params);
  };

  onPagin = (offset) => {
    this.params.offset = offset;
    this.onLoad(this.params);
  };

  onShowSizeChange = (current, _) => {
    this.params.offset = current;

    this.onLoad(this.params);
  };

  render() {
    const { loading } = this.state;
    const { offset: currentPage } = this.params;
    const user = this.props.user.toJS();
    const { personal } = user;
    const doc = user.coupons;
    const { page, list } = doc;
    const dataSource = list.map((item) => {
      return {
        name: item.name_en,
        code: item.code,
        points: item.amount,
        badge: BadgeMap[item.level],
        redeemDateTime: item.created_at,
      };
    });
    return (
      <UserInfo title={`USERS-${personal.tmp_fname}`} name="COUPONS">
        <CouponFilter onFind={this.onFilter} />
        <Table
          bordered
          loading={loading}
          rowKey={(record) => record.code}
          dataSource={dataSource}
          columns={this.columns}
          pagination={{
            current: currentPage || 1,
            position: 'both',
            total: page.total,
            pageSizeOptions: ['10', '100', '1000'],
            onChange: this.onPagin,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
            showQuickJumper: true,
            showTotal: (total) => `Total ${format.toDigi(total)}`,
            hideOnSinglePage: false,
          }}
        />
      </UserInfo>
    );
  }
}

export default inject('user')(observer(UserCoupon));
