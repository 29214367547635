/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography, List } from 'antd';

import UserInfo from '../UserInfo';

const { Title, Text } = Typography;
const REMARK =
  '* การอัพเดตจาก badge level 1 ไปยัง badge level 2 จะต้องทำกิจกรรมที่ได้พ้อยจากการ เดิน, วิ่ง, ปั่น, ว่าย ให้ครบตามที่กำหนด';

const BadgeCounter = (props) => {
  const {
    user,
    match: { params },
  } = props;
  useEffect(() => {
    user.getBadgeCounter(params.id);
  }, []);
  const {
    badgeCounter: { list, total },
  } = user.toJS();

  return (
    <>
      <UserInfo title="User Badge Counter" name="BADGE COUNTER">
        <List
          header={
            <div>
              <Title level={3}>Badge 1 to 2 Activity Counter</Title>
            </div>
          }
          footer={
            <div>
              <Text>{`Total: ${total}`}</Text>
            </div>
          }
          bordered
          dataSource={list}
          renderItem={(item) => (
            <List.Item>
              <Text>
                {item.name} Counter: {item.value}
              </Text>
            </List.Item>
          )}
        />
        <div style={css.footer}>
          <Text>{REMARK}</Text>
        </div>
      </UserInfo>
    </>
  );
};

const css = {
  footer: {
    padding: '15px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
};

export default inject('user')(observer(BadgeCounter));
