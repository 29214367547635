import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const ExportButton = (props) => {
  const { children } = props;

  return <StyledExportButton {...props}>{children}</StyledExportButton>;
};

const StyledExportButton = styled(Button)`
  float: right;
  margin-right: 40px;
  text-transform: uppercase;
  border-radius: 18px;
  font-weight: 500;
  height: 36px;
  width: 120px;
  background-color: #ef843d;
  border-color: #ef843d;
  border-color: #fd9a21;
  color: #fff;
  &:disabled {
    background-color: #cccc;
    border-color: #cccc;
    color: #444;
  }
  &:hover {
    background-color: #ffb732;
    border-color: #ffb732;
    color: #fff;
  }
`;

export default ExportButton;
