/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import './css/TagSelector.css';

const options = [
  { label: 'Whitelist 1', value: 'Whitelist 1' },
  { label: 'Whitelist 2', value: 'Whitelist 2' },
  { label: 'Whitelist 3', value: 'Whitelist 3' },
  { label: 'Whitelist 4', value: 'Whitelist 4' },
  { label: 'Whitelist 5', value: 'Whitelist 5' },
  { label: 'Whitelist 6', value: 'Whitelist 6' },
  { label: 'Whitelist 7', value: 'Whitelist 7' },
];

function WhitelistCheckBox(props) {
  return (
      <div className="tag-selector">
        <Checkbox.Group options={props.options || options} {...props} />
      </div>
  );
}

export default WhitelistCheckBox;
