const getCommuImage = (type) => {
  switch (type) {
    case 'eating_check_in':
    case 'traveling_check_in':
      return require('../assets/img/community/defaultPassiveActivity.jpg');
    case 'steps':
      return require('../assets/img/community/stepBaseImage.png');
    case 'swimming':
      return require('../assets/img/community/swimBaseImage.png');
    case 'cycling':
      return require('../assets/img/community/cyclingBaseImage.png');
    case 'running':
      return require('../assets/img/community/runningBaseImage.png');
    default:
      return 'null';
  }
};

export default getCommuImage;
