import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'antd';
import styled from 'styled-components';

import { SelectFile } from '../button';
import ButtonSection from './ButtonSection';
import { format } from '../../utils/format';

export class ImportCode extends Component {
  onGenerate = async (evt) => {
    const { onGenerate } = this.props;
    if (onGenerate) onGenerate({ type: 'import', data: this.config.import });
  };

  onExport = (evt) => {
    const { onExport } = this.props;
    if (onExport) onExport();
  };

  onFile = (file) => {
    const list = [];
    const lines = file.split('\n');
    for (let item of lines) {
      item = item.replace('\r', '');
      const cols = item.split(',');
      if (cols.length > 0) {
        let code = cols[0];
        code = code.trim();
        if (code.length > 0) list.push(code);
      }
    }

    this.config.import.list = list;
    this.props.gen.setConfig(this.config);
  };

  render() {
    const { exportor, generator } = this.props;
    const { config } = this.props.gen.toJS();
    this.config = config;
    const { list } = config.import;
    return (
      <div>
        <Form hideRequiredMark>
          <Row type="flex" justify="space-between">
            <Row type="flex">
              <Col>
                <Total>Total {format.toDigi(list.length)} codes</Total>
              </Col>
              <Col>
                <SelectFile disabled={false} accept=".csv" onChange={this.onFile} style={{ width: 150 }} />
              </Col>
            </Row>

            <Col span={12} offset={1}>
              <ButtonSection
                exportor={exportor}
                generator={generator}
                onExport={this.onExport}
                onGenerate={this.onGenerate}
                txtGenerate="IMPORT CODE"
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const Total = styled.div`
  padding: 10px;
  font-size: 18px;
`;

export default inject('gen')(observer(ImportCode));
