import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';

import { TabMenu } from '../../components/forms';
import { history } from '../../utils/history';

const name = 'BANNERS';
export default class BannerTab extends Component {
  onChange = (item = {}) => {
    const { url } = item.params;
    if (url) history.push(url);
  };

  render() {
    const { defaultIndex = 0, loading, children } = this.props;

    return (
      <HeaderMenu title={name}>
        <Loading loading={loading} />
        <Page style={pageCss}>
          <TabMenu defaultIndex={defaultIndex} menus={menus} onChange={this.onChange} />
          <br />
          {children}
        </Page>
      </HeaderMenu>
    );
  }
}

const pageCss = {
  backgroundColor: 'white',
  borderRadius: '5px',
};

const menus = [
  {
    name: 'ALL HOME BANNER',
    params: { url: '/office/banners/all/home' },
  },
  {
    name: 'HOME BANNER IN APP',
    params: { url: '/office/banners/all/home?display=app' },
  },
  {
    name: 'ALL REWARD BANNER',
    params: { url: '/office/banners/all/reward' },
  },
  {
    name: 'REWARD BANNER IN APP',
    params: { url: '/office/banners/all/reward?display=app' },
  },
  {
    name: 'ALL CHALLENGE BANNER',
    params: { url: '/office/banners/all/challenge' },
  },
  {
    name: 'CHALLENGE BANNER IN APP',
    params: { url: '/office/banners/all/challenge?display=app' },
  },
];
