import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Row, Col } from 'antd';
import moment from 'moment';
import { isEqual } from 'lodash';

import { SaveSection } from '../../../../components/common';
import { KeyBtn } from '../../../../components/button';
import { InputInt } from '../../../../components/forms';
import { timer } from '../../../../utils/timer';

import Item from './Item';

export class Eating extends Component {
  state = {
    validate: {},
  };

  inRange({ index = 0, value, list = [] }) {
    let i = 0;
    const val = timer.toTime(value).unix();
    for (const item of list) {
      if (i !== index) {
        const start_at = timer.toTime(item.start_at).unix();
        const end_at = timer.toTime(item.end_at).unix();
        if (val >= start_at && val <= end_at) {
          return true;
        }
      }
      i++;
    }

    return false;
  }

  onSave = () => {
    const { onSave } = this.props;
    const validate = {};
    const { list } = this.doc.data;

    if (list.length === 0) validate.list = true;

    let index = 0;
    for (const item of list) {
      const value = {};

      if (this.inRange({ index, value: item.start_at, list })) value.start_at = true;
      if (this.inRange({ index, value: item.end_at, list })) value.end_at = true;

      if (isEqual({}, value) === false) validate[index] = value;
      index++;
    }

    this.setState({ validate });

    if (isEqual({}, validate) && onSave) {
      onSave();
    }
  };

  onAdd = () => {
    const item = {
      start_at: moment().startOf('day'),
      end_at: moment().endOf('day'),
      eating_check_in: 0,
      eating_take_photo: 0,
      eating_share_to_social: 0,
      max_period: 0,
    };

    this.doc.data.list.push(item);
    this.props.config.setPassiveActivity(this.doc);
  };

  onRemove = (index) => {
    this.doc.data.list.splice(index, 1);
    this.props.config.setPassiveActivity(this.doc);
  };

  onChange = (index, item) => {
    this.doc.data.list[index] = item;
    this.props.config.setPassiveActivity(this.doc);
  };

  onMaxDay = (val) => {
    this.doc.data.max_day = val;
    this.props.config.setPassiveActivity(this.doc);
  };

  render() {
    const { validate } = this.state;
    const doc = this.props.config.toJS().passive;
    this.doc = doc;
    const { data } = doc;
    const content = data.list.map((item, index) => {
      return (
        <Item
          key={index}
          index={index}
          item={item}
          validate={validate[index]}
          onChange={this.onChange}
          onRemove={this.onRemove}
        />
      );
    });

    return (
      <div>
        <Row>
          <Col span={4}>
            <Form.Item label="MAX PER DAY">
              <InputInt value={data.max_day} onChange={this.onMaxDay} text="time" />
            </Form.Item>
          </Col>
        </Row>

        {content}

        <h3>Period List</h3>
        <Row>
          <Col span={4}>
            <Form.Item validateStatus={validate.list ? 'error' : ''} help={validate.list ? 'Please add period!' : ''}>
              <KeyBtn text="ADD" onClick={this.onAdd} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item>
              <Remark>* การเปลี่ยนจำนวนการให้แต้มจะมีผลในวันถัดไป</Remark>
            </Form.Item>
          </Col>
        </Row>

        <SaveSection onClick={this.onSave} />
      </div>
    );
  }
}

const Remark = styled.p`
  font-size: 16px;
`;

export default inject('config')(observer(Eating));
