import styled from 'styled-components';

export const Title = styled.p`
  font-size: 20px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 1px;
  color: #1e1e1e;
`;

export { default as DisplayStatus } from './DisplayStatus';
export { default as NoData } from './NoData';
export { default as Page } from './Page';
export { default as RowItem } from './RowItem';
export { default as IntCol } from './IntCol';
export { default as DateCol } from './DateCol';
export { default as Loading } from './Loading';
export { default as Cols } from './Cols';
export { default as OnRight } from './OnRight';
export { default as SaveSection } from './SaveSection';
export { default as Progress } from './Progress';
export { default as UserActivity } from './UserActivity';
