import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Generator from '../../../components/generator';
import GenInfo from './GenInfo';

export class GenCode extends Component {
  render() {
    const { onSave, onGenerate, onExport } = this.props;
    const { original } = this.props.coupon.toJS();

    let generator = false;
    let exportor = false;

    if (original.coupon_id) {
      exportor = true;
      if (original.status === 'draft') generator = true;
    }
    return (
      <div>
        <GenInfo onSave={onSave} />
        <hr />
        <Generator
          exportor={exportor}
          generator={generator}
          onSave={this.onSave}
          onGenerate={onGenerate}
          onExport={onExport}
        />
        <br />
      </div>
    );
  }
}

export default inject('coupon')(observer(GenCode));
