import { cloneDeep } from 'lodash';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';

const consent = {
  consent_id: undefined,
  version: '',
  desc_en: '',
  desc_th: '',
  type: '',
};

const passive = {
  key: undefined,
  value: 0,
  status: '',
  data: {
    max_day: 0,
    list: [],
  },
};

export class Configuration extends BaseStore {
  constructor() {
    super();
    this.observable({
      consent: cloneDeep(consent),
      passive: cloneDeep(passive),
    });
  }

  async getConsent({ type = 'consent' }) {
    this.consent = cloneDeep(consent);

    const url = `${config.api.max}/v1/office/utils/consent/${type}`;
    const res = await http.get(url, { token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }

    const data = res.body;
    this.consent = data;
    return data;
  }

  async saveConsent({ type = 'consent' }) {
    const data = this.toJS().consent;
    const json = {
      version: data.version,
      desc_en: data.desc_en,
      desc_th: data.desc_th,
    };
    const url = `${config.api.max}/v1/office/utils/consent/${type}`;
    const res = await http.put(url, { json, token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async upLoadImage({ image = '' }) {
    const prefix = image.substring(0, 4);
    if (prefix === 'data') {
      image = await this.uploadFile({ file: image });
    } else if (prefix === 'cons') {
      // consent
      image = { paths: image };
    }

    return image;
  }

  async consentCheckVersion(type, version) {
    const url = `${config.api.max}/v1/office/utils/consent/checkVersion/${type}`;
    const res = await http.post(url, { json: { version }, token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async saveImageConsent({ type = 'consent' }) {
    const data = this.toJS().consent;

    await this.consentCheckVersion(type, data.version);

    const [imageEn, imageTh] = await Promise.all([
      this.upLoadImage({ image: data.desc_en }),
      this.upLoadImage({ image: data.desc_th }),
    ]);
    const json = {
      version: data.version,
      desc_en: imageEn.paths,
      desc_th: imageTh.paths,
    };

    const url = `${config.api.max}/v1/office/utils/consent/${type}`;
    const res = await http.put(url, { json, token: true });
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
    if (
      (type === 'redeem' || type === 'point' || type === 'upgrade') &&
      (imageTh.url !== undefined || imageEn.url !== undefined)
    ) {
      let configKeyTh;
      let configKeyEn;
      switch (type) {
        case 'redeem':
          configKeyTh = 'howToRedeemImageTH';
          configKeyEn = 'howToRedeemImageEN';
          break;
        case 'point':
          configKeyTh = 'howToEarnPointImageTH';
          configKeyEn = 'howToEarnPointImageEN';
          break;
        case 'upgrade':
          configKeyTh = 'howToInviteFriendTH';
          configKeyEn = 'howToInviteFriendEN';
          break;
        default:
          break;
      }
      const list = [];
      if (imageTh.url) {
        list.push({
          key: configKeyTh,
          value: imageTh.url,
          status: 'active',
        });
      }
      if (imageEn.url) {
        list.push({
          key: configKeyEn,
          value: imageEn.url,
          status: 'active',
        });
      }
      const configUrl = `${config.api.max}/v1/office/configs/updateList`;
      const configRes = await http.put(configUrl, { token: true, json: { value: list } });
      if (configRes.statusCode !== 200) {
        error.launch({ message: configRes.body.message });
      }
    }
  }

  setConsent(doc) {
    this.consent = doc;
  }

  async uploadFile({ uuid, file }) {
    let url = `${config.api.max}/v1/office/utils/image/public/consent`;
    if (uuid) url += `?uuid=${uuid}`;

    const json = { file };
    const res = await http.post(url, { json, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }

    // path = 'consent/...'
    // url = 'https://...'
    /** @type {{ paths: number, url: string}} */
    if (res.body.url === undefined) {
      error.launch({ message: 'url is invalid' });
    }

    return res.body;
  }

  async getPassiveActivity() {
    const url = `${config.api.max}/v1/office/utils/config/cron_eating`;
    const res = await http.get(url, { token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }

    let doc = res.body;
    if (doc.key === undefined) {
      doc = cloneDeep(passive);
    } else if (doc.data === undefined) {
      doc.data = cloneDeep(passive.data);
    }

    this.passive = doc;
  }

  async savePassiveActivity() {
    const url = `${config.api.max}/v1/office/utils/config/cron_eating`;
    const json = this.toJS().passive;
    const res = await http.put(url, { json, token: true });

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
  }

  async setPassiveActivity(data) {
    this.passive = data;
  }
}

export default new Configuration();
