import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import HeaderMenu from '../../components/main/HeaderMenu';
import { Page, Loading } from '../../components/common';
import { notify } from '../../utils/notify';
import ChallengeForm from './ChallengeForm';
import { history } from '../../utils/history';

class ChallengeDetail extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.challenge.get(id);
    }
  }

  handleOnSave = async (values) => {
    try {
      let promiseTask;
      const { id } = this.props.match.params;
      if (id) {
        promiseTask = this.props.challenge.update(id, values);
      } else {
        promiseTask = this.props.challenge.create(values);
      }
      await promiseTask;
      notify.success({ title: 'Save challenge success' });
      history.push(`/office/challenges`);
    } catch (error) {
      notify.error({ title: 'Save Challenge Fail' });
      console.error(error);
    }
  };

  handleOnReset = () => {
    this.props.challenge.reset();
  };

  handleGetVenueById = async (id) => {
    try {
      await this.props.challenge.getVenueById(id);
    } catch (error) {
      notify.error({ title: 'Fetch venue failed' });
    }
  };

  render() {
    const { detail, venue, loading } = this.props.challenge;
    const { id } = this.props.match.params;
    const title = id ? 'EDIT CHALLENGE' : 'NEW CHALLENGE';

    return (
      <HeaderMenu title={title}>
        <Page style={css.page}>
          <Loading loading={loading} />
          <ChallengeForm
            challenge={detail}
            venue={venue}
            onSubmit={this.handleOnSave}
            onGetVenueById={this.handleGetVenueById}
            onReset={this.handleOnReset}
          />
        </Page>
      </HeaderMenu>
    );
  }
}

const css = {
  page: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
};

export default inject('challenge', 'util')(observer(ChallengeDetail));
