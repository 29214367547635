import React, { Component } from 'react';
import styled from 'styled-components';
import { Pagination as Pagina } from 'antd';

import { format } from '../../utils/format';

export default class Pagination extends Component {
  onChange = (index, size) => {
    const { onChange } = this.props;
    if (onChange) onChange(index, size);
  };

  onShowSizeChange = (current, size) => {
    const { onChange } = this.props;
    if (onChange) onChange(1, size);
  };

  render() {
    const { style, page = {} } = this.props;
    const { offset = 1, total = 0, limit = 1 } = page;

    return (
      <Page style={style}>
        <Pagina
          showTotal={(total) => `Total ${format.toDigi(total)}`}
          showQuickJumper
          showSizeChanger={this.props.showSizeChanger}
          pageSizeOptions={this.props.pageSizeOptions}
          current={offset}
          pageSize={limit}
          total={total}
          onChange={this.onChange}
          onShowSizeChange={this.onShowSizeChange}
        />
      </Page>
    );
  }
}

const Page = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;
