import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { error } from '../utils/error';
import UserFilterUtilities from '../utils/user-filter-utilities';

export class Report extends BaseStore {
  constructor() {
    super();
    this.observable({
      userReport: {
        list: [],
        loading: false,
      },
    });
  }

  async exportUserList(params = {}) {
    const data = UserFilterUtilities.getQueryStringAsObject(params);

    const url = `${config.api.max}/v1/office/report/user`;
    this.loading = true;
    const res = await http.post(url, { json: data, token: true });
    this.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    return res.body.fileName;
  }

  async getUploadUserReportsFiles() {
    const url = `${config.api.max}/v1/office/report/user`;
    this.userReport.loading = true;
    const res = await http.get(url, { token: true });
    this.userReport.loading = false;

    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
    }
    this.userReport.list = res.body;
  }

  async downloadFile({ path, fileName }) {
    const url = `${config.api.max}/v1/office/report/download/${fileName}`;
    this.userReport.loading = true;
    const qs = { path };
    const res = await http.get(url, { token: true, qs });
    this.userReport.loading = false;
    if (res.statusCode !== 200) {
      error.launch({ message: res.body.message });
      return;
    }
    window.open(res.body.url, '_self');
  }
}

export default new Report();
