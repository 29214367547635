const config = {
  web: {
    maintenance: false,
  },
  upload: {
    url: 'https://fwdth-max2-u1.s3-ap-southeast-1.amazonaws.com',
  },
  api: {
    max: 'https://fwd.max-api.tqld.xyz/api',
  },
  limit: {
    page: 10,
    export: 1000,
    generate: 1000,
  },
};

module.exports = config;
