import React, { Component } from 'react';
import styled from 'styled-components';
import { PulseLoader } from 'halogenium';

export default class LoadingBtn extends Component {
  render() {
    const { loading } = this.props;
    return (
      <Section className={this.props.className} style={this.props.style}>
        <PulseLoader loading={loading || true} color="#444444" size="16px" margin="4px" />
      </Section>
    );
  }
}

const Section = styled.div`
  text-align: center;
  width: 100%;
`;
