import React, { Component } from 'react';
import { Col } from 'antd';

export default class Cols extends Component {
  render() {
    const { children } = this.props;

    return (
      <Col {...this.props} style={colCss}>
        {children}
      </Col>
    );
  }
}

const colCss = {
  padding: '0px 4px',
};
