import React, { Component } from 'react';

import ConfigBase from './MarkdownBase';

export default class ConfigTermType extends Component {
  render() {
    return <ConfigBase title="TERM CONFIGURATION" type="term" menus={menus} />;
  }
}

const menus = [{ name: 'Term TH', value: 'th' }, { name: 'Term ENG', value: 'eng' }];
