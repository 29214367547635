import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { chain as _chain } from 'lodash';
import { Row, Col, Form, Typography, Checkbox } from 'antd';

import moment from 'moment';
import { CheckList, DropdownList, InputInt, Input } from '../../../components/forms';

import { BadgeMenu, IsAgentMenu, IsBadgeMenu, IsTierMenu, IsWhitelistMenu } from '../../../definition/dropdown';
import CheckBox from '../../../components/forms/CheckBox';
import { SaveBtn } from '../../../components/button';
import SpecificBadgeCheckBox from '../../../components/forms/SpecificBadgeCheckBox';
import SpecificTierCheckBox from '../../../components/forms/SpecificTierCheckBox';
import ImportExportExcel from '../../../components/common/ImportExportExcel';
import { whitelistOptions, specificBadgeOptions, specificTierOptions, codeShowOptions } from '../../../config/checkbox-options';
import ShowCodeCheckBox from '../../../components/forms/ShowCodeCheckBox'
import WhitelistCheckBox from '../../../components/forms/WhitelistCheckBox'

import Colors from '../../../assets/Colors';

const { Text } = Typography;

const HEADER = ['plancode'];

const DISPLAY_PLANCODE_VALUE_LIMIT = 10;

const EXPORT_PLANCODE_FORMAT_DATE = 'YYYYMMDD';

export class WrappedGenInfo extends Component {
  componentWillReceiveProps() {
    this.forceUpdate();
  }

  onSave = async (evt) => {
    const { form, onSave } = this.props;
    try {
      evt.preventDefault();
      await form.validateFields();
      if (onSave) {
        onSave();
      }
    } catch (e) {
      console.error(e);
    }
  };

  isEnabledPlancode = (value) => value === 'true';

  handleOnIsWhitelistChange = (value) => {
    if (value === 'false') {
      this.props.form.setFieldsValue({
        whitelist_value: null,
      });
    }
  };

  handleOnIsBadgeChange = (value) => {
    if (value === 'false') {
      this.props.form.setFieldsValue({
        badge_value: null,
      });
    }
  };

  handleOnIsTierChange = (value) => {
    if (value === 'false') {
      this.props.form.setFieldsValue({
        tier_value: null,
      });
    }
  };

  handleOnIsPlancodeChange = (value) => {
    if (value === 'false') {
      this.props.form.setFieldsValue({
        plancode_value: null,
      });
    }
  };

  handleOnIsCodeShowChange(value) {
    if (value === 'false') {
      this.props.form.setFieldsValue({
        code_value: null,
      });
    }
  }

  onImportExcel = async (jsonArr) => {
    const plancodes = jsonArr.map((v) => {
      return v.plancode;
    });
    const distinctPlancodes = _chain(plancodes)
      .uniq()
      .value();
    this.props.form.setFieldsValue({
      plancode_value: distinctPlancodes.join(','),
    });
  };

  onExportExcel = async () => {
    const items = this.props.reward.detail.plancode_value.split(',');

    const output = items.map((v) => {
      return [v];
    });
    const fileName = `Plancode_Reward_${moment().format(EXPORT_PLANCODE_FORMAT_DATE)}`;
    const sheetName = 'plancode';
    return { output, fileName, sheetName };
  };

  onDownloadExample = () => {
    const output = [['p1'], ['p2']];
    const fileName = 'Example_template_plancode';
    const sheetName = 'plancode';
    return { output, fileName, sheetName };
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldsValue } = form;
    const required = true;
    const reward = this.props.reward.toJS();
    const doc = reward.detail;
    const { original } = reward;
    const readOnly = original.status !== 'draft';

    const renderWhitelistCheckBox = () => {
      const { is_agent } = getFieldsValue();
      const isWhitelistEnabled = is_agent === 'true';

      console.log(doc.whitelist_value, this.props.whitelist.whitelists.map((e) => ({ label: `${e.name}`, value: e.whitelist_id })))

      const content = (
        <Col span={10} offset={1}>
          <Form.Item label="Specific Whitelist Values">
            {getFieldDecorator('whitelist_value', {
              initialValue: doc.whitelist_value ? doc.whitelist_value.split(',') : doc.whitelist_value,
              rules: [
                {
                  required: isWhitelistEnabled,
                  message: 'Please provide specific whitelist level.',
                },
              ],
            })(<WhitelistCheckBox 
              options={this.props.whitelist.whitelists.filter(e => e.status === 'active').map((e) => ({ label: `${e.name}`, value: `${e.whitelist_id}` }))} 
            />)}
          </Form.Item>
        </Col>
      );

      return isWhitelistEnabled && content;
    };

    const renderSpecificTier = () => {
      const { is_tier } = getFieldsValue();
      const isTierEnabled = is_tier === 'true';

      const content = (
        <Col span={10} offset={1}>
          <Form.Item label="Specific Tier Values">
            {getFieldDecorator('tier_value', {
              initialValue: doc.tier_value ? doc.tier_value.split(',') : doc.tier_value,
              rules: [
                {
                  required: isTierEnabled,
                  message: 'Please provide specific tier level.',
                },
              ],
            })(<SpecificTierCheckBox options={this.props.tier.tierConfig.map(e => ({label: `Tier ${e.tier}`, value: e.tier}))} />)}
          </Form.Item>
        </Col>
      );

      return isTierEnabled && content;
    };

    const renderSpecificBadge = () => {
      const { is_badge } = getFieldsValue();
      const isBadgeEnabled = is_badge === 'true';

      const content = (
        <Col span={10} offset={1}>
          <Form.Item label="Specific Badge Values">
            {getFieldDecorator('badge_value', {
              initialValue: doc.badge_value ? doc.badge_value.split(',') : doc.badge_value,
              rules: [
                {
                  required: isBadgeEnabled,
                  message: 'Please provide specific badge level.',
                },
              ],
            })(<CheckBox options={specificBadgeOptions} />)}
          </Form.Item>
        </Col>
      );

      return isBadgeEnabled && content;
    };

    const renderPlancodeValue = () => {
      const { plancode_value } = doc;
      const { is_plancode } = getFieldsValue();
      const isEnabledPlancode = this.isEnabledPlancode(is_plancode);

      const plancodeValues = plancode_value ? plancode_value.split(',') : [];

      const total = plancodeValues.length;
      const label =
        total <= DISPLAY_PLANCODE_VALUE_LIMIT ? `Plancode (Total:${total})` : `Example plancode 10 of ${total}`;
      const displayPlancodeValues = plancodeValues.slice(0, DISPLAY_PLANCODE_VALUE_LIMIT);

      const renderPlancodeTag = () => {
        return (
          <PlanCodeTagContainer>
            {displayPlancodeValues.map((dp) => {
              return (
                <PlanCodeTagItem key={dp}>
                  <Text>{dp}</Text>
                </PlanCodeTagItem>
              );
            })}
          </PlanCodeTagContainer>
        );
      };
      const content = (
        <Row>
          <Form.Item label={label}>
            {getFieldDecorator('plancode_value', {
              initialValue: doc.plancode_value,
              rules: [
                {
                  required: isEnabledPlancode,
                  message: 'Please provide specific plancode.',
                },
              ],
            })(renderPlancodeTag())}
          </Form.Item>
        </Row>
      );
      return isEnabledPlancode && content;
    };

    const renderPlancode = () => {
      const { is_plancode } = getFieldsValue();
      const isEnabledPlancode = this.isEnabledPlancode(is_plancode);
      const content = (
        <Row>
          <ImportExportExcel
            style={{ padding: 0 }}
            headerImport={HEADER}
            headerExport={HEADER}
            handleExportExcel={this.onExportExcel}
            handleImportExcel={this.onImportExcel}
            handleDownloadExample={this.onDownloadExample}
          />
        </Row>
      );
      return isEnabledPlancode && content;
    };

    const renderShowCodeCheckBox = () => {

      console.log('doc.show_code_config', doc.show_code_config)

      const content = (
        <Col span={10}>
          <Form.Item label="Code show in App:">
            {getFieldDecorator('show_code_config', {
              initialValue: doc.show_code_config ? doc.show_code_config.split(',') : doc.show_code_config,
            })(<ShowCodeCheckBox />)}
          </Form.Item>
        </Col>
      );

      return content;
    };

    return (
      <Form>
        <Row>
          <Col span={6}>
            <Form.Item label="Reward Type">
              {getFieldDecorator('reward_cat', {
                initialValue: doc.reward_cat,
                rules: [{ required, message: 'Please choose reward type!' }],
              })(
                <DropdownList
                  menus={categoryMenu}
                  readOnly={readOnly}
                  allowClear={false}
                  onChange={this.props.onChangeRewardCat}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item label="Total Reward">
              {getFieldDecorator('max_qty', {
                initialValue: doc.max_qty,
                rules: [{ required, message: 'Please fill total reward!' }],
              })(<InputInt readOnly={readOnly} text="rewards" />)}
            </Form.Item>

            <Form.Item label="Max Reward Per Month">
              {getFieldDecorator('max_month', {
                initialValue: doc.max_month,
                rules: [{ required, message: 'Please fill max reward monthly!' }],
              })(<InputInt readOnly={readOnly} text="rewards" />)}
            </Form.Item>

            <Form.Item label="Max Reward Per Day">
              {getFieldDecorator('max_daily', {
                initialValue: doc.max_daily,
                rules: [{ required, message: 'Please fill max point daily!' }],
              })(<InputInt readOnly={readOnly} text="rewards" />)}
            </Form.Item>
          </Col>

          <Col span={14} offset={1}>
            <Row>
              <Col span={10}>
                <Form.Item label="Text Button by TH">
                  {getFieldDecorator('max_qty_error_th', {
                    initialValue: doc.max_qty_error_th,
                    // rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item label="Text Button by EN">
                  {getFieldDecorator('max_qty_error_en', {
                    initialValue: doc.max_qty_error_en,
                    // rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <Form.Item label="Text Button by TH">
                  {getFieldDecorator('max_month_error_th', {
                    initialValue: doc.max_month_error_th,
                    // rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item label="Text Button by EN">
                  {getFieldDecorator('max_month_error_en', {
                    initialValue: doc.max_month_error_en,
                    // rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <Form.Item label="Text Button by TH">
                  {getFieldDecorator('max_daily_error_th', {
                    initialValue: doc.max_daily_error_th,
                    // rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item label="Text Button by EN">
                  {getFieldDecorator('max_daily_error_en', {
                    initialValue: doc.max_daily_error_en,
                    // rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Line />

        <Row>
          <Col span={6}>
            <Form.Item label="Max Users Per Campaign">
              {getFieldDecorator('max_per_person', {
                initialValue: doc.max_per_person,
                rules: [{ required: false, message: 'Please fill max user!' }],
              })(<InputInt readOnly={readOnly} text="users" />)}
            </Form.Item>

            <Form.Item label="Max User Per Month">
              {getFieldDecorator('max_per_month_person', {
                initialValue: doc.max_per_month_person,
                rules: [{ required, message: 'Please fill max point monthly!' }],
              })(<InputInt readOnly={readOnly} text="users" />)}
            </Form.Item>

            <Form.Item label="Max User Per Day">
              {getFieldDecorator('max_per_daily_person', {
                initialValue: doc.max_per_daily_person,
                rules: [{ required, message: 'Please fill max user daily!' }],
              })(<InputInt readOnly={readOnly} text="users" />)}
            </Form.Item>
          </Col>

          <Col span={14} offset={1}>
            <Row>
              <Col span={10}>
                <Form.Item label="Text Button by TH">
                  {getFieldDecorator('max_per_person_error_th', {
                    initialValue: doc.max_per_person_error_th,
                    // rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item label="Text Button by EN">
                  {getFieldDecorator('max_per_person_error_en', {
                    initialValue: doc.max_per_person_error_en,
                    // rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <Form.Item label="Text Button by TH">
                  {getFieldDecorator('max_per_month_person_error_th', {
                    initialValue: doc.max_per_month_person_error_th,
                    // rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item label="Text Button by EN">
                  {getFieldDecorator('max_per_month_person_error_en', {
                    initialValue: doc.max_per_month_person_error_en,
                    // rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <Form.Item label="Text Button by TH">
                  {getFieldDecorator('max_per_daily_person_error_th', {
                    initialValue: doc.max_per_daily_person_error_th,
                    // rules: [{ required, message: 'Please fill text button TH!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item label="Text Button by EN">
                  {getFieldDecorator('max_per_daily_person_error_en', {
                    initialValue: doc.max_per_daily_person_error_en,
                    // rules: [{ required, message: 'Please fill text button EN!' }, { max: 40 }],
                  })(<Input text="text button" maxLength={40} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Line />

        <Row>
          <Col span={10}>
            <Form.Item label="Available for user level">
              {getFieldDecorator('user_level', {
                initialValue: `${doc.user_level}`,
                rules: [{ required, message: 'Please choose user level badge!' }],
              })(<DropdownList menus={BadgeMenu} allowClear={false} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Is Specific Badge">
              {getFieldDecorator('is_badge', {
                initialValue: doc.is_badge ? `${doc.is_badge}` : 'false',
              })(
                <DropdownList
                  readOnly={readOnly}
                  menus={IsBadgeMenu}
                  allowClear={false}
                  onChange={this.handleOnIsBadgeChange}
                />
              )}
            </Form.Item>
          </Col>
          {renderSpecificBadge()}
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Is Specific Tier">
              {getFieldDecorator('is_tier', {
                initialValue: doc.is_tier ? `${doc.is_tier}` : 'false',
              })(<DropdownList readOnly={readOnly} menus={IsTierMenu} onChange={this.handleOnIsTierChange} />)}
            </Form.Item>
          </Col>
          {renderSpecificTier()}
        </Row>
        <Line />
        <Row>
          <Col span={10}>
            <Form.Item label="Is Specific Plancode">
              {getFieldDecorator('is_plancode', {
                initialValue: doc.is_plancode ? `${doc.is_plancode}` : 'false',
              })(
                <DropdownList
                  readOnly={readOnly}
                  menus={IsBadgeMenu}
                  allowClear={false}
                  onChange={this.handleOnIsPlancodeChange}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {renderPlancode()}
          {renderPlancodeValue()}
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Whitelist">
              {getFieldDecorator('is_agent', {
                initialValue: doc.is_agent ? `${doc.is_agent}` : 'false',
              })(
                <DropdownList
                  readOnly={readOnly}
                  menus={IsWhitelistMenu}
                  allowClear={false}
                  onChange={this.handleOnIsWhitelistChange}
                />
              )}
            </Form.Item>
          </Col>
          {renderWhitelistCheckBox()}
        </Row>

        {doc.reward_cat !== 'lucky-draw' && <Row>
          { renderShowCodeCheckBox() }
        </Row>}


        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SaveBtn onClick={this.onSave} style={{ width: 200 }} />
        </div>
      </Form>
    );
  }
}

const categoryMenu = [
  { name: 'Electronic Code', value: 'electronic' },
  { name: 'Lucky draw', value: 'lucky-draw' },
  { name: 'Physical Reward', value: 'physical' },
];

const onFieldsChange = (props, changedFields, allFields) => {
  const doc = props.reward.toJS().detail;
  const keys = Object.keys(changedFields);

  for (const name of keys) {
    const item = changedFields[name];
    if (['user_level'].includes(name)) doc[name] = +item.value;
    else if (['badge_value'].includes(name)) {
      doc[name] = item.value ? item.value.join(',') : null;
    } else if (['tier_value'].includes(name)) {
      doc[name] = item.value ? item.value.join(',') : null;
    } else if (['whitelist_value'].includes(name)) {
      doc[name] = item.value ? item.value.join(',') : null;
    } else if (['show_code_config'].includes(name)) {
      doc[name] = item.value ? item.value.join(',') : null;
    } else doc[name] = item.value;
  }
  props.reward.setDoc(doc);
};

const Line = styled.div`
  height: 1px;
  background-color: rgb(238, 238, 238);
  margin: 20px 0 20px 0;
`;

const PlanCodeTagContainer = styled.div`
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
`;

const PlanCodeTagItem = styled.div`
  display: flex;
  background-color: ${Colors.orange2}
  border-radius: 5px;
  padding: 3px 10px;
  align-items: center;
  margin-right: 5px;
  margin-top: 10px;
`;

const GenInfo = Form.create({ onFieldsChange })(WrappedGenInfo);
export default inject('reward', 'whitelist', 'tier')(observer(GenInfo));
